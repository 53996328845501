/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateJobApplicationAttributes } from "graphql/types";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const CREATE_JOB_APPLICATION = gql`
  mutation ($attributes: CreateJobApplicationAttributes!) {
    createJobApplication(input: { attributes: $attributes }) {
      jobApplication {
        ...JobApplicationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
  errors: ErrorFragment[];
}

interface CreateJobApplicationMutation {
  createJobApplication: Payload | null;
}

export const useCreateJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateJobApplicationMutation,
    { attributes: CreateJobApplicationAttributes }
  >(CREATE_JOB_APPLICATION);

  const createJobApplication = (
    attributes: CreateJobApplicationAttributes,
    onSuccess?: (jobApplication: JobApplicationFragment) => void
  ) =>
    mutation({
      variables: { attributes },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createJobApplication?.errors ?? [];
        const jobApplication = data?.createJobApplication?.jobApplication;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && jobApplication && onSuccess(jobApplication),
          onRetry: () => createJobApplication(attributes, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createJobApplication,
    isLoading: loading,
    jobApplication: data?.createJobApplication?.jobApplication ?? null,
    errors: data?.createJobApplication?.errors ?? [],
  };
};
