/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  INVOICE_FRAGMENT,
  InvoiceFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const SUBMIT_INVOICE = gql`
  mutation ($id: ID!) {
    submitInvoice(input: { id: $id }) {
      invoice {
        ...InvoiceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoice?: InvoiceFragment | null;
  errors: ErrorFragment[];
}

interface SubmitInvoiceMutation {
  submitInvoice: Payload | null;
}

export const useSubmitInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.submittedInvoice",
    "Invoice was submitted successfully"
  );
  // Values for extraction
  // t("common:success.submittedInvoice", "Invoice was submitted successfully")

  const [mutation, { data, loading, called }] = useMutation<
    SubmitInvoiceMutation,
    { id: string }
  >(SUBMIT_INVOICE);

  const submitInvoice = (
    id: string,
    onSuccess?: (response: InvoiceFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitInvoice?.errors ?? [];
        const invoice = data?.submitInvoice?.invoice;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => invoice && onSuccess && onSuccess(invoice),
          onRetry: () => submitInvoice(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    submitInvoice,
    isLoading: loading,
    called,
    invoice: data?.submitInvoice?.invoice,
    errors: data?.submitInvoice?.errors ?? [],
  };
};
