import { useContext } from "react";
import {
  HiQuestionMarkCircle,
  HiOutlineChatAlt,
  HiOutlineLogin,
  HiOutlineSparkles,
  // HiOutlineViewGrid,
} from "react-icons/hi";

import tw from "tw-generated";
import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { BeaconContext } from "providers/Beacon";
import { useLocalizedRoutePath } from "utils";
import { useTranslation } from "translations";

import { MenuList } from ".";
import useSideMenuItems from "./useSideMenuItems";

export default (): JSX.Element | null => {
  const { t } = useTranslation("common");
  const getRoutePath = useLocalizedRoutePath();
  const { logout, session } = useContext(AuthContext);
  const { isMd } = useContext(TailwindContext);
  const { openChat } = useContext(BeaconContext);
  const sideMenuItems = useSideMenuItems({ session });

  if (!session) return null;

  return (
    <MenuList
      menuItems={[
        ...(isMd ? [] : [...sideMenuItems, "DIVIDER" as const]),

        // {
        //   id: "top_menu_list-go_to_workspaces" as const,
        //   Icon: HiOutlineViewGrid,
        //   label: t("layout.topMenu.link.workspaces", "Manage workspaces"),
        //   to: getRoutePath("ACCOUNT_SELECTION"),
        // },
        {
          id: "top_menu_list-go_to_benefits" as const,
          Icon: HiOutlineSparkles,
          label: t("layout.topMenu.link.benefits", "Benefits"),
          to: getRoutePath("BENEFITS"),
        },
        {
          id: "top_menu_list-go_to_help" as const,
          Icon: HiQuestionMarkCircle,
          label: t("layout.topMenu.link.helpCenter", "Help center"),
          to: t("common:links.help", "https://en.support.manymore.com"),
          isExternal: true,
          iconClassName: tw("text-blue-900"),
        },
        ...(isMd
          ? [
              {
                id: "top_menu_list-chat" as const,
                Icon: HiOutlineChatAlt,
                label: t("layout.topMenu.link.openChat", "Chat"),
                onClick: openChat,
              },
            ]
          : []),
        {
          id: "top_menu_list-log_out" as const,
          Icon: HiOutlineLogin,
          label: t("layout.topMenu.button.logOut", "Log out"),
          onClick: logout,
        },
      ]}
    />
  );
};
