import { useContext, useRef } from "react";
import {
  HiMenu,
  HiOutlineLogout,
  HiQuestionMarkCircle,
  HiX,
} from "react-icons/hi";

import tw from "tw-generated";
import { useLocalizedRoutePath } from "utils";
import logo from "assets/branding/logo.svg";
import { Button, Link } from "components/common/basic";
import { Trans, useTranslation } from "translations";
import { TailwindContext } from "providers/Tailwind";
import { useMenu } from "hooks";
import { MenuList } from "./common";

export default (): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isShowingMenu, setIsShowingMenu] = useMenu(menuRef);
  const toggleMenu = () => setIsShowingMenu(!isShowingMenu);

  const getRoutePath = useLocalizedRoutePath();
  const { t } = useTranslation("common");
  const { isMd } = useContext(TailwindContext);

  const linkBase = tw("px-1", "h-full", "inline-flex", "items-center");
  const linkStyles = tw(linkBase, "text-gray-500", "hover:text-gray-700");

  const fullscreenMenuStyles = tw(
    "absolute",
    "top-full",
    "left-0",
    "border-t-2",
    "border-silver",
    "bg-white",
    "h-screen",
    "w-screen"
  );

  return (
    <header
      className={tw("sticky", "top-0", "z-40", "shadow")}
      style={{ backgroundColor: "#E4E8ED" }}
    >
      <div
        className={tw(
          "mx-auto",
          "flex",
          "items-center",
          "justify-between",
          "py-4",
          "pl-7",
          "pr-6"
        )}
        style={{ maxWidth: "940px" }}
      >
        <div className={tw("flex", "items-center", "space-x-5")}>
          <Link
            id="marketing_header-go_to_homepage"
            to={t("links.homepage", "https://manymore.com/en")}
            isExternal
            unstyled
            className={linkStyles}
          >
            <img
              className={tw("h-8", "w-auto")}
              src={logo}
              alt="Manymore logo"
            />
          </Link>

          {isMd && (
            <>
              <Link
                id="marketing_header-go_to_help"
                to={t("common:links.help", "https://en.support.manymore.com")}
                isExternal
                unstyled
                className={linkStyles}
              >
                <Trans ns="common" i18nKey="header_marketing.link.help">
                  Help center
                </Trans>
              </Link>
            </>
          )}
        </div>

        <div className={tw("flex", "items-center", "space-x-4")}>
          <Link
            id="marketing_header-log_in"
            to={getRoutePath("LOGIN")}
            unstyled
          >
            <Button id="marketing_header-log_in" size="md" variant="secondary">
              <Trans ns="common" i18nKey="header_marketing.link.login">
                Log in
              </Trans>
            </Button>
          </Link>

          {isMd ? (
            <Link
              id="marketing_header-sign_up"
              to={getRoutePath("SIGNUP")}
              unstyled
            >
              <Button id="marketing_header-sign_up" size="md" variant="primary">
                <Trans ns="common" i18nKey="header_marketing.link.signup">
                  Sign up
                </Trans>
              </Button>
            </Link>
          ) : (
            <div ref={menuRef}>
              <button
                aria-label="menu"
                aria-expanded={isShowingMenu}
                onClick={toggleMenu}
                className={tw("block")}
              >
                {isShowingMenu ? (
                  <HiX size="28px" className={tw("text-gray-900")} />
                ) : (
                  <HiMenu size="28px" className={tw("text-gray-900")} />
                )}
              </button>

              {isShowingMenu && (
                <nav className={fullscreenMenuStyles}>
                  <MenuList
                    menuItems={[
                      {
                        id: "marketing_header-go_to_help",
                        Icon: HiQuestionMarkCircle,
                        label: t(
                          "header_marketing.link.helpCenter",
                          "Help center"
                        ),
                        to: t(
                          "common:links.help",
                          "https://en.support.manymore.com"
                        ),
                        isExternal: true,
                        iconClassName: tw("text-blue-900"),
                      },
                      {
                        id: "marketing_header-go_to_signup",
                        Icon: HiOutlineLogout,
                        label: t("header_marketing.link.signup", "Sign up"),
                        to: getRoutePath("SIGNUP"),
                      },
                    ]}
                  />
                </nav>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
