/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { FREELANCE_PROFILES, FreelanceProfilesQuery } from "graphql/queries";

const ARCHIVE_FREELANCE_PROFILE = gql`
  mutation ($id: ID!) {
    archiveFreelanceProfile(input: { id: $id }) {
      freelanceProfile {
        ...FreelanceProfileFragment
      }
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
`;

interface Payload {
  freelanceProfile: FreelanceProfileFragment;
}

interface ArchiveFreelanceProfileMutation {
  archiveFreelanceProfile: Payload | null;
}

export const useArchiveFreelanceProfileMutation = () => {
  const [mutation, { data, loading }] = useMutation<
    ArchiveFreelanceProfileMutation,
    { id: string }
  >(ARCHIVE_FREELANCE_PROFILE);

  const archiveFreelanceProfile = (id: string) =>
    mutation({
      variables: { id },
      update(cache, { data: archivedProfileData }) {
        cache.modify({
          fields: {
            freelanceProfiles() {
              const archivedProfile =
                archivedProfileData?.archiveFreelanceProfile?.freelanceProfile;
              if (!archivedProfile) return;

              const existingFreelanceProfiles =
                cache.readQuery<FreelanceProfilesQuery>({
                  query: FREELANCE_PROFILES,
                });
              const profiles =
                existingFreelanceProfiles?.freelanceProfiles.items;
              if (!profiles) return;

              const isArchivedProfiles = Boolean(profiles[0].archivedAt);

              const items = isArchivedProfiles
                ? [...profiles, archivedProfile]
                : profiles.filter((profile) => profile?.id !== id);
              const total = isArchivedProfiles
                ? existingFreelanceProfiles.freelanceProfiles.total + 1
                : existingFreelanceProfiles.freelanceProfiles.total - 1;

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: { freelanceProfiles: { items, total } },
              });
            },
          },
        });
      },
    }).catch((_error: ApolloError) => null);

  return {
    archiveFreelanceProfile,
    isLoading: loading,
    freelanceProfile: data?.archiveFreelanceProfile?.freelanceProfile,
  };
};
