import { Component, ErrorInfo } from "react";
import Rollbar from "rollbar";

import { ErrorLoggerContext } from "providers/ErrorLogger";
import ErrorPage from "components/common/pages/ErrorPage";

interface Props {
  children?: React.ReactNode;
}

interface State {
  error: Error | null;
}

export default class ErrorBoundaryBase extends Component<Props, State> {
  static contextType = ErrorLoggerContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, _info: ErrorInfo) {
    const { logger } = this.context as { logger?: Rollbar };

    if (logger) logger.error(error.message);
  }

  render() {
    if (this.state.error) return <ErrorPage />;

    return this.props.children;
  }
}
