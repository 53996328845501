import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import tw from "tw-generated";
import { useTranslation } from "translations";
import { Heading } from "components/account/composite";
import { useLocalizedRoutePath } from "utils";
import { useFreelanceProfilesQuery } from "graphql/queries";

import PayoutProfilesList from "./PayoutProfilesList";
import EmptyState from "./EmptyState";

export default (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const getRoutePath = useLocalizedRoutePath();
  const { t } = useTranslation("account");

  const activeProfilesPayload = useFreelanceProfilesQuery({
    page: 1,
    perPage: 1,
    filter: { archived: false },
  });
  const hasActive = activeProfilesPayload.freelanceProfiles.length > 0;

  const archivedProfilesPayload = useFreelanceProfilesQuery({
    page: 1,
    perPage: 1,
    filter: { archived: true },
  });
  const hasArchived = archivedProfilesPayload.freelanceProfiles.length > 0;

  return (
    <>
      <Heading
        header={t("payoutProfiles.header", "My Payout Settings")}
        action={{
          type: "link",
          actionID: "payout_profiles_header-add_profile",
          to: getRoutePath("FREELANCE_PROFILE_WIZARD"),
        }}
        actionLabel={t("payoutProfiles.action.newProfile", "Add payout method")}
      />

      <div className={tw("my-6", "space-y-6")}>
        {!hasActive && !hasArchived && <EmptyState />}

        {hasActive && <PayoutProfilesList filter={{ archived: false }} />}

        {hasArchived && (
          <div className={tw("rounded-lg", "shadow-md", "p-4", "space-y-4")}>
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={tw(
                "w-full",
                "flex",
                "justify-between",
                "items-center"
              )}
            >
              <h3 className={tw("text-lg", "font-bold")}>
                {t(
                  "payoutProfiles.archivedProfiles.heading",
                  "Archived payout methods"
                )}
              </h3>

              {isCollapsed ? (
                <HiChevronDown size={24} />
              ) : (
                <HiChevronUp size={24} />
              )}
            </button>

            {!isCollapsed && <PayoutProfilesList filter={{ archived: true }} />}
          </div>
        )}
      </div>
    </>
  );
};
