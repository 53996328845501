/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { gql, useLazyQuery } from "@apollo/client";
import { QueryNodeArgs } from "graphql/types";
import { useRef, useEffect } from "react";
import {
  OCCUPATION_CODE_FRAGMENT,
  OccupationCodeFragment,
} from "graphql/fragments";

const OCCUPATION_CODE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on OccupationCode {
        ...OccupationCodeFragment
      }
    }
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export const useOccupationCodeLazyQuery = () => {
  const [getOccupationCodeQuery, { data, loading, called }] = useLazyQuery<
    { node: OccupationCodeFragment },
    QueryNodeArgs
  >(OCCUPATION_CODE);

  const resolveRef =
    useRef<(occupationCode?: OccupationCodeFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getOccupationCode = async (id: string) => {
    getOccupationCodeQuery({ variables: { id } });

    return new Promise<OccupationCodeFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getOccupationCode,
    occupationCode: data?.node,
    isLoading: loading,
  };
};
