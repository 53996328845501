import React from "react";

import { tw } from "tw-generated";

export interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  id: string;
  name: string;
  label: string | JSX.Element;
  hint?: string;
  helpText?: string;
  errorMessage?: string;
  hideLabel?: boolean;
}

export default React.forwardRef(
  (
    {
      id,
      name,
      label,
      hint,
      helpText,
      errorMessage,
      hideLabel = false,
      ...props
    }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText && `${id}-help`;
    const errorId = hasError && `${id}-error`;
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

    const labelContainerStyles = tw("flex", "justify-between");

    const labelStyles = tw("block", "text-sm", "text-gray-700", {
      "sr-only": hideLabel,
    });

    const hintStyles = tw("text-sm", "text-gray-500");

    const inputBase = tw(
      "mt-1",
      "w-full",
      "rounded-md",
      "shadow-sm",
      "shadow-sm",
      "sm:text-sm",
      "rounded-md",
      "focus:ring-gray-900",
      "focus:border-gray-900",
      "border-gray-300"
    );
    const inputError = tw("border-2", "border-error", "bg-error-light");
    const inputDisabled = tw("bg-gray-100", "text-gray-700");
    const inputStyles = tw(inputBase, {
      [inputError]: hasError,
      [inputDisabled]: props.disabled || props.readOnly,
    });

    const textBase = tw("mt-2", "text-sm");
    const helpTextStyles = tw(textBase, "text-gray-700");
    const errorMessageStyles = tw(textBase, "text-error");

    return (
      <div>
        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {hint && <span className={hintStyles}>{hint}</span>}
        </div>

        <textarea
          {...props}
          id={id}
          ref={ref}
          name={name}
          className={inputStyles}
          aria-invalid={hasError}
          aria-describedby={inputDescriptionIDs}
        />

        {helpTextId && (
          <p className={helpTextStyles} id={helpTextId}>
            {helpText}
          </p>
        )}

        {errorId && (
          <p className={errorMessageStyles} id={errorId}>
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
