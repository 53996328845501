/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentGroupQuery, DOCUMENT_GROUP } from "graphql/queries";
import { Base64AttachmentAttributes } from "graphql/types";

const UPLOAD_DOCUMENT_RECIPIENTS = gql`
  mutation ($id: ID!, $csv: Base64AttachmentAttributes!) {
    uploadDocumentRecipients(input: { documentVersionId: $id, csv: $csv }) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface UploadDocumentRecipientsMutation {
  uploadDocumentRecipients: Payload | null;
}

export const useUploadDocumentRecipientsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UploadDocumentRecipientsMutation,
    { id: string; csv: Base64AttachmentAttributes }
  >(UPLOAD_DOCUMENT_RECIPIENTS);

  const uploadDocumentRecipients = (
    variables: { id: string; csv: Base64AttachmentAttributes },
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({
      variables,
      update(cache, { data: newDocumentRecipientsData }) {
        const newDocumentRecipients =
          newDocumentRecipientsData?.uploadDocumentRecipients?.documentVersion
            ?.documentRecipients;
        if (!newDocumentRecipients) return;

        cache.modify({
          fields: {
            node() {
              const documentGroup = cache.readQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
              });
              if (!documentGroup) return;

              const documentVersions = documentGroup.node.documentVersions.map(
                (version) => {
                  if (version.id === variables.id) {
                    const documentRecipients = [
                      ...version.documentRecipients,
                      ...newDocumentRecipients,
                    ];

                    return { ...version, documentRecipients };
                  }

                  return version;
                }
              );

              cache.writeQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
                data: {
                  node: {
                    ...documentGroup.node,
                    documentVersions,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.uploadDocumentRecipients?.errors ?? [];
        const documentVersion = data?.uploadDocumentRecipients?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    uploadDocumentRecipients,
    isLoading: loading,
    documentVersion: data?.uploadDocumentRecipients?.documentVersion,
    errors: data?.uploadDocumentRecipients?.errors ?? [],
  };
};
