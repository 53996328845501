/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryDocumentsArgs } from "graphql/types";

import { DOCUMENT_FRAGMENT, DocumentFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const ACCOUNT_DOCUMENTS = gql`
  query ($filter: DocumentsFilter, $page: Int, $perPage: Int) {
    accountDocuments(filter: $filter, page: $page, perPage: $perPage) {
      items {
        ...DocumentFragment
      }
      total
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export type AccountDocumentsQuery = {
  accountDocuments: PaginatedPayload<DocumentFragment>;
};

type QueryArgs = Pick<QueryDocumentsArgs, "page" | "perPage" | "filter">;

export const useAccountDocumentsLazyQuery = () => {
  const [getDocumentsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<AccountDocumentsQuery, QueryArgs>(ACCOUNT_DOCUMENTS, {
      fetchPolicy: "network-only",
    });

  const documents = data?.accountDocuments.items?.filter(Boolean) ?? [];
  const total = data?.accountDocuments.total ?? 0;

  const resolveRef = useRef<(documents?: DocumentFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(documents);
      resolveRef.current = undefined;
    }
  }, [documents, called, isLoading]);

  const getDocuments = async (args?: QueryArgs) => {
    const variables = { ...args };

    getDocumentsQuery({ variables });

    return new Promise<DocumentFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getDocuments, documents, total, isLoading, called };
};
