import { useContext, useEffect, useState } from "react";
import moment from "moment";
import tw from "tw-generated";
import { TailwindContext } from "providers/Tailwind";
import { HiChevronDown } from "react-icons/hi";
import { useTranslation } from "translations";
import DatePickerCalendar from "./DatePickerCalendar";
import "./DatePickerCustomStyles.css";
import DatePickerButton from "./DatePickerButton";

interface Props {
  label: string;
  setDateFilters: (from: string, to: string) => void;
  from?: string;
  to?: string;
}

export default ({ label, from, to, setDateFilters }: Props): JSX.Element => {
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const { isXl } = useContext(TailwindContext);
  const { t } = useTranslation("common");

  const submitDateFilter = () => {
    if (!startDate || !endDate) return;

    setDateFilters(startDate, endDate);
    setOpenDatePicker(false);
  };

  const setDatesFromProps = () => {
    setStartDate(from);
    setEndDate(to);

    setOpenDatePicker(false);
  };

  const onCancel = () => {
    setDatesFromProps();
    setOpenDatePicker(false);
  };

  useEffect(setDatesFromProps, [from, to]);

  const getButtonLabel = (start?: string, end?: string) => {
    if (!start) return t("filterDateRange.placeholder", "Choose date range");

    const from = moment(start).format("DD/MM/YYYY");
    const to = end
      ? moment(end).format("DD/MM/YYYY")
      : t("filterDateRange.dateToPlaceholder", "dd/mm/yyyy");

    return `${from} - ${to}`;
  };

  return (
    <div className={tw("flex", "flex-col", "lg:w-60")}>
      <span className={tw("block", "text-sm", "text-gray-700", "mb-1")}>
        {label}
      </span>

      <DatePickerButton
        id="dateFilter_button"
        name="dateFilter_button"
        TrailingIcon={HiChevronDown}
        onClick={() => setOpenDatePicker(!openDatePicker)}
      >
        {getButtonLabel(startDate, endDate)}
      </DatePickerButton>

      {openDatePicker && (
        <DatePickerCalendar
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          monthsShown={isXl ? 2 : 1}
          onCancel={onCancel}
          setDateRange={([start, end]) => {
            setStartDate(start ? start.toDateString() : undefined);
            setEndDate(end ? end.toDateString() : undefined);
          }}
          submitDateFilter={submitDateFilter}
        />
      )}
    </div>
  );
};
