import { IconType } from "react-icons";

import { tw } from "tw-generated";

import { ActionID } from "./types";

type ButtonSize = "sm" | "md" | "lg";
type ButtonVariant = "primary" | "secondary" | "tertiary" | "danger";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  id: ActionID;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  LeadingIcon?: IconType;
  TrailingIcon?: IconType;
  isDiv?: boolean;
}

export default ({
  type = "button",
  variant = "primary",
  size = "lg",
  fullWidth,
  LeadingIcon,
  TrailingIcon,
  isDiv,
  children,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element => {
  const base = tw(
    "inline-flex",
    "items-center",
    "justify-center",
    "font-semibold",
    "border",
    "rounded",
    "shadow-sm",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900"
  );

  const small = tw("px-2.5", "py-1.5", "space-x-2", "text-xs");
  const medium = tw("px-4", "py-2", "space-x-2", "text-sm");
  const large = tw("px-6", "py-3", "space-x-2", "text-base");

  const primary = props.disabled
    ? tw("bg-gray-100", "border-transparent", "text-gray-500", "cursor-default")
    : tw(
        "bg-primary",
        "border-transparent",
        "text-primary-contrast",
        "hover:bg-primary-hover"
      );
  const secondary = props.disabled
    ? tw("border-gray-100", "text-gray-300", "cursor-default")
    : tw(
        "bg-white",
        "border-primary",
        "text-primary-contrast",
        "hover:bg-primary"
      );
  const tertiary = props.disabled
    ? tw("border-gray-100", "text-gray-300", "cursor-default")
    : tw("bg-white", "border-gray-300", "text-gray-700", "hover:bg-gray-100");
  const danger = props.disabled
    ? tw("bg-white", "border-gray-100", "text-gray-300", "cursor-default")
    : tw("bg-white", "border-error", "text-error", "hover:bg-error-light");

  const buttonStyles = tw(base, {
    [small]: size === "sm",
    [medium]: size === "md",
    [large]: size === "lg",

    [primary]: variant === "primary",
    [secondary]: variant === "secondary",
    [tertiary]: variant === "tertiary",
    [danger]: variant === "danger",

    "w-full": fullWidth,
  });

  const tabIndex =
    props.tabIndex ?? (!props.onClick && type === "button" ? -1 : undefined);

  if (isDiv)
    return (
      <div
        {...(props as React.ButtonHTMLAttributes<HTMLDivElement>)}
        className={buttonStyles}
        tabIndex={tabIndex}
      >
        {LeadingIcon && <LeadingIcon size={16} />}

        <span>{children}</span>

        {TrailingIcon && <TrailingIcon size={16} />}
      </div>
    );

  return (
    <button {...props} type={type} className={buttonStyles} tabIndex={tabIndex}>
      {LeadingIcon && <LeadingIcon size={16} />}

      <span>{children}</span>

      {TrailingIcon && <TrailingIcon size={16} />}
    </button>
  );
};
