import { useContext, useState } from "react";

import tw from "tw-generated";
import mmFirma from "assets/branding/mmFirma.svg";
import { PartialBackground } from "components/common/wrappers";
import { CompanyFragment, FreelanceProfileFragment } from "graphql/fragments";
import { Trans, useTranslation } from "translations";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Link,
} from "components/common/basic";
import { BrregSearch } from "components/common/composite";
import { useLocalizedRoutePath, getNameFromSession } from "utils";
import { AuthContext } from "providers/Authentication";
import { FreelanceTypeEnum } from "graphql/types";

import CreateOrgProfileForm from "./CreateOrgProfileForm";
import Agreement from "./Agreement";

interface Props {
  onSuccessfulEdit: (profile: FreelanceProfileFragment) => void;
  freelanceProfiles: FreelanceProfileFragment[];
  profile?: FreelanceProfileFragment;
}

export default ({
  onSuccessfulEdit,
  freelanceProfiles,
  profile,
}: Props): JSX.Element => {
  const [hasCheckedDocument, setHasCheckedDocument] = useState(
    Boolean(profile?.id)
  );
  const [freelanceProfile, setFreelanceProfile] = useState<
    FreelanceProfileFragment | undefined
  >(profile);

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");
  const getRoutePath = useLocalizedRoutePath();

  const formId = "organizationFreelanceProfileForm";

  const setBrregOrganization = ({
    address,
    name: organizationName,
    orgNumber: organizationNumber,
  }: CompanyFragment) => {
    const existingFreelanceProfile = freelanceProfiles.find(
      (profile) => profile.organizationNumber === organizationNumber
    );

    if (existingFreelanceProfile) {
      setFreelanceProfile(existingFreelanceProfile);
    } else {
      const referencePerson = session && {
        email: session.user.email,
        name: getNameFromSession(session),
      };

      const newFreelanceProfile: FreelanceProfileFragment = {
        id: "",
        freelanceType: FreelanceTypeEnum.Organization,
        address: address ?? undefined,
        organizationName,
        organizationNumber,
        referencePeople: referencePerson ? [referencePerson] : [],
        jobChecks: [],
        bankAccounts: [],
        verified: true,
      };

      setFreelanceProfile(newFreelanceProfile);
    }
  };

  const setUnverifiedOrganization = () => {
    const referencePerson = session && {
      email: session.user.email,
      name: getNameFromSession(session),
    };

    const newFreelanceProfile: FreelanceProfileFragment = {
      id: "",
      freelanceType: FreelanceTypeEnum.Organization,
      referencePeople: referencePerson ? [referencePerson] : [],
      jobChecks: [],
      bankAccounts: [],
      verified: false,
    };

    setFreelanceProfile(newFreelanceProfile);
  };

  return (
    <PartialBackground
      heading={
        <>
          <div
            className={tw(
              "flex",
              "space-y-4",
              "flex-col",
              "sm:space-y-0",
              "sm:flex-row",
              "sm:justify-between"
            )}
          >
            <h1 className={tw("text-2xl", "font-extrabold")}>
              <Trans
                ns="account"
                i18nKey="freelanceProfileWizard.freelanceProfileCreation.organization.heading"
              >
                Set up your ENK / AS freelance profile
              </Trans>
            </h1>

            <Link
              id="create_org_profile-go_to_help"
              to={t(
                "freelanceProfileWizard.freelanceProfileCreation.articles.link",
                "https://en.support.manymore.com"
              )}
              isExternal
              unstyled
            >
              <Button
                id="create_org_profile-go_to_help"
                size="md"
                variant="tertiary"
              >
                <Trans
                  ns="account"
                  i18nKey="freelanceProfileWizard.freelanceProfileCreation.articles.label"
                >
                  Help articles
                </Trans>
              </Button>
            </Link>
          </div>

          <div className={tw("mt-5", "mb-12")}>
            <Divider />
          </div>
        </>
      }
      content={
        <div className={tw("mx-auto", "max-w-screen-sm")}>
          <Card
            header={
              <CardHeader className={tw("bg-deepBlue-900")}>
                <img className={tw("h-9")} src={mmFirma} alt="Manymore firma" />
              </CardHeader>
            }
            footer={
              freelanceProfile ? (
                <CardFooter
                  className={tw(
                    "flex",
                    "justify-end",
                    "bg-deepBlue-50",
                    "space-x-3"
                  )}
                >
                  <Link
                    id="create_org_profile-cancel"
                    unstyled
                    to={getRoutePath("PAYOUT_PROFILES")}
                  >
                    <Button
                      id="create_org_profile-cancel"
                      variant="tertiary"
                      size="sm"
                    >
                      <Trans
                        ns="account"
                        i18nKey="freelanceProfileWizard.freelanceProfileCreation.organization.cancel"
                      >
                        Cancel
                      </Trans>
                    </Button>
                  </Link>

                  <Button
                    id="create_org_profile-sign_and_save"
                    type="submit"
                    form={formId}
                    variant="primary"
                    size="sm"
                    disabled={!hasCheckedDocument}
                  >
                    <Trans
                      ns="account"
                      i18nKey="freelanceProfileWizard.freelanceProfileCreation.organization.submit"
                    >
                      Sign with eSign and save
                    </Trans>
                  </Button>
                </CardFooter>
              ) : undefined
            }
          >
            {!freelanceProfile ? (
              <CardBody>
                <div className={tw("w-full", "flex", "flex-col")}>
                  <h2 className={tw("my-4", "text-lg", "font-bold")}>
                    <Trans
                      ns="account"
                      i18nKey="freelanceProfileWizard.freelanceProfileCreation.organization.brregSearch.label"
                    >
                      Find your ENK / AS on Brreg
                    </Trans>
                  </h2>

                  <BrregSearch onSelect={setBrregOrganization} />
                </div>

                <Divider />

                <div className={tw("w-full", "flex", "flex-col", "space-y-2")}>
                  <h3 className={tw("text-sm", "font-bold")}>
                    {t(
                      "freelanceProfileWizard.freelanceProfileCreation.organization.international.heading",
                      "Is your organization not registered in Norways business register (brreg.no)?"
                    )}
                  </h3>

                  <p className={tw("text-xs", "font-semibold")}>
                    {t(
                      "freelanceProfileWizard.freelanceProfileCreation.organization.international.notice",
                      "This organization will not be automatically verified, please check your details carefully."
                    )}
                  </p>

                  <Button
                    id="create_org_profile-create_profile_for_org_outside_norway"
                    size="sm"
                    variant="tertiary"
                    onClick={() => setUnverifiedOrganization()}
                  >
                    {t(
                      "freelanceProfileWizard.freelanceProfileCreation.organization.international.button.label",
                      "Click here to create a freelance profile for an organization outside of Norway."
                    )}
                  </Button>
                </div>
              </CardBody>
            ) : (
              <>
                <CreateOrgProfileForm
                  onSuccessfulEdit={onSuccessfulEdit}
                  freelanceProfile={freelanceProfile}
                  formId={formId}
                />

                <Agreement
                  onChecked={() => setHasCheckedDocument(true)}
                  isOrganization={true}
                  isChecked={hasCheckedDocument}
                />
              </>
            )}
          </Card>
        </div>
      }
    />
  );
};
