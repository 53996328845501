/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentVersionAttributes } from "graphql/types";

const UPDATE_DOCUMENT_GROUP = gql`
  mutation ($id: ID!, $attributes: DocumentVersionAttributes!) {
    updateDocumentVersion(input: { attributes: $attributes, id: $id }) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface UpdateDocumentVersionMutation {
  updateDocumentVersion: Payload | null;
}

export const useUpdateDocumentVersionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateDocumentVersionMutation,
    { id: string; attributes: DocumentVersionAttributes }
  >(UPDATE_DOCUMENT_GROUP);

  const updateDocumentVersion = (
    variables: { id: string; attributes: DocumentVersionAttributes },
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateDocumentVersion?.errors ?? [];
        const documentVersion = data?.updateDocumentVersion?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateDocumentVersion,
    isLoading: loading,
    documentVersion: data?.updateDocumentVersion?.documentVersion,
    errors: data?.updateDocumentVersion?.errors ?? [],
  };
};
