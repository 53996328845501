import { TBackgroundColor, TTailwindString, tw } from "tw-generated";

interface Props {
  header?: JSX.Element;
  footer?: JSX.Element;
  className?: TTailwindString;
  bgColor?: TBackgroundColor;
  isFlat?: boolean;
}

export default ({
  header,
  footer,
  className,
  bgColor,
  isFlat = false,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const hasHeaderOrFooter = Boolean(header || footer);
  const outerStyles = tw(
    className,
    bgColor ?? "bg-white",
    isFlat ? null : "shadow-md",
    "rounded-lg",
    "flex",
    "flex-col"
  );

  const innerStyles = tw("flex-1", "flex", "flex-col", "space-y-6", {
    "py-8": !hasHeaderOrFooter,
    "py-4": hasHeaderOrFooter,
  });

  return (
    <div className={outerStyles}>
      {header}

      <div className={innerStyles}>{children}</div>

      {footer}
    </div>
  );
};
