/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryOccupationCodesArgs } from "graphql/types";

import {
  OCCUPATION_CODE_FRAGMENT,
  OccupationCodeFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const OCCUPATION_CODES = gql`
  query ($page: Int, $perPage: Int, $keyword: String) {
    occupationCodes(page: $page, perPage: $perPage, keyword: $keyword) {
      items {
        ...OccupationCodeFragment
      }
      total
    }
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export interface OccupationCodesQuery {
  occupationCodes: PaginatedPayload<OccupationCodeFragment>;
}

type QueryArgs = Pick<QueryOccupationCodesArgs, "page" | "perPage" | "keyword">;

export const useOccupationCodesLazyQuery = () => {
  const [getOccupationCodesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<OccupationCodesQuery, QueryArgs>(OCCUPATION_CODES);

  const occupationCodes = data?.occupationCodes.items?.filter(Boolean) ?? [];
  const total = data?.occupationCodes.total ?? 0;

  const resolveRef =
    useRef<(occupationCodes?: OccupationCodeFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(occupationCodes);
      resolveRef.current = undefined;
    }
  }, [occupationCodes, called, isLoading]);

  const getOccupationCodes = async (variables?: QueryArgs) => {
    getOccupationCodesQuery({ variables });

    return new Promise<OccupationCodeFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getOccupationCodes,
    occupationCodes,
    total,
    isLoading,
  };
};
