/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryUserInvoiceImportsArgs } from "graphql/types";

import {
  USER_INVOICE_IMPORT_FRAGMENT,
  UserInvoiceImportFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const USER_INVOICE_IMPORTS = gql`
  query ($page: Int, $perPage: Int, $filter: UserInvoiceImportsFilter) {
    userInvoiceImports(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...UserInvoiceImportFragment
      }
      total
    }
  }
  ${USER_INVOICE_IMPORT_FRAGMENT}
`;

interface UserInvoiceImportsQuery {
  userInvoiceImports: PaginatedPayload<UserInvoiceImportFragment>;
}

type QueryArgs = Pick<
  QueryUserInvoiceImportsArgs,
  "page" | "perPage" | "filter"
>;

export const useUserInvoiceImportsLazyQuery = () => {
  const [getUserInvoiceImportsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<UserInvoiceImportsQuery, QueryArgs>(USER_INVOICE_IMPORTS, {
      fetchPolicy: "network-only",
    });

  const userInvoiceImports =
    data?.userInvoiceImports.items?.filter(Boolean) ?? [];

  const resolveRef =
    useRef<(userInvoiceImports?: UserInvoiceImportFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(userInvoiceImports);
      resolveRef.current = undefined;
    }
  }, [userInvoiceImports, called, isLoading]);

  const getUserInvoiceImports = async (args?: QueryArgs) => {
    const variables = { ...args };

    getUserInvoiceImportsQuery({ variables });

    return new Promise<UserInvoiceImportFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getUserInvoiceImports, userInvoiceImports, isLoading, called };
};
