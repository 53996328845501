import React, { useContext, useEffect, useState } from "react";
import { UserFragment } from "graphql/fragments";

import { AuthContext } from "./Authentication";
import { IntlContext } from "./i18n";
import { LanguageEnum } from "graphql/types";

interface Context {
  loadBeacon: () => void;
  openChat: () => void;
}

export const BeaconContext = React.createContext<Context>({
  loadBeacon: () => null,
  openChat: () => null,
});

interface Props {
  children?: React.ReactNode;
}

const domain = "https://beacon-v2.helpscout.net";

export const BeaconProvider = ({ children }: Props): JSX.Element => {
  const [beaconInitialized, setBeaconInitialized] = useState(false);
  const [providerKey, setProviderKey] = useState<string>();

  const { session } = useContext(AuthContext);
  const { currentLocale } = useContext(IntlContext);

  /* eslint-disable */
  const loadBeacon = () => {
    (function (e, t) {
      function addScript() {
        const firstScriptTag = document.getElementsByTagName("script")[0];
        const beaconScript = document.createElement("script");

        beaconScript.async = false;
        beaconScript.src = domain;
        firstScriptTag.parentNode?.insertBefore(beaconScript, firstScriptTag);
      }

      if (
        ((window.Beacon = function (method: any, options: any, data: any) {
          (e.Beacon as any).readyQueue.push({ method, options, data });
        }),
        ((window.Beacon as any).readyQueue = []),
        t.readyState === "complete")
      )
        return addScript();

      e.addEventListener("load", addScript, false);
    })(window, document);
  };
  /* eslint-enable */

  useEffect(() => {
    if (!window.Beacon || !providerKey) return;

    if (beaconInitialized) window.Beacon("destroy");

    window.Beacon("init", providerKey);
    window.Beacon("config", { hideFABOnMobile: true });
    setBeaconInitialized(true);

    if (session?.user) identify(session.user);
  }, [!!window.Beacon, providerKey]);

  useEffect(() => {
    if (beaconInitialized) window.Beacon?.("logout");

    if (session?.user) identify(session.user);
  }, [session?.user.email]);

  useEffect(() => {
    const enKey = process.env.REACT_APP_BEACON_PROVIDER_KEY_EN;
    const nbKey = process.env.REACT_APP_BEACON_PROVIDER_KEY_NB;
    const defaultKey = enKey;

    switch (currentLocale) {
      case LanguageEnum.En:
        setProviderKey(enKey);
        break;

      case LanguageEnum.Nb:
        setProviderKey(nbKey);
        break;

      default:
        ((_locale: never) => setProviderKey(defaultKey))(currentLocale);
    }
  }, [currentLocale]);

  const openChat = () => beaconInitialized && window.Beacon?.("open");

  const identify = ({ email, occupation, id }: UserFragment) =>
    window.Beacon?.("identify", { userId: id, email, occupation });

  return (
    <BeaconContext.Provider value={{ loadBeacon, openChat }}>
      {children}
    </BeaconContext.Provider>
  );
};
