import React from "react";

import { tw } from "tw-generated";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  label: string;
}

export default React.forwardRef(
  (
    { id, name, label, ...props }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const radioStyles = tw("flex", "items-center");

    const inputBase = tw(
      "focus:ring-gray-900",
      "h-4",
      "w-4",
      "border-gray-300"
    );
    const inputDefault = tw("text-gray-900", "hover:text-gray-700");
    const inputDisabled = tw("bg-gray-100", "text-gray-100");
    const inputStyles = tw(inputBase, {
      [inputDefault]: !props.disabled,
      [inputDisabled]: props.disabled,
    });

    const labelStyles = tw("ml-3", "block", "text-sm", "text-gray-700");

    return (
      <div className={radioStyles}>
        <input
          {...props}
          id={id}
          ref={ref}
          type="radio"
          className={inputStyles}
        />
        <label htmlFor={id} className={labelStyles}>
          {label}
        </label>
      </div>
    );
  }
);
