import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Button,
  Divider,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { PersonFragment } from "graphql/fragments";
import { useErrorLogger } from "hooks";
import { emailValidationSchema } from "utils";
import { useCreatePersonMutation } from "graphql/mutations";

interface Props {
  onClose: () => void;
  onSuccess?: (person: PersonFragment) => void;
}

export default ({ onSuccess, onClose }: Props): JSX.Element => {
  const { createPerson } = useCreatePersonMutation();

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    email: emailValidationSchema(t),
    name: Yup.string().trim(),
    phone: Yup.string().trim(),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<PersonFragment>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);

  const onSubmit = (person: PersonFragment) =>
    person.email &&
    createPerson(
      {
        attributes: {
          ...person,
          email: person.email,
        },
      },
      (person) => {
        if (onSuccess && person) onSuccess(person);

        onClose();
      }
    );

  const formId = "addPersonForm";

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="addPersonForm_create.title">
                  Add Person to list
                </Trans>
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="add_person-cancel"
              onClick={onClose}
              variant="tertiary"
              size="sm"
            >
              <Trans ns="common" i18nKey="addPersonForm.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="add_person-add_person"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="addPersonForm.submit">
                Add
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody className={tw("-my-2")}>
          <Divider />
        </CardBody>

        <CardBody>
          <form
            className={tw("w-full", "space-y-4")}
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              id="email"
              {...register("email")}
              type="email"
              label={t("addPersonForm.email.label", "Email")}
              placeholder={t(
                "addPersonForm.email.placeholder",
                "Email address"
              )}
              errorMessage={errors.email?.message}
            />

            <Input
              id="name"
              {...register("name")}
              label={t("addPersonForm.name.label", "Name")}
              placeholder={t("addPersonForm.name.placeholder", "Name")}
              hint={t("addPersonForm.name.hint", "Optional")}
              errorMessage={errors.name?.message}
            />

            <Input
              id="phone"
              {...register("phone")}
              label={t("addPersonForm.phone.label", "Phone number")}
              placeholder={t("addPersonForm.phone.placeholder", "Phone number")}
              hint={t("addPersonForm.phone.hint", "Optional")}
              errorMessage={errors.phone?.message}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
