/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentGroupQuery, DOCUMENT_GROUP } from "graphql/queries";

const CREATE_DOCUMENT_VERSION = gql`
  mutation ($id: ID!) {
    createDocumentVersion(input: { documentGroupId: $id }) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface CreateDocumentVersionMutation {
  createDocumentVersion: Payload | null;
}

export const useCreateDocumentVersionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateDocumentVersionMutation,
    { id: string }
  >(CREATE_DOCUMENT_VERSION);

  const createDocumentVersion = (
    id: string,
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache, { data: newDocumentVersionData }) {
        const newVersionNode =
          newDocumentVersionData?.createDocumentVersion?.documentVersion;
        if (!newVersionNode) return;

        cache.modify({
          fields: {
            node() {
              const documentGroup = cache.readQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
              });
              if (!documentGroup) return;

              const documentVersions = [
                ...documentGroup.node.documentVersions,
                newVersionNode,
              ];

              cache.writeQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
                data: {
                  node: {
                    ...documentGroup.node,
                    documentVersions,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDocumentVersion?.errors ?? [];
        const documentVersion = data?.createDocumentVersion?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createDocumentVersion,
    isLoading: loading,
    documentVersion: data?.createDocumentVersion?.documentVersion,
    errors: data?.createDocumentVersion?.errors ?? [],
  };
};
