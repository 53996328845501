import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Button,
  Divider,
  Select,
  CheckBox,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { BankAccountFragment } from "graphql/fragments";
import { useErrorLogger } from "hooks";
import { useCreateBankAccountMutation } from "graphql/mutations";
import { CurrencyEnum, FreelanceTypeEnum } from "graphql/types";
import { useFreelanceProfilesQuery } from "graphql/queries";
import { useEffect, useState } from "react";
import {
  bankAccountNumberValidationSchema,
  getAlphanumericValueFromString,
} from "utils";

interface FormValues {
  freelanceProfileId: string;
  accountNumber: string;
  currency: CurrencyEnum;
  swiftCode: string;
  default: boolean;
}

interface Props {
  onClose: () => void;
  freelanceProfileId?: string;
  onSuccess?: (bankAccount: BankAccountFragment) => void;
  showProfileSelector?: boolean;
}

export default ({
  onClose,
  freelanceProfileId,
  onSuccess,
  showProfileSelector = false,
}: Props): JSX.Element => {
  const [isOrg, setIsOrg] = useState<boolean>();
  const [isInternational, setIsInternational] = useState<boolean>();
  const { freelanceProfiles, isLoading } = useFreelanceProfilesQuery();
  const { createBankAccount } = useCreateBankAccountMutation();

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const errorMessages = {
    freelanceProfileId: t(
      "popup.addBankAccount.freelanceProfileId.error.required",
      "A connected payout profile is required"
    ),
    accountNumber: t(
      "popup.addBankAccount.accountNumber.error.required",
      "A bank account number is required"
    ),
    currency: t(
      "popup.addBankAccount.currency.error.required",
      "A currency is required"
    ),
    swiftCode: t(
      "popup.addBankAccount.swiftCode.swiftCode.length",
      "The swift code must be between 8 and 11 characters"
    ),
  };
  const validationSchema = Yup.object({
    freelanceProfileId: Yup.string()
      .required(errorMessages.freelanceProfileId)
      .oneOf(freelanceProfiles.map(({ id }) => id)),

    currency: Yup.string()
      .required(errorMessages.currency)
      .oneOf(isOrg ? Object.values(CurrencyEnum) : [CurrencyEnum.Nok]),
    swiftCode: Yup.string()
      .transform((value) => (value ? value : undefined))
      .min(8, errorMessages.swiftCode)
      .max(11, errorMessages.swiftCode),
    ...(isInternational
      ? { accountNumber: Yup.string().required(errorMessages.accountNumber) }
      : { accountNumber: bankAccountNumberValidationSchema(t) }),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    resetField,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const freelanceProfileIdValue = watch("freelanceProfileId");

  const onSubmit = ({ freelanceProfileId, ...values }: FormValues) =>
    createBankAccount(
      freelanceProfileId,
      {
        ...values,
        accountNumber: isInternational
          ? values.accountNumber
          : getAlphanumericValueFromString(values.accountNumber),
        default: [true, "true"].includes(values.default ?? false),
      },
      (bankAccount) => {
        if (onSuccess && bankAccount) onSuccess(bankAccount);

        onClose();
      }
    );

  useEffect(() => {
    const freelanceProfile = freelanceProfiles.find(
      (profile) => profile.id === freelanceProfileIdValue
    );
    if (!freelanceProfile) return;

    setIsInternational(freelanceProfile.address?.country !== "NO");

    const newIsOrg =
      freelanceProfile.freelanceType === FreelanceTypeEnum.Organization;
    if (isOrg !== newIsOrg) {
      resetField("currency", { defaultValue: CurrencyEnum.Nok });

      setIsOrg(
        freelanceProfile?.freelanceType === FreelanceTypeEnum.Organization
      );
    }
  }, [freelanceProfileIdValue, freelanceProfiles.length]);

  const formId = "addBankAccountForm";

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="popup.addBankAccount.title">
                  Add Bank Account
                </Trans>
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="add_bank_account-cancel"
              onClick={onClose}
              variant="tertiary"
              size="sm"
            >
              <Trans ns="common" i18nKey="popup.addBankAccount.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="add_bank_account-add_bank_account"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="popup.addBankAccount.submit">
                Add
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody className={tw("-my-2")}>
          <Divider />
        </CardBody>

        <CardBody>
          <form
            className={tw("w-full", "space-y-4")}
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h2 className={tw("text-lg", "font-bold")}>
              <Trans
                ns="common"
                i18nKey="popup.addBankAccount.form.accountDetailsSection.title"
              >
                Account details
              </Trans>
            </h2>

            <div className={tw("flex", "space-x-4")}>
              <Input
                className={tw("w-3/4")}
                id="accountNumber"
                {...register("accountNumber")}
                label={t(
                  "popup.addBankAccount.form.accountNumber.label",
                  "Account number"
                )}
                placeholder={t(
                  "popup.addBankAccount.form.accountNumber.placeholder",
                  "Number"
                )}
                errorMessage={errors.accountNumber?.message}
              />

              <Controller
                control={control}
                name="currency"
                defaultValue={CurrencyEnum.Nok}
                render={({ field }) => (
                  <Select
                    className={tw("w-1/4")}
                    id={field.name}
                    name={field.name}
                    label={t(
                      "popup.addBankAccount.form.currency.label",
                      "Currency"
                    )}
                    options={
                      isOrg
                        ? Object.values(CurrencyEnum).map((value) => ({
                            label: value,
                            value,
                          }))
                        : [
                            {
                              label: CurrencyEnum.Nok,
                              value: CurrencyEnum.Nok,
                            },
                          ]
                    }
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={errors.currency?.message}
                  />
                )}
              />
            </div>

            <Input
              id="swiftCode"
              {...register("swiftCode")}
              label={t(
                "popup.addBankAccount.form.swiftCode.label",
                "BIC / SWIFT"
              )}
              placeholder={t(
                "popup.addBankAccount.form.swiftCode.placeholder",
                "Number"
              )}
              hint={t("popup.addBankAccount.form.swiftCode.hint", "Optional")}
              errorMessage={errors.swiftCode?.message}
            />

            <input
              {...register("freelanceProfileId")}
              defaultValue={freelanceProfileId ?? freelanceProfiles[0].id}
              style={{ display: "none" }}
            />
            {!isLoading && showProfileSelector && (
              <>
                <Divider />

                <h2 className={tw("my-4", "text-lg", "font-bold")}>
                  <Trans
                    ns="common"
                    i18nKey="popup.addBankAccount.form.payoutProfileSection.title"
                  >
                    Payout profile attachment
                  </Trans>
                </h2>

                <p>
                  <Trans
                    ns="common"
                    i18nKey="popup.addBankAccount.form.payoutProfileSection.heading"
                  >
                    Please provide the payout profile the bank account should be
                    attached to.
                  </Trans>
                </p>

                <Controller
                  control={control}
                  name="freelanceProfileId"
                  defaultValue={freelanceProfileId ?? freelanceProfiles[0].id}
                  render={({ field }) => (
                    <Select
                      id={field.name}
                      name={field.name}
                      label={t(
                        "popup.addBankAccount.form.freelanceProfileId.label",
                        "Payout profile"
                      )}
                      options={freelanceProfiles.map((profile) => ({
                        label:
                          profile.freelanceType ===
                          FreelanceTypeEnum.Organization
                            ? `${profile.organizationName} (${profile.organizationNumber})`
                            : `${profile.firstName} ${profile.lastName}`,
                        value: profile.id,
                      }))}
                      value={field.value}
                      onChange={field.onChange}
                      errorMessage={errors.freelanceProfileId?.message}
                    />
                  )}
                />
              </>
            )}

            <Divider />

            <CheckBox
              id="default"
              {...register("default")}
              label={t(
                "popup.addBankAccount.form.makeDefault.label",
                "Make default"
              )}
              errorMessage={errors.default?.message}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
