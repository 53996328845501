import tw from "tw-generated";
import { HiInformationCircle } from "react-icons/hi";

interface Props {
  heading?: string;
  body?: string | JSX.Element;
}

export default ({ heading, body }: Props): JSX.Element => (
  <div
    className={tw(
      "w-full",
      "rounded-md",
      "bg-blue-100",
      "p-4",
      "flex",
      "space-x-5"
    )}
  >
    <HiInformationCircle
      size={24}
      className={tw("text-blue-700", "flex-shrink-0")}
    />

    <div className={tw("w-full", "flex", "flex-col", "space-y-1")}>
      {heading && (
        <p className={tw("font-bold", "text-gray-900", "whitespace-pre-wrap")}>
          {heading}
        </p>
      )}

      {body}
    </div>
  </div>
);
