import { gql } from "@apollo/client";

import { SignatureTemplate, DocumentTemplate } from "graphql/types";

import { DOCUMENT_FILE_FRAGMENT, DocumentFileFragment } from "./documentFile";

export const DOCUMENT_TEMPLATE_FRAGMENT = gql`
  fragment DocumentTemplateFragment on DocumentTemplate {
    id
    activatedOn
    activation
    category
    copyRecipients
    createdAt
    documentFiles {
      ...DocumentFileFragment
    }
    expiration
    expirationMonths
    expiredOn
    name
    signatureTemplates {
      designation
    }
  }
  ${DOCUMENT_FILE_FRAGMENT}
`;

export interface DocumentTemplateFragment
  extends Pick<
    DocumentTemplate,
    | "id"
    | "activatedOn"
    | "activation"
    | "category"
    | "copyRecipients"
    | "createdAt"
    | "expiration"
    | "expirationMonths"
    | "expiredOn"
    | "name"
    | "signatureTemplates"
  > {
  documentFiles: DocumentFileFragment[];
  signatureTemplates: Pick<SignatureTemplate, "designation">[];
}
