/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpdateInvoiceAttributes,
  ReferencePersonAttributes,
  BulkInvoiceLineAttributes,
} from "graphql/types";
import {
  INVOICE_FRAGMENT,
  InvoiceFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const UPDATE_INVOICE = gql`
  mutation (
    $id: ID!
    $freelanceProfileId: ID
    $bankAccountId: ID
    $senderReferencePerson: ReferencePersonAttributes
    $clientId: ID
    $clientReferencePerson: ReferencePersonAttributes
    $lines: [BulkInvoiceLineAttributes!]
    $buyerReference: String
    $orderReference: String
    $invoicedOn: String
    $dueOn: String
  ) {
    updateInvoice(
      input: {
        id: $id
        attributes: {
          freelanceProfileId: $freelanceProfileId
          bankAccountId: $bankAccountId
          senderReferencePerson: $senderReferencePerson
          clientId: $clientId
          clientReferencePerson: $clientReferencePerson
          lines: $lines
          buyerReference: $buyerReference
          orderReference: $orderReference
          invoicedOn: $invoicedOn
          dueOn: $dueOn
        }
      }
    ) {
      invoice {
        ...InvoiceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoice?: InvoiceFragment | null;
  errors: ErrorFragment[];
}

interface UpdateInvoiceMutation {
  updateInvoice: Payload | null;
}

export const useUpdateInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateInvoiceMutation,
    {
      id: string;
      freelanceProfileId?: string;
      bankAccountId?: string;
      senderReferencePerson?: ReferencePersonAttributes;
      clientId?: string;
      clientReferencePerson?: ReferencePersonAttributes;
      lines?: BulkInvoiceLineAttributes[];
      buyerReference?: string;
      orderReference?: string;
      invoicedOn?: string;
      dueOn?: string;
    }
  >(UPDATE_INVOICE);

  const updateInvoice = (
    id: string,
    attributes: UpdateInvoiceAttributes,
    onSuccess?: () => void
  ) =>
    mutation({
      variables: {
        id,
        freelanceProfileId: attributes.freelanceProfileId ?? undefined,
        bankAccountId: attributes.bankAccountId ?? undefined,
        senderReferencePerson: attributes.senderReferencePerson ?? undefined,
        clientId: attributes.clientId ?? undefined,
        clientReferencePerson: attributes.clientReferencePerson ?? undefined,
        lines: attributes.lines ?? undefined,
        buyerReference: attributes.buyerReference ?? undefined,
        orderReference: attributes.orderReference ?? undefined,
        invoicedOn: attributes.invoicedOn ?? undefined,
        dueOn: attributes.dueOn ?? undefined,
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateInvoice?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateInvoice,
    isLoading: loading,
    invoice: data?.updateInvoice?.invoice,
    errors: data?.updateInvoice?.errors ?? [],
  };
};
