import { TTailwindString, tw } from "tw-generated";

interface Props {
  variant?: "left-aligned" | "centered";
  className?: TTailwindString;
}

export default ({
  variant = "left-aligned",
  className,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const cardBodyBase = tw("flex", "flex-col", "space-y-4");
  const leftAlignedStyles = tw("px-4", "items-start");
  const centeredStyles = tw("px-4", "sm:px-10", "items-stretch");

  const cardBodyStyles = tw(cardBodyBase, className, {
    [leftAlignedStyles]: variant === "left-aligned",
    [centeredStyles]: variant === "centered",
  });

  return <div className={cardBodyStyles}>{children}</div>;
};
