/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentRecipientAttributes } from "graphql/types";
import {
  DocumentGroupQuery,
  DocumentRecipientsQuery,
  DOCUMENT_GROUP,
  DOCUMENT_RECIPIENTS,
} from "graphql/queries";

const CREATE_DOCUMENT_RECIPIENTS = gql`
  mutation ($id: ID!, $attributes: [DocumentRecipientAttributes!]!) {
    createDocumentRecipients(
      input: { documentVersionId: $id, attributes: $attributes }
    ) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface CreateDocumentRecipientsMutation {
  createDocumentRecipients: Payload | null;
}

export const useCreateDocumentRecipientsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateDocumentRecipientsMutation,
    { id: string; attributes: DocumentRecipientAttributes[] }
  >(CREATE_DOCUMENT_RECIPIENTS);

  const createDocumentRecipients = (
    variables: { id: string; attributes: DocumentRecipientAttributes[] },
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({
      variables,
      update(cache, { data: newDocumentRecipientsData }) {
        const newDocumentRecipients =
          newDocumentRecipientsData?.createDocumentRecipients?.documentVersion
            ?.documentRecipients;
        if (!newDocumentRecipients) return;

        cache.modify({
          fields: {
            node() {
              const documentGroup = cache.readQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
              });
              if (!documentGroup) return;

              const documentVersions = documentGroup.node.documentVersions.map(
                (version) => {
                  if (version.id === variables.id) {
                    const documentRecipients = [
                      ...version.documentRecipients,
                      ...newDocumentRecipients,
                    ];

                    return { ...version, documentRecipients };
                  }

                  return version;
                }
              );

              cache.writeQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
                data: {
                  node: {
                    ...documentGroup.node,
                    documentVersions,
                  },
                },
              });
            },

            documentRecipients() {
              const existingRecipients =
                cache.readQuery<DocumentRecipientsQuery>({
                  query: DOCUMENT_RECIPIENTS,
                });
              if (!existingRecipients) return;

              const items = [
                ...(existingRecipients.documentRecipients.items ?? []),
                ...newDocumentRecipients,
              ];

              cache.writeQuery<DocumentRecipientsQuery>({
                query: DOCUMENT_RECIPIENTS,
                data: {
                  documentRecipients: {
                    items,
                    total: existingRecipients.documentRecipients.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDocumentRecipients?.errors ?? [];
        const documentVersion = data?.createDocumentRecipients?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createDocumentRecipients,
    isLoading: loading,
    documentVersion: data?.createDocumentRecipients?.documentVersion,
    errors: data?.createDocumentRecipients?.errors ?? [],
  };
};
