import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryInvoicesArgs, Invoice } from "graphql/types";

import {
  CLIENT_FRAGMENT,
  FREELANCE_PROFILE_FRAGMENT,
  REFERENCE_PERSON_FRAGMENT,
  ClientFragment,
  FreelanceProfileFragment,
  ReferencePersonFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const SIMPLIFIED_INVOICES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: InvoicesFilter
    $sortBy: InvoicesSortByEnum
  ) {
    invoices(page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy) {
      items {
        id
        client {
          ...ClientFragment
        }
        clientReferencePerson {
          ...ReferencePersonFragment
        }
        freelanceProfile {
          ...FreelanceProfileFragment
        }
        submittedAt
        rejectedAt
        publishedAt
        resolvedAt
        dueOn
        invoiceNumber
        status
        amount
        vat
        payoutAmount
        refundStatus
      }
      total
    }
  }
  ${CLIENT_FRAGMENT}
  ${FREELANCE_PROFILE_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
`;

export interface SimpleInvoice
  extends Pick<
    Invoice,
    | "id"
    | "submittedAt"
    | "rejectedAt"
    | "publishedAt"
    | "resolvedAt"
    | "dueOn"
    | "invoiceNumber"
    | "status"
    | "amount"
    | "vat"
    | "payoutAmount"
    | "refundStatus"
    | "currency"
  > {
  client?: ClientFragment;
  clientReferencePerson?: ReferencePersonFragment;
  freelanceProfile: FreelanceProfileFragment;
}

interface SimplifiedInvoicesQuery {
  invoices: PaginatedPayload<SimpleInvoice>;
}

type QueryArgs = Pick<
  QueryInvoicesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useSimplifiedInvoicesLazyQuery = (): {
  getInvoices: (args?: QueryArgs) => Promise<SimpleInvoice[] | undefined>;
  invoices: SimpleInvoice[];
  total: number;
  isLoading: boolean;
  called: boolean;
} => {
  const [getInvoicesQuery, { data, loading: isLoading, called }] = useLazyQuery<
    SimplifiedInvoicesQuery,
    QueryArgs
  >(SIMPLIFIED_INVOICES, {
    fetchPolicy: "network-only",
  });

  const invoices = data?.invoices.items?.filter(Boolean) ?? [];
  const total = data?.invoices.total ?? 0;

  const resolveRef = useRef<(invoices?: SimpleInvoice[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(invoices);
      resolveRef.current = undefined;
    }
  }, [invoices, called, isLoading]);

  const getInvoices = async (args?: QueryArgs) => {
    const variables = { ...args };

    getInvoicesQuery({ variables });

    return new Promise<SimpleInvoice[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getInvoices,
    invoices,
    total,
    isLoading,
    called,
  };
};
