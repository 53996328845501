import { Trans } from "translations";
import { tw } from "tw-generated";
import Spinner from "./OnlySpinner";

export default (): JSX.Element => {
  const containerStyles = tw(
    "flex",
    "flex-col",
    "items-center",
    "space-y-6",
    "m-10"
  );

  const textStyles = tw("text-2xl", "font-bold", "text-gray-900");

  return (
    <div className={containerStyles}>
      <Spinner size="md" />

      <p className={textStyles}>
        <Trans ns="common" i18nKey="spinner">
          Loading...
        </Trans>
      </p>
    </div>
  );
};
