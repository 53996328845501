import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { useTranslation, Trans } from "translations";
import { confirmationCodeSchema } from "utils";
import { PhoneNumberFragment } from "graphql/fragments";
import {
  useVerifyPhoneNumberMutation,
  useSendSmsConfirmationMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import {
  Button,
  Card,
  CardBody,
  Input,
  PhoneNumberInput,
} from "components/common/basic";

interface FormValues {
  code: string;
}

interface Props {
  onClose: () => void;
  phoneNumber: PhoneNumberFragment;
  onSuccessfulValidation: () => void;
}

export const ValidateSMSCodeForm = ({
  onClose,
  phoneNumber: { countryCode, number },
  onSuccessfulValidation,
}: Props): JSX.Element => {
  const { verifyPhoneNumber, isLoading: isVerifyingCode } =
    useVerifyPhoneNumberMutation();
  const { sendSmsConfirmation, isLoading: isResendingCode } =
    useSendSmsConfirmationMutation();
  const isLoading = isVerifyingCode || isResendingCode;

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    code: confirmationCodeSchema(t),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = async ({ code }: FormValues) => {
    await verifyPhoneNumber({ countryCode, number, token: code });

    onSuccessfulValidation();
  };

  const handleResendCode = () => sendSmsConfirmation({ countryCode, number });

  return (
    <Card>
      <CardBody>
        <div className={tw("w-full", "flex", "justify-between")}>
          <h3 className={tw("text-lg", "font-extrabold")}>
            <Trans
              ns="common"
              i18nKey="popup.phoneNumberVerification.validateSMSCode.heading"
            >
              Phone number verification
            </Trans>
          </h3>

          <button onClick={onClose}>
            <HiX size={24} className={tw("text-gray-400")} />
          </button>
        </div>

        <p className={tw("text-sm")}>
          <Trans
            ns="common"
            i18nKey="popup.phoneNumberVerification.validateSMSCode.paragraph"
          >
            We have sent you a SMS with verification code, type it below and we
            are ready to go!
          </Trans>
        </p>

        <PhoneNumberInput
          id="phone"
          name="phone"
          label={t(
            "popup.phoneNumberVerification.validateSMSCode.phoneNumber.label",
            "Mobile nr."
          )}
          countryCodes={[
            { label: "NO", value: "+47" },
            { label: "PL", value: "+48" },
          ]}
          value={{ countryCode, number }}
          disabled
        />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className={tw("flex", "space-x-4", "items-end")}
        >
          <Input
            id="code"
            {...register("code")}
            label={t(
              "popup.phoneNumberVerification.validateSMSCode.smsCode.label",
              "Your 5-digit code"
            )}
            errorMessage={errors.code?.message}
          />

          <Button
            id="validate_sms_code_form-submit_code"
            type="submit"
            variant="primary"
            size="md"
            disabled={isLoading}
          >
            <Trans
              ns="common"
              i18nKey="popup.phoneNumberVerification.validateSMSCode.button.submit"
            >
              Submit the code
            </Trans>
          </Button>
        </form>

        <p className={tw("text-sm")}>
          <Trans
            ns="common"
            i18nKey="popup.phoneNumberVerification.validateSMSCode.codeArrivalInformation"
          >
            The code may take a couple of minutes to arrive, depending on your
            network connection.
          </Trans>
        </p>

        <Button
          id="validate_sms_code_form-resend_code"
          onClick={handleResendCode}
          variant="secondary"
          size="md"
          disabled={isLoading}
        >
          <Trans
            ns="common"
            i18nKey="popup.phoneNumberVerification.validateSMSCode.button.resendCode"
          >
            Resend the code
          </Trans>
        </Button>
      </CardBody>
    </Card>
  );
};
