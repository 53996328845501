/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateSessionOtpInput } from "graphql/types";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const CREATE_SESSION_OTP = gql`
  mutation ($input: CreateSessionOtpInput!) {
    createSessionOtp(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface CreateSessionOtpMutation {
  createSessionOtp: Payload | null;
}

export const useSendMagicLinkMutation = (
  onSuccess?: (input: CreateSessionOtpInput) => void
) => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentMagicLink",
    "Magic link was sent successfully"
  );
  // Values for extraction
  // t("common:success.sentMagicLink", "Magic link was sent successfully")

  const [mutation, { data, loading }] = useMutation<
    CreateSessionOtpMutation,
    { input: CreateSessionOtpInput }
  >(CREATE_SESSION_OTP);

  const sendMagicLink = (input: CreateSessionOtpInput) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createSessionOtp?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(input),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendMagicLink,
    isLoading: loading,
    errors: data?.createSessionOtp?.errors ?? [],
  };
};
