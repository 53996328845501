import { useContext } from "react";
import { HiX } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { IntlContext } from "providers/i18n";
import { useErrorLogger } from "hooks";
import { Button, Textarea } from "components/common/basic";
import { useSendSupportMessageMutation } from "graphql/mutations";
import { HelpArticleFragment } from "graphql/fragments";
import { LanguageEnum } from "graphql/types";
import warningSymbol from "assets/symbols/warning.svg";

import PopupTemplate from "./PopupTemplate";

interface Props {
  onClose: () => void;
  helpArticle: HelpArticleFragment;
  clientId: string;
  freelanceProfileId: string;
}

export default ({
  onClose,
  helpArticle,
  clientId,
  freelanceProfileId,
}: Props): JSX.Element => {
  const { sendSupportMessage } = useSendSupportMessageMutation();

  const { t } = useTranslation("common");
  const { currentLocale } = useContext(IntlContext);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ message: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string()
          .trim()
          .required(
            t(
              "popup.blockedManualInvoicing.error.required",
              "A message is required"
            )
          ),
      })
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({ message }: { message: string }) => {
    const input = {
      clientId,
      freelanceProfileId,
      message,
    };
    sendSupportMessage(input, onClose);
  };

  const clientName = helpArticle.name ?? "";
  const message =
    currentLocale === LanguageEnum.En
      ? helpArticle.enMessage
      : helpArticle.nbMessage;

  return (
    <PopupTemplate onClose={onClose}>
      <div
        className={tw(
          "bg-white",
          "shadow",
          "rounded-lg",
          "flex",
          "space-x-4",
          "p-6"
        )}
      >
        <img
          className={tw("h-10", "object-contain")}
          src={warningSymbol}
          alt="Warning sign"
        />

        <div className={tw("flex-1", "space-y-4")}>
          <div className={tw("w-full", "flex", "justify-between")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              <Trans ns="common" i18nKey="popup.blockedManualInvoicing.heading">
                Do not invoice manually
              </Trans>
            </h3>

            <button onClick={onClose}>
              <HiX size={24} className={tw("text-gray-400")} />
            </button>
          </div>

          <p className={tw("text-sm", "text-gray-700", "whitespace-pre-wrap")}>
            {message}
          </p>

          <p className={tw("text-sm", "text-gray-700", "font-bold")}>
            <Trans
              ns="common"
              i18nKey="popup.blockedManualInvoicing.emailOption"
              defaults="Do you wish to send an email to {{ clientName }}?"
              values={{ clientName }}
            />
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className={tw("space-y-4")}>
            <div className={tw("flex", "flex-col", "space-y-1")}>
              <Textarea
                label={t(
                  "popup.blockedManualInvoicing.message.label",
                  "Send message"
                )}
                id="message"
                {...register("message")}
                rows={5}
              />
            </div>

            <div className={tw("flex", "justify-end")}>
              <Button
                id="blocked_manual_invoicing-send"
                type="submit"
                size="sm"
              >
                <Trans
                  ns="common"
                  i18nKey="popup.blockedManualInvoicing.button.sendEmail"
                >
                  Send
                </Trans>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </PopupTemplate>
  );
};
