import { IconType } from "react-icons";

import { tw } from "tw-generated";

import { ActionID } from "../../../../common/basic/types";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  id: ActionID;
  fullWidth?: boolean;
  trailingIconSize?: number;
  TrailingIcon?: IconType;
}

export default ({
  fullWidth,
  trailingIconSize,
  TrailingIcon,
  children,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element => {
  const base = tw(
    "inline-flex",
    "items-center",
    "justify-between",
    "border",
    "shadow-sm",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900",
    "rounded-md",
    "px-3",
    "py-2",
    "space-x-2",
    "sm:text-sm"
  );

  const disabledStyles = props.disabled
    ? tw("border-gray-100", "text-gray-400", "cursor-default")
    : tw("bg-white", "border-gray-300", "cursor-default");

  const buttonStyles = tw(base, disabledStyles, {
    "w-full": fullWidth,
  });

  return (
    <button {...props} type="button" className={buttonStyles} tabIndex={-1}>
      <span>{children}</span>

      {TrailingIcon && (
        <TrailingIcon className={tw("text-gray-400")} size={20} />
      )}
    </button>
  );
};
