import { useContext } from "react";

import { LanguageEnum } from "graphql/types";
import { IntlContext } from "providers/i18n";
import { AuthContext } from "providers/Authentication";

export type Route =
  | "TEST: ERROR_PAGE"
  | "TEST: SIGNATURE"
  | "TOKEN_HANDLER"
  | "GATE_KEEPER"
  | "BANK_ID_VERIFICATION"
  | "IDENTITY_VERIFICATION"
  | "CRIIPTO_CALLBACK"
  | "REFERRER"
  | "PRIVACY"
  | "TERMS"
  | "FREELANCER"
  | "HOME"
  | "DASHBOARD"
  | "SIGNUP"
  | "LOGIN"
  | "INVOICES"
  | "INVOICES_ALL"
  | "INVOICES_BY_CLIENT"
  | "INVOICES_IMPORTED"
  | "PURCHASES"
  | "PURCHASES_ALL"
  | "PURCHASES_BY_SUPPLIER"
  | "WORK"
  | "ACCOUNT"
  | "PAYSLIPS"
  | "PAYOUT_SETTINGS"
  | "PAYOUT_PROFILES"
  | "PAYOUT_SERVICE"
  | "PAYOUT_SERVICE_INVOICES"
  | "PAYOUT_SERVICE_PAYSLIPS"
  | "PAYOUT_SERVICE_PAYOUT_SETTINGS"
  | "PAYOUT_SERVICE_DRAFTS"
  | "PAYOUT_SERVICE_CREDIT_NOTES"
  | "PAYOUT_SERVICE_ALL_INVOICES"
  | "BANK_ACCOUNTS"
  | "FREELANCE_PROFILE_WIZARD"
  | "FREELANCE_PROFILE_WIZARD_SIGN"
  | "FREELANCE_PROFILE_WIZARD_SUCCESS"
  | "JOBS"
  | "JOB"
  | "JOB_CHECKS"
  | "ACCOUNT_SELECTION"
  | "ACCOUNT_SETTINGS"
  | "BENEFITS"
  | "MAGIC_LINK_LOGIN"
  | "MAGIC_LINK_VERIFICATION"
  | "RESET_PASSWORD"
  | "INVOICE_IMPORT"
  | "INVOICE"
  | "INVOICE_INITIALIZATION"
  | "INVOICE_CREATION"
  | "INVOICE_DUPLICATION"
  | "PUBLIC_INVOICE"
  | "CREDIT_NOTE"
  | "CREDIT_NOTE_CREATION"
  | "DOCUMENTS"
  | "DOCUMENT"
  | "AGREEMENTS"
  | "AGREEMENT"
  | "CREATE_DOCUMENT"
  | "CREATE_QUICK_SIGN"
  | "SIGN_DOCUMENT"
  | "PUBLIC_DOCUMENT"
  | "ENROLMENT_WIZARD"
  | "PEOPLE"
  | "PERSON"
  | "CHECKMORE"
  | "CHECKMORE_CHECKS"
  | "CHECKMORE_INVITE"
  | "CHECKMORE_WIZARD";

type Routes = {
  [K in Route]: (arg1?: string, arg2?: string) => string;
};

const routes: Routes = {
  "TEST: ERROR_PAGE": () => "/test/error-page",
  "TEST: SIGNATURE": () => "/test/signature",
  TOKEN_HANDLER: (destination) => `/token-handler/${destination}`,

  HOME: () => "/",
  GATE_KEEPER: () => "/action-required",
  BANK_ID_VERIFICATION: () => "/bank-id-verification",
  IDENTITY_VERIFICATION: () => "/alternative-id-verification",
  CRIIPTO_CALLBACK: () => "/criipto-callback",
  REFERRER: (referrer) => `/referrer/${referrer}`,

  // Logged out pages:
  PRIVACY: () => "/privacy",
  TERMS: () => "/terms",
  FREELANCER: () => "/freelancer",

  // Login pages:
  LOGIN: () => "/login",
  SIGNUP: () => "/login/new-user",
  MAGIC_LINK_LOGIN: () => "/login/magic-link",
  MAGIC_LINK_VERIFICATION: () => "/login/magic-link/verification",
  RESET_PASSWORD: () => "/login/reset-password",

  DASHBOARD: () => "/dashboard",
  INVOICES: () => "/invoices",
  INVOICES_ALL: () => "/invoices/all",
  INVOICES_BY_CLIENT: () => "/invoices/by-client",
  INVOICES_IMPORTED: () => "/invoices/imported",
  INVOICE_IMPORT: () => "/invoice-import",
  INVOICE: (id) => `/invoice/${id}`,
  INVOICE_INITIALIZATION: () => "/invoice-initialization",
  INVOICE_CREATION: (id) => `/invoice-creation/${id}`,
  INVOICE_DUPLICATION: (id) => `/invoice-duplication/${id}`,
  PUBLIC_INVOICE: (id) => `/public-invoice/${id}`,
  CREDIT_NOTE: (id) => `/credit-note/${id}`,
  CREDIT_NOTE_CREATION: (id) => `/credit-note-creation/${id}`,

  PURCHASES: () => "/purchases",
  PURCHASES_ALL: () => "/purchases/all",
  PURCHASES_BY_SUPPLIER: () => "/purchases/by-supplier",

  WORK: () => "/work",
  ACCOUNT: () => "/account",
  PAYSLIPS: () => "/account/payslips",
  PAYOUT_SERVICE: () => "/account/payout-service",
  PAYOUT_SERVICE_INVOICES: () => "/account/payout-service/invoices",
  PAYOUT_SERVICE_PAYSLIPS: () => "/account/payout-service/payslips",
  PAYOUT_SERVICE_PAYOUT_SETTINGS: () =>
    "/account/payout-service/payout-settings",
  PAYOUT_SERVICE_DRAFTS: () => "/account/payout-service/drafts",
  PAYOUT_SERVICE_CREDIT_NOTES: () => "/account/payout-service/credit-notes",
  PAYOUT_SERVICE_ALL_INVOICES: () => "/account/payout-service/all-invoices",
  PAYOUT_SETTINGS: () => "/account/payout-settings",
  PAYOUT_PROFILES: () => "/account/payout-settings/payout-profiles",
  BANK_ACCOUNTS: () => "/account/payout-settings/bank-accounts",

  // Freelance profile wizard
  FREELANCE_PROFILE_WIZARD: (id) =>
    id ? `/freelance-profile-wizard/${id}` : "/freelance-profile-wizard",
  FREELANCE_PROFILE_WIZARD_SIGN: (id) =>
    `/freelance-profile-wizard/${id}/agreement`,
  FREELANCE_PROFILE_WIZARD_SUCCESS: () => "/freelance-profile-wizard/success",

  JOBS: () => "/jobs",
  JOB: (id) => `/jobs/${id}`,
  JOB_CHECKS: () => "/account/verification-checks",
  ACCOUNT_SELECTION: () => "/account/account-selection",
  ACCOUNT_SETTINGS: () => "/account/account-settings",
  BENEFITS: () => "/benefits",
  DOCUMENTS: () => "/documents",
  DOCUMENT: (id) => `/documents/${id}`,
  AGREEMENTS: () => `/agreements`,
  AGREEMENT: (id) => `/agreements/${id}`,
  CREATE_DOCUMENT: () => "/create-document",
  CREATE_QUICK_SIGN: (id) =>
    id ? `/quick-sign-creation/${id}` : "/quick-sign-creation",
  SIGN_DOCUMENT: (token) => `/sign-document/${token}`,
  PUBLIC_DOCUMENT: (token) =>
    token ? `/public-document/${token}` : "/public-document",

  ENROLMENT_WIZARD: (jobId) => `/enrolment-wizard/${jobId}`,

  PEOPLE: () => "/people",
  PERSON: (id, tab) => (tab ? `/people/${id}/${tab}` : `/people/${id}`),

  CHECKMORE: () => "/checkmore",
  CHECKMORE_CHECKS: () => "/checkmore/checks",
  CHECKMORE_INVITE: () => "/checkmore/invite",
  CHECKMORE_WIZARD: (id, step) =>
    step ? `/checkmore/wizard/${id}/${step}` : `/checkmore/wizard/${id}`,
};

export const getRoutePath = (
  routeName: Route,
  arg1?: string,
  arg2?: string
): string => routes[routeName](arg1, arg2);

type RouteGetter = (routeName: Route, arg1?: string, arg2?: string) => string;

export const getOutdatedRoutePath =
  (localePrefix: string): RouteGetter =>
  (routeName: Route, arg1?: string, arg2?: string) =>
    localePrefix + routes[routeName](arg1, arg2);

export const getPrefixedRoutePath =
  (localePrefix: string, accountPrefix: string): RouteGetter =>
  (routeName: Route, arg1?: string, arg2?: string) =>
    localePrefix + accountPrefix + routes[routeName](arg1, arg2);

export const getLocalePrefix = (locale: LanguageEnum): string => {
  const localePrefix =
    Object.keys(LanguageEnum).find((key) => LanguageEnum[key] === locale) ??
    LanguageEnum.En;

  return `/${localePrefix.toLowerCase()}`;
};

export const unspecifiedAccountString = "a";

export const useLocalizedRoutePath = (): RouteGetter => {
  const { currentLocale } = useContext(IntlContext);
  const { session } = useContext(AuthContext);

  return getPrefixedRoutePath(
    getLocalePrefix(currentLocale),
    `/${session?.account.id ?? unspecifiedAccountString}`
  );
};

export const checkIsRoutePath = (
  pathname: string,
  routeName: Route,
  arg1?: string,
  arg2?: string
) => pathname.includes(routes[routeName](arg1, arg2));

export const useLocalizedRoutePathGetter = (): ((
  accountID?: string
) => RouteGetter) => {
  const { currentLocale } = useContext(IntlContext);
  const { session } = useContext(AuthContext);

  return (accountID = session?.account.id ?? unspecifiedAccountString) =>
    getPrefixedRoutePath(getLocalePrefix(currentLocale), `/${accountID}`);
};

export const useDefaultRouteName = (): Route => {
  const { isLoggedIn, session } = useContext(AuthContext);

  if (!isLoggedIn) return "HOME";

  if (session?.hasFreelanceProfiles === false) return "GATE_KEEPER";

  return "DASHBOARD";
};
