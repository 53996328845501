import React from "react";
import Rollbar from "rollbar";

interface Context {
  logger?: Rollbar;
}

export const ErrorLoggerContext = React.createContext<Context>({
  logger: undefined,
});

interface Props {
  children?: React.ReactNode;
}

export const ErrorLoggerProvider = ({ children }: Props): JSX.Element => {
  const { REACT_APP_ROLLBAR_TOKEN, REACT_APP_ROLLBAR_ENVIRONMENT } =
    process.env;

  const logger = REACT_APP_ROLLBAR_TOKEN
    ? new Rollbar({
        accessToken: REACT_APP_ROLLBAR_TOKEN,
        environment: REACT_APP_ROLLBAR_ENVIRONMENT ?? "unknown",
      })
    : undefined;

  return (
    <ErrorLoggerContext.Provider value={{ logger }}>
      {children}
    </ErrorLoggerContext.Provider>
  );
};
