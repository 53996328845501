/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  ERROR_FRAGMENT,
  ErrorFragment,
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const SUBMIT_JOB_APPLICATION = gql`
  mutation ($id: ID!) {
    submitJobApplication(input: { id: $id }) {
      jobApplication {
        ...JobApplicationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  jobApplication?: JobApplicationFragment | null;
  errors: ErrorFragment[];
}

interface SubmitJobApplicationMutation {
  submitJobApplication: Payload | null;
}

export const useSubmitJobApplicationMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.submittedJobApplication",
    "Job application was submitted successfully"
  );
  // Values for extraction
  // t("common:success.submittedJobApplication", "Job application was submitted successfully")

  const [mutation, { data, loading, called }] = useMutation<
    SubmitJobApplicationMutation,
    { id: string }
  >(SUBMIT_JOB_APPLICATION);

  const submitJobApplication = (
    id: string,
    onSuccess?: (response: JobApplicationFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.submitJobApplication?.errors ?? [];
        const jobApplication = data?.submitJobApplication?.jobApplication;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            jobApplication && onSuccess && onSuccess(jobApplication),
          onRetry: () => submitJobApplication(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    submitJobApplication,
    isLoading: loading,
    called,
    jobApplication: data?.submitJobApplication?.jobApplication,
    errors: data?.submitJobApplication?.errors ?? [],
  };
};
