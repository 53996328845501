import toast from "react-hot-toast";
import { HiOutlineLink } from "react-icons/hi";

import { tw } from "tw-generated";
import { useTranslation } from "translations";
import { SuccessCard } from "components/common/basic";

interface Props {
  textToCopy: string;
  successMessage?: string;
}

export default ({ textToCopy, successMessage }: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(textToCopy).then(() => {
          toast.custom((toastProps) => (
            <SuccessCard
              heading={t("copyToClipboard.success.heading", "Copied!")}
              body={
                successMessage ??
                t("copyToClipboard.success.body", "Copied to your clipboard")
              }
              toastProps={toastProps}
            />
          ));
        });
      }}
    >
      <HiOutlineLink size={24} className={tw("text-gray-400")} />
    </button>
  );
};
