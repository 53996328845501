import { gql } from "@apollo/client";

import { Invoice, CreditNote } from "graphql/types";

import { CLIENT_FRAGMENT, ClientFragment } from "./client";
import {
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "./freelanceProfile";
import { ADDRESS_FRAGMENT } from "./address";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  CREDIT_NOTE_LINE_FRAGMENT,
  CreditNoteLineFragment,
} from "./creditNoteLine";
import { EVENT_FRAGMENT, EventFragment } from "./event";
import {
  REFERENCE_PERSON_FRAGMENT,
  ReferencePersonFragment,
} from "./referencePerson";

export const CREDIT_NOTE_FRAGMENT = gql`
  fragment CreditNoteFragment on CreditNote {
    id
    amount
    approvedAt
    client {
      ...ClientFragment
    }
    creditNoteNumber
    creditedOn
    platformFeeAmount
    ehfSentAt
    emailSentAt
    employerTaxAmount
    events {
      ...EventFragment
    }
    freelanceProfile {
      ...FreelanceProfileFragment
    }
    grossSalary
    invoice {
      id
      invoiceNumber
      buyerReference
      orderReference
      events {
        ...EventFragment
      }
      senderReferencePerson {
        ...ReferencePersonFragment
      }
      clientReferencePerson {
        ...ReferencePersonFragment
      }
    }
    lines {
      ...CreditNoteLineFragment
    }
    payoutAmount
    publishedAt
    rejectedAt
    resolvedAt
    salaryTaxAmount
    status
    vat
    pdfDocument {
      ...AttachmentFragment
    }
    currency
  }
  ${CLIENT_FRAGMENT}
  ${FREELANCE_PROFILE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${CREDIT_NOTE_LINE_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

interface CreditNoteInvoice
  extends Pick<
    Invoice,
    "id" | "invoiceNumber" | "buyerReference" | "orderReference"
  > {
  events: EventFragment[];
  clientReferencePerson?: ReferencePersonFragment;
  senderReferencePerson?: ReferencePersonFragment;
}
export interface CreditNoteFragment
  extends Pick<
    CreditNote,
    | "id"
    | "amount"
    | "approvedAt"
    | "creditNoteNumber"
    | "creditedOn"
    | "platformFeeAmount"
    | "ehfSentAt"
    | "emailSentAt"
    | "employerTaxAmount"
    | "grossSalary"
    | "payoutAmount"
    | "publishedAt"
    | "rejectedAt"
    | "resolvedAt"
    | "salaryTaxAmount"
    | "status"
    | "vat"
    | "currency"
  > {
  client?: ClientFragment;
  events: EventFragment[];
  freelanceProfile: FreelanceProfileFragment;
  invoice: CreditNoteInvoice;
  lines: CreditNoteLineFragment[];
  pdfDocument?: AttachmentFragment;
}
