/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useEffect, useRef } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { QueryPartnerJobApplicationsArgs } from "graphql/types";

import {
  JOB_APPLICATION_FRAGMENT,
  JobApplicationFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const PARTNER_JOB_APPLICATIONS = gql`
  query ($page: Int, $perPage: Int, $filter: JobApplicationsFilter) {
    partnerJobApplications(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...JobApplicationFragment
      }
      total
    }
  }
  ${JOB_APPLICATION_FRAGMENT}
`;

export type PartnerJobApplicationsQuery = {
  partnerJobApplications: PaginatedPayload<JobApplicationFragment>;
};

type QueryArgs = Pick<
  QueryPartnerJobApplicationsArgs,
  "page" | "perPage" | "filter"
>;

export const usePartnerJobApplicationsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<
    PartnerJobApplicationsQuery,
    QueryArgs
  >(PARTNER_JOB_APPLICATIONS, { variables: { ...args } });

  const partnerJobApplications =
    data?.partnerJobApplications.items?.filter(Boolean) ?? [];

  return { partnerJobApplications, isLoading };
};

export const usePartnerJobApplicationsLazyQuery = () => {
  const [getPartnerJobApplicationsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PartnerJobApplicationsQuery, QueryArgs>(
      PARTNER_JOB_APPLICATIONS,
      { fetchPolicy: "network-only" }
    );

  const total = data?.partnerJobApplications.total ?? 0;
  const partnerJobApplications =
    data?.partnerJobApplications.items?.filter(Boolean) ?? [];

  const resolveRef =
    useRef<(jobApplications?: JobApplicationFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(partnerJobApplications);
      resolveRef.current = undefined;
    }
  }, [partnerJobApplications, called, isLoading]);

  const getPartnerJobApplications = async (args?: QueryArgs) => {
    const variables = { ...args };

    getPartnerJobApplicationsQuery({ variables });

    return new Promise<JobApplicationFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPartnerJobApplications,
    partnerJobApplications,
    isLoading,
    total,
  };
};
