/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateSessionInput, CreateSessionPayload } from "graphql/types";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const CREATE_SESSION = gql`
  mutation ($input: CreateSessionInput!) {
    createSession(input: $input) {
      token
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload extends Pick<CreateSessionPayload, "token"> {
  errors: ErrorFragment[];
}

export interface CreateSessionMutation {
  createSession: Payload | null;
}

export const useCreateSessionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateSessionMutation,
    { input: CreateSessionInput }
  >(CREATE_SESSION);

  const createSession = (input: CreateSessionInput, onSuccess?: () => void) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createSession?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    createSession,
    session: data?.createSession,
    isLoading: loading,
    errors: data?.createSession?.errors ?? [],
  };
};
