import { TTailwindString, tw } from "tw-generated";

interface Props {
  variant?: "left-aligned" | "centered";
  className?: TTailwindString;
}

export default ({
  variant = "left-aligned",
  className,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const cardHeaderBase = tw("rounded-t-lg");
  const leftAlignedStyles = tw("p-4");
  const centeredStyles = tw("p-10", "sm:px-4");

  const cardHeaderStyles = tw(cardHeaderBase, className, {
    [leftAlignedStyles]: variant === "left-aligned",
    [centeredStyles]: variant === "centered",
  });

  return <div className={cardHeaderStyles}>{children}</div>;
};
