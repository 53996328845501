/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { BankAccountAttributes } from "graphql/types";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  BANK_ACCOUNT_FRAGMENT,
  BankAccountFragment,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { FreelanceProfilesQuery, FREELANCE_PROFILES } from "graphql/queries";

const CREATE_BANK_ACCOUNT = gql`
  mutation ($freelanceProfileId: ID!, $attributes: BankAccountAttributes!) {
    createBankAccount(
      input: {
        freelanceProfileId: $freelanceProfileId
        attributes: $attributes
      }
    ) {
      bankAccount {
        ...BankAccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BANK_ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  bankAccount?: BankAccountFragment | null;
  errors: ErrorFragment[];
}

interface CreateBankAccountMutation {
  createBankAccount: Payload | null;
}

export const useCreateBankAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateBankAccountMutation,
    { freelanceProfileId: string; attributes: BankAccountAttributes }
  >(CREATE_BANK_ACCOUNT);

  const createBankAccount = (
    freelanceProfileId: string,
    attributes: BankAccountAttributes,
    onSuccess?: (bankAccount: BankAccountFragment) => void
  ) =>
    mutation({
      variables: { freelanceProfileId, attributes },
      update(cache, { data: newBankAccountData }) {
        cache.modify({
          fields: {
            freelanceProfiles() {
              const freelanceProfilesQuery =
                cache.readQuery<FreelanceProfilesQuery>({
                  query: FREELANCE_PROFILES,
                });
              if (!freelanceProfilesQuery) return;

              const bankAccount =
                newBankAccountData?.createBankAccount?.bankAccount;
              if (!bankAccount) return;

              const items =
                freelanceProfilesQuery.freelanceProfiles.items?.reduce(
                  (acc: FreelanceProfileFragment[], profile) => {
                    if (profile.id === freelanceProfileId)
                      return [
                        ...acc,
                        {
                          ...profile,
                          bankAccounts: [...profile.bankAccounts, bankAccount],
                          defaultBankAccount: bankAccount,
                        },
                      ];

                    return [...acc, profile];
                  },
                  []
                );

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: freelanceProfilesQuery.freelanceProfiles.total,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createBankAccount?.errors ?? [];
        const bankAccount = data?.createBankAccount?.bankAccount;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && bankAccount && onSuccess(bankAccount),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createBankAccount,
    isLoading: loading,
    bankAccount: data?.createBankAccount?.bankAccount,
    errors: data?.createBankAccount?.errors ?? [],
  };
};
