/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateFreelanceProfileAttributes } from "graphql/types";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  FREELANCE_PROFILE_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { FreelanceProfilesQuery, FREELANCE_PROFILES } from "graphql/queries";

const CREATE_FREELANCE_PROFILE = gql`
  mutation ($attributes: CreateFreelanceProfileAttributes!) {
    createFreelanceProfile(input: { attributes: $attributes }) {
      freelanceProfile {
        ...FreelanceProfileFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${FREELANCE_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  freelanceProfile?: FreelanceProfileFragment | null;
  errors: ErrorFragment[];
}

interface CreateFreelanceProfileMutation {
  createFreelanceProfile: Payload | null;
}

export const useCreateFreelanceProfileMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateFreelanceProfileMutation,
    { attributes: CreateFreelanceProfileAttributes }
  >(CREATE_FREELANCE_PROFILE);

  const createFreelanceProfile = (
    { attributes }: { attributes: CreateFreelanceProfileAttributes },
    onSuccess?: (freelanceProfile: FreelanceProfileFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newProfileData }) {
        const newProfileNode =
          newProfileData?.createFreelanceProfile?.freelanceProfile;
        if (!newProfileNode) return;

        cache.modify({
          fields: {
            freelanceProfiles() {
              const existingProfiles = cache.readQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
              });
              if (!existingProfiles) return;

              const items = [
                ...(existingProfiles.freelanceProfiles.items ?? []),
                newProfileNode,
              ];

              cache.writeQuery<FreelanceProfilesQuery>({
                query: FREELANCE_PROFILES,
                data: {
                  freelanceProfiles: {
                    items,
                    total: existingProfiles.freelanceProfiles.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createFreelanceProfile?.errors ?? [];
        const profile = data?.createFreelanceProfile?.freelanceProfile;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && profile && onSuccess(profile),
          onRetry: () => createFreelanceProfile({ attributes }, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createFreelanceProfile,
    isLoading: loading,
    freelanceProfile: data?.createFreelanceProfile?.freelanceProfile,
    errors: data?.createFreelanceProfile?.errors ?? [],
  };
};
