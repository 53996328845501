/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryRefundsArgs } from "graphql/types";

import { CreditNoteFragment, CREDIT_NOTE_FRAGMENT } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const REFUNDS = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: CreditNotesFilter
    $sortBy: CreditNotesSortByEnum
  ) {
    refunds(page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy) {
      items {
        ...CreditNoteFragment
      }
      total
    }
  }
  ${CREDIT_NOTE_FRAGMENT}
`;

export interface RefundsQuery {
  refunds: PaginatedPayload<CreditNoteFragment>;
}

type QueryArgs = Pick<
  QueryRefundsArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useRefundsLazyQuery = () => {
  const [getRefundsQuery, { data, loading: isLoading, called }] = useLazyQuery<
    RefundsQuery,
    QueryArgs
  >(REFUNDS, { fetchPolicy: "network-only" });

  const refunds = data?.refunds.items?.filter(Boolean) ?? [];
  const total = data?.refunds.total ?? 0;

  const resolveRef = useRef<(refunds?: CreditNoteFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(refunds);
      resolveRef.current = undefined;
    }
  }, [refunds, called, isLoading]);

  const getRefunds = async (args?: QueryArgs) => {
    const variables = { ...args };

    getRefundsQuery({ variables });

    return new Promise<CreditNoteFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getRefunds, refunds, total, isLoading, called };
};
