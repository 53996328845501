import { Link } from "react-router-dom";

import { TTailwindString, tw } from "tw-generated";

import { ActionID } from "./types";

interface Props {
  id: ActionID;
  to: string;
  isExternal?: boolean;
  openSeparate?: boolean;
  color?: "black" | "gray" | "white" | "blue";
  unstyled?: boolean;
  className?: TTailwindString;
  onClick?: () => void;
  title?: string;
}

export default ({
  id,
  to,
  isExternal = false,
  openSeparate = isExternal ? true : false,
  color = "black",
  unstyled,
  className,
  onClick,
  title,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const baseLink = tw("underline");
  const blackLink = tw("text-gray-900", "hover:text-gray-700");
  const grayLink = tw("text-gray-500", "hover:text-gray-300");
  const whiteLink = tw("text-white", "hover:text-gray-100");
  const blueLink = tw("text-blue-900", "hover:text-blue-700");

  const props = {
    id,
    title,
    target: openSeparate ? "_blank" : undefined,
    className: unstyled
      ? className
      : tw(baseLink, className, {
          [blackLink]: color === "black",
          [grayLink]: color === "gray",
          [whiteLink]: color === "white",
          [blueLink]: color === "blue",
        }),
  };

  if (isExternal)
    return (
      <a {...props} href={to} rel="noopener noreferrer" onClick={onClick}>
        {children}
      </a>
    );

  return (
    <Link {...props} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};
