import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { pdfjs } from "react-pdf";
import { Toaster } from "react-hot-toast";

import "raf/polyfill";
import "moment/locale/nb";

import TailwindProvider from "providers/Tailwind";
import IntlProvider from "providers/i18n";
import { AuthProvider } from "providers/Authentication";
import { PopupProvider } from "providers/PopupHandler";
import { ErrorLoggerProvider } from "providers/ErrorLogger";
import { BeaconProvider } from "providers/Beacon";
import apolloClient from "graphql/apolloClient";

import "./index.css";
import App from "./App";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const container = document.getElementById("root");
const app = (
  <TailwindProvider>
    <ErrorLoggerProvider>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <IntlProvider>
            <PopupProvider>
              <BeaconProvider>
                <Toaster position="top-right" />

                <App />
              </BeaconProvider>
            </PopupProvider>
          </IntlProvider>
        </AuthProvider>
      </ApolloProvider>
    </ErrorLoggerProvider>
  </TailwindProvider>
);

if (container) createRoot(container).render(app);
