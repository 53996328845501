import { useContext, useState } from "react";
import { HiOutlineFilter } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import { TailwindContext } from "providers/Tailwind";

import FilterPopup from "./FilterPopup";
import Filters, { Filter, FilterValues } from "./Filters";
import useSearchParamsState from "./useSearchParamsState";

interface Props {
  filters: Filter[];
  defaultValues: FilterValues;
  onChange: (values: FilterValues) => void;
}

export default ({ filters, defaultValues, onChange }: Props): JSX.Element => {
  const [isChangingFilters, setIsChangingFilters] = useState(false);
  const [filterValues, setFilterValues, clearFilters] = useSearchParamsState(
    defaultValues,
    onChange
  );

  const { isXl } = useContext(TailwindContext);

  return (
    <>
      {isXl ? (
        <form
          className={tw("px-4", "py-6", "space-y-2", "bg-silver")}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={tw("flex", "space-x-4")}>
            <Filters
              filters={filters}
              values={filterValues}
              setFilterValue={(name: string, value: string) =>
                setFilterValues({ [name]: value })
              }
              setFilterValues={setFilterValues}
            />
          </div>

          <button
            type="reset"
            className={tw("block", "ml-auto", "text-gray-900")}
            onClick={clearFilters}
          >
            <Trans ns="common" i18nKey="filterBar.button.clearFilters">
              Clear filters
            </Trans>
          </button>
        </form>
      ) : (
        <div className={tw("px-4", "py-6", "space-y-2", "bg-silver")}>
          <button
            type="button"
            className={tw(
              "bg-white",
              "py-2",
              "px-3",
              "rounded-md",
              "shadow-sm",
              "flex",
              "justify-between",
              "items-center",
              "w-full",
              "shadow-sm",
              "sm:text-sm",
              "rounded-md",
              "focus:ring-gray-900",
              "focus:border-gray-900",
              "border-gray-300",
              "border",
              "border-gray-300"
            )}
            onClick={() => setIsChangingFilters(true)}
          >
            <span>
              <Trans ns="common" i18nKey="filterBar.button.filters">
                Filters
              </Trans>
            </span>

            <HiOutlineFilter size={20} />
          </button>

          <button
            type="reset"
            className={tw("block", "ml-auto", "text-gray-900")}
            onClick={clearFilters}
          >
            <Trans ns="common" i18nKey="filterBar.button.clearFilters">
              Clear filters
            </Trans>
          </button>

          {isChangingFilters && (
            <FilterPopup
              filters={filters}
              defaultValues={filterValues}
              clearFilters={clearFilters}
              onApply={(values) => {
                setFilterValues(values);

                setIsChangingFilters(false);
              }}
              onCancel={() => setIsChangingFilters(false)}
            />
          )}
        </div>
      )}
    </>
  );
};
