/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  INVOICE_FRAGMENT,
  InvoiceFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const DUPLICATE_INVOICE = gql`
  mutation ($id: ID!) {
    duplicateInvoice(input: { id: $id }) {
      invoice {
        ...InvoiceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoice?: InvoiceFragment | null;
  errors: ErrorFragment[];
}

interface DuplicateInvoiceMutation {
  duplicateInvoice: Payload | null;
}

export const useDuplicateInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.duplicatedInvoice",
    "Invoice was duplicated successfully"
  );
  // Values for extraction
  // t("common:success.duplicatedInvoice", "Invoice was duplicated successfully")

  const [mutation, { data, loading }] = useMutation<
    DuplicateInvoiceMutation,
    { id: string }
  >(DUPLICATE_INVOICE);

  const duplicateInvoice = (
    id: string,
    onSuccess?: (invoice: InvoiceFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.duplicateInvoice?.errors ?? [];
        const invoice = data?.duplicateInvoice?.invoice;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && invoice && onSuccess(invoice),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    invoice: data?.duplicateInvoice?.invoice,
    duplicateInvoice,
    isLoading: loading,
    errors: data?.duplicateInvoice?.errors ?? [],
  };
};
