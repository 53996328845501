import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { Trans } from "translations";
import { tw } from "tw-generated";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "value"
  > {
  id: string;
  label: string;
  countryCodes: { label: string; value: string }[];
  registerCountry?: UseFormRegisterReturn;
  registerNumber?: UseFormRegisterReturn;
  value?: { countryCode: string; number: string };
  hint?: string;
  helpText?: string;
  errorMessage?: string;
}

export default ({
  id,
  label,
  countryCodes,
  registerCountry,
  registerNumber,
  value,
  hint,
  helpText,
  errorMessage,
  children,
  ...props
}: React.PropsWithChildren<Props>): JSX.Element => {
  const hasHelpText = helpText !== undefined;
  const hasError = errorMessage !== undefined;

  const helpTextId = hasHelpText && `${id}-help`;
  const errorId = hasError && `${id}-error`;
  const inputDescriptionIDs =
    hasHelpText || hasError ? `${helpTextId} ${errorId}` : undefined;

  const labelContainerStyles = tw("flex", "justify-between");

  const labelStyles = tw("block", "text-sm", "text-gray-700");

  const hintStyles = tw("text-sm", "text-gray-500");

  const inputContainerStyles = tw(
    "mt-1",
    "relative",
    "rounded-md",
    "shadow-sm"
  );

  const inputBase = tw(
    "block",
    "w-full",
    "shadow-sm",
    "sm:text-sm",
    "rounded-md",
    "focus:ring-gray-900",
    "focus:border-gray-900",
    "border-gray-300",
    { "pl-16": countryCodes.length > 0 }
  );
  const inputError = tw("border-2", "border-error", "bg-error-light");
  const inputDisabled = tw("bg-gray-100", "text-gray-700");
  const inputStyles = tw(inputBase, {
    [inputError]: hasError,
    [inputDisabled]: props.disabled,
  });

  const countryCodeWrapperStyles = tw(
    "absolute",
    "inset-y-0",
    "flex",
    "items-center",
    "left-0",
    {
      "text-gray-400": !hasError,
      "text-error": hasError,
    }
  );

  const textBase = tw("mt-2", "text-sm");
  const helpTextStyles = tw(textBase, "text-gray-700");
  const errorMessageStyles = tw(textBase, "text-error");

  return (
    <div>
      <div className={labelContainerStyles}>
        <label htmlFor={id} className={labelStyles}>
          {label}
        </label>

        {hint && <span className={hintStyles}>{hint}</span>}
      </div>

      <div className={inputContainerStyles}>
        {countryCodes.length > 0 && (
          <div className={countryCodeWrapperStyles}>
            <label htmlFor="phoneCountry" className={tw("sr-only")}>
              <Trans ns="common" i18nKey="phoneNumberInput.countryCode.label">
                Country code
              </Trans>
            </label>

            <select
              {...registerCountry}
              id={`${id}Country`}
              className={tw(
                "focus:ring-gray-900",
                "focus:border-gray-900",
                "h-full",
                "py-0",
                "pl-3",
                "pr-7",
                "border-transparent",
                "bg-transparent",
                "text-gray-500",
                "sm:text-sm",
                "rounded-md"
              )}
              disabled={props.disabled}
              value={value?.countryCode}
            >
              {countryCodes.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )}

        <input
          {...props}
          {...registerNumber}
          id={`${id}Number`}
          type="text"
          className={inputStyles}
          aria-invalid={hasError}
          aria-describedby={inputDescriptionIDs}
          value={value?.number}
        />
      </div>

      {helpTextId && (
        <p className={helpTextStyles} id={helpTextId}>
          {helpText}
        </p>
      )}

      {errorId && (
        <p className={errorMessageStyles} id={errorId}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};
