/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { QueryJobChecksArgs } from "graphql/types";

import { JOB_CHECK_FRAGMENT, JobCheckFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const JOB_CHECKS = gql`
  query ($page: Int, $perPage: Int, $jobCheckType: JobCheckTypeEnum) {
    jobChecks(page: $page, perPage: $perPage, jobCheckType: $jobCheckType) {
      items {
        ...JobCheckFragment
      }
      total
    }
  }
  ${JOB_CHECK_FRAGMENT}
`;

export type JobChecksQuery = {
  jobChecks: PaginatedPayload<JobCheckFragment>;
};

type QueryArgs = Pick<QueryJobChecksArgs, "page" | "perPage" | "jobCheckType">;

export const useJobChecksQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<JobChecksQuery, QueryArgs>(
    JOB_CHECKS,
    { variables: { ...args } }
  );

  const jobChecks = data?.jobChecks.items?.filter(Boolean) ?? [];

  return { jobChecks, isLoading };
};

export const useJobChecksLazyQuery = () => {
  const [getJobChecksQuery, { data, loading: isLoading, called }] =
    useLazyQuery<JobChecksQuery, QueryArgs>(JOB_CHECKS, {
      fetchPolicy: "network-only",
    });

  const jobChecks = data?.jobChecks.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(jobChecks?: JobCheckFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(jobChecks);
      resolveRef.current = undefined;
    }
  }, [jobChecks, called, isLoading]);

  const getJobChecks = async () => {
    getJobChecksQuery();

    return new Promise<JobCheckFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getJobChecks,
    jobChecks,
    isLoading,
  };
};
