import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { Button, Link } from "components/common/basic";
import { Page } from "components/common/wrappers";
import { useLocalizedRoutePath } from "utils";
import happyPeople from "assets/illustrations/modals/happyPeople.svg";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const getRoutePath = useLocalizedRoutePath();

  return (
    <Page title={t("freelanceProfileWizard.success.pageTitle", "Success")}>
      <div
        className={tw(
          "flex",
          "flex-col",
          "items-center",
          "mx-auto",
          "space-y-10"
        )}
      >
        <img
          className={tw("h-40", "w-auto")}
          src={happyPeople}
          alt="Happy people"
        />

        <h1
          className={tw("w-full", "text-lg", "font-extrabold", "text-center")}
        >
          <Trans ns="account" i18nKey="freelanceProfileWizard.success.title">
            You are all set to start sending invoices and making money
          </Trans>
        </h1>

        <div>
          <Link
            id="freelance_profile__success-view_my_dashboard"
            to={getRoutePath("DASHBOARD")}
            unstyled
          >
            <Button id="freelance_profile__success-view_my_dashboard">
              <Trans
                ns="account"
                i18nKey="freelanceProfileWizard.success.button"
              >
                View my dashboard
              </Trans>
            </Button>
          </Link>
        </div>
      </div>
    </Page>
  );
};
