import { useState } from "react";
import { HiChevronDown } from "react-icons/hi";

import tw from "tw-generated";

import BESearch from "../BESearch";

interface Option {
  label: string;
  value: string;
}

interface Props {
  lazyQuery: {
    options: Option[];
    getOptions: (
      page: number,
      perPage: number,
      searchInput?: string
    ) => Promise<unknown>;
    total: number;
    isLoading: boolean;
  };
  onSelect: (value: string) => void;
  placeholder: string;
  label: string;
}

export default ({
  lazyQuery,
  onSelect,
  placeholder,
  label,
}: Props): JSX.Element => {
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState<Option>();

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    lazyQuery.getOptions(p, perPage, searchInput ?? keyword).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  const buttonLabel = (
    <>
      {selected?.label ?? placeholder}

      <HiChevronDown size={20} className={tw("text-gray-400")} />
    </>
  );

  return (
    <BESearch<Option>
      id={label}
      itemKey="value"
      items={lazyQuery.options}
      page={page}
      pages={Math.ceil(lazyQuery.total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={(item) => <p>{item.label}</p>}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        onSelect(option.value);
        setSelected(option);
      }}
      buttonLabel={buttonLabel}
      label={label}
      isLoading={lazyQuery.isLoading}
      fixedHeight="h-72"
    />
  );
};
