import tw from "tw-generated";
import { Trans } from "translations";
import { Button, Card, CardFooter } from "components/common/basic";
import { ClientFragment } from "graphql/fragments";

import PopupTemplate from "../PopupTemplate";
import EditClient from "./EditClient";
import AddClient from "./AddClient";

interface Props {
  onClose: () => void;
  clientOrFreelanceProfileId: ClientFragment | string;
  onSuccess?: (client: ClientFragment) => void;
}

export default ({
  clientOrFreelanceProfileId,
  onClose,
  onSuccess,
}: Props): JSX.Element => {
  const formId = "client-creation-form";

  const closeOnSuccess = (client: ClientFragment) => {
    onSuccess?.(client);

    onClose();
  };

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="client_creation-cancel"
              variant="tertiary"
              size="sm"
              onClick={onClose}
            >
              <Trans ns="common" i18nKey="popup.clientCreation.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="client_creation-save"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="popup.clientCreation.submit">
                Save
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        {typeof clientOrFreelanceProfileId === "string" ? (
          <AddClient
            formId={formId}
            freelanceProfileId={clientOrFreelanceProfileId}
            onClose={onClose}
            onSuccess={closeOnSuccess}
          />
        ) : (
          <EditClient
            formId={formId}
            client={clientOrFreelanceProfileId}
            onClose={onClose}
            onSuccess={closeOnSuccess}
          />
        )}
      </Card>
    </PopupTemplate>
  );
};
