import { gql } from "@apollo/client";

import {
  Document,
  DocumentRecipientStatusEnum,
  Signature,
} from "graphql/types";

import { AttachmentFragment, ATTACHMENT_FRAGMENT } from "./attachment";
import { DocumentFileFragment, DOCUMENT_FILE_FRAGMENT } from "./documentFile";

export const SIGNATURE_FRAGMENT = gql`
  fragment SignatureFragment on Signature {
    id
    clientName
    customFields {
      format
      helpText
      name
      required
    }
    customValues {
      name
      value
    }
    designation
    email
    name
    phone
    notifiedAt
    refusedAt
    signatureNumber
    signedAt
    status
    userId
    document {
      id
      activatedOn
      activation
      expiration
      expirationMonths
      expiredOn
      name
      status
      documentFiles {
        ...DocumentFileFragment
      }
      signedDocument {
        ...AttachmentFragment
      }
      inADocumentGroup
    }
    documentRecipient {
      id
      email
      status
      documentVersion {
        id
        details
        hosts {
          email
        }
        documentRecipients {
          email
          status
        }
      }
    }
  }
  ${DOCUMENT_FILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

interface SignatureDocument
  extends Pick<
    Document,
    | "id"
    | "activatedOn"
    | "activation"
    | "expiration"
    | "expirationMonths"
    | "expiredOn"
    | "name"
    | "status"
    | "inADocumentGroup"
  > {
  documentFiles: DocumentFileFragment[];
  signedDocument?: AttachmentFragment;
}

export interface SignatureFragment
  extends Pick<
    Signature,
    | "id"
    | "clientName"
    | "customFields"
    | "customValues"
    | "designation"
    | "email"
    | "name"
    | "phone"
    | "notifiedAt"
    | "refusedAt"
    | "signatureNumber"
    | "signedAt"
    | "status"
    | "userId"
  > {
  document: SignatureDocument;
  documentRecipient?: {
    id: string;
    email: string;
    status: DocumentRecipientStatusEnum;
    documentVersion: {
      id: string;
      details?: string;
      hosts: {
        email: string;
      }[];
      documentRecipients: {
        email: string;
        status: DocumentRecipientStatusEnum;
      }[];
    };
  };
}
