import React, { useState } from "react";

import { ErrorCodeEnum, FreelanceTypeEnum } from "graphql/types";
import {
  AccountFragment,
  BankAccountFragment,
  ClientFragment,
  DocumentGroupFragment,
  ErrorFragment,
  FreelanceProfileFragment,
  HelpArticleFragment,
  JobFragment,
  PersonFragment,
  ReferencePersonFragment,
} from "graphql/fragments";
import {
  AgreementCreation,
  BlockedManualInvoicing,
  ClientCreation,
  FreelanceProfile,
  ReferencePerson,
  ConfirmAction,
  JobCreation,
  PhoneNumberVerification,
  Signature,
  AddPerson,
  AddPeople,
  AddTeam,
  ViewBankAccount,
  RemovePerson,
  StepLoaderPopup,
} from "components/common/popups";

import { useDialog } from "./useDialog";
import AddBankAccount from "components/common/popups/AddBankAccount";
import {
  AdditionMethod,
  AddPeopleIcon,
} from "components/common/popups/AddPeople/AddPeople";

type ErrorHandler = (errorMessage: string) => Promise<unknown>;

interface BlockedManualInvoicingPopup {
  id: "BlockedManualInvoicing";
  props: {
    helpArticle: HelpArticleFragment;
    clientId: string;
    freelanceProfileId: string;
  };
}
interface ConfirmActionPopup {
  id: "ConfirmAction";
  props: {
    onConfirmation: () => void;
    confirmationLabel: string;
    confirmationHeading: string;
    confirmationBody: string;
  };
}
interface ClientCreationPopup {
  id: "ClientCreation";
  props: {
    clientOrFreelanceProfileId: ClientFragment | string;
    onSuccess?: (client: ClientFragment) => void;
  };
}
interface FreelanceProfilePopup {
  id: "FreelanceProfile";
  props: {
    freelanceType: FreelanceTypeEnum;
    profile?: FreelanceProfileFragment;
    onSuccess?: (profile: FreelanceProfileFragment) => void;
  };
}
interface ReferencePersonPopup {
  id: "ReferencePerson";
  props: {
    parent: ClientFragment | FreelanceProfileFragment;
    referencePerson: ReferencePersonFragment | null;
    onSuccess: (person: ReferencePersonFragment) => void;
  };
}
interface JobCreationPopup {
  id: "JobCreation";
  props?: { job: JobFragment };
}
interface AgreementCreationPopup {
  id: "AgreementCreation";
  props?: {
    documentGroup?: DocumentGroupFragment;
    onSuccess?: (documentGroup: DocumentGroupFragment) => void;
  };
}
interface SignaturePopup {
  id: "Signature";
  props: {
    documentName: string;
    onSignature: (data: string) => void;
  };
}
interface AddPersonPopup {
  id: "AddPerson";
  props?: { onSuccess?: (person: PersonFragment) => void };
}

interface AddPeoplePopup {
  id: "AddPeople";
  props: {
    title: string;
    onSuccess?: () => void;
    availableMethods?: AdditionMethod[];
    description?: string;
    icon?: AddPeopleIcon;
  };
}

interface StepLoaderPopup {
  id: "StepLoader";
  props: {
    title: string;
    steps: string[];
    action: (
      onSuccess: () => void,
      onError: (errors: ErrorFragment[]) => void
    ) => void;
    isAutoComplete?: boolean;
  };
}

interface RemovePersonPopup {
  id: "RemovePerson";
  props: { person: PersonFragment; onSuccess?: () => void };
}

interface AddBankAccountPopup {
  id: "AddBankAccount";
  props?: { freelanceProfileId?: string; onSuccess?: () => void };
}

interface ViewBankAccountPopup {
  id: "ViewBankAccount";
  props: { bankAccount: BankAccountFragment };
}

interface AddTeamPopup {
  id: "AddTeam";
  props: { onSuccess?: (account: AccountFragment) => void };
}

export type Popup =
  | BlockedManualInvoicingPopup
  | ConfirmActionPopup
  | ClientCreationPopup
  | FreelanceProfilePopup
  | ReferencePersonPopup
  | JobCreationPopup
  | AgreementCreationPopup
  | SignaturePopup
  | AddPersonPopup
  | AddPeoplePopup
  | RemovePersonPopup
  | AddBankAccountPopup
  | ViewBankAccountPopup
  | AddTeamPopup
  | StepLoaderPopup;

interface Context {
  handleSecondSecurityLevelRequiredError: ErrorHandler;
  openPopup: (popup: Popup) => void;
  closePopups: () => void;
}

export const PopupContext = React.createContext<Context>({
  handleSecondSecurityLevelRequiredError: () => new Promise(() => null),
  openPopup: () => null,
  closePopups: () => null,
});

interface Props {
  children?: React.ReactNode;
}

export const PopupProvider = ({ children }: Props): JSX.Element => {
  const [activePopup, setActivePopup] = useState<Popup>();
  const closePopups = () => setActivePopup(undefined);

  const {
    reject: rejectPhoneDialog,
    resolve: resolvePhoneDialog,
    isOpen: isPhoneDialogOpened,
    handleError: handleSecondSecurityLevelRequiredError,
  } = useDialog((code: string) => code === ErrorCodeEnum.AuthLevel_2Required);

  return (
    <PopupContext.Provider
      value={{
        handleSecondSecurityLevelRequiredError,
        openPopup: setActivePopup,
        closePopups,
      }}
    >
      {children}

      {((popup?: Popup) => {
        switch (popup?.id) {
          case "BlockedManualInvoicing":
            return (
              <BlockedManualInvoicing onClose={closePopups} {...popup.props} />
            );

          case "ConfirmAction":
            return <ConfirmAction onClose={closePopups} {...popup.props} />;

          case "ClientCreation":
            return <ClientCreation onClose={closePopups} {...popup.props} />;

          case "FreelanceProfile":
            return <FreelanceProfile onClose={closePopups} {...popup.props} />;

          case "ReferencePerson":
            return <ReferencePerson onClose={closePopups} {...popup.props} />;

          case "JobCreation":
            return <JobCreation onClose={closePopups} {...popup.props} />;

          case "AgreementCreation":
            return <AgreementCreation onClose={closePopups} {...popup.props} />;

          case "Signature":
            return <Signature onClose={closePopups} {...popup.props} />;

          case "AddPerson":
            return <AddPerson onClose={closePopups} {...popup.props} />;

          case "AddPeople":
            return <AddPeople onClose={closePopups} {...popup.props} />;

          case "RemovePerson":
            return <RemovePerson onClose={closePopups} {...popup.props} />;

          case "AddBankAccount":
            return <AddBankAccount onClose={closePopups} {...popup.props} />;

          case "ViewBankAccount":
            return <ViewBankAccount onClose={closePopups} {...popup.props} />;

          case "StepLoader":
            return <StepLoaderPopup onClose={closePopups} {...popup.props} />;

          case "AddTeam":
            return <AddTeam onClose={closePopups} {...popup.props} />;

          default:
            return null;
        }
      })(activePopup)}

      {isPhoneDialogOpened && (
        <PhoneNumberVerification
          onClose={rejectPhoneDialog}
          onSuccessfulVerification={resolvePhoneDialog}
        />
      )}
    </PopupContext.Provider>
  );
};
