import { gql } from "@apollo/client";

import { Attachment } from "graphql/types";

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFragment on Attachment {
    contentType
    downloadPath
    thumbPath
  }
`;

export interface AttachmentFragment
  extends Pick<Attachment, "contentType" | "downloadPath" | "thumbPath"> {}
