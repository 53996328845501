/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { INVOICES, InvoicesQuery } from "graphql/queries";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const DESTROY_INVOICE = gql`
  mutation ($id: ID!) {
    destroyInvoice(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface DestroyInvoiceMutation {
  destroyInvoice: Payload | null;
}

export const useDestroyInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.removedInvoice",
    "Invoice was removed successfully"
  );
  // Values for extraction
  // t("common:success.removedInvoice", "Invoice was removed successfully")

  const [mutation, { data, loading }] = useMutation<
    DestroyInvoiceMutation,
    { id: string }
  >(DESTROY_INVOICE);

  const destroyInvoice = (id: string, onSuccess?: () => void) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            invoices() {
              const existingInvoices = cache.readQuery<InvoicesQuery>({
                query: INVOICES,
              });
              if (!existingInvoices) return;

              const items = existingInvoices.invoices.items?.filter(
                (invoiceItem) => invoiceItem?.id !== id
              );

              cache.writeQuery<InvoicesQuery>({
                query: INVOICES,
                data: {
                  invoices: {
                    items,
                    total: existingInvoices.invoices.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyInvoice?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyInvoice,
    isLoading: loading,
    errors: data?.destroyInvoice?.errors ?? [],
  };
};
