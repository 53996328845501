import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Button,
  Divider,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import {
  ClientFragment,
  FreelanceProfileFragment,
  ReferencePersonFragment,
} from "graphql/fragments";
import { useErrorLogger } from "hooks";
import {
  emailValidationSchema,
  phoneValidationSchema,
  referencePersonToAttributes,
} from "utils";
import {
  useUpdateClientMutation,
  useUpdateFreelanceProfileMutation,
} from "graphql/mutations";

interface Props {
  parent: ClientFragment | FreelanceProfileFragment;
  referencePerson: ReferencePersonFragment | null;
  onSuccess: (person: ReferencePersonFragment) => void;
  onClose: () => void;
}

export default ({
  parent,
  referencePerson,
  onSuccess,
  onClose,
}: Props): JSX.Element => {
  const { updateClient } = useUpdateClientMutation();
  const { updateFreelanceProfile } = useUpdateFreelanceProfileMutation();
  const isClient = "clientType" in parent;
  const updateParent = isClient ? updateClient : updateFreelanceProfile;
  const companyName = isClient ? parent.name : parent.organizationName;

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(
        t(
          "referencePersonForm.name.error.required",
          "A name is required for a reference person"
        )
      )
      .test(
        "uniqueName",
        t(
          "referencePersonForm.name.error",
          "Reference people can not have the same name"
        ),
        (value) =>
          parent.referencePeople.filter(({ name }) => {
            const isEditedPerson = name === referencePerson?.name;
            if (isEditedPerson) return false;

            return name.toLowerCase() === value?.toLowerCase();
          }).length === 0
      ),
    email: emailValidationSchema(t).test(
      "uniqueEmail",
      t(
        "referencePersonForm.email.error",
        "Reference people can not have the same email"
      ),
      (value) =>
        parent.referencePeople.filter(({ email }) => {
          const isEditedPerson = email === referencePerson?.email;
          if (isEditedPerson) return false;

          return email.toLowerCase() === value?.toLowerCase();
        }).length === 0
    ),
    phone: phoneValidationSchema(t),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ReferencePersonFragment>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);

  const onSubmit = (person: ReferencePersonFragment) => {
    const updatedReferencePeople = referencePerson
      ? parent.referencePeople.map((refPerson) =>
          refPerson.email === person.email ? person : refPerson
        )
      : [...parent.referencePeople, person];

    updateParent(
      {
        id: parent.id,
        attributes: {
          referencePeople: updatedReferencePeople.map(
            referencePersonToAttributes
          ),
        },
      },
      () => {
        onSuccess(person);

        onClose();
      }
    );
  };

  const formId = "referencePersonForm";

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                {referencePerson ? (
                  <Trans ns="common" i18nKey="referencePersonForm_edit.title">
                    Edit Reference Person
                  </Trans>
                ) : (
                  <Trans ns="common" i18nKey="referencePersonForm_create.title">
                    Add Reference Person
                  </Trans>
                )}
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="reference_person-cancel"
              onClick={onClose}
              variant="tertiary"
              size="sm"
            >
              <Trans ns="common" i18nKey="referencePersonForm.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="reference_person-save"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="referencePersonForm.submit">
                Save
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <Divider />
        </CardBody>

        <CardBody>
          <h3 className={tw("text-lg", "font-bold")}>Reference Person</h3>

          <p>
            <Trans
              ns="common"
              i18nKey="referencePersonForm.helperText"
              defaults="Please provide the name of your contact at <0>{{ companyName }}</0>. Your invoices will mention this person information."
              values={{ companyName }}
              components={[<strong />]}
            />
          </p>
        </CardBody>

        <CardBody>
          <form
            className={tw("w-full", "space-y-4")}
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              id="name"
              {...register("name")}
              label={t(
                "referencePersonForm.name.label",
                "Name of reference person"
              )}
              errorMessage={errors.name?.message}
              defaultValue={referencePerson?.name}
            />

            <Input
              id="email"
              {...register("email")}
              type="email"
              label={t("referencePersonForm.email.label", "Email address")}
              errorMessage={errors.email?.message}
              defaultValue={referencePerson?.email}
            />

            <Input
              id="phone"
              {...register("phone")}
              label={t("referencePersonForm.phone.label", "Phone number")}
              errorMessage={errors.phone?.message}
              defaultValue={referencePerson?.phone ?? ""}
            />

            <Input
              id="referenceNumber"
              {...register("referenceNumber")}
              label={t(
                "referencePersonForm.referenceNumber.label",
                "Reference number"
              )}
              errorMessage={errors.referenceNumber?.message}
              defaultValue={referencePerson?.referenceNumber ?? ""}
              hint={t("referencePersonForm.referenceNumber.hint", "Optional")}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
