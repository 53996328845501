import React from "react";

import tw, { TTailwindString, TWidth } from "tw-generated";
import { Trans } from "translations";
import { Divider } from "components/common/basic";
import { ActionMenu } from "components/common/composite";

import { MenuItem } from "../wrappers/Layout/common/MenuList/types";

interface Column {
  title: string;
  width: TWidth;
  cellID: string;
  showTotal: boolean;
  totalStyle?: TTailwindString;
  formatTotal?: (amount: number) => string;
}

export interface Row {
  id: string;
  cells: Record<string, JSX.Element>;
  actions?: MenuItem[];
}

interface Props {
  columns: Column[];
  rows: Row[];
  totals?: Record<string, number>;
  footer?: JSX.Element;
  isCompact?: boolean;
}

export default ({
  columns,
  rows,
  totals,
  footer,
  isCompact,
}: Props): JSX.Element => {
  const hasActions =
    rows.findIndex(({ actions }) => typeof actions !== "undefined") !== -1;
  const tableStyles = tw("overflow-x-auto", "overflow-y-hidden", "grid");

  const headerStyles = tw(
    "py-3",
    "px-6",
    "space-x-3",
    "flex",
    "uppercase",
    "bg-deepBlue-50",
    "text-xs",
    "font-semibold"
  );
  const columnBase = tw("flex-grow", "flex-shrink-0", "text-left");
  const actionColumnStyles = tw(columnBase, "h-full", "w-12");

  return (
    <div className={tableStyles}>
      <div className={headerStyles}>
        {columns.map((column) => (
          <p
            key={`header-for-${column.cellID}`}
            className={tw(
              columnBase,
              column.width,
              "whitespace-nowrap",
              "overflow-hidden",
              "overflow-ellipsis"
            )}
          >
            {column.title}
          </p>
        ))}

        {hasActions && <span className={actionColumnStyles} />}
      </div>

      {rows.map(({ id, cells, actions }) => (
        <React.Fragment key={id}>
          <Divider />

          <div
            className={tw(
              "py-2",
              "px-6",
              "space-x-3",
              "flex",
              "items-center",
              "text-sm",
              "font-semibold"
            )}
          >
            {columns.map((column) => (
              <div
                key={`value-for-${column.cellID}`}
                className={tw(columnBase, column.width)}
              >
                {cells[column.cellID]}
              </div>
            ))}

            {hasActions && (
              <div
                className={tw(
                  actionColumnStyles,
                  "bg-white",
                  "flex",
                  "justify-center",
                  "items-center",
                  "sticky",
                  "right-0",
                  "focus-within:z-10"
                )}
              >
                {actions?.length ? <ActionMenu menuItems={actions} /> : null}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}

      {totals && (
        <div
          className={tw(
            "py-2",
            "px-6",
            "space-x-3",
            "flex",
            "bg-deepBlue-50",
            "text-sm",
            "font-bold",
            "text-gray-900"
          )}
        >
          {columns.map(
            (
              {
                width,
                showTotal,
                totalStyle,
                cellID,
                formatTotal = (amount) => amount,
              },
              index
            ) => (
              <React.Fragment key={`total-for-${cellID}`}>
                {index === 0 ? (
                  <p className={tw(columnBase, width)}>
                    <Trans ns="common" i18nKey="table.footer.total">
                      Total:
                    </Trans>
                  </p>
                ) : showTotal ? (
                  <p className={tw(columnBase, width, totalStyle)}>
                    {formatTotal(totals[cellID])}
                  </p>
                ) : (
                  <span className={tw(columnBase, width)} />
                )}
              </React.Fragment>
            )
          )}

          {hasActions && <span className={actionColumnStyles} />}
        </div>
      )}

      {!isCompact && (
        <div className={tw(totals ? "h-40" : "h-56")}>{footer}</div>
      )}
    </div>
  );
};
