import tw from "tw-generated";
import { Trans } from "translations";
import { Button, Card, CardBody, CardFooter } from "components/common/basic";
import warningSymbol from "assets/symbols/warning.svg";

import PopupTemplate from "./PopupTemplate";

interface Props {
  onClose: () => void;
  onConfirmation: () => void;
  confirmationLabel: string;
  confirmationHeading: string;
  confirmationBody: string;
}

export default ({
  onClose,
  onConfirmation,
  confirmationLabel,
  confirmationHeading,
  confirmationBody,
}: Props): JSX.Element => (
  <PopupTemplate onClose={onClose}>
    <Card
      footer={
        <CardFooter
          className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
        >
          <Button
            id="confirmation_action-cancel"
            variant="tertiary"
            size="sm"
            onClick={onClose}
          >
            <Trans ns="common" i18nKey="popup.confirmAction.cancel">
              Cancel
            </Trans>
          </Button>

          <Button
            id="confirmation_popup-confirm"
            onClick={() => {
              onConfirmation();
              onClose();
            }}
            variant="primary"
            size="sm"
          >
            {confirmationLabel}
          </Button>
        </CardFooter>
      }
    >
      <CardBody className={tw("max-w-lg")}>
        <div className={tw("w-full", "flex", "space-x-4")}>
          <img
            className={tw("h-10", "object-contain")}
            src={warningSymbol}
            alt="Warning sign"
          />

          <div className={tw("space-y-2")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              {confirmationHeading}
            </h3>

            <p className={tw("text-sm", "text-gray-500")}>{confirmationBody}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  </PopupTemplate>
);
