import { useContext, useEffect, useState } from "react";

import { useFreelanceProfilesLazyQuery } from "graphql/queries";
import { FreelanceTypeEnum } from "graphql/types";
import { FreelanceProfileFragment } from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { Spinner } from "components/common/basic";

import FreelanceTypeSelector from "./FreelanceTypeSelector";
import FreelanceProfileCreation from "./FreelanceProfileCreation";

interface Props {
  profile?: FreelanceProfileFragment;
}
export default ({ profile }: Props): JSX.Element => {
  const [freelanceType, setFreelanceType] = useState<FreelanceTypeEnum>();

  const { session } = useContext(AuthContext);

  const { getFreelanceProfiles } = useFreelanceProfilesLazyQuery();

  useEffect(() => {
    getFreelanceProfiles();
  }, []);

  if (!session) return <Spinner />;

  if (profile)
    return (
      <FreelanceProfileCreation
        freelanceType={profile.freelanceType}
        profile={profile}
      />
    );

  const type = session.hasIndividualFreelanceProfile
    ? FreelanceTypeEnum.Organization
    : freelanceType;
  if (type) return <FreelanceProfileCreation freelanceType={type} />;

  return <FreelanceTypeSelector setFreelanceType={setFreelanceType} />;
};
