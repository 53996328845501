import tw from "tw-generated";

import MenuItemComponent from "./MenuItem";
import { MenuItem } from "./types";

interface Props {
  menuItems: MenuItem[];
  iconsOnly?: boolean;
  isSubmenu?: boolean;
  closeSelf?: () => void;
}

export default ({
  menuItems,
  iconsOnly,
  isSubmenu,
  closeSelf,
}: Props): JSX.Element => {
  const submenuStyles = tw("px-2", "mb-4", {
    [tw("ml-7", "border-l", "border-gray-100")]: !iconsOnly,
  });
  const listStyles = tw(
    "space-y-1",
    "max-w-full",
    isSubmenu ? submenuStyles : tw("p-4", "w-full")
  );

  return (
    <ul className={listStyles}>
      {menuItems.map((item, index) => (
        <MenuItemComponent
          key={index}
          item={item}
          closeSelf={closeSelf}
          iconsOnly={iconsOnly}
        />
      ))}
    </ul>
  );
};
