import { useContext, useEffect, useState } from "react";

import { IntlContext } from "providers/i18n";
import { LanguageEnum } from "graphql/types";

import en from "./en.md";
import no from "./no.md";
import { MarkdownTemplate } from "components/markdown/composite";
import { useTranslation } from "translations";

export default (): JSX.Element => {
  const [termsAndConditions, setTermsAndConditions] = useState("");

  const { t } = useTranslation("common");
  const { currentLocale } = useContext(IntlContext);

  useEffect(() => {
    fetch(currentLocale === LanguageEnum.En ? en : no)
      .then((response) => response.text())
      .then(setTermsAndConditions);
  }, [currentLocale]);

  return (
    <MarkdownTemplate
      title={t("markdown_privacy.title", "Privacy policy")}
      markdownText={termsAndConditions}
    />
  );
};
