/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { QueryFreelanceProfilesArgs } from "graphql/types";

import {
  BANK_ACCOUNT_FRAGMENT,
  FreelanceProfileFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const BANK_ACCOUNTS_BY_PROFILE = gql`
  query (
    $page: Int
    $perPage: Int
    $sortBy: FreelanceProfilesSortByEnum
    $filter: FreelanceProfilesFilter
  ) {
    freelanceProfiles(
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      filter: $filter
    ) {
      items {
        id
        freelanceType
        organizationName
        organizationNumber
        firstName
        lastName
        bankAccounts {
          ...BankAccountFragment
        }
      }
      total
    }
  }
  ${BANK_ACCOUNT_FRAGMENT}
`;

type BankAccountsByFreelanceProfile = Pick<
  FreelanceProfileFragment,
  | "id"
  | "freelanceType"
  | "organizationName"
  | "organizationNumber"
  | "firstName"
  | "lastName"
  | "bankAccounts"
>;

export type BankAccountsByProfileQuery = {
  freelanceProfiles: PaginatedPayload<BankAccountsByFreelanceProfile>;
};

type QueryArgs = Pick<
  QueryFreelanceProfilesArgs,
  "page" | "perPage" | "sortBy" | "filter"
>;

export const useBankAccountsByProfileQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<
    BankAccountsByProfileQuery,
    QueryArgs
  >(BANK_ACCOUNTS_BY_PROFILE, {
    variables: { ...args },
  });

  const freelanceProfiles =
    data?.freelanceProfiles.items?.filter(Boolean) ?? [];

  return {
    freelanceProfiles,
    isLoading,
  };
};

export const useBankAccountsByProfileLazyQuery = () => {
  const [getBankAccountsByProfileQuery, { data, loading: isLoading, called }] =
    useLazyQuery<BankAccountsByProfileQuery, QueryArgs>(
      BANK_ACCOUNTS_BY_PROFILE,
      {
        fetchPolicy: "network-only",
      }
    );

  const freelanceProfiles =
    data?.freelanceProfiles.items?.filter(Boolean) ?? [];
  const total = data?.freelanceProfiles.total ?? 0;

  const resolveRef =
    useRef<(freelanceProfiles?: BankAccountsByFreelanceProfile[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(freelanceProfiles);
      resolveRef.current = undefined;
    }
  }, [freelanceProfiles, called, isLoading]);

  const getFreelanceProfiles = async (args?: QueryArgs) => {
    const variables = { ...args };

    getBankAccountsByProfileQuery({ variables });

    return new Promise<BankAccountsByFreelanceProfile[] | undefined>(
      (resolve) => {
        resolveRef.current = resolve;
      }
    );
  };

  return { getFreelanceProfiles, freelanceProfiles, total, isLoading, called };
};
