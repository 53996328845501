import tw from "tw-generated";
import mmLonn from "assets/branding/mmLonn.svg";
import { FreelanceProfileFragment } from "graphql/fragments";
import { CardBody, Spinner } from "components/common/basic";
import { useSessionQuery } from "graphql/queries";

import CreateIndProfileForm from "./CreateIndProfileForm";
import Agreement from "./Agreement";

interface Props {
  formId: string;
  onSuccessfulEdit: (profile: FreelanceProfileFragment) => void;
  setIsChecked: () => void;
  profile?: FreelanceProfileFragment;
  isChecked: boolean;
}

export default ({
  formId,
  onSuccessfulEdit,
  setIsChecked,
  profile,
  isChecked,
}: Props): JSX.Element => {
  const { session, isLoading } = useSessionQuery();
  const identity = session?.account.identities?.[0];

  return (
    <div className={tw("mx-auto", "max-w-screen-sm")}>
      <div className={tw("p-4", "mb-4", "bg-blue-900")}>
        <img className={tw("h-9")} src={mmLonn} alt="Manymore lonn" />
      </div>

      <CardBody>
        {isLoading ? (
          <div className={tw("w-full")}>
            <Spinner />
          </div>
        ) : (
          <CreateIndProfileForm
            identity={identity}
            onSuccessfulEdit={onSuccessfulEdit}
            freelanceProfile={profile}
            formId={formId}
          />
        )}
      </CardBody>

      <Agreement
        isChecked={isChecked}
        onChecked={setIsChecked}
        isOrganization={false}
      />
    </div>
  );
};
