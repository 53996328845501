import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { NameSpace } from "translations/shared";
import { IntlContext } from "providers/i18n";
import { Spinner } from "components/common/basic";

interface Props {
  nameSpace: NameSpace;
}

export default ({ nameSpace }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const { currentLocale } = useContext(IntlContext);

  useEffect(() => {
    const hasResourceBundle = i18n.hasResourceBundle(currentLocale, nameSpace);

    if (hasResourceBundle) setIsLoading(false);
    else i18n.loadNamespaces(nameSpace).then(() => setIsLoading(false));
  }, [nameSpace]);

  if (isLoading) return <Spinner />;

  return <Outlet />;
};
