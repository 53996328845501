import { HiX } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Input,
  Textarea,
  Link,
} from "components/common/basic";
import { useErrorLogger } from "hooks";
import { PopupTemplate } from "components/common/popups";
import {
  useCreateDocumentGroupMutation,
  useUpdateDocumentGroupMutation,
} from "graphql/mutations";
import { DocumentGroupFragment } from "graphql/fragments";

export interface FormValues {
  name: string;
  description: string;
}

interface Props {
  onClose: () => void;
  documentGroup?: DocumentGroupFragment;
  onSuccess?: (documentGroup: DocumentGroupFragment) => void;
}

export default ({ onClose, documentGroup, onSuccess }: Props): JSX.Element => {
  const { createDocumentGroup } = useCreateDocumentGroupMutation();
  const { updateDocumentGroup } = useUpdateDocumentGroupMutation();

  const { t } = useTranslation("common");
  const formId = "add-agreement-form";

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t(
        "popup.agreementCreation.error.error.name.required",
        "A name is required"
      )
    ),
    description: Yup.string().required(
      t(
        "popup.agreementCreation.error.error.description.required",
        "A description is required"
      )
    ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = (attributes: FormValues) =>
    documentGroup
      ? updateDocumentGroup({ id: documentGroup.id, attributes }, (updated) => {
          onSuccess?.(updated);

          onClose();
        })
      : createDocumentGroup(attributes, (created) => {
          onSuccess?.(created);

          onClose();
        });

  return (
    <PopupTemplate onClose={onClose} maxWidth="max-w-screen-xl">
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="agreement_creation-save_and_continue"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="popup.agreementCreation.submit">
                Save and continue
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <div className={tw("w-full", "flex", "justify-between")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              <Trans ns="common" i18nKey="popup.agreementCreation.heading">
                Create a new Bulk Sign document
              </Trans>
            </h3>

            <button onClick={onClose}>
              <HiX size={24} className={tw("text-gray-400")} />
            </button>
          </div>

          <p className={tw("text-gray-900")}>
            <Trans ns="common" i18nKey="popup.agreementCreation.explanation">
              With our self service eSignature sulution you are incontrol and
              are able to manage people, versions and who has and who hasn’t
              signed yet. View our{" "}
              <Link
                id="agreement_creation-go_to_help"
                color="black"
                isExternal
                to={t(
                  "popup.agreementCreation.explanation.link",
                  "https://en.support.manymore.com"
                )}
              >
                help article
              </Link>{" "}
              to get more insight.
              <br />
              Step 1: Add your Parent information (This page)
              <br />
              Step 2: Create your first version
              <br />
              Step 3: Add the people who should sign or send/embed the document
              link
            </Trans>
          </p>
        </CardBody>

        <Divider />

        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <CardBody>
            <h4 className={tw("text-lg", "font-bold")}>
              <Trans
                ns="common"
                i18nKey="popup.agreementCreation.documentInfo.heading"
              >
                Document info
              </Trans>
            </h4>

            <div className={tw("w-1/2", "space-y-8")}>
              <Input
                id="name"
                {...register("name")}
                label={t(
                  "popup.agreementCreation.documentInfo.name.label",
                  "Give your BulkSign request a name"
                )}
                errorMessage={errors.name?.message}
                defaultValue={documentGroup?.name ?? ""}
              />

              <Textarea
                label={t(
                  "popup.agreementCreation.documentInfo.description.label",
                  "Document description"
                )}
                id="description"
                {...register("description")}
                errorMessage={errors.description?.message}
                rows={5}
                defaultValue={documentGroup?.description ?? ""}
              />
            </div>
          </CardBody>
        </form>
      </Card>
    </PopupTemplate>
  );
};
