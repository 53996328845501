import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import { useApolloClient } from "@apollo/client";

import { AccountTypeEnum } from "graphql/types";
import { useSessionLazyQuery } from "graphql/queries";
import { SessionFragment } from "graphql/fragments";
import { storage } from "utils";
import { Spinner } from "components/common/basic";

interface Context {
  isLoggedIn: boolean;
  isPartnerAccount: boolean;
  session?: SessionFragment;
  login: (token: string) => void;
  logout: () => void;
  refresh: () => Promise<SessionFragment | undefined>;
}

export const AuthContext = React.createContext<Context>({
  isLoggedIn: false,
  isPartnerAccount: false,
  session: undefined,
  login: () => null,
  logout: () => null,
  refresh: () => new Promise(() => undefined),
});

interface Props {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [token, setToken] = useState(Cookie.get("dp-token"));
  const login = (authToken: string) => setToken(authToken);
  const logout = () => setToken(undefined);

  const client = useApolloClient();
  const { isLoading, session, getSession } = useSessionLazyQuery();

  useEffect(() => {
    // Reset store on load to prevent breaking changes from BE to affect users
    client.resetStore();
  }, []);

  // Get session for the current token
  useEffect(() => {
    if (!token) {
      storage.removeSession("dp-token");
      Cookie.remove("dp-token");
      client.clearStore();

      return;
    }

    storage.setSession("dp-token", token);
    Cookie.set("dp-token", token);
    session
      ? client.resetStore()
      : setTimeout(() => {
          getSession();
        }, 0);
  }, [token]);

  // Prevent errors when BE returns a null session
  useEffect(() => {
    if (session === null) logout();
  }, [session]);

  // Ensure that all tabs have the same session
  useEffect(() => {
    const checkToken = () => {
      const currentToken = Cookie.get("dp-token");
      if (!currentToken) logout();
      else if (currentToken !== token) login(currentToken);
    };

    window.addEventListener("visibilitychange", checkToken);
    return () => window.removeEventListener("visibilitychange", checkToken);
  }, []);

  // Make data available for analytics
  useEffect(() => {
    if (!session) return;

    const consentCookie: string | undefined = Cookie.get("cookieyes-consent");
    const hasConsented = consentCookie
      ?.split(",")
      .some((str) => str === "analytics:yes");

    if (hasConsented)
      window.dataLayer?.push({
        userID: session.user.id,
        email: session.user.email,
      });
  }, [session?.user.id]);

  const isLoggedIn = Boolean(token);
  const isPartnerAccount =
    session?.account?.accountType === AccountTypeEnum.Team;

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isPartnerAccount,
        session,
        login,
        logout,
        refresh: getSession,
      }}
    >
      {isLoading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};
