/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { ReceiptAttributes } from "graphql/types";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  RECEIPT_FRAGMENT,
  ReceiptFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { INVOICE, InvoiceQuery } from "graphql/queries";

const CREATE_RECEIPT = gql`
  mutation ($attributes: ReceiptAttributes!, $invoiceId: ID!) {
    createReceipt(input: { invoiceId: $invoiceId, attributes: $attributes }) {
      receipt {
        ...ReceiptFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${RECEIPT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  receipt?: ReceiptFragment | null;
  errors: ErrorFragment[];
}

interface CreateReceiptMutation {
  createReceipt: Payload | null;
}

export const useCreateReceiptMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.createdReceipt",
    "Your file was uploaded successfully"
  );
  // Values for extraction
  // t("common:success.createdReceipt", "Your file was uploaded successfully")

  const [mutation, { data, loading }] = useMutation<
    CreateReceiptMutation,
    { invoiceId: string; attributes: ReceiptAttributes }
  >(CREATE_RECEIPT);

  const createReceipt = (
    invoiceId: string,
    attributes: ReceiptAttributes,
    onSuccess?: (receipt: ReceiptFragment) => void
  ) =>
    mutation({
      variables: { invoiceId, attributes },
      update(cache, { data: newReceiptData }) {
        const newReceiptNode = newReceiptData?.createReceipt?.receipt;
        if (!newReceiptNode) return;

        cache.modify({
          fields: {
            node() {
              const invoice = cache.readQuery<InvoiceQuery>({
                query: INVOICE,
              });
              if (!invoice) return;

              cache.writeQuery<InvoiceQuery>({
                query: INVOICE,
                data: {
                  node: {
                    ...invoice.node,
                    receipts: [...invoice.node.receipts, newReceiptNode],
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createReceipt?.errors ?? [];
        const receipt = data?.createReceipt?.receipt;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => receipt && onSuccess && onSuccess(receipt),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createReceipt,
    isLoading: loading,
    receipt: data?.createReceipt?.receipt,
    errors: data?.createReceipt?.errors ?? [],
  };
};
