/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateClientInput } from "graphql/types";
import {
  CLIENT_FRAGMENT,
  ClientFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { CLIENTS, ClientsQuery } from "graphql/queries";

const CREATE_CLIENT = gql`
  mutation ($input: CreateClientInput!) {
    createClient(input: $input) {
      client {
        ...ClientFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  client?: ClientFragment | null;
  errors: ErrorFragment[];
}

interface CreateClientMutation {
  createClient: Payload | null;
}

export const useCreateClientMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateClientMutation,
    { input: CreateClientInput }
  >(CREATE_CLIENT);

  const createClient = (
    input: CreateClientInput,
    onSuccess?: (client: ClientFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache, { data: newProfileData }) {
        const newClientNode = newProfileData?.createClient?.client;
        if (!newClientNode) return;

        cache.modify({
          fields: {
            clients() {
              const existingClients = cache.readQuery<ClientsQuery>({
                query: CLIENTS,
              });
              if (!existingClients) return;

              const items = [
                ...(existingClients.clients.items ?? []),
                newClientNode,
              ];

              cache.writeQuery<ClientsQuery>({
                query: CLIENTS,
                data: {
                  clients: {
                    items,
                    total: existingClients.clients.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createClient?.errors ?? [];
        const client = data?.createClient?.client;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => client && onSuccess && onSuccess(client),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createClient,
    isLoading: loading,
    client: data?.createClient?.client,
    errors: data?.createClient?.errors ?? [],
  };
};
