/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { DOCUMENT_FRAGMENT, DocumentFragment } from "graphql/fragments";

const PUBLIC_DOCUMENT = gql`
  query ($token: String!) {
    publicDocument(token: $token) {
      ... on Document {
        ...DocumentFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const usePublicDocumentLazyQuery = () => {
  const [getPublicDocumentQuery, { data, loading, called }] = useLazyQuery<
    { publicDocument: DocumentFragment },
    { token: string }
  >(PUBLIC_DOCUMENT);

  const resolveRef = useRef<(document?: DocumentFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.publicDocument);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getPublicDocument = async (token: string) => {
    getPublicDocumentQuery({ variables: { token } });

    return new Promise<DocumentFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPublicDocument,
    publicDocument: data?.publicDocument,
    isLoading: loading,
  };
};
