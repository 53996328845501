import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Button,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { useErrorLogger } from "hooks";
import { useCreateTeamAccountMutation } from "graphql/mutations/createTeamAccount";
import { AccountFragment } from "graphql/fragments";

interface FormValues {
  name: string;
}

interface Props {
  onClose: () => void;
  onSuccess?: (account: AccountFragment) => void;
}

export default ({ onClose, onSuccess }: Props): JSX.Element => {
  const { createTeamAccount } = useCreateTeamAccountMutation();

  const { t } = useTranslation("common");
  const { reportErrors } = useErrorLogger();

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t("popup.addTeamAccount.name.error.required", "A name is required")
    ),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  reportErrors(errors);
  const name = watch("name");

  const onSubmit = ({ name }: FormValues) =>
    createTeamAccount(name, (teamAccount) => {
      onSuccess?.(teamAccount);

      onClose();
    });

  const formId = "addTeamAccountForm";

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        className={tw("w-full")}
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="popup.addTeamAccount.title">
                  Create a new team space
                </Trans>
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="add_bank_account-add_bank_account"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
              disabled={!name}
            >
              <Trans ns="common" i18nKey="popup.addTeamAccount.submit">
                Save
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <form
            className={tw("w-full", "space-y-4")}
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              id="name"
              {...register("name")}
              label={t(
                "popup.addTeamAccount.form.name.label",
                "Team space name"
              )}
              placeholder={t(
                "popup.addTeamAccount.form.name.placeholder",
                "Name"
              )}
              errorMessage={errors.name?.message}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
