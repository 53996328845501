import { useEffect, useRef } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { QuerySalarySlipsArgs } from "graphql/types";

import { SALARY_SLIP_FRAGMENT, SalarySlipFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const SALARY_SLIPS = gql`
  query ($page: Int, $perPage: Int) {
    salarySlips(page: $page, perPage: $perPage) {
      items {
        ...SalarySlipFragment
      }
      total
    }
  }
  ${SALARY_SLIP_FRAGMENT}
`;

export type SalarySlipsQuery = {
  salarySlips: PaginatedPayload<SalarySlipFragment>;
};

type QueryArgs = Pick<QuerySalarySlipsArgs, "page" | "perPage">;

export const useSalarySlipsQuery = (
  args: QueryArgs = {}
): {
  salarySlips: SalarySlipFragment[];
  isLoading: boolean;
} => {
  const { data, loading: isLoading } = useQuery<SalarySlipsQuery, QueryArgs>(
    SALARY_SLIPS,
    { variables: { ...args } }
  );

  const salarySlips = data?.salarySlips.items?.filter(Boolean) ?? [];

  return { salarySlips, isLoading };
};

export const useSalarySlipsLazyQuery = (): {
  getSalarySlips: (
    args?: QueryArgs
  ) => Promise<SalarySlipFragment[] | undefined>;
  salarySlips: SalarySlipFragment[];
  total: number;
  isLoading: boolean;
  called: boolean;
} => {
  const [getSalarySlipsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<SalarySlipsQuery, QueryArgs>(SALARY_SLIPS, {
      fetchPolicy: "network-only",
    });

  const salarySlips = data?.salarySlips.items?.filter(Boolean) ?? [];
  const total = data?.salarySlips.total ?? 0;

  const resolveRef = useRef<(salarySlips?: SalarySlipFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(salarySlips);
      resolveRef.current = undefined;
    }
  }, [salarySlips, called, isLoading]);

  const getSalarySlips = async (args?: QueryArgs) => {
    const variables = { ...args };

    getSalarySlipsQuery({ variables });

    return new Promise<SalarySlipFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getSalarySlips, salarySlips, total, isLoading, called };
};
