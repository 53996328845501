import { IconType } from "react-icons";
import { HiX } from "react-icons/hi";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import csvUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";
import tw from "tw-generated";
import { useTranslation } from "translations";
import { emailValidationSchema } from "utils";
import { useErrorLogger } from "hooks";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Radio,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { DocumentUpload } from "components/common/composite";
import {
  useCreatePeopleMutation,
  useUploadPeopleMutation,
} from "graphql/mutations";

import PeopleInputTable, { FormWithPeopleInputTable } from "./PeopleInputTable";

export enum AdditionMethod {
  manual = "MANUAL",
  csv = "CSV",
}

interface FormValues extends FormWithPeopleInputTable {
  method?: AdditionMethod;
}

interface IconBase {
  type: "ICON" | "IMAGE";
}
interface IconTypeIcon extends IconBase {
  type: "ICON";
  Icon: IconType;
}
interface ImageTypeIcon extends IconBase {
  type: "IMAGE";
  alt: string;
  src: string;
}
export type AddPeopleIcon = IconTypeIcon | ImageTypeIcon;

interface Props {
  title: string;
  onClose: () => void;
  onSuccess?: () => void;
  availableMethods?: AdditionMethod[];
  description?: string;
  icon?: AddPeopleIcon;
}

export default ({
  title,
  onClose,
  onSuccess,
  availableMethods = [AdditionMethod.manual, AdditionMethod.csv],
  description,
  icon,
}: Props): JSX.Element => {
  const { createPeople } = useCreatePeopleMutation();
  const { uploadPeople } = useUploadPeopleMutation();

  const { t } = useTranslation("common");
  const formId = "add-people-form";

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: { method: availableMethods[0] },
    resolver: yupResolver(
      Yup.object().shape({
        method: Yup.string()
          .required(
            t("popup.addPeople.error.method.required", "Please select a method")
          )
          .oneOf(availableMethods),
        people: Yup.array().of(
          Yup.object().shape({
            email: emailValidationSchema(t),
          })
        ),
      })
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const { field: methodField } = useController({ name: "method", control });
  const method = watch("method");

  const onSubmit = ({ method, people }: FormValues) => {
    if (method !== AdditionMethod.manual) return;
    if (!people) return;

    createPeople({
      attributes: people.map((person) => ({
        email: person.email ?? "",
        name: person.name ?? "",
        phone: person.phone,
      })),
    }).then(() => {
      onSuccess?.();

      onClose();
    });
  };

  const uploadCSV = (files: File[]) => {
    if (files.length < 1) return;
    const file = files[0];

    const reader = new FileReader();

    reader.onload = (event) => {
      if (typeof event.target?.result !== "string") return;

      uploadPeople({ csv: { data: event.target.result } }).then(() => {
        onSuccess?.();

        onClose();
      });
    };

    reader.readAsDataURL(file);
  };

  const hasMultipleAdditionMethods = availableMethods.length > 1;

  return (
    <PopupTemplate onClose={onClose} maxWidth="max-w-screen-xl">
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="add_people-add_people"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
            >
              {t("popup.addPeople.submit", "Add people")}
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <div className={tw("w-full", "flex", "space-x-4")}>
            {icon && (
              <div className={tw("h-10", "w-10", "flex-shrink-0")}>
                {icon.type === "ICON" ? (
                  <icon.Icon size={40} />
                ) : (
                  <img
                    alt={icon.alt}
                    src={icon.src}
                    className={tw("h-full", "w-auto")}
                  />
                )}
              </div>
            )}

            <div className={tw("w-full")}>
              <div className={tw("w-full", "flex", "justify-between")}>
                <h3 className={tw("text-lg", "font-extrabold")}>{title}</h3>

                <button onClick={onClose}>
                  <HiX size={24} className={tw("text-gray-400")} />
                </button>
              </div>

              <p className={tw("mb-8")}>{description}</p>

              <div className={tw("my-4")}>
                <h4 className={tw("text-base", "font-bold")}>
                  {t("popup.addPeople.explanation.heading", "Invite people")}
                </h4>
                <p>
                  {t(
                    "popup.addPeople.explanation.body",
                    "All the fields below will be shown to the recipient."
                  )}
                </p>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className={tw("w-full", "space-y-4")}
                id={formId}
              >
                <input {...register("method")} style={{ display: "none" }} />

                {hasMultipleAdditionMethods &&
                  availableMethods.includes(AdditionMethod.manual) && (
                    <Radio
                      id="method.manual"
                      name={methodField.name}
                      onChange={methodField.onChange}
                      checked={methodField.value === AdditionMethod.manual}
                      value={AdditionMethod.manual}
                      label={t("popup.addPeople.method.manual", "Manually")}
                    />
                  )}

                {method === AdditionMethod.manual && (
                  <PeopleInputTable
                    control={control}
                    register={register}
                    peopleErrors={errors.people}
                    clearErrors={clearErrors}
                    minPeople={1}
                  />
                )}

                {hasMultipleAdditionMethods &&
                  availableMethods.includes(AdditionMethod.csv) && (
                    <Radio
                      id="method.csv"
                      name={methodField.name}
                      onChange={methodField.onChange}
                      checked={methodField.value === AdditionMethod.csv}
                      value={AdditionMethod.csv}
                      label={t("popup.addPeople.method.csv", "CSV")}
                    />
                  )}

                {method === AdditionMethod.csv && (
                  <DocumentUpload
                    id="csv-upload"
                    selectDocument={uploadCSV}
                    clearDocument={() => null}
                    illustration={{
                      src: csvUploadIllustration,
                      alt: t(
                        "popup.addPeople.uploadCSV.illustration.alt",
                        "File attachment"
                      ),
                    }}
                    validFileTypes={["text/csv"]}
                    fileRequirementsText={t(
                      "popup.addPeople.uploadCSV.validFileTypes",
                      "Files with .csv extension"
                    )}
                  />
                )}
              </form>
            </div>
          </div>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
