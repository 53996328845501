/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryCompaniesArgs } from "graphql/types";

import { COMPANY_FRAGMENT, CompanyFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const COMPANIES = gql`
  query ($name: String!, $page: Int, $perPage: Int) {
    companies(name: $name, page: $page, perPage: $perPage) {
      items {
        ...CompanyFragment
      }
      total
    }
  }
  ${COMPANY_FRAGMENT}
`;

export interface CompaniesQuery {
  companies: PaginatedPayload<CompanyFragment>;
}

type QueryArgs = Pick<QueryCompaniesArgs, "name" | "page" | "perPage">;

export const useCompaniesLazyQuery = () => {
  const [getCompaniesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<CompaniesQuery, QueryArgs>(COMPANIES);

  const companies = data?.companies.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(companies?: CompanyFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(companies);
      resolveRef.current = undefined;
    }
  }, [companies, called, isLoading]);

  const getCompanies = async (variables: QueryArgs) => {
    getCompaniesQuery({ variables });

    return new Promise<CompanyFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getCompanies, companies, isLoading };
};
