import { gql } from "@apollo/client";

import { Person } from "graphql/types";

export const PERSON_FRAGMENT = gql`
  fragment PersonFragment on Person {
    id
    email
    name
    phone
  }
`;

export interface PersonFragment
  extends Pick<Person, "id" | "email" | "name" | "phone"> {}
