import { ClientFragment } from "graphql/fragments";
import { CardBody } from "components/common/basic";

import CreateIndClientForm from "./CreateIndClientForm";

interface Props {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  client?: ClientFragment;
}

export default ({ formId, onSuccessfulEdit, client }: Props): JSX.Element => {
  return (
    <CardBody>
      <CreateIndClientForm
        formId={formId}
        onSuccessfulEdit={onSuccessfulEdit}
        client={client}
      />
    </CardBody>
  );
};
