import { useState } from "react";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  CheckBox,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { PersonFragment } from "graphql/fragments";

interface Props {
  person: PersonFragment;
  onClose: () => void;
  onSuccess?: () => void;
}

export default ({ person, onSuccess, onClose }: Props): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false);
  // TODO: Use real mutation when available
  const { removePerson } = {
    removePerson: (_id: string, onSuccess: () => void) => onSuccess(),
  };

  const { t } = useTranslation("common");

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                {t("popup.removePerson.title", "Delete person")}
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="remove_person-remove_person"
              variant="danger"
              size="sm"
              onClick={() =>
                removePerson(person.id, () => {
                  onSuccess?.();

                  onClose();
                })
              }
              disabled={!isChecked}
            >
              {t(
                "popup.removePerson.button.delete",
                "Delete <{{personName}}>",
                {
                  personName: person.name,
                }
              )}
            </Button>

            <Button
              id="remove_person-cancel"
              onClick={onClose}
              variant="primary"
              size="sm"
            >
              {t("popup.removePerson.cancel", "Cancel")}
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <p className={tw("text-sm", "text-gray-900")}>
            {t(
              "popup.removePerson.body",
              "You're about to delete <{{personName}}> from your account. This action will prevent you from seeing their information.",
              { personName: person.name }
            )}
          </p>
        </CardBody>

        <CardBody>
          <CheckBox
            id="confirmation"
            name="confirmation"
            label={t(
              "popup.removePerson.checkbox.label",
              "Delete all the information you have of this person including documents they have signed and checks they have taken for you."
            )}
            onChange={(event) => setIsChecked(event.target.checked)}
          />
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
