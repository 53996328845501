import { useContext } from "react";
import { Navigate } from "react-router";

import { AuthContext } from "providers/Authentication";
import { AccountTypeEnum } from "graphql/types";
import { useLocalizedRoutePath } from "utils";

export default (): JSX.Element => {
  const getRoutePath = useLocalizedRoutePath();

  const { session } = useContext(AuthContext);

  if (session?.account.accountType === AccountTypeEnum.Team)
    return <Navigate to={getRoutePath("JOBS")} />;

  return <Navigate to={getRoutePath("PAYOUT_SERVICE")} />;
};
