/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { Base64AttachmentAttributes } from "graphql/types";

const UPLOAD_PEOPLE = gql`
  mutation ($csv: Base64AttachmentAttributes!) {
    uploadPeople(input: { csv: $csv }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface UploadPeopleMutation {
  uploadPeople: Payload | null;
}

export const useUploadPeopleMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UploadPeopleMutation,
    { csv: Base64AttachmentAttributes }
  >(UPLOAD_PEOPLE);

  const uploadPeople = (
    variables: { csv: Base64AttachmentAttributes },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.uploadPeople?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    uploadPeople,
    isLoading: loading,
    errors: data?.uploadPeople?.errors ?? [],
  };
};
