import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  BankAccountFragment,
  FreelanceProfileFragment,
} from "graphql/fragments";
import { Input, Select } from "components/common/basic";
import { CurrencyEnum } from "graphql/types";

export interface Props {
  control: Control<FreelanceProfileFragment>;
  register: UseFormRegister<FreelanceProfileFragment>;
  bankAccount?: BankAccountFragment;
  bankAccountErrors?: FieldErrorsImpl<BankAccountFragment>;
}

export default ({
  control,
  register,
  bankAccount,
  bankAccountErrors,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <div className={tw("w-full", "space-y-4")}>
      <h2 className={tw("text-lg", "font-bold")}>
        <Trans
          ns="account"
          i18nKey="freelanceProfileForm.bankAccountSection.title"
        >
          Bank account information
        </Trans>
      </h2>

      <div className={tw("flex", "space-x-4")}>
        <Input
          className={tw("w-3/4")}
          id="accountNumber"
          {...register("defaultBankAccount.accountNumber")}
          defaultValue={bankAccount?.accountNumber}
          label={t(
            "freelanceProfileForm.bankAccount.accountNumber.label",
            "Account number"
          )}
          placeholder={t(
            "freelanceProfileForm.bankAccount.accountNumber.placeholder",
            "Number"
          )}
          errorMessage={bankAccountErrors?.accountNumber?.message}
        />

        <Controller
          control={control}
          name="defaultBankAccount.currency"
          defaultValue={bankAccount?.currency ?? CurrencyEnum.Nok}
          render={({ field }) => (
            <Select
              className={tw("w-1/4")}
              id={field.name}
              name={field.name}
              label={t(
                "freelanceProfileForm.bankAccount.currency.label",
                "Currency"
              )}
              options={Object.values(CurrencyEnum).map((value) => ({
                label: value,
                value,
              }))}
              value={field.value}
              onChange={field.onChange}
              errorMessage={bankAccountErrors?.currency?.message}
            />
          )}
        />
      </div>

      <Input
        id="swiftCode"
        {...register("defaultBankAccount.swiftCode")}
        defaultValue={bankAccount?.swiftCode ?? ""}
        label={t(
          "freelanceProfileForm.bankAccount.swiftCode.label",
          "BIC / SWIFT"
        )}
        placeholder={t(
          "freelanceProfileForm.bankAccount.swiftCode.placeholder",
          "Number"
        )}
        hint={t("freelanceProfileForm.bankAccount.swiftCode.hint", "Optional")}
        errorMessage={bankAccountErrors?.swiftCode?.message}
      />
    </div>
  );
};
