import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router";

import tw from "tw-generated";
import { SessionFragment } from "graphql/fragments";
import { Button, Divider, Link } from "components/common/basic";
import { PopupContext } from "providers/PopupHandler";
import logo from "assets/branding/logo.svg";
import menuOpen from "assets/symbols/menu_open.svg";
import menuClose from "assets/symbols/menu_close.svg";
import { useLocalizedRoutePathGetter } from "utils";

import { MenuList } from "./common";
import useSideMenuItems from "./common/useSideMenuItems";

interface Props {
  session: SessionFragment;
}

export default ({ session }: Props): JSX.Element => {
  const [redirectAccountID, setRedirectAccountID] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation("common");

  const sideMenuItems = useSideMenuItems({ session });
  const { openPopup } = useContext(PopupContext);

  const navigate = useNavigate();
  const getRoutePathGetter = useLocalizedRoutePathGetter();

  useEffect(() => {
    const isAvailable = session.accounts.some(
      ({ id }) => id === redirectAccountID
    );
    if (!isAvailable) return;

    const redirectToPath = getRoutePathGetter(redirectAccountID)("INVOICES");
    navigate(redirectToPath);
  }, [session.accounts.length]);

  const sideMenuStyles = tw(
    "sticky",
    "top-0",
    "h-screen",
    "overflow-auto",
    "z-50",
    "bg-white",
    "shadow-lg",
    "flex-shrink-0",
    "flex",
    "flex-col",
    "items-center",
    { "w-72": isExpanded }
  );

  const logoStyles = tw("h-6", "w-auto");

  return (
    <div className={sideMenuStyles}>
      <div
        className={tw("my-4", "p-4", "flex", "justify-between", {
          "w-full": isExpanded,
        })}
      >
        {isExpanded && (
          <Link
            id="sideMenu-go_to_homepage"
            to={t("links.homepage", "https://manymore.com/en")}
            isExternal
            unstyled
          >
            <img className={logoStyles} src={logo} alt="Manymore logo" />
          </Link>
        )}

        <button onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? (
            <img className={tw("h-6")} src={menuClose} alt="Menu close" />
          ) : (
            <img className={tw("h-6")} src={menuOpen} alt="Menu open" />
          )}
        </button>
      </div>

      <div className={tw("w-full", "px-4")}>
        <Divider />
      </div>

      <MenuList iconsOnly={!isExpanded} menuItems={sideMenuItems} />

      <div className={tw("w-full", "p-4", "mt-auto")}>
        <Button
          id="sideMenu-create_team"
          variant="tertiary"
          size="sm"
          fullWidth
          onClick={() =>
            openPopup({
              id: "AddTeam",
              props: { onSuccess: ({ id }) => setRedirectAccountID(id) },
            })
          }
          LeadingIcon={isExpanded ? HiPlus : undefined}
        >
          {isExpanded ? (
            t("sideMenu.button.addTeam", "Create a new team")
          ) : (
            <HiPlus />
          )}
        </Button>
      </div>
    </div>
  );
};
