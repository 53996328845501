import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import { CardBody } from "components/common/basic";
import { ClientTypeEnum } from "graphql/types";

import CreateOrgClient from "./CreateOrgClient";
import CreateIndClient from "./CreateIndClient";
import { ClientFragment } from "graphql/fragments";

interface Props {
  formId: string;
  client: ClientFragment;
  onClose: () => void;
  onSuccess?: (client: ClientFragment) => void;
}

export default ({ formId, client, onClose, onSuccess }: Props): JSX.Element => {
  const isOrg = client.clientType === ClientTypeEnum.Organization;

  return (
    <>
      <CardBody>
        <div className={tw("w-full", "space-y-2")}>
          <div className={tw("w-full", "flex", "justify-between")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              <Trans ns="common" i18nKey="popup.clientCreation_edit.heading">
                Edit client
              </Trans>
            </h3>

            <button onClick={onClose}>
              <HiX size={24} className={tw("text-gray-400")} />
            </button>
          </div>
        </div>
      </CardBody>

      {isOrg ? (
        <CreateOrgClient
          formId={formId}
          onSuccessfulEdit={onSuccess ?? onClose}
          client={client}
        />
      ) : (
        <CreateIndClient
          formId={formId}
          onSuccessfulEdit={onSuccess ?? onClose}
          client={client}
        />
      )}
    </>
  );
};
