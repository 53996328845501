/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_TEMPLATE_FRAGMENT,
  DocumentTemplateFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const CREATE_DOCUMENT_TEMPLATE = gql`
  mutation ($id: ID!) {
    createDocumentTemplate(input: { documentId: $id }) {
      documentTemplate {
        ...DocumentTemplateFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_TEMPLATE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentTemplate?: DocumentTemplateFragment | null;
  errors: ErrorFragment[];
}

interface CreateDocumentTemplateMutation {
  createDocumentTemplate: Payload | null;
}

export const useCreateDocumentTemplateMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.createdDocumentTemplate",
    "Template was created successfully"
  );
  // Values for extraction
  // t("common:success.createdDocumentTemplate", "Template was created successfully")

  const [mutation, { data, loading }] = useMutation<
    CreateDocumentTemplateMutation,
    { id: string }
  >(CREATE_DOCUMENT_TEMPLATE);

  const createDocumentTemplate = (
    id: string,
    onSuccess?: (documentTemplate: DocumentTemplateFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDocumentTemplate?.errors ?? [];
        const document = data?.createDocumentTemplate?.documentTemplate;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && document && onSuccess(document),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    documentTemplate: data?.createDocumentTemplate?.documentTemplate,
    createDocumentTemplate,
    isLoading: loading,
    errors: data?.createDocumentTemplate?.errors ?? [],
  };
};
