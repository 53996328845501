import { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { AuthContext } from "providers/Authentication";
import { useFreelanceProfileLazyQuery } from "graphql/queries";
import { useLocalizedRoutePath } from "utils";
import { Spinner } from "components/common/basic";

import Create from "./Create";

export default (): JSX.Element => {
  const { session } = useContext(AuthContext);
  const { id } = useParams<{ id?: string }>();
  const getRoutePath = useLocalizedRoutePath();

  const { freelanceProfile, getFreelanceProfile, isLoading, called } =
    useFreelanceProfileLazyQuery();

  useEffect(() => {
    if (id) getFreelanceProfile(id);
  }, [id]);

  if (id && (!called || isLoading)) return <Spinner />;

  const isAdmin = session?.administrator;
  const identity = session?.account?.identities?.[0];

  if (!isAdmin && !identity)
    return <Navigate to={getRoutePath("BANK_ID_VERIFICATION")} />;

  return <Create profile={freelanceProfile} />;
};
