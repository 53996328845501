import { Suspense, useContext } from "react";
import { useLocation } from "react-router";

import tw from "tw-generated";
import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { checkIsRoutePath } from "utils";

import MarketingHeader from "./MarketingHeader";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import SandboxHeader from "./SandboxHeader";
import AppHeader from "./AppHeader";
import AppTabBar from "./AppTabBar";
import AdminHeader from "./AdminHeader";
import { Spinner } from "../../basic";
import SideMenu from "./SideMenu";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props): JSX.Element => {
  const { isLoggedIn, session } = useContext(AuthContext);
  const { isMd } = useContext(TailwindContext);

  const { pathname } = useLocation();

  const outerWrapperStyles = tw("min-h-screen", "flex", "flex-row", {
    "pb-16": !isMd && Boolean(session), // Space behind AppTabBar
  });
  const innerWrapperStyles = tw(
    "min-h-screen",
    "flex-1",
    "flex",
    "flex-col",
    "overflow-x-hidden"
  );

  const isEnrolment = checkIsRoutePath(pathname, "ENROLMENT_WIZARD", "");
  const isSignaturePage = checkIsRoutePath(pathname, "SIGN_DOCUMENT", "");
  const isTokenHandler = checkIsRoutePath(pathname, "TOKEN_HANDLER", "");
  const isReferrer = checkIsRoutePath(pathname, "REFERRER", "");
  if (isEnrolment || isSignaturePage || isTokenHandler || isReferrer)
    return <div className={innerWrapperStyles}>{children}</div>;

  const isLoginPage = checkIsRoutePath(pathname, "LOGIN");
  // TODO: Hide menus on account selection page
  // const isAccountSelection = pathname.includes(
  //   getRoutePath("ACCOUNT_SELECTION")
  // );
  const isAdmin = session?.administrator;
  const isRestricted = isAdmin ? false : session?.user.confirmed === false;

  const isMarketing = !isLoggedIn && !isLoginPage;
  const isOnboarding = !isLoggedIn || isRestricted;

  const isSandbox = window.location.hostname === "sandbox.my.manymore.com";

  // TODO: Hide menus on account selection page
  // if (isAccountSelection)
  //   return (
  //     <div className={outerWrapperStyles}>
  //       <div className={innerWrapperStyles}>
  //         {isSandbox && <SandboxHeader />}

  //         <Suspense fallback={<Spinner />}>{children}</Suspense>
  //       </div>
  //     </div>
  //   );

  return (
    <div className={outerWrapperStyles}>
      {isMd && isLoggedIn && session && !isRestricted && (
        <SideMenu session={session} />
      )}

      <div className={innerWrapperStyles}>
        {isSandbox && <SandboxHeader />}

        {isMarketing ? (
          <MarketingHeader />
        ) : isOnboarding ? (
          <OnboardingHeader />
        ) : (
          <>
            {isAdmin && <AdminHeader session={session} />}

            {isMd && <AppHeader />}
          </>
        )}

        <Suspense fallback={<Spinner />}>{children}</Suspense>

        {isOnboarding ? (
          <OnboardingFooter />
        ) : (
          !isMd && session && <AppTabBar />
        )}
      </div>
    </div>
  );
};
