import { useState } from "react";
import { HiX } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import logoFileIllustration from "assets/illustrations/dragAndDrop/logoUpload.svg";
import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Input,
  Link,
  Textarea,
} from "components/common/basic";
import { useCreateJobMutation, useUpdateJobMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { JobFragment } from "graphql/fragments";

import PopupTemplate from "./PopupTemplate";
import { DocumentUpload } from "../composite";

interface FormValues {
  title: string;
  tagline: string;
  description: string;
  applicationLimit: number;
  privacyLink: string;
  termsLink: string;
  primaryDefault: string;
  primaryHover: string;
  primaryContrast: string;
  applicationForm: string;
  jobChecks: string;
}

interface Props {
  onClose: () => void;
  job?: JobFragment;
}

export default ({ onClose, job }: Props): JSX.Element => {
  const [logoUpload, setLogoUpload] = useState<string>();

  const { t } = useTranslation("common");
  const formId = "job-creation-form";

  const { createJob, isLoading: isCreating } = useCreateJobMutation();
  const { updateJob, isLoading: isUpdating } = useUpdateJobMutation();
  const isLoading = isCreating || isUpdating;

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string()
          .trim()
          .required(
            t("popup.jobCreation.title.error.required", "A title is required")
          ),
        tagline: Yup.string()
          .trim()
          .required(
            t(
              "popup.jobCreation.tagline.error.required",
              "A tagline is required"
            )
          ),
        description: Yup.string()
          .trim()
          .required(
            t(
              "popup.jobCreation.description.error.required",
              "A job intro text is required"
            )
          ),
        applicationLimit: Yup.number()
          .typeError(
            t(
              "popup.jobCreation.applicationLimit.error.type",
              "An application limit is required"
            )
          )
          .required(
            t(
              "popup.jobCreation.applicationLimit.error.required",
              "An application limit is required"
            )
          )
          .min(
            1,
            t(
              "popup.jobCreation.applicationLimit.error.tooLow",
              "A job must allow at least one applicant"
            )
          ),
        privacyLink: Yup.string()
          .trim()
          .required(
            t(
              "popup.jobCreation.privacyLink.error.required",
              "A privacy policy is required"
            )
          ),
        termsLink: Yup.string()
          .trim()
          .required(
            t(
              "popup.jobCreation.termsLink.error.required",
              "Terms are required"
            )
          ),
        primaryDefault: Yup.string(),
        primaryHover: Yup.string(),
        primaryContrast: Yup.string(),
        applicationForm: Yup.string().test(
          "isJSON",
          t(
            "popup.jobCreation.applicationForm.error.notJSON",
            "The application form must be a valid JSON"
          ),
          (value: unknown) => {
            if (typeof value !== "string") return false;

            try {
              JSON.parse(value);
            } catch (_error) {
              return false;
            }
            return true;
          }
        ),
        jobChecks: Yup.string().test(
          "isJSON",
          t(
            "popup.jobCreation.jobChecks.error.notJSON",
            "The job checks must be a valid JSON"
          ),
          (value: unknown) => {
            if (typeof value !== "string") return false;

            try {
              JSON.parse(value);
            } catch (_error) {
              return false;
            }
            return true;
          }
        ),
      })
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const [watchedPrimaryDefault, watchedPrimaryHover, watchedPrimaryContrast] =
    watch(["primaryDefault", "primaryHover", "primaryContrast"]);

  const selectDocumentForUpload = () => (files: File[]) => {
    const file = files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      if (typeof event.target?.result !== "string") return;

      setLogoUpload(event.target.result);
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = ({
    applicationForm,
    primaryDefault,
    primaryHover,
    primaryContrast,
    jobChecks,
    ...values
  }: FormValues) => {
    if (isLoading) return;

    const attributes = {
      ...values,
      ...(logoUpload ? { logo: { data: logoUpload } } : {}),
      colors: {
        default: primaryDefault,
        hover: primaryHover,
        contrast: primaryContrast,
      },
      applicationForm: JSON.parse(applicationForm),
      jobChecks: JSON.parse(jobChecks),
    };

    if (job) updateJob({ id: job.id, attributes }, onClose);
    else createJob(attributes, onClose);
  };

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="job_creation-cancel"
              variant="tertiary"
              size="sm"
              onClick={onClose}
            >
              <Trans ns="common" i18nKey="popup.jobCreation.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="job_creation-save"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
              disabled={isLoading}
            >
              <Trans ns="common" i18nKey="popup.jobCreation.submit">
                Save
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <div className={tw("w-full", "flex", "justify-between")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              <Trans ns="common" i18nKey="popup.jobCreation.heading">
                Create a new job
              </Trans>
            </h3>

            <button onClick={onClose}>
              <HiX size={24} className={tw("text-gray-400")} />
            </button>
          </div>

          <div className={tw("w-full")}>
            <DocumentUpload
              id="job-logo"
              document={logoUpload}
              label={t("popup.jobCreation.logoUpload.label", "Logo")}
              isInputLabel
              selectDocument={selectDocumentForUpload()}
              clearDocument={() => setLogoUpload(undefined)}
              illustration={{
                src: job?.logo
                  ? process.env.REACT_APP_API_URL + job.logo.downloadPath
                  : logoFileIllustration,
                alt: t("popup.jobCreation.logoUpload.imageAlt", "Job logo"),
              }}
              maxSize={3_000_000}
              isImageUpload
              fileRequirementsText={t(
                "popup.jobCreation.logoUpload.selectUpload.requirements",
                "PNG, JPG, GIF up to 3MB"
              )}
            />
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={tw("w-full", "space-y-4")}
            id={formId}
          >
            <Input
              autoFocus
              id="title"
              {...register("title")}
              label={t(
                "popup.jobCreation.input.title.label",
                "Job title (for reference)"
              )}
              placeholder={t(
                "popup.jobCreation.input.title.placeholder",
                "Job title"
              )}
              errorMessage={errors.title?.message}
              defaultValue={job?.title}
            />

            <Input
              id="tagline"
              {...register("tagline")}
              label={t("popup.jobCreation.input.tagline.label", "Tagline")}
              placeholder={t(
                "popup.jobCreation.input.tagline.placeholder",
                "Tagline"
              )}
              errorMessage={errors.tagline?.message}
              defaultValue={job?.tagline ?? ""}
            />

            <Textarea
              label={t(
                "popup.jobCreation.input.description.placeholder",
                "Job intro text"
              )}
              id="description"
              {...register("description")}
              rows={5}
              className={tw("rounded-md", "border-gray-300")}
              defaultValue={job?.description ?? ""}
              errorMessage={errors.description?.message}
            />

            <Input
              type="number"
              id="applicationLimit"
              {...register("applicationLimit")}
              label={t(
                "popup.jobCreation.input.applicationLimit.label",
                "Max approved workers"
              )}
              errorMessage={errors.applicationLimit?.message}
              defaultValue={job?.applicationLimit ?? 45}
            />

            <Input
              id="privacyLink"
              {...register("privacyLink")}
              label={t(
                "popup.jobCreation.input.privacyLink.label",
                "Privacy Policy"
              )}
              placeholder={t(
                "popup.jobCreation.input.privacyLink.placeholder",
                "Full URL to privacy policy"
              )}
              errorMessage={errors.privacyLink?.message}
              defaultValue={job?.privacyLink ?? ""}
            />

            <Input
              id="termsLink"
              {...register("termsLink")}
              label={t("popup.jobCreation.input.termsLink.label", "Terms")}
              placeholder={t(
                "popup.jobCreation.input.termsLink.placeholder",
                "Full URL to terms"
              )}
              errorMessage={errors.termsLink?.message}
              defaultValue={job?.termsLink ?? ""}
            />

            <Divider />

            <h4 className={tw("text-lg", "font-extrabold")}>
              <Trans
                ns="common"
                i18nKey="popup.jobCreation.section.colors.heading"
              >
                Job colors
              </Trans>
            </h4>

            <div className={tw("flex", "space-x-8")}>
              <div className={tw("flex-1", "space-y-4")}>
                <Input
                  id="primaryDefault"
                  {...register("primaryDefault")}
                  label={t(
                    "popup.jobCreation.input.primaryDefault.label",
                    "Button color"
                  )}
                  errorMessage={errors.primaryDefault?.message}
                  defaultValue={job?.colors.default ?? ""}
                />

                <Input
                  id="primaryHover"
                  {...register("primaryHover")}
                  label={t(
                    "popup.jobCreation.input.primaryHover.label",
                    "Hover color"
                  )}
                  errorMessage={errors.primaryHover?.message}
                  defaultValue={job?.colors.hover ?? ""}
                />

                <Input
                  id="primaryContrast"
                  {...register("primaryContrast")}
                  label={t(
                    "popup.jobCreation.input.primaryContrast.label",
                    "Contrast color"
                  )}
                  errorMessage={errors.primaryContrast?.message}
                  defaultValue={job?.colors.contrast ?? ""}
                />
              </div>

              <div
                className={tw("flex-1", "flex", "flex-col", "space-y-1")}
                style={
                  {
                    "--primary": watchedPrimaryDefault ?? job?.colors.default,
                    "--primary-hover": watchedPrimaryHover ?? job?.colors.hover,
                    "--primary-contrast":
                      watchedPrimaryContrast ?? job?.colors.contrast,
                  } as React.CSSProperties
                }
              >
                <h5 className={tw("text-sm", "text-gray-700")}>
                  <Trans ns="common" i18nKey="popup.jobCreation.colors.preview">
                    Preview
                  </Trans>
                </h5>

                <div
                  className={tw(
                    "flex-1",
                    "flex",
                    "items-center",
                    "justify-center",
                    "border",
                    "border-gray-300",
                    "rounded-md"
                  )}
                >
                  <Button id="job_creation-hover_me">Hover me</Button>
                </div>
              </div>
            </div>

            <Divider />

            <h4 className={tw("text-lg", "font-extrabold")}>
              <Trans
                ns="common"
                i18nKey="popup.jobCreation.section.questions.heading"
              >
                Form questions
              </Trans>
            </h4>

            <Textarea
              label={
                <Trans
                  ns="common"
                  i18nKey="popup.jobCreation.input.applicationForm.label"
                >
                  Contact{" "}
                  <Link
                    id="job_creation-mail_to_support_link1"
                    isExternal
                    to="mailto:support@manymore.com"
                  >
                    support@manymore.com
                  </Link>{" "}
                  to create a JSON, or upload a valid JSON to create a job
                </Trans>
              }
              id="applicationForm"
              {...register("applicationForm")}
              rows={5}
              className={tw("rounded-md", "border-gray-300")}
              defaultValue={JSON.stringify(job?.applicationForm)}
              errorMessage={errors.applicationForm?.message}
            />

            <Divider />

            <h4 className={tw("text-lg", "font-extrabold")}>
              <Trans
                ns="common"
                i18nKey="popup.jobCreation.section.jobChecks.heading"
              >
                Job checks
              </Trans>
            </h4>

            <Textarea
              label={
                <Trans
                  ns="common"
                  i18nKey="popup.jobCreation.input.jobChecks.label"
                >
                  Contact{" "}
                  <Link
                    id="job_creation-mail_to_support_link2"
                    isExternal
                    to="mailto:support@manymore.com"
                  >
                    support@manymore.com
                  </Link>{" "}
                  to create a JSON, or upload a valid JSON to create a job
                </Trans>
              }
              id="jobChecks"
              {...register("jobChecks")}
              rows={5}
              className={tw("rounded-md", "border-gray-300")}
              defaultValue={JSON.stringify(job?.jobChecks)}
              errorMessage={errors.jobChecks?.message}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
