/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryCreditNotesArgs } from "graphql/types";

import { CreditNoteFragment, CREDIT_NOTE_FRAGMENT } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const CREDIT_NOTES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: CreditNotesFilter
    $sortBy: CreditNotesSortByEnum
  ) {
    creditNotes(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      items {
        ...CreditNoteFragment
      }
      total
    }
  }
  ${CREDIT_NOTE_FRAGMENT}
`;

export interface CreditNotesQuery {
  creditNotes: PaginatedPayload<CreditNoteFragment>;
}

type QueryArgs = Pick<
  QueryCreditNotesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useCreditNotesLazyQuery = () => {
  const [getCreditNotesQuery, { data, loading: isLoading, called }] =
    useLazyQuery<CreditNotesQuery, QueryArgs>(CREDIT_NOTES, {
      fetchPolicy: "network-only",
    });

  const creditNotes = data?.creditNotes.items?.filter(Boolean) ?? [];
  const total = data?.creditNotes.total ?? 0;

  const resolveRef = useRef<(creditNotes?: CreditNoteFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(creditNotes);
      resolveRef.current = undefined;
    }
  }, [creditNotes, called, isLoading]);

  const getCreditNotes = async (args?: QueryArgs) => {
    const variables = { ...args };

    getCreditNotesQuery({ variables });

    return new Promise<CreditNoteFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getCreditNotes, creditNotes, total, isLoading, called };
};
