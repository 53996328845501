import { useContext } from "react";
import { useLocation } from "react-router";
import { HiOutlineLogin, HiOutlinePlusCircle } from "react-icons/hi";

import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { useLocalizedRoutePath } from "utils";
import { Trans, useTranslation } from "translations";
import { Button, Link } from "components/common/basic";

import { HeaderTemplate } from "./common";

export default (): JSX.Element => {
  const { isMd } = useContext(TailwindContext);
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useLocalizedRoutePath();

  const isSignupPage = pathname === getRoutePath("SIGNUP");

  return (
    <>
      <HeaderTemplate
        left={
          <Link
            id="onboarding_header-go_to_hommepage"
            to={t("links.homepage", "https://manymore.com/en")}
            unstyled
            isExternal
            openSeparate={false}
          >
            <Trans ns="common" i18nKey="header_onboarding.link.home">
              Back to the home page
            </Trans>
          </Link>
        }
        right={
          isMd ? (
            <>
              {isLoggedIn ? (
                <Button
                  id="onboarding_header-log_out"
                  size="md"
                  variant="tertiary"
                  onClick={logout}
                >
                  <Trans ns="common" i18nKey="header_onboarding.button.logOut">
                    Log out
                  </Trans>
                </Button>
              ) : isSignupPage ? (
                <Link
                  id="onboarding_header-log_in"
                  to={getRoutePath("LOGIN")}
                  unstyled
                >
                  <Button
                    id="onboarding_header-log_in"
                    size="md"
                    variant="tertiary"
                  >
                    <Trans ns="common" i18nKey="header_onboarding.link.logIn">
                      Log in
                    </Trans>
                  </Button>
                </Link>
              ) : (
                <Link
                  id="onboarding_header-sign_up"
                  to={getRoutePath("SIGNUP")}
                  unstyled
                >
                  <Button
                    id="onboarding_header-sign_up"
                    size="md"
                    variant="tertiary"
                  >
                    <Trans ns="common" i18nKey="header_onboarding.link.signUp">
                      Sign up
                    </Trans>
                  </Button>
                </Link>
              )}
            </>
          ) : null
        }
        menuList={[
          isLoggedIn
            ? {
                id: "onboarding_header-log_out",
                Icon: HiOutlineLogin,
                label: t("header_onboarding.button.logOut", "Log out"),
                onClick: logout,
              }
            : isSignupPage
            ? {
                id: "onboarding_header-log_in",
                Icon: HiOutlineLogin,
                label: t("header_onboarding.link.logIn", "Log in"),
                to: getRoutePath("LOGIN"),
              }
            : {
                id: "onboarding_header-sign_up",
                Icon: HiOutlinePlusCircle,
                label: t("header_onboarding.link.signUp", "Sign up"),
                to: getRoutePath("SIGNUP"),
              },
        ]}
        showMenuToggle={!isMd}
      />
    </>
  );
};
