import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import {
  HiOutlineDocument,
  // HiOutlineDocumentDuplicate,
  HiOutlinePencilAlt,
  HiPlus,
} from "react-icons/hi";

import tw from "tw-generated";
import { useLocalizedRoutePath } from "utils";
import { Trans, useTranslation } from "translations";
import { Button } from "components/common/basic";
import { useMenu } from "hooks";

import { HeaderTemplate, MenuList, TopMenuList } from "./common";

export default (): JSX.Element => {
  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingCreateMenu, setIsShowingCreateMenu] = useMenu(createMenuRef);
  const toggleCreateMenu = () => setIsShowingCreateMenu(!isShowingCreateMenu);

  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useLocalizedRoutePath();

  useEffect(() => {
    setIsShowingCreateMenu(false);
  }, [pathname]);

  const createMenuStyles = tw(
    "origin-top-right",
    "absolute",
    "right-0",
    "mt-2",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  return (
    <>
      <HeaderTemplate
        left={<></>}
        right={
          <>
            <div className={tw("relative")} ref={createMenuRef}>
              <Button
                id="app_header-create"
                size="md"
                aria-label="creation menu"
                aria-expanded={isShowingCreateMenu}
                onClick={toggleCreateMenu}
                TrailingIcon={HiPlus}
              >
                <Trans ns="common" i18nKey="header_loggedIn.button.create">
                  Create
                </Trans>
              </Button>

              {isShowingCreateMenu && (
                <>
                  <nav className={createMenuStyles}>
                    <MenuList
                      menuItems={[
                        {
                          id: "app_header-create_one_invoice" as const,
                          Icon: HiOutlineDocument,
                          label: t(
                            "header_loggedIn.link.createOneInvoice",
                            "One invoice"
                          ),
                          to: getRoutePath("INVOICE_INITIALIZATION"),
                        },
                        // {
                        //   id: "app_header-create_multiple_invoices" as const,
                        //   Icon: HiOutlineDocumentDuplicate,
                        //   label: t(
                        //     "header_loggedIn.link.createMultipleInvoices",
                        //     "Multiple invoices"
                        //   ),
                        //   to: getRoutePath("INVOICE_IMPORT"),
                        // },
                        {
                          id: "app_header-create_esignature" as const,
                          Icon: HiOutlinePencilAlt,
                          label: t(
                            "header_loggedIn.link.createESignature",
                            "New eSignature"
                          ),
                          to: getRoutePath("CREATE_DOCUMENT"),
                        },
                      ]}
                    />
                  </nav>
                </>
              )}
            </div>
          </>
        }
        menuList={<TopMenuList />}
        showMenuToggle
      />
    </>
  );
};
