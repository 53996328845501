import { gql } from "@apollo/client";

import { Event } from "graphql/types";

export const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    actor
    eventType
    occurredAt
  }
`;

export interface EventFragment
  extends Pick<Event, "actor" | "eventType" | "occurredAt"> {}
