/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateUserPayload, CreateUserInput } from "graphql/types";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";

const CREATE_USER = gql`
  mutation ($input: CreateUserInput!) {
    createUser(input: $input) {
      token
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload extends Pick<CreateUserPayload, "token"> {
  errors: ErrorFragment[];
}

export interface CreateUserMutation {
  createUser: Payload | null;
}

export const useCreateUserMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateUserMutation,
    { input: CreateUserInput }
  >(CREATE_USER);

  const createUser = (
    input: CreateUserInput,
    onSuccess?: (token?: string) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createUser?.errors ?? [];
        const token = data?.createUser?.token ?? undefined;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(token),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createUser,
    isLoading: loading,
    token: data?.createUser?.token,
    errors: data?.createUser?.errors ?? [],
  };
};
