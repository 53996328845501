/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const NOTIFY_DOCUMENT = gql`
  mutation ($id: ID!) {
    notifyDocument(input: { id: $id }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface NotifyDocumentMutation {
  notifyDocument: Payload | null;
}

export const useNotifyDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.notifiedDocument",
    "Reminder(s) sent successfully"
  );
  // Values for extraction
  // t("common:success.notifiedDocument", "Reminder(s) sent successfully")

  const [mutation, { data, loading, called }] = useMutation<
    NotifyDocumentMutation,
    { id: string }
  >(NOTIFY_DOCUMENT);

  const notifyDocument = (
    id: string,
    onSuccess?: (response: DocumentFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.notifyDocument?.errors ?? [];
        const document = data?.notifyDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => notifyDocument(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    notifyDocument,
    isLoading: loading,
    called,
    document: data?.notifyDocument?.document,
    errors: data?.notifyDocument?.errors ?? [],
  };
};
