import { gql } from "@apollo/client";

import { Identity } from "graphql/types";

export const IDENTITY_FRAGMENT = gql`
  fragment IdentityFragment on Identity {
    firstName
    lastName
  }
`;

export interface IdentityFragment
  extends Pick<Identity, "firstName" | "lastName"> {}
