/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { QueryDocumentGroupsArgs } from "graphql/types";

import {
  DOCUMENT_GROUP_FRAGMENT,
  DocumentGroupFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const DOCUMENT_GROUPS = gql`
  query ($page: Int, $perPage: Int) {
    documentGroups(page: $page, perPage: $perPage) {
      items {
        ...DocumentGroupFragment
      }
      total
    }
  }
  ${DOCUMENT_GROUP_FRAGMENT}
`;

export type DocumentGroupsQuery = {
  documentGroups: PaginatedPayload<DocumentGroupFragment>;
};

type QueryArgs = Pick<QueryDocumentGroupsArgs, "page" | "perPage">;

export const useDocumentGroupsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<DocumentGroupsQuery, QueryArgs>(
    DOCUMENT_GROUPS,
    {
      variables: { ...args },
    }
  );

  const documentGroups = data?.documentGroups.items?.filter(Boolean) ?? [];
  const total = data?.documentGroups.total ?? 0;

  return { documentGroups, total, isLoading };
};

export const useDocumentGroupsLazyQuery = () => {
  const [getDocumentGroupsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<DocumentGroupsQuery, QueryArgs>(DOCUMENT_GROUPS, {
      fetchPolicy: "network-only",
    });

  const documentGroups = data?.documentGroups.items?.filter(Boolean) ?? [];
  const total = data?.documentGroups.total ?? 0;

  const resolveRef =
    useRef<(documentGroups?: DocumentGroupFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(documentGroups);
      resolveRef.current = undefined;
    }
  }, [documentGroups, called, isLoading]);

  const getDocumentGroups = async (args?: QueryArgs) => {
    const variables = { ...args };

    getDocumentGroupsQuery({ variables });

    return new Promise<DocumentGroupFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getDocumentGroups, documentGroups, total, isLoading, called };
};
