import { useTranslation } from "react-i18next";
import {
  HiLockClosed,
  HiOutlineAdjustments,
  HiOutlineCollection,
  HiOutlineCurrencyDollar,
  HiOutlineCash,
  HiOutlineDocumentText,
  HiOutlinePencil,
  HiOutlineUserCircle,
} from "react-icons/hi";
import { useLocation } from "react-router";

import avatar from "assets/branding/avatar.svg";
import { SessionFragment } from "graphql/fragments";
import { AccountTypeEnum } from "graphql/types";
import { useLocalizedRoutePathGetter } from "utils";

import { MenuItem, MenuItemGroup } from "./MenuList/types";

interface Props {
  session?: SessionFragment;
}

export default ({ session }: Props): MenuItem[] => {
  const { t } = useTranslation("common");

  const getRoutePath = useLocalizedRoutePathGetter();
  const { pathname } = useLocation();

  if (!session) return [];

  return session.accounts.reduce(
    (acc: MenuItem[], { id, accountType, name, email, logo }) => {
      const isPartner = accountType === AccountTypeEnum.Team;
      const getAccountPath = getRoutePath(id);
      const logoImg = logo
        ? {
            src: `${process.env.REACT_APP_API_URL}${logo?.downloadPath}`,
            alt: "Profile image",
          }
        : undefined;

      if (isPartner) {
        const partnerGroup: MenuItemGroup = {
          id: "sideMenu-partner_group",
          isOpenByDefault: id === session.account.id,
          label: name ?? email,
          image: {
            src: logoImg ? logoImg.src : avatar,
            alt: "Partner logo",
          },
          items: [
            {
              id: "sideMenu-go_to_workflows" as const,
              Icon: HiOutlineCollection,
              label: t("layout.sideMenu.link.workflows", "Workflows"),
              to: getAccountPath("JOBS"),
              isActive: pathname.includes(getAccountPath("JOBS")),
            },
            {
              id: "sideMenu-go_to_eSignatures" as const,
              Icon: HiOutlinePencil,
              label: t("layout.sideMenu.link.eSignatures", "eSignatures"),
              to: getAccountPath("DOCUMENTS"),
              isActive: pathname.includes(getAccountPath("DOCUMENTS")),
            },
            {
              id: "sideMenu-go_to_invoices" as const,
              Icon: HiOutlineDocumentText,
              label: t("layout.sideMenu.link.invoices", "Invoices"),
              to: getAccountPath("INVOICES"),
              isActive: pathname.includes(getAccountPath("INVOICES")),
            },
            {
              id: "sideMenu-go_to_payouts" as const,
              Icon: HiOutlineCurrencyDollar,
              label: t("layout.sideMenu.link.payouts", "Payouts"),
              to: getAccountPath("PURCHASES"),
              isActive: pathname.includes(getAccountPath("PURCHASES")),
            },
          ],
        };

        return [
          ...acc,
          "DIVIDER",
          { text: t("layout.sideMenu.groupTitle.freeSpace", "Free space") },
          partnerGroup,
        ];
      }

      const workerGroup: MenuItemGroup = {
        id: "sideMenu-worker_group",
        isOpenByDefault: id === session.account.id,
        label: name ?? email,
        ...(logoImg ? { image: logoImg } : { Icon: HiOutlineUserCircle }),
        items: [
          {
            id: "sideMenu-go_to_eSignatures" as const,
            Icon: HiOutlinePencil,
            label: t("layout.sideMenu.link.eSignatures", "eSignatures"),
            to: getAccountPath("DOCUMENTS"),
            isActive: pathname.includes(getAccountPath("DOCUMENTS")),
          },
          {
            id: "sideMenu-go_to_payoutService" as const,
            Icon: HiOutlineCash,
            label: t("layout.sideMenu.link.payoutService", "Payout Service"),
            to: getAccountPath("PAYOUT_SERVICE"),
            isActive: pathname.includes(getAccountPath("PAYOUT_SERVICE")),
          },
          {
            id: "sideMenu-go_to_settings" as const,
            Icon: HiOutlineAdjustments,
            label: t("layout.sideMenu.link.settings", "Settings"),
            to: getAccountPath("ACCOUNT_SETTINGS"),
            isActive: pathname.includes(getAccountPath("ACCOUNT_SETTINGS")),
          },
        ],
      };
      return [
        {
          text: t("layout.sideMenu.groupTitle.private", "Private"),
          Icon: HiLockClosed,
        },
        workerGroup,
        ...acc,
      ];
    },
    []
  );
};
