import tw from "tw-generated";
import mmLonn from "assets/branding/mmLonn.svg";
import { PartialBackground } from "components/common/wrappers";
import { FreelanceProfileFragment } from "graphql/fragments";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Divider,
  Button,
  Link,
} from "components/common/basic";
import { useLocalizedRoutePath } from "utils";

import CreateIndProfileForm from "./CreateIndProfileForm";
import Agreement from "./Agreement";
import { useState } from "react";

interface Props {
  onSuccessfulEdit: (profile: FreelanceProfileFragment) => void;
  profile?: FreelanceProfileFragment;
}

export default ({ onSuccessfulEdit, profile }: Props): JSX.Element => {
  const [hasCheckedDocument, setHasCheckedDocument] = useState(
    Boolean(profile?.id)
  );

  const { t } = useTranslation("account");
  const getRoutePath = useLocalizedRoutePath();

  const formId = "individualFreelanceProfileForm";

  return (
    <PartialBackground
      heading={
        <>
          <div
            className={tw(
              "flex",
              "space-y-4",
              "flex-col",
              "sm:space-y-0",
              "sm:flex-row",
              "sm:justify-between"
            )}
          >
            <h1 className={tw("text-2xl", "font-extrabold")}>
              <Trans
                ns="account"
                i18nKey="freelanceProfileWizard.freelanceProfileCreation.individual.heading"
              >
                Set up your ENK / AS freelance profile
              </Trans>
            </h1>

            <Link
              id="create_ind_profile-go_to_help"
              to={t(
                "freelanceProfileWizard.freelanceProfileCreation.articles.link",
                "https://en.support.manymore.com"
              )}
              isExternal
              unstyled
            >
              <Button
                id="create_ind_profile-go_to_help"
                size="md"
                variant="tertiary"
              >
                <Trans
                  ns="account"
                  i18nKey="freelanceProfileWizard.freelanceProfileCreation.articles.label"
                >
                  Help articles
                </Trans>
              </Button>
            </Link>
          </div>

          <div className={tw("mt-5", "mb-12")}>
            <Divider />
          </div>
        </>
      }
      content={
        <div className={tw("mx-auto", "max-w-screen-sm")}>
          <Card
            header={
              <CardHeader className={tw("bg-blue-900")}>
                <img className={tw("h-9")} src={mmLonn} alt="Manymore lonn" />
              </CardHeader>
            }
            footer={
              <CardFooter
                className={tw(
                  "bg-deepBlue-50",
                  "flex",
                  "justify-end",
                  "space-x-3"
                )}
              >
                <Link
                  id="create_ind_profile-cancel"
                  unstyled
                  to={getRoutePath("PAYOUT_PROFILES")}
                >
                  <Button
                    id="create_ind_profile-cancel"
                    variant="tertiary"
                    size="sm"
                  >
                    <Trans
                      ns="account"
                      i18nKey="freelanceProfileWizard.freelanceProfileCreation.individual.cancel"
                    >
                      Cancel
                    </Trans>
                  </Button>
                </Link>

                <Button
                  id="create_ind_profile-sign_and_save"
                  type="submit"
                  form={formId}
                  variant="primary"
                  size="sm"
                  disabled={!hasCheckedDocument}
                >
                  <Trans
                    ns="account"
                    i18nKey="freelanceProfileWizard.freelanceProfileCreation.individual.submit"
                  >
                    Sign with eSign and save
                  </Trans>
                </Button>
              </CardFooter>
            }
          >
            <CardBody>
              <CreateIndProfileForm
                onSuccessfulEdit={onSuccessfulEdit}
                freelanceProfile={profile}
                formId={formId}
              />
            </CardBody>

            <Agreement
              onChecked={() => setHasCheckedDocument(true)}
              isChecked={hasCheckedDocument}
              isOrganization={false}
            />
          </Card>
        </div>
      }
    />
  );
};
