/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  ERROR_FRAGMENT,
  ErrorFragment,
  DOCUMENT_RECIPIENT_FRAGMENT,
  DocumentRecipientFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const NOTIFY_DOCUMENT_RECIPIENT_HOSTS = gql`
  mutation ($id: ID!) {
    notifyDocumentRecipientHosts(input: { id: $id }) {
      documentRecipient {
        ...DocumentRecipientFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_RECIPIENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentRecipient?: DocumentRecipientFragment | null;
  errors: ErrorFragment[];
}

interface NotifyDocumentRecipientHostsMutation {
  notifyDocumentRecipientHosts: Payload | null;
}

export const useNotifyDocumentRecipientHostsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.notifiedSignature",
    "Reminder sent successfully"
  );
  // Values for extraction
  // t("common:success.notifiedSignature", "Reminder sent successfully")

  const [mutation, { data, loading, called }] = useMutation<
    NotifyDocumentRecipientHostsMutation,
    { id: string }
  >(NOTIFY_DOCUMENT_RECIPIENT_HOSTS);

  const notifyDocumentRecipientHosts = (
    id: string,
    onSuccess?: (response: DocumentRecipientFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.notifyDocumentRecipientHosts?.errors ?? [];
        const documentRecipient =
          data?.notifyDocumentRecipientHosts?.documentRecipient;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            documentRecipient && onSuccess && onSuccess(documentRecipient),
          onRetry: () => notifyDocumentRecipientHosts(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    notifyDocumentRecipientHosts,
    isLoading: loading,
    called,
    documentRecipient: data?.notifyDocumentRecipientHosts?.documentRecipient,
    errors: data?.notifyDocumentRecipientHosts?.errors ?? [],
  };
};
