/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentRecipientAttributes } from "graphql/types";

const CREATE_PEOPLE = gql`
  mutation ($attributes: [DocumentRecipientAttributes!]!) {
    createPeople(input: { attributes: $attributes }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface CreatePeopleMutation {
  createPeople: Payload | null;
}

export const useCreatePeopleMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreatePeopleMutation,
    { attributes: DocumentRecipientAttributes[] }
  >(CREATE_PEOPLE);

  const createPeople = (
    variables: { attributes: DocumentRecipientAttributes[] },
    onSuccess?: () => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.createPeople?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createPeople,
    isLoading: loading,
    errors: data?.createPeople?.errors ?? [],
  };
};
