import { gql } from "@apollo/client";

import { Account } from "graphql/types";

import { IDENTITY_FRAGMENT, IdentityFragment } from "./identity";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    id
    identities {
      ...IdentityFragment
    }
    accountType
    industrySector
    email
    name
    description
    logo {
      ...AttachmentFragment
    }
    facebookLink
    instagramLink
    linkedinLink
    twitterLink
    website
    hasFreelanceProfiles
  }
  ${IDENTITY_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export interface AccountFragment
  extends Pick<
    Account,
    | "id"
    | "accountType"
    | "industrySector"
    | "email"
    | "name"
    | "description"
    | "facebookLink"
    | "instagramLink"
    | "linkedinLink"
    | "twitterLink"
    | "website"
    | "hasFreelanceProfiles"
  > {
  identities?: IdentityFragment[];
  logo?: AttachmentFragment;
}
