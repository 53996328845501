import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import {
  HiOutlineDocument,
  // HiOutlineDocumentDuplicate,
  HiOutlinePencilAlt,
  HiPlus,
  HiX,
  HiMenu,
  HiOutlineDocumentText,
} from "react-icons/hi";

import tw from "tw-generated";
import { useLocalizedRoutePath } from "utils";
import { Trans, useTranslation } from "translations";
import { Link } from "components/common/basic";
import { useMenu } from "hooks";

import { MenuList, TopMenuList } from "./common";

export default (): JSX.Element => {
  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingCreateMenu, setIsShowingCreateMenu] = useMenu(createMenuRef);
  const [isShowingNavMenu, setIsShowingNavMenu] = useState(false);
  const toggleCreationMenu = () => setIsShowingCreateMenu(!isShowingCreateMenu);
  const toggleNavMenu = () => setIsShowingNavMenu(!isShowingNavMenu);

  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useLocalizedRoutePath();

  const isPeople = pathname.includes(getRoutePath("PEOPLE"));

  useEffect(() => {
    setIsShowingCreateMenu(false);
    setIsShowingNavMenu(false);
  }, [pathname]);

  const tabBarStyles = tw(
    "fixed",
    "z-30",
    "bottom-0",
    "border-t-2",
    "border-silver",
    "bg-white",
    "w-screen",
    "flex"
  );

  const linkBase = tw(
    "w-full",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "space-y-1",
    "pt-2",
    "pb-4",
    "text-xs",
    "font-bold"
  );
  const linkStyles = tw(
    linkBase,
    "border-transparent",
    "text-gray-500",
    "hover:border-gray-300",
    "hover:text-gray-700"
  );
  const activeLinkStyles = tw(linkBase, "border-blue-900", "text-blue-900");

  const createButtonStyles = tw(
    "w-12",
    "h-12",
    "rounded-full",
    "flex",
    "justify-center",
    "items-center",

    "border",
    "border-transparent",

    "shadow-sm",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900",
    "bg-primary",
    "text-primary-contrast",
    "hover:bg-primary-hover"
  );

  const dropdownMenuStyles = tw(
    "absolute",
    "bottom-full",
    "mb-6",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  const fullscreenMenuStyles = tw(
    "fixed",
    "z-40",
    "top-0",
    "left-0",
    "bg-white",
    "h-screen",
    "w-screen",
    "pb-16",
    "overflow-auto"
  );

  return (
    <>
      <nav className={tabBarStyles}>
        <div className={tw("flex-1")}>
          <Link
            id="app_tab_bar-go_to_invoices"
            to={getRoutePath("INVOICES")}
            unstyled
            className={isPeople ? activeLinkStyles : linkStyles}
          >
            <HiOutlineDocumentText size="24px" />

            <Trans ns="common" i18nKey="layout.appTabBar.link.invoices.label">
              Invoices
            </Trans>
          </Link>
        </div>

        <div
          ref={createMenuRef}
          className={tw("relative", "flex-1", "flex", "justify-center")}
        >
          <div className={tw("absolute", "bottom-0")}>
            <div className={linkStyles}>
              <button
                aria-label="create menu"
                aria-expanded={isShowingCreateMenu}
                onClick={toggleCreationMenu}
                className={createButtonStyles}
              >
                <HiPlus size="24px" />
              </button>

              <Trans ns="common" i18nKey="header_loggedIn.button.create">
                Create
              </Trans>
            </div>
          </div>

          {isShowingCreateMenu && (
            <nav className={dropdownMenuStyles}>
              <MenuList
                menuItems={[
                  {
                    id: "app_tab_bar-go_to_one_invoice",
                    Icon: HiOutlineDocument,
                    label: t(
                      "header_loggedIn.link.createOneInvoice",
                      "One invoice"
                    ),
                    to: getRoutePath("INVOICE_INITIALIZATION"),
                  },
                  // {
                  //   id: "app_tab_bar-go_to_multiple_invoices",
                  //   Icon: HiOutlineDocumentDuplicate,
                  //   label: t(
                  //     "header_loggedIn.link.createMultipleInvoices",
                  //     "Multiple invoices"
                  //   ),
                  //   to: getRoutePath("INVOICE_IMPORT"),
                  // },
                  {
                    id: "app_tab_bar-go_to_new_esignature",
                    Icon: HiOutlinePencilAlt,
                    label: t(
                      "header_loggedIn.link.createESignature",
                      "New eSignature"
                    ),
                    to: getRoutePath("CREATE_DOCUMENT"),
                  },
                ]}
              />
            </nav>
          )}
        </div>

        <div className={tw("relative", "flex-1")}>
          <button
            aria-label="menu"
            aria-expanded={isShowingNavMenu}
            onClick={toggleNavMenu}
            className={isShowingNavMenu ? activeLinkStyles : linkStyles}
          >
            {isShowingNavMenu ? <HiX size="24px" /> : <HiMenu size="24px" />}

            <Trans ns="common" i18nKey="header_loggedIn.button.menu">
              Account
            </Trans>
          </button>
        </div>
      </nav>

      {isShowingNavMenu && (
        <nav className={fullscreenMenuStyles}>
          <TopMenuList />

          {/* TODO: Show workspace in mobile menu */}
          {/* <Workspace /> */}
        </nav>
      )}
    </>
  );
};
