import { lazy } from "react";

const BankIDVerification = lazy(() => import("./BankIDVerification"));
const CriiptoCallback = lazy(() => import("./CriiptoCallback"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const GateKeeper = lazy(() => import("./GateKeeper"));
const IdentityVerification = lazy(() => import("./IdentityVerification"));
const ReferrerPage = lazy(() => import("./ReferrerPage"));

export {
  BankIDVerification,
  CriiptoCallback,
  ErrorPage,
  GateKeeper,
  IdentityVerification,
  ReferrerPage,
};
