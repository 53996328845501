/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { RefuseDocumentInput } from "graphql/types";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const REFUSE_DOCUMENT = gql`
  mutation ($input: RefuseDocumentInput!) {
    refuseDocument(input: $input) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface RefuseDocumentMutation {
  refuseDocument: Payload | null;
}

export const useRefuseDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading, called }] = useMutation<
    RefuseDocumentMutation,
    { input: RefuseDocumentInput }
  >(REFUSE_DOCUMENT);

  const refuseDocument = (
    input: RefuseDocumentInput,
    onSuccess?: (response: DocumentFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.refuseDocument?.errors ?? [];
        const document = data?.refuseDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => refuseDocument(input, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    refuseDocument,
    isLoading: loading,
    called,
    document: data?.refuseDocument?.document,
    errors: data?.refuseDocument?.errors ?? [],
  };
};
