/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { SendDocumentVersionRecipientsEnum } from "graphql/types";

const SEND_DOCUMENT_VERSION = gql`
  mutation ($id: ID!, $recipients: SendDocumentVersionRecipientsEnum!) {
    sendDocumentVersion(input: { id: $id, recipients: $recipients }) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface SendDocumentVersionMutation {
  sendDocumentVersion: Payload | null;
}

export const useSendDocumentVersionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    SendDocumentVersionMutation,
    {
      id: string;
      recipients: SendDocumentVersionRecipientsEnum;
    }
  >(SEND_DOCUMENT_VERSION);

  const sendDocumentVersion = (
    variables: {
      id: string;
      recipients: SendDocumentVersionRecipientsEnum;
    },
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({
      variables,
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendDocumentVersion?.errors ?? [];
        const documentVersion = data?.sendDocumentVersion?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendDocumentVersion,
    isLoading: loading,
    documentVersion: data?.sendDocumentVersion?.documentVersion,
    errors: data?.sendDocumentVersion?.errors ?? [],
  };
};
