import { tw } from "tw-generated";

interface Props {
  message?: string;
}

export default ({ message }: Props): JSX.Element => {
  const lineStyles = tw("w-full", "border-t", "border-deepBlue-100");

  if (message) {
    const lineContainerStyles = tw(
      "absolute",
      "inset-0",
      "flex",
      "items-center"
    );

    const textContainerStyles = tw(
      "relative",
      "flex",
      "justify-center",
      "text-sm"
    );
    const textStyles = tw("px-2", "bg-white", "text-gray-500");

    return (
      <div className={tw("relative")}>
        <div className={lineContainerStyles}>
          <div className={lineStyles} />
        </div>

        <div className={textContainerStyles}>
          <span className={textStyles}>{message}</span>
        </div>
      </div>
    );
  }

  return <div className={lineStyles}></div>;
};
