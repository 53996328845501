/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  ACCOUNT_FRAGMENT,
  AccountFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { SESSION, SessionQuery } from "graphql/queries";

const CREATE_TEAM_ACCOUNT = gql`
  mutation ($name: String!) {
    createTeamAccount(input: { name: $name }) {
      account {
        ...AccountFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ACCOUNT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  account?: AccountFragment | null;
  errors: ErrorFragment[];
}

interface CreateTeamAccountMutation {
  createTeamAccount: Payload | null;
}

export const useCreateTeamAccountMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateTeamAccountMutation,
    { name: string }
  >(CREATE_TEAM_ACCOUNT);

  const createTeamAccount = (
    name: string,
    onSuccess?: (account: AccountFragment) => void
  ) =>
    mutation({
      variables: { name },
      update(cache, { data: newTeamAccountData }) {
        const newTeamAccountNode =
          newTeamAccountData?.createTeamAccount?.account;
        if (!newTeamAccountNode) return;

        cache.modify({
          fields: {
            session() {
              const currentSession = cache.readQuery<SessionQuery>({
                query: SESSION,
              });
              if (!currentSession) return;

              const accounts = [
                ...(currentSession.session.accounts ?? []),
                newTeamAccountNode,
              ];

              cache.writeQuery<SessionQuery>({
                query: SESSION,
                data: {
                  session: {
                    ...currentSession.session,
                    accounts,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createTeamAccount?.errors ?? [];
        const account = data?.createTeamAccount?.account;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && account && onSuccess(account),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createTeamAccount,
    isLoading: loading,
    account: data?.createTeamAccount?.account,
    errors: data?.createTeamAccount?.errors ?? [],
  };
};
