/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateCreditNoteInput } from "graphql/types";
import {
  CREDIT_NOTE_FRAGMENT,
  CreditNoteFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const CREATE_CREDIT_NOTE = gql`
  mutation ($input: CreateCreditNoteInput!) {
    createCreditNote(input: $input) {
      creditNote {
        ...CreditNoteFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CREDIT_NOTE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  creditNote?: CreditNoteFragment | null;
  errors: ErrorFragment[];
}

interface CreateCreditNoteMutation {
  createCreditNote: Payload | null;
}

export const useCreateCreditNoteMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateCreditNoteMutation,
    { input: CreateCreditNoteInput }
  >(CREATE_CREDIT_NOTE);

  const createCreditNote = (
    input: CreateCreditNoteInput,
    onSuccess?: (creditNote: CreditNoteFragment) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createCreditNote?.errors ?? [];
        const creditNote = data?.createCreditNote?.creditNote;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => creditNote && onSuccess && onSuccess(creditNote),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createCreditNote,
    isLoading: loading,
    creditNote: data?.createCreditNote?.creditNote,
    errors: data?.createCreditNote?.errors ?? [],
  };
};
