import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

import { useSwitchAccountMutation } from "graphql/mutations";
import { AuthContext } from "providers/Authentication";
import {
  useLocalizedRoutePath,
  useDefaultRouteName,
  unspecifiedAccountString,
} from "utils";
import { NameSpace } from "translations/shared";
import { Spinner } from "components/common/basic";

import NSRoute from "./NSRoute";

interface Props {
  isLoggedInRoute: boolean;
  nameSpace?: NameSpace;
}

export default ({ isLoggedInRoute, nameSpace }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);

  const { isLoggedIn, session, login } = useContext(AuthContext);
  const { switchAccount } = useSwitchAccountMutation();
  const navigate = useNavigate();

  const defaultRouteName = useDefaultRouteName();
  const getRedirectPath = useLocalizedRoutePath();
  const { pathname } = useLocation();
  const { accountID: expectedAccountID } = useParams();

  useEffect(() => {
    if (!isLoading) setIsLoading(true);

    if (isLoggedInRoute !== isLoggedIn)
      return navigate(getRedirectPath(defaultRouteName));
    else if (isLoggedIn) {
      if (!session) return;

      // Check that there are no required actions pending
      const isAdmin = session.administrator;
      if (isLoggedInRoute && !isAdmin) {
        const isConfirmed = session.user.confirmed === true;
        const hasReferrer = typeof session.user.referrer === "string";
        const hasOccupation = typeof session.user.occupation === "string";

        if (!isConfirmed || !hasReferrer || !hasOccupation) {
          const gateKeeperPath = getRedirectPath("GATE_KEEPER");
          const isGateKeeper = pathname === gateKeeperPath;
          if (!isGateKeeper) return navigate(gateKeeperPath);
        }
      }

      // Check that the correct account is selected
      const isAcceptedAccount =
        expectedAccountID === unspecifiedAccountString ||
        expectedAccountID === session.account.id;
      if (expectedAccountID && !isAcceptedAccount) {
        const isAvailableAccount = session.accounts.some(
          ({ id }) => id === expectedAccountID
        );
        if (!isAvailableAccount)
          return navigate(getRedirectPath(defaultRouteName));
        else {
          switchAccount({ id: expectedAccountID }, login);
          return;
        }
      }
    }

    setIsLoading(false);
  }, [isLoggedInRoute, pathname, isLoggedIn, session]);

  if (isLoading) return <Spinner />;

  if (nameSpace) return <NSRoute nameSpace={nameSpace} />;

  return <Outlet />;
};
