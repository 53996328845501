import secureUpload from "assets/illustrations/modals/secureUpload.svg";
import tw from "tw-generated";
import { Trans } from "translations";

export default (): JSX.Element => (
  <div className={tw("flex", "items-center", "space-x-2")}>
    <img src={secureUpload} alt="Secure upload" />

    <p className={tw("text-sm", "font-bold", "text-blue-900", "uppercase")}>
      <Trans ns="common" i18nKey="secureUpload.message">
        Secure upload
      </Trans>
    </p>
  </div>
);
