import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "components/common/basic";

import Filters, { Filter, FilterValues } from "./Filters";

interface Props {
  filters: Filter[];
  defaultValues: FilterValues;
  clearFilters: () => void;
  onApply: (values: FilterValues) => void;
  onCancel: () => void;
}

export default ({
  filters,
  defaultValues,
  clearFilters,
  onApply,
  onCancel,
}: Props): JSX.Element | null => {
  const [filterValues, setFilterValues] = useState(defaultValues);

  const setFilterValue = (name: string, value: string) =>
    setFilterValues({
      ...filterValues,
      [name]: value,
    });

  const backgroundStyles = tw(
    "fixed",
    "top-0",
    "left-0",
    "z-50",
    "w-screen",
    "h-screen",
    "overflow-y-auto",
    "mt-0"
  );

  // Prevent scroll when popup is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <div className={backgroundStyles}>
      <Card
        className={tw("min-h-screen")}
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h3 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="clients.filterPopup.title">
                  Filters
                </Trans>
              </h3>

              <button onClick={onCancel}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw(
              "sticky",
              "bottom-0",
              "flex",
              "justify-end",
              "bg-deepBlue-50",
              "space-x-3"
            )}
          >
            <Button
              id="filter_popup-apply"
              onClick={() => onApply(filterValues)}
              variant="primary"
              size="sm"
            >
              <Trans ns="common" i18nKey="clients.filterPopup.submit">
                Apply
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <form>
          <CardBody className={tw("items-stretch")}>
            <Filters
              filters={filters}
              values={filterValues}
              setFilterValue={setFilterValue}
              setFilterValues={setFilterValues}
            />

            <button
              type="reset"
              className={tw("block", "ml-auto", "text-gray-900")}
              onClick={clearFilters}
            >
              Clear filters
            </button>
          </CardBody>
        </form>
      </Card>
    </div>
  );
};
