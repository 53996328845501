import { useContext } from "react";
import moment from "moment";
import tw from "tw-generated";
import { CalendarContainer, CalendarContainerProps } from "react-datepicker";
import { Button } from "components/common/basic";
import { useTranslation } from "translations";
import { TailwindContext } from "providers/Tailwind";
import { HiX } from "react-icons/hi";

interface DateContainerProps extends CalendarContainerProps {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: ([start, end]: [start: Date | null, end: Date | null]) => void;
  submitDateFilter: () => void;
  onCancel: () => void;
}

export default ({
  className,
  children,
  startDate,
  endDate,
  setDateRange,
  submitDateFilter,
  onCancel,
}: DateContainerProps) => {
  const { t } = useTranslation("common");
  const { isLg, isXl, is2xl } = useContext(TailwindContext);

  const datePickerShortcuts = [
    {
      label: t("filterDateRange.today", "Today"),
      from: moment().startOf("day").toDate(),
      to: moment().endOf("day").toDate(),
    },
    {
      label: t("filterDateRange.yesterday", "Yesterday"),
      from: moment().startOf("day").subtract(1, "days").toDate(),
      to: moment().endOf("day").subtract(1, "days").toDate(),
    },
    {
      label: t("filterDateRange.thisMonth", "This month"),
      from: moment().startOf("month").toDate(),
      to: moment().endOf("month").toDate(),
    },
    {
      label: t("filterDateRange.prevMonths", "Previous month"),
      from: moment().subtract(1, "months").startOf("month").toDate(),
      to: moment().subtract(1, "months").endOf("month").toDate(),
    },
    {
      label: t("filterDateRange.thisYear", "This year"),
      from: moment().startOf("year").toDate(),
      to: moment().endOf("year").toDate(),
    },
    {
      label: t("filterDateRange.prevYear", "Previous Year"),
      from: moment().subtract(1, "year").startOf("year").toDate(),
      to: moment().subtract(1, "year").endOf("year").toDate(),
    },
  ];

  const mobileWrapperStyles = tw(
    "fixed",
    "top-0",
    "left-0",
    "w-screen",
    "h-screen"
  );

  const mobileShortcutsStyles = tw(
    "items-center",
    "justify-between",
    "border-b",
    "gap-2"
  );

  const wrapperStyles = tw(
    { [mobileWrapperStyles]: !isLg },
    { absolute: isLg },
    { "right-10": !is2xl },
    "z-50",
    "overflow-y-auto",
    "bg-white",
    "flex",
    "flex-col",
    "xl:max-w-max",
    "shadow-md"
  );

  const shortcutsListStyles = tw(
    { [mobileShortcutsStyles]: !isLg },
    "border-deepBlue-50",
    "flex",
    "flex-wrap",
    "pb-6",
    "xl:pr-6",
    "xl:border-r",
    "xl:flex-col",
    "xl:border-b-0"
  );

  return (
    <div className={wrapperStyles}>
      <div
        className={tw(
          "flex",
          "flex-col",
          "p-4",
          "h-screen",
          "xl:flex-row",
          "xl:h-auto"
        )}
      >
        {!isXl && (
          <div className={tw("flex", "justify-between", "mt-4", "mb-8")}>
            <h3 className={tw("text-lg", "font-extrabold")}>
              {t("filterDateRange.popupHeader", "Date picker")}
            </h3>

            <button onClick={onCancel}>
              <HiX size={24} className={tw("text-gray-400")} />
            </button>
          </div>
        )}

        <ul className={shortcutsListStyles}>
          {datePickerShortcuts.map(({ label, from, to }) => {
            const isActive = (() => {
              if (!startDate || !endDate) return false;

              if (startDate.toDateString() !== from.toDateString())
                return false;

              if (endDate.toDateString() !== to.toDateString()) return false;

              return true;
            })();

            const shortcutItemStyles = tw(
              "cursor-pointer",
              "py-2",
              "px-2",
              "rounded-md",
              "flex-grow",
              "text-center",
              "border",
              "border-gray-100",
              "xl:border-0",
              "xl:flex-grow-0",
              "xl:text-left",
              "xl:px-3",
              "xl:py-2",
              "xl:m-0",
              isActive ? "bg-blue-100" : "hover:bg-silver"
            );

            return (
              <li
                key={label}
                className={tw(shortcutItemStyles)}
                onClick={() => setDateRange([from, to])}
              >
                {label}
              </li>
            );
          })}
        </ul>

        <CalendarContainer className={className}>
          <div>{children}</div>
        </CalendarContainer>
      </div>

      <div
        className={tw(
          "sticky",
          "bottom-0",
          "flex",
          "space-x-4",
          "justify-end",
          "p-4",
          "border-t",
          "border-deepBlue-100",
          "bg-white",
          "xl:border-0"
        )}
      >
        <Button
          id="dateFilter_cancel"
          size="md"
          aria-label="date filter cancel"
          variant="tertiary"
          onClick={onCancel}
        >
          {t("filterDateRange.cancel", "Cancel")}
        </Button>

        <Button
          id="dateFilter_apply"
          size="md"
          aria-label="date filter apply"
          onClick={submitDateFilter}
          disabled={!endDate}
        >
          {t("filterDateRange.apply", "Apply")}
        </Button>
      </div>
    </div>
  );
};
