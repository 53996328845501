import { gql } from "@apollo/client";

import { DocumentRecipient } from "graphql/types";
import { SIGNATURE_FRAGMENT, SignatureFragment } from "./signature";

export const DOCUMENT_RECIPIENT_FRAGMENT = gql`
  fragment DocumentRecipientFragment on DocumentRecipient {
    id
    email
    invitedBy
    name
    notifiedAt
    phone
    sequenceNumber
    signedAt
    status
    signature {
      ...SignatureFragment
    }
  }
  ${SIGNATURE_FRAGMENT}
`;

export interface DocumentRecipientFragment
  extends Pick<
    DocumentRecipient,
    | "id"
    | "email"
    | "invitedBy"
    | "name"
    | "notifiedAt"
    | "phone"
    | "sequenceNumber"
    | "signedAt"
    | "status"
  > {
  signature: SignatureFragment;
}
