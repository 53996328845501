import { useEffect, useRef, useState } from "react";
import { HiChevronLeft, HiChevronRight, HiSearch } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { useDebounce, usePagination, useMenu } from "hooks";

import { Input } from "../basic";

interface Props<T> {
  id: string;
  itemKey: string;
  renderListItemLabel: (item: T) => JSX.Element;
  onSearch: (searchInput: string) => Promise<T[]>;
  onSelect: (item: T) => void;
  buttonLabel: JSX.Element;
  label?: string;
  placeholder?: string;
  listHeader?: JSX.Element;
  minQueryLength?: number;
  perPage?: number;
  errorMessage?: string;
  debounceTime?: number;
  reloadOnOpen?: boolean;
}

export default <T,>({
  id,
  itemKey,
  renderListItemLabel,
  onSearch,
  onSelect,
  buttonLabel,
  label,
  placeholder,
  listHeader,
  minQueryLength = 1,
  perPage = 5,
  errorMessage,
  debounceTime = 750,
  reloadOnOpen = false,
}: Props<T>): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, debounceTime);

  const { page, pages, pageItems, goBack, goNext, resetItems } = usePagination({
    items: [] as T[],
    perPage,
  });

  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useMenu(createMenuRef);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (debouncedValue.length < minQueryLength) return;

    onSearch(debouncedValue).then(resetItems);
  }, [debouncedValue]);

  useEffect(() => {
    if (!reloadOnOpen || !isOpen) return;

    onSearch(searchInput).then(resetItems);
  }, [isOpen]);

  return (
    <div className={tw("relative")} ref={createMenuRef}>
      {label && (
        <label
          htmlFor={id}
          className={tw("block", "text-sm", "text-gray-700", "mb-1")}
        >
          {label}
        </label>
      )}

      <button
        id={id}
        type="button"
        onClick={toggleIsOpen}
        className={tw(
          "py-2",
          "px-3",
          "rounded-md",
          "shadow-sm",
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "shadow-sm",
          "sm:text-sm",
          "rounded-md",
          "focus:ring-gray-900",
          "focus:border-gray-900",
          "border-gray-300",
          {
            [tw("border", "border-gray-300")]: !errorMessage,
            [tw("border-2", "border-error", "bg-error-light")]: !!errorMessage,
          }
        )}
        aria-describedby={errorMessage ? "address-error" : undefined}
      >
        {buttonLabel}
      </button>

      {errorMessage && (
        <p className={tw("mt-2", "text-sm", "text-error")} id="address-error">
          {errorMessage}
        </p>
      )}

      {isOpen && (
        <div className={tw("absolute", "z-10", "w-full", "mt-1", "pb-24")}>
          <div className={tw("bg-silver", "shadow", "rounded-lg")}>
            <div className={tw("py-2", "px-4")}>
              <Input
                autoFocus
                id="searchInput"
                name="searchInput"
                label={t("dropdown.searchInput.label", "Search")}
                placeholder={
                  placeholder ?? t("dropdown.searchInput.label", "Search")
                }
                hideLabel
                onChange={(event) => setSearchInput(event.target.value)}
                LeadingIcon={HiSearch}
              />
            </div>

            {pageItems.length > 0 && (
              <>
                {listHeader}

                <ul className={tw("bg-white", "py-1", "space-y-1")}>
                  {pageItems.map((item) => {
                    const selectOption = () => {
                      onSelect(item);

                      setIsOpen(false);
                    };

                    return (
                      <li key={item[itemKey]}>
                        <button
                          type="button"
                          onClick={selectOption}
                          className={tw(
                            "text-left",
                            "w-full",
                            "hover:bg-silver",
                            "py-1",
                            "px-3"
                          )}
                        >
                          {renderListItemLabel(item)}
                        </button>
                      </li>
                    );
                  })}
                </ul>

                {pages > 1 && (
                  <div
                    className={tw(
                      "flex",
                      "justify-center",
                      "items-center",
                      "p-4",
                      "space-x-4"
                    )}
                  >
                    <button
                      type="button"
                      onClick={goBack}
                      className={tw("text-blue-900")}
                    >
                      <HiChevronLeft size={24} />
                    </button>

                    <p
                      className={tw(
                        "text-sm",
                        "font-semibold",
                        "text-deepBlue-900"
                      )}
                    >
                      <Trans
                        ns="common"
                        i18nKey="searchBrreg.pageNav"
                        defaults="{{ page }} of {{ pages }}"
                        values={{ page, pages }}
                      />
                    </p>

                    <button
                      type="button"
                      onClick={goNext}
                      className={tw("text-blue-900")}
                    >
                      <HiChevronRight size={24} />
                    </button>
                  </div>
                )}
              </>
            )}
          </div>{" "}
        </div>
      )}
    </div>
  );
};
