import { useState } from "react";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { FreelanceTypeEnum } from "graphql/types";
import { FreelanceProfileFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { Button, Card, CardBody, CardFooter } from "components/common/basic";

import PopupTemplate from "../PopupTemplate";
import CreateOrganizationProfile from "./CreateOrgProfile";
import CreateIndividualProfile from "./CreateIndProfile";

interface Props {
  onClose: () => void;
  freelanceType: FreelanceTypeEnum;
  profile?: FreelanceProfileFragment;
  onSuccess?: (profile: FreelanceProfileFragment) => void;
}

export default ({
  onClose,
  freelanceType,
  profile,
  onSuccess,
}: Props): JSX.Element => {
  const [hasCheckedDocument, setHasCheckedDocument] = useState(
    Boolean(profile?.id)
  );

  const { t } = useTranslation("common");

  const onSuccessfulEdit = (profile: FreelanceProfileFragment) => {
    onSuccess?.(profile);
    onClose();
  };

  const formId = "freelance-profile-form";

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="freelance_profile-cancel"
              variant="tertiary"
              size="sm"
              onClick={onClose}
            >
              {t("popup.freelanceProfile.cancel", "Cancel")}
            </Button>

            <Button
              id="freelance_profile-save"
              type="submit"
              form={formId}
              variant="primary"
              size="sm"
              disabled={!hasCheckedDocument}
            >
              {t("popup.freelanceProfile.submit", "Save")}
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <div className={tw("w-full", "space-y-2")}>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h3 className={tw("text-lg", "font-extrabold")}>
                {profile
                  ? t(
                      "popup.freelanceProfile_edit.heading",
                      "Edit your payout profile"
                    )
                  : t(
                      "popup.freelanceProfile_create.heading",
                      "Add your payout profile"
                    )}
              </h3>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </div>
        </CardBody>

        {freelanceType === FreelanceTypeEnum.Organization ? (
          <CreateOrganizationProfile
            formId={formId}
            onSuccessfulEdit={onSuccessfulEdit}
            setIsChecked={() => setHasCheckedDocument(true)}
            isChecked={hasCheckedDocument}
            profile={profile}
          />
        ) : (
          <CreateIndividualProfile
            formId={formId}
            onSuccessfulEdit={onSuccessfulEdit}
            setIsChecked={() => setHasCheckedDocument(true)}
            isChecked={hasCheckedDocument}
            profile={profile}
          />
        )}
      </Card>
    </PopupTemplate>
  );
};
