import { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import { useOccupationCodesLazyQuery } from "graphql/queries";
import { OccupationCodeFragment } from "graphql/fragments";

import BESearch from "./BESearch";

interface Props {
  onSelect: (occupation: OccupationCodeFragment) => void;
  label?: string;
  errorMessage?: string;
  defaultOccupation?: OccupationCodeFragment;
}

export default ({
  label,
  onSelect,
  errorMessage,
  defaultOccupation,
}: Props): JSX.Element => {
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [occupation, setOccupation] = useState<
    OccupationCodeFragment | undefined
  >(defaultOccupation);
  const { occupationCodes, getOccupationCodes, total } =
    useOccupationCodesLazyQuery();

  useEffect(() => {
    getOccupationCodes({ page, perPage, keyword });
  }, []);

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getOccupationCodes({
      page: p,
      perPage,
      keyword: searchInput ?? keyword,
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  const buttonLabel = (
    <>
      {occupation ? (
        occupation.name
      ) : (
        <Trans ns="common" i18nKey="searchOccupation.label">
          Search our DB by occupation name
        </Trans>
      )}

      <HiChevronDown size={20} />
    </>
  );

  return (
    <BESearch<OccupationCodeFragment>
      id="occupation-search"
      itemKey="id"
      items={occupationCodes}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setOccupation(option);
        onSelect(option);
      }}
      buttonLabel={buttonLabel}
      label={label}
      errorMessage={errorMessage}
      fixedHeight="h-72"
    />
  );
};

const renderListItemLabel = (occupation: OccupationCodeFragment) => (
  <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
    {occupation.name}
  </p>
);
