/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreateJobAttributes } from "graphql/types";
import {
  JOB_FRAGMENT,
  JobFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  JobsQuery,
  JOBS,
  PartnerJobsQuery,
  PARTNER_JOBS,
} from "graphql/queries";

const CREATE_JOB = gql`
  mutation ($attributes: CreateJobAttributes!) {
    createJob(input: { attributes: $attributes }) {
      job {
        ...JobFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${JOB_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  job?: JobFragment | null;
  errors: ErrorFragment[];
}

interface CreateJobMutation {
  createJob: Payload | null;
}

export const useCreateJobMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateJobMutation,
    { attributes: CreateJobAttributes }
  >(CREATE_JOB);

  const createJob = (
    attributes: CreateJobAttributes,
    onSuccess?: (job: JobFragment) => void
  ) =>
    mutation({
      variables: { attributes },
      update(cache, { data: newJobData }) {
        const newJobNode = newJobData?.createJob?.job;
        if (!newJobNode) return;

        cache.modify({
          fields: {
            jobs() {
              const existing = cache.readQuery<JobsQuery>({
                query: JOBS,
              });
              if (!existing) return;

              const items = [newJobNode, ...(existing.jobs.items ?? [])];

              cache.writeQuery<JobsQuery>({
                query: JOBS,
                data: {
                  jobs: {
                    items,
                    total: existing.jobs.total + 1,
                  },
                },
              });
            },
            partnerJobs() {
              const existing = cache.readQuery<PartnerJobsQuery>({
                query: JOBS,
              });
              if (!existing) return;

              const items = [newJobNode, ...(existing.partnerJobs.items ?? [])];

              cache.writeQuery<PartnerJobsQuery>({
                query: PARTNER_JOBS,
                data: {
                  partnerJobs: {
                    items,
                    total: existing.partnerJobs.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createJob?.errors ?? [];
        const job = data?.createJob?.job;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && job && onSuccess(job),
          onRetry: () => createJob(attributes, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createJob,
    isLoading: loading,
    job: data?.createJob?.job,
    errors: data?.createJob?.errors ?? [],
  };
};
