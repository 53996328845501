import { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TagManager from "react-gtm-module";
import Cookie from "js-cookie";
import moment from "moment";

import { BeaconContext } from "providers/Beacon";
import { IntlContext } from "providers/i18n";
import {
  getOutdatedRoutePath,
  getPrefixedRoutePath,
  useLocalizedRoutePath,
  useDefaultRouteName,
} from "utils";

import { Freelancer, Privacy, Terms } from "components/markdown/pages";

import {
  Dashboard,
  EnsureValidLocale,
  Home,
  NSRoute,
  NSRouteWithAuth,
  SearchHandler,
  TokenHandler,
} from "components/app";
import { Layout, ErrorBoundary } from "components/common/wrappers";
import {
  BankIDVerification,
  CriiptoCallback,
  ReferrerPage,
  ErrorPage,
  GateKeeper,
  IdentityVerification,
} from "components/common/pages";
import {
  Login,
  MagicLink,
  MagicLinkVerification,
  ResetPassword,
  Signup,
} from "components/login/pages";
import {
  AccountSettings,
  Benefits,
  FreelanceProfileWizard,
  FreelanceProfileWizardSuccess,
  Payslips,
  JobChecks,
  AccountSelection,
  PayoutService,
} from "components/account/pages";
import {
  Invoice,
  Invoices,
  InvoiceDuplication,
  // InvoiceImport,
  PublicInvoice,
  CreditNote,
  CreditNoteCreation,
  Purchases,
  InvoiceCreation,
  InvoiceInitialization,
} from "components/invoices/pages";
import { EnrolmentWizard, Jobs, Job } from "components/partners/pages";
import {
  Agreement,
  Document,
  Documents,
  DocumentTypeSelector,
  PublicDocument,
  QuickSign,
  Signature,
  SignatureTestPage,
} from "components/documents/pages";
import { Work } from "components/work/pages";
import { People, Person } from "components/people/pages";
import PayoutSettings from "components/account/pages/PayoutSettings";
import {
  CheckInvitations,
  CheckMore,
  CheckMoreWizard,
  Checks,
} from "components/checkmore/pages";

const {
  REACT_APP_PROD_REDIRECTION_URL,
  REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
  REACT_APP_GOOGLE_TAG_MANAGER_ENV,
  REACT_APP_PAGE_TITLE,
  REACT_APP_GOOGLE_PLACES_API_KEY,
} = process.env;

export default (): JSX.Element => {
  const { currentLocale } = useContext(IntlContext);
  const { loadBeacon } = useContext(BeaconContext);

  const getRoutePath = getPrefixedRoutePath("/:locale", "/:accountID");
  const getOutdatedPath = getOutdatedRoutePath("/:locale");
  const getLocalizedRoutePath = useLocalizedRoutePath();
  const defaultRouteName = useDefaultRouteName();

  const isSignaturePage = window.location.pathname.includes(
    getLocalizedRoutePath("SIGN_DOCUMENT", "")
  );
  const isIframe = window.location !== window.parent.location;
  const isStandalone = isSignaturePage || isIframe;

  useEffect(() => {
    moment.locale(currentLocale);
  }, [currentLocale]);

  useEffect(() => {
    if (isStandalone) return;

    loadBeacon();
  }, []);

  useEffect(() => {
    if (!REACT_APP_PROD_REDIRECTION_URL) return;
    if (isStandalone) return;

    const cookie = Cookie.get("dp-test-cookie");
    const active = (cookie ? JSON.parse(cookie) : {}).active;

    if (!active) window.location.assign(REACT_APP_PROD_REDIRECTION_URL);
  }, [REACT_APP_PROD_REDIRECTION_URL]);

  useEffect(() => {
    if (
      !REACT_APP_GOOGLE_TAG_MANAGER_ID ||
      !REACT_APP_GOOGLE_TAG_MANAGER_AUTH ||
      !REACT_APP_GOOGLE_TAG_MANAGER_ENV
    )
      return;

    const tagManagerArgs = {
      gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID,
      auth: REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
      preview: REACT_APP_GOOGLE_TAG_MANAGER_ENV,
    };

    if (document) TagManager.initialize(tagManagerArgs);
  }, [
    REACT_APP_GOOGLE_TAG_MANAGER_ID,
    REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    REACT_APP_GOOGLE_TAG_MANAGER_ENV,
  ]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`${REACT_APP_PAGE_TITLE} | %s`}
        defaultTitle={REACT_APP_PAGE_TITLE}
        htmlAttributes={{ lang: currentLocale }}
      >
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places`}
        />
      </Helmet>

      <Router>
        <SearchHandler />
        <EnsureValidLocale />

        <Layout>
          <ErrorBoundary>
            <Routes>
              <Route
                path="*"
                element={
                  <Navigate to={getLocalizedRoutePath(defaultRouteName)} />
                }
              />

              <Route path={getRoutePath("HOME")} element={<Home />} />

              <Route
                path={getRoutePath("REFERRER", ":referrer")}
                element={<ReferrerPage />}
              />

              <Route
                path={getRoutePath("TEST: ERROR_PAGE")}
                element={<ErrorPage />}
              />
              <Route
                path={getRoutePath("TEST: SIGNATURE")}
                element={<SignatureTestPage />}
              />

              <Route
                path={getRoutePath("TOKEN_HANDLER", ":destination")}
                element={<TokenHandler />}
              />

              <Route path={getRoutePath("PRIVACY")} element={<Privacy />} />
              <Route path={getRoutePath("TERMS")} element={<Terms />} />
              <Route
                path={getRoutePath("FREELANCER")}
                element={<Freelancer />}
              />

              <Route element={<NSRouteWithAuth isLoggedInRoute={true} />}>
                <Route
                  path={getRoutePath("GATE_KEEPER")}
                  element={<GateKeeper />}
                />
                <Route
                  path={getRoutePath("BANK_ID_VERIFICATION")}
                  element={<BankIDVerification />}
                />
                <Route
                  path={getRoutePath("IDENTITY_VERIFICATION")}
                  element={<IdentityVerification />}
                />
              </Route>

              <Route
                path={getRoutePath("CRIIPTO_CALLBACK")}
                element={<CriiptoCallback />}
              />

              <Route
                element={
                  <NSRouteWithAuth nameSpace="login" isLoggedInRoute={false} />
                }
              >
                <Route path={getRoutePath("SIGNUP")} element={<Signup />} />
                <Route path={getRoutePath("LOGIN")} element={<Login />} />
                <Route
                  path={getRoutePath("MAGIC_LINK_LOGIN")}
                  element={<MagicLink />}
                />
                <Route
                  path={getRoutePath("RESET_PASSWORD")}
                  element={<ResetPassword />}
                />
              </Route>

              <Route element={<NSRoute nameSpace="login" />}>
                <Route
                  path={getRoutePath("MAGIC_LINK_VERIFICATION")}
                  element={<MagicLinkVerification />}
                />
              </Route>

              <Route
                element={<NSRouteWithAuth nameSpace="common" isLoggedInRoute />}
              >
                <Route
                  path={getRoutePath("DASHBOARD")}
                  element={<Dashboard />}
                />
              </Route>

              <Route
                element={
                  <NSRouteWithAuth nameSpace="invoices" isLoggedInRoute />
                }
              >
                <Route
                  path={getRoutePath("INVOICES") + "/*"}
                  element={<Invoices />}
                />
                <Route
                  path={getRoutePath("PURCHASES") + "/*"}
                  element={<Purchases />}
                />
                <Route
                  path={getRoutePath("INVOICE_INITIALIZATION")}
                  element={<InvoiceInitialization />}
                />
                <Route
                  path={getRoutePath("INVOICE_CREATION", ":id")}
                  element={<InvoiceCreation />}
                />
                {/* <Route
                  path={getRoutePath("INVOICE_IMPORT")}
                  element={<InvoiceImport />}
                /> */}
                <Route
                  path={getRoutePath("INVOICE", ":id")}
                  element={<Invoice />}
                />
                <Route
                  path={getRoutePath("INVOICE_DUPLICATION", ":id")}
                  element={<InvoiceDuplication />}
                />
                <Route
                  path={getRoutePath("CREDIT_NOTE", ":id")}
                  element={<CreditNote />}
                />
                <Route
                  path={getRoutePath("CREDIT_NOTE_CREATION", ":id")}
                  element={<CreditNoteCreation />}
                />
              </Route>

              <Route
                element={<NSRouteWithAuth nameSpace="work" isLoggedInRoute />}
              >
                <Route path={getRoutePath("WORK")} element={<Work />} />
              </Route>

              <Route
                element={
                  <NSRouteWithAuth nameSpace="account" isLoggedInRoute />
                }
              >
                <Route
                  path={getRoutePath("ACCOUNT_SELECTION")}
                  element={<AccountSelection />}
                />
                <Route
                  path={getRoutePath("ACCOUNT_SETTINGS")}
                  element={<AccountSettings />}
                />
                <Route path={getRoutePath("BENEFITS")} element={<Benefits />} />

                <Route
                  path={getRoutePath("PAYOUT_SETTINGS")}
                  element={<PayoutSettings />}
                />
                <Route
                  path={getRoutePath("PAYOUT_SERVICE") + "/*"}
                  element={<PayoutService />}
                />
                <Route
                  path={getRoutePath("PAYOUT_PROFILES")}
                  element={<PayoutSettings />}
                />
                <Route
                  path={getRoutePath("BANK_ACCOUNTS")}
                  element={<PayoutSettings />}
                />

                <Route path={getRoutePath("PAYSLIPS")} element={<Payslips />} />
                <Route
                  path={getRoutePath("JOB_CHECKS")}
                  element={<JobChecks />}
                />
                <Route
                  path={getRoutePath("FREELANCE_PROFILE_WIZARD_SUCCESS")}
                  element={<FreelanceProfileWizardSuccess />}
                />
                <Route
                  path={getRoutePath("FREELANCE_PROFILE_WIZARD", ":id")}
                  element={<FreelanceProfileWizard />}
                />
                <Route
                  path={getRoutePath("FREELANCE_PROFILE_WIZARD")}
                  element={<FreelanceProfileWizard />}
                />
              </Route>

              <Route
                element={
                  <NSRouteWithAuth nameSpace="documents" isLoggedInRoute />
                }
              >
                <Route
                  path={getRoutePath("CREATE_DOCUMENT")}
                  element={<DocumentTypeSelector />}
                />

                <Route
                  path={getRoutePath("CREATE_QUICK_SIGN")}
                  element={<QuickSign />}
                />
                <Route
                  path={getRoutePath("CREATE_QUICK_SIGN", ":id")}
                  element={<QuickSign />}
                />

                <Route
                  path={getRoutePath("DOCUMENT", ":id")}
                  element={<Document />}
                />
                <Route
                  path={getRoutePath("AGREEMENT", ":id")}
                  element={<Agreement />}
                />
                <Route
                  path={getRoutePath("DOCUMENTS")}
                  element={<Documents />}
                />
                <Route
                  path={getRoutePath("AGREEMENTS")}
                  element={<Documents />}
                />
              </Route>

              <Route element={<NSRoute nameSpace="documents" />}>
                <Route
                  path={getRoutePath("PUBLIC_DOCUMENT")}
                  element={<PublicDocument />}
                />
                <Route
                  path={getRoutePath("PUBLIC_DOCUMENT", ":token")}
                  element={<PublicDocument />}
                />

                <Route
                  path={getRoutePath("SIGN_DOCUMENT", ":token")}
                  element={<Signature />}
                />
              </Route>

              <Route element={<NSRoute nameSpace="invoices" />}>
                <Route
                  path={getRoutePath("PUBLIC_INVOICE", ":id")}
                  element={<PublicInvoice />}
                />
              </Route>

              <Route element={<NSRoute nameSpace="partners" />}>
                <Route
                  path={getOutdatedPath("ENROLMENT_WIZARD", ":jobId") + "/*"}
                  element={<EnrolmentWizard />}
                />
                <Route
                  path={getRoutePath("ENROLMENT_WIZARD", ":jobId") + "/*"}
                  element={<EnrolmentWizard />}
                />
              </Route>
              <Route
                element={
                  <NSRouteWithAuth nameSpace="partners" isLoggedInRoute />
                }
              >
                <Route path={getRoutePath("JOBS")} element={<Jobs />} />
                <Route path={getRoutePath("JOB", ":id")} element={<Job />} />
              </Route>

              <Route
                element={<NSRouteWithAuth nameSpace="people" isLoggedInRoute />}
              >
                <Route path={getRoutePath("PEOPLE")} element={<People />} />

                <Route
                  path={getRoutePath("PERSON", ":id", ":tab")}
                  element={<Person />}
                />
                <Route
                  path={getRoutePath("PERSON", ":id")}
                  element={<Person />}
                />
              </Route>

              <Route element={<NSRoute nameSpace="checkmore" />}>
                <Route
                  path={getRoutePath("CHECKMORE_WIZARD", ":id", ":step")}
                  element={<CheckMoreWizard />}
                />
                <Route
                  path={getRoutePath("CHECKMORE_WIZARD", ":id")}
                  element={<CheckMoreWizard />}
                />
              </Route>

              <Route
                element={
                  <NSRouteWithAuth nameSpace="checkmore" isLoggedInRoute />
                }
              >
                <Route
                  path={getRoutePath("CHECKMORE")}
                  element={<CheckMore />}
                />
                <Route
                  path={getRoutePath("CHECKMORE_CHECKS")}
                  element={<Checks />}
                />
                <Route
                  path={getRoutePath("CHECKMORE_INVITE")}
                  element={<CheckInvitations />}
                />
              </Route>
            </Routes>
          </ErrorBoundary>
        </Layout>
      </Router>
    </HelmetProvider>
  );
};
