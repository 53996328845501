/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { QueryNodeArgs } from "graphql/types";
import {
  DOCUMENT_GROUP_FRAGMENT,
  DocumentGroupFragment,
} from "graphql/fragments";

export const DOCUMENT_GROUP = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on DocumentGroup {
        ...DocumentGroupFragment
      }
    }
  }
  ${DOCUMENT_GROUP_FRAGMENT}
`;

export type DocumentGroupQuery = { node: DocumentGroupFragment };

export const useDocumentGroupQuery = (id: string) => {
  const { data, loading } = useQuery<DocumentGroupQuery>(DOCUMENT_GROUP, {
    variables: { id },
  });

  return { documentGroup: data?.node, isLoading: loading };
};

export const useDocumentGroupLazyQuery = () => {
  const [getDocumentGroupQuery, { data, loading, called }] = useLazyQuery<
    DocumentGroupQuery,
    QueryNodeArgs
  >(DOCUMENT_GROUP);

  const resolveRef = useRef<(documentGroup?: DocumentGroupFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getDocumentGroup = async (id: string) => {
    getDocumentGroupQuery({ variables: { id } });

    return new Promise<DocumentGroupFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getDocumentGroup,
    documentGroup: data?.node,
    isLoading: loading,
  };
};
