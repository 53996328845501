/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DOCUMENTS, DocumentsQuery } from "graphql/queries";

const DESTROY_DOCUMENT = gql`
  mutation ($id: ID!) {
    destroyDocument(input: { id: $id }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface DestroyDocumentMutation {
  destroyDocument: Payload | null;
}

export const useDestroyDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.destroyedDocument",
    "Document destroyed successfully"
  );
  // Values for extraction
  // t("common:success.destroyedDocument", "Document destroyed successfully")

  const [mutation, { data, loading, called }] = useMutation<
    DestroyDocumentMutation,
    { id: string }
  >(DESTROY_DOCUMENT);

  const destroyDocument = (
    id: string,
    onSuccess?: (response: DocumentFragment) => void
  ) =>
    mutation({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            documents() {
              const existingDocuments = cache.readQuery<DocumentsQuery>({
                query: DOCUMENTS,
              });
              if (!existingDocuments?.documents.items) return;

              const items = existingDocuments.documents.items?.filter(
                (documentTemplateItem) => documentTemplateItem?.id !== id
              );

              cache.writeQuery<DocumentsQuery>({
                query: DOCUMENTS,
                data: {
                  documents: {
                    items,
                    total: existingDocuments.documents.total - 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.destroyDocument?.errors ?? [];
        const document = data?.destroyDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => document && onSuccess && onSuccess(document),
          onRetry: () => destroyDocument(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    destroyDocument,
    isLoading: loading,
    called,
    document: data?.destroyDocument?.document,
    errors: data?.destroyDocument?.errors ?? [],
  };
};
