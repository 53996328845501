import { Document, Page } from "react-pdf";

import tw from "tw-generated";
import { Trans } from "translations";
import { Button, FileUpload } from "components/common/basic";

interface Props {
  id: string;
  selectDocument: (files: File[]) => void;
  clearDocument: () => void;
  illustration: {
    src: string;
    alt: string;
  };
  fileRequirementsText: string;
  validFileTypes?: string[];
  isImageUpload?: boolean;
  label?: string;
  document?: string;
  documentPreview?: JSX.Element;
  isInputLabel?: boolean;
  maxSize?: number;
}

export default ({
  id,
  selectDocument,
  clearDocument,
  illustration,
  label,
  document,
  documentPreview,
  isInputLabel,
  isImageUpload,
  validFileTypes = isImageUpload
    ? ["image/png", "image/jpg", "image/jpeg"]
    : ["application/pdf"],
  fileRequirementsText,
  maxSize,
}: Props): JSX.Element => {
  const labelStyles = isInputLabel
    ? tw("block", "text-sm", "text-gray-700")
    : tw("mb-2", "font-bold", "text-gray-900");

  return (
    <div className={tw("flex", "flex-col", "space-y-2")}>
      <label
        htmlFor={id}
        className={tw(labelStyles, {
          "sr-only": !label,
        })}
      >
        {label ?? id}
      </label>

      <div>
        {document ? (
          <div
            className={tw(
              "w-full",
              "p-5",
              "flex",
              "flex-col",
              "space-y-5",
              "border-2",
              "border-gray-300",
              "rounded-md",
              "overflow-hidden"
            )}
          >
            {documentPreview ??
              (isImageUpload || document?.includes("data:image") ? (
                <img
                  alt="uploading"
                  src={document}
                  className={tw("m-0", "w-full")}
                />
              ) : (
                <Document
                  file={document}
                  className={tw("m-0", "overflow-auto")}
                >
                  <Page pageNumber={1} />
                </Document>
              ))}

            <Button
              id="document_upload-remove_upload"
              variant="tertiary"
              size="md"
              fullWidth
              onClick={clearDocument}
            >
              <Trans ns="common" i18nKey="documentUpload.removeUpload">
                Remove upload
              </Trans>
            </Button>
          </div>
        ) : (
          <FileUpload
            id={id}
            onFileDrop={selectDocument}
            validFileTypes={validFileTypes}
            maxSize={maxSize}
          >
            <div
              className={tw(
                "w-full",
                "p-5",
                "flex",
                "flex-col",
                "space-y-4",
                "border-2",
                "border-gray-300",
                "rounded-md",
                "overflow-hidden",
                "border-dashed"
              )}
            >
              <div className={tw("flex", "justify-center")}>
                <img src={illustration.src} alt={illustration.alt} />
              </div>

              <div className={tw("text-center")}>
                <p className={tw("text-sm", "font-semibold", "text-gray-700")}>
                  <Trans
                    ns="common"
                    i18nKey="documentUpload.button.selectUpload.upload"
                  >
                    <span className={tw("text-blue-900")}>Upload</span> a file
                    or drag and drop
                  </Trans>
                </p>

                <p className={tw("text-xs", "text-gray-500")}>
                  {fileRequirementsText}
                </p>
              </div>
            </div>
          </FileUpload>
        )}
      </div>
    </div>
  );
};
