import { useEffect, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useFloating } from "@floating-ui/react-dom";

import tw from "tw-generated";

import { MenuItemGroup } from "./types";
import MenuList from "./MenuList";

interface Props {
  item: MenuItemGroup;
  iconsOnly?: boolean;
  closeSelf?: () => void;
}
export default ({
  item: { items, label, image, Icon, iconClassName, isOpenByDefault },
  iconsOnly,
  closeSelf,
}: Props) => {
  const [isHover, setIsHover] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: "right",
    strategy: "fixed",
  });
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  useEffect(() => {
    if (items.some((item) => item.isActive)) setIsOpen(true);
  }, items);

  const listItemStyles = tw(
    "my-1",
    "rounded-md",
    "hover:bg-blue-100",
    "hover:text-blue-900",
    {
      [tw("p-2", "mx-2")]: iconsOnly,
      [tw(
        "group",
        "flex",
        "items-center",
        "w-full",
        "space-x-3",
        "px-4",
        "py-2"
      )]: !iconsOnly,
      ["mb-2"]: !iconsOnly && !isOpen,
    }
  );
  const listItemIconStyles = tw(
    "text-deepBlue-900",
    "group-hover:text-blue-900"
  );
  const iconStyles = iconClassName ?? listItemIconStyles;

  const Chevron = isOpen ? HiChevronUp : HiChevronDown;

  return (
    <li>
      <button className={listItemStyles} onClick={() => setIsOpen(!isOpen)}>
        <span
          ref={isHover ? reference : undefined}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {image ? (
            <img
              {...image}
              className={tw("rounded-full", "w-6", "h-6", "object-cover")}
            />
          ) : (
            Icon && (
              <Icon
                size="24px"
                className={iconClassName ?? listItemIconStyles}
              />
            )
          )}
        </span>

        {iconsOnly ? (
          <span
            className={tw(
              "ml-3",
              "p-2",
              "bg-gray-900",
              "rounded-md",
              "text-white",
              "text-xs",
              "font-semibold",
              "whitespace-nowrap",
              "pointer-events-none",
              { hidden: !isHover }
            )}
            ref={floating}
            style={{ position: strategy, left: x ?? 0, top: y ?? 0 }}
          >
            <span className="arrow-left" style={{ left: -7 }} />
            {label}
          </span>
        ) : (
          <>
            <span className={tw("flex-1", "text-left", "truncate")}>
              {label}
            </span>

            <Chevron size="24px" className={iconStyles} />
          </>
        )}
      </button>

      {isOpen && (
        <MenuList
          menuItems={items}
          closeSelf={closeSelf}
          iconsOnly={iconsOnly}
          isSubmenu
        />
      )}
    </li>
  );
};
