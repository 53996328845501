import { useEffect, useState } from "react";

export default (
  containerRef: React.RefObject<HTMLDivElement> | null
): [boolean, (shouldOpen: boolean) => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const checkFocus = (event: MouseEvent | KeyboardEvent) => {
    const targetElement = event.target instanceof Element && event.target;
    if (!targetElement) return setIsOpen(false);

    const hasFocus = containerRef?.current?.contains(targetElement);
    if (!hasFocus) setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("mousedown", checkFocus);

    return () => window.removeEventListener("mousedown", checkFocus);
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", checkFocus);

    return () => window.removeEventListener("keyup", checkFocus);
  }, []);

  return [isOpen, setIsOpen];
};
