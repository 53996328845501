import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Button,
  Divider,
} from "components/common/basic";
import { PopupTemplate } from "components/common/popups";
import { BankAccountFragment } from "graphql/fragments";

interface Props {
  bankAccount: BankAccountFragment;
  onClose: () => void;
}

export default ({ bankAccount, onClose }: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        header={
          <CardHeader>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h2 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="popup.viewBankAccount.title">
                  View Bank Account
                </Trans>
              </h2>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>
          </CardHeader>
        }
        footer={
          <CardFooter
            className={tw("bg-deepBlue-50", "flex", "justify-end", "space-x-3")}
          >
            <Button
              id="view_bank_account-close"
              onClick={onClose}
              variant="tertiary"
              size="sm"
            >
              <Trans ns="common" i18nKey="popup.viewBankAccount.close">
                Close
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody className={tw("-my-2")}>
          <Divider />
        </CardBody>

        <CardBody>
          <h2 className={tw("text-lg", "font-bold")}>
            <Trans
              ns="common"
              i18nKey="popup.viewBankAccount.form.accountDetailsSection.title"
            >
              Account details
            </Trans>
          </h2>

          <div className={tw("w-full", "flex", "space-x-4")}>
            <Input
              className={tw("w-3/4")}
              id="accountNumber"
              label={t(
                "popup.viewBankAccount.form.accountNumber.label",
                "Account number"
              )}
              defaultValue={bankAccount.accountNumber}
              disabled
            />

            <Input
              className={tw("w-1/4")}
              id="accountNumber"
              label={t("popup.viewBankAccount.form.currency.label", "Currency")}
              defaultValue={bankAccount.currency}
              disabled
            />
          </div>

          {bankAccount.swiftCode && (
            <Input
              id="swiftCode"
              label={t(
                "popup.viewBankAccount.form.swiftCode.label",
                "BIC / SWIFT"
              )}
              defaultValue={bankAccount.swiftCode}
              disabled
            />
          )}
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
