import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HiX } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { phoneValidationSchema } from "utils";
import { PhoneNumberFragment } from "graphql/fragments";
import { useSendSmsConfirmationMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  PhoneNumberInput,
} from "components/common/basic";

interface FormValues {
  phoneNumber: string;
  phoneCountry: string;
}

interface Props {
  onClose: () => void;
  onSubmit: (values: PhoneNumberFragment) => void;
}

export const SendSMSCodeForm = ({ onClose, onSubmit }: Props): JSX.Element => {
  const { sendSmsConfirmation, isLoading } = useSendSmsConfirmationMutation();

  const { t } = useTranslation("common");
  const formId = "send-sms-code-form";

  const validationSchema = Yup.object({
    phoneNumber: phoneValidationSchema(t).required(
      t(
        "phoneNumberVerification.sendSMSCode.phoneNumber.error.required",
        "A phone number is required"
      )
    ),
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmitForm = async ({ phoneNumber, phoneCountry }: FormValues) => {
    const attributes = {
      number: phoneNumber,
      countryCode: phoneCountry,
    };

    await sendSmsConfirmation(attributes);

    onSubmit(attributes as PhoneNumberFragment);
  };

  return (
    <Card
      footer={
        <CardFooter
          className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
        >
          <Button
            id="send_sms_code_form-send_sms_code"
            type="submit"
            form={formId}
            variant="primary"
            size="sm"
            disabled={isLoading}
          >
            <Trans
              ns="common"
              i18nKey="popup.phoneNumberVerification.sendSMSCode.submit"
            >
              Send sms code
            </Trans>
          </Button>
        </CardFooter>
      }
    >
      <CardBody>
        <div className={tw("w-full", "flex", "justify-between")}>
          <h3 className={tw("text-lg", "font-extrabold")}>
            <Trans
              ns="common"
              i18nKey="popup.phoneNumberVerification.sendSMSCode.heading"
            >
              Phone number verification
            </Trans>
          </h3>

          <button onClick={onClose}>
            <HiX size={24} className={tw("text-gray-400")} />
          </button>
        </div>

        <p className={tw("text-sm")}>
          <Trans
            ns="common"
            i18nKey="popup.phoneNumberVerification.sendSMSCode.paragraph"
          >
            For security reasons we need to verify your identity. We are going
            to send you an SMS with verification code to specified telephone
            number!
          </Trans>
        </p>

        <form
          onSubmit={handleSubmit(onSubmitForm)}
          className={tw("w-1/2")}
          id={formId}
        >
          <PhoneNumberInput
            id="phone"
            label={t(
              "popup.phoneNumberVerification.sendSMSCode.phoneNumber.label",
              "Mobile nr."
            )}
            countryCodes={[
              { label: "NO", value: "+47" },
              { label: "PL", value: "+48" },
            ]}
            registerCountry={register("phoneCountry")}
            registerNumber={register("phoneNumber")}
          />
        </form>
      </CardBody>
    </Card>
  );
};
