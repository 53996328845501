import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import tw from "tw-generated";
import { useCreateSessionFromOtpMutation } from "graphql/mutations";
import { useLocalizedRoutePath } from "utils";
import { Trans, useTranslation } from "translations";
import errorPageIllustration from "assets/illustrations/uncategorized/opps.svg";
import { Page } from "../common/wrappers";
import { Spinner } from "../common/basic";

export default (): JSX.Element => {
  const { destination } = useParams<{ destination?: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const getRoutePath = useLocalizedRoutePath();
  const { createSessionFromOtp, errors } = useCreateSessionFromOtpMutation();

  const { t } = useTranslation("common");

  useEffect(() => {
    if (!search) return;

    const searchParams = new URLSearchParams(search);
    const stage2Token = searchParams.get("stage-2-token");
    if (stage2Token) {
      const [jobId, otp] = stage2Token.split(":");

      createSessionFromOtp({ otp }, (token) => {
        const pathname = getRoutePath("ENROLMENT_WIZARD", jobId);

        if (destination === "enrolment-wizard")
          navigate(`${pathname}?dp-token=${token}`);
      });
    }
  }, [search]);

  const contentStyles = tw(
    "mx-auto",
    "max-w-2xl",
    "flex",
    "flex-col",
    "space-y-4",
    "items-center",
    "text-center"
  );

  if (errors.length) {
    const imageStyles = tw("h-40", "my-12");

    return (
      <Page
        title={t("tokenHandler_error.pageTitle", "Opps")}
        className={tw("bg-silver")}
      >
        <img
          className={imageStyles}
          src={errorPageIllustration}
          alt="Man with a tablet that shows an error message"
        />

        <div className={contentStyles}>
          <h1 className={tw("text-2xl", "font-extrabold")}>
            <Trans ns="common" i18nKey="tokenHandler_error.heading">
              OPPS!
            </Trans>
          </h1>

          <p className={tw("text-lg", "font-semibold", "text-gray-400")}>
            <Trans ns="common" i18nKey="tokenHandler_error.body">
              Sorry, the link you have used has expired. We have sent you a new
              link to your email.
            </Trans>
          </p>
        </div>
      </Page>
    );
  }

  return (
    <Page
      title={t("tokenHandler.pageTitle", "Redirecting...")}
      className={tw("bg-silver")}
    >
      <div className={contentStyles}>
        <Spinner />
      </div>
    </Page>
  );
};
