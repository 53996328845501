import { gql } from "@apollo/client";

import { Enrollment } from "graphql/types";

import { ACCOUNT_FRAGMENT, AccountFragment } from "./account";

export const ENROLMENT_FRAGMENT = gql`
  fragment EnrolmentFragment on Enrollment {
    id
    approved
    account {
      ...AccountFragment
    }
    partnerAccount {
      ...AccountFragment
    }
    freelanceProfile {
      id
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export interface EnrolmentFragment extends Pick<Enrollment, "id" | "approved"> {
  account: AccountFragment;
  partnerAccount: AccountFragment;
  freelanceProfile?: {
    id: string;
  };
}
