import { gql } from "@apollo/client";

import { DocumentGroup } from "graphql/types";

import {
  DocumentVersionFragment,
  DOCUMENT_VERSION_FRAGMENT,
} from "./documentVersion";

export const DOCUMENT_GROUP_FRAGMENT = gql`
  fragment DocumentGroupFragment on DocumentGroup {
    id
    name
    description
    sequenceNumber
    token
    documentVersions {
      ...DocumentVersionFragment
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
`;

export interface DocumentGroupFragment
  extends Pick<
    DocumentGroup,
    "id" | "name" | "description" | "sequenceNumber" | "token"
  > {
  documentVersions: DocumentVersionFragment[];
}
