/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { QueryNodeArgs } from "graphql/types";
import { PERSON_FRAGMENT, PersonFragment } from "graphql/fragments";

const PERSON = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Person {
        ...PersonFragment
      }
    }
  }
  ${PERSON_FRAGMENT}
`;

export type PersonQuery = { node: PersonFragment };

export const usePersonQuery = (id: string) => {
  const { data, loading } = useQuery<PersonQuery>(PERSON, {
    variables: { id },
  });

  return { person: data?.node, isLoading: loading };
};

export const usePersonLazyQuery = () => {
  const [getPersonQuery, { data, loading, called }] = useLazyQuery<
    PersonQuery,
    QueryNodeArgs
  >(PERSON);

  const resolveRef = useRef<(person?: PersonFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getPerson = async (id: string) => {
    getPersonQuery({ variables: { id } });

    return new Promise<PersonFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getPerson,
    person: data?.node,
    isLoading: loading,
  };
};
