import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import tw from "tw-generated";
import { useTranslation } from "translations";
import { Page } from "components/common/wrappers";
import { useLocalizedRoutePath } from "utils";

import PayoutProfiles from "./PayoutProfiles";
import BankAccounts from "./BankAccounts";

enum TabEnum {
  "profiles" = "PAYOUT_PROFILES",
  "bank-accounts" = "BANK_ACCOUNTS",
}

export default (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(TabEnum.profiles);

  const { t } = useTranslation("account");

  const getRoutePath = useLocalizedRoutePath();
  const { pathname } = useLocation();

  // Uncomment and add imports to allow switching between tabs
  // const navigate = useNavigate();
  // const setTabPath = (tab: TabEnum) => navigate(getRoutePath(tab));

  useEffect(() => {
    switch (pathname) {
      case getRoutePath("PAYOUT_PROFILES"):
        setCurrentTab(TabEnum["profiles"]);
        break;

      case getRoutePath("BANK_ACCOUNTS"):
        setCurrentTab(TabEnum["bank-accounts"]);
        break;
    }
  }, [pathname]);

  return (
    <Page
      title={t("payoutSettings.pageTitle", "Payout settings")}
      className={tw("w-full", "max-w-screen-md")}
    >
      {/* Uncomment and add imports to allow switching between tabs      
      <Heading header={t("payoutSettings.header", "Payout settings")} />

      <div className={tw("mt-12", "mb-10")}>
        <Tabs
          tabs={[
            {
              label: t("payoutSettings.tab.profiles", "Payout profiles"),
              value: TabEnum["profiles"],
            },
            {
              label: t("payoutSettings.tab.bankAccounts", "Bank accounts"),
              value: TabEnum["bank-accounts"],
            },
          ]}
          activeTab={currentTab}
          setActiveTab={setTabPath}
        />
        <Divider />
      </div> */}

      {(() => {
        switch (currentTab) {
          case TabEnum["profiles"]:
            return <PayoutProfiles />;

          case TabEnum["bank-accounts"]:
            return <BankAccounts />;

          default:
            return null;
        }
      })()}
    </Page>
  );
};
