import { TailwindContext } from "providers/Tailwind";
import { useContext } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { Trans } from "translations";
import tw from "tw-generated";

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  isCompact?: boolean;
}

export default ({
  currentPage,
  totalPages,
  onPageChange,
  isCompact,
}: Props): JSX.Element => {
  const { isLg } = useContext(TailwindContext);

  const pageNavContainer = tw(
    "inline-flex",
    "my-4",
    "text-sm",
    "text-gray-500"
  );

  return (
    <div className={pageNavContainer}>
      <div className={tw("w-32", "flex", "justify-start")}>
        {currentPage > 1 && (
          <button
            className={tw("flex", "space-x-3")}
            onClick={() => onPageChange(currentPage - 1)}
          >
            <HiArrowLeft size={20} />

            <span>
              <Trans ns="common" i18nKey="pageNav.back">
                Previous
              </Trans>
            </span>
          </button>
        )}
      </div>

      <div className={tw("flex-1", "flex", "justify-center", "space-x-8")}>
        {isLg && !isCompact ? (
          getPages(currentPage, totalPages).map((page, index) => {
            const styles = tw("w-4", "font-semibold", {
              "text-blue-900": page === currentPage,
            });

            if (page === 0)
              return (
                <span className={styles} key={index}>
                  ...
                </span>
              );

            return (
              <button
                className={styles}
                onClick={() => onPageChange(page)}
                key={index}
              >
                {page}
              </button>
            );
          })
        ) : (
          <select
            className={tw("py-0", "border-none")}
            onChange={(event) => onPageChange(Number(event.target.value))}
            value={currentPage}
          >
            {getNumbersStartingAt(1, totalPages).map((pageNumber) => (
              <option key={pageNumber} value={pageNumber}>
                {pageNumber}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className={tw("w-32", "flex", "justify-end")}>
        {currentPage < totalPages && (
          <button
            className={tw("flex", "space-x-3")}
            onClick={() => onPageChange(currentPage + 1)}
          >
            <span>
              <Trans ns="common" i18nKey="pageNav.next">
                Next
              </Trans>
            </span>

            <HiArrowRight size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

const getNumbersStartingAt = (startAt: number, amount: number) =>
  [...Array(amount)].map((_value, index) => startAt + index);

const getPages = (current: number, pages: number) => {
  const separator = 0;
  const firstPage = 1;
  const lastPage = pages;

  if (pages < 13) return getNumbersStartingAt(1, pages);

  if (current < 6) return [...getNumbersStartingAt(1, 10), separator, lastPage];

  if (current === 6)
    return [...getNumbersStartingAt(1, 11), separator, lastPage];

  if (pages - current === 6)
    return [firstPage, separator, ...getNumbersStartingAt(pages - 10, 11)];

  if (pages - current < 6)
    return [firstPage, separator, ...getNumbersStartingAt(pages - 9, 10)];

  return [
    firstPage,
    separator,
    ...getNumbersStartingAt(current - 4, 10),
    separator,
    lastPage,
  ];
};
