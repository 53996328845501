import { useEffect, useRef, useState } from "react";
import { HiChevronLeft, HiChevronRight, HiSearch } from "react-icons/hi";

import tw, { THeight } from "tw-generated";
import { Trans, useTranslation } from "translations";
import { useDebounce, useMenu } from "hooks";

import { Input, Spinner } from "../basic";

interface Props<T> {
  id: string;
  itemKey: string;
  items: T[];
  page: number;
  pages: number;
  setPage: (page: number) => void;
  onSearch: (searchInput: string) => void;
  renderListItemLabel: (item: T) => JSX.Element;
  onSelect: (item: T) => void;
  buttonLabel: JSX.Element;
  label?: string;
  placeholder?: string;
  listHeader?: JSX.Element;
  footer?: JSX.Element;
  emptyState?: JSX.Element;
  isLoading?: boolean;
  errorMessage?: string;
  fixedHeight?: THeight;
}

export default <T,>({
  id,
  itemKey,
  items,
  page,
  pages,
  setPage,
  onSearch,
  renderListItemLabel,
  onSelect,
  buttonLabel,
  label,
  placeholder,
  listHeader,
  footer,
  emptyState,
  isLoading,
  errorMessage,
  fixedHeight = "h-96",
}: Props<T>): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const debouncedValue = useDebounce(searchInput, 750);

  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useMenu(createMenuRef);
  const toggleIsOpen = () => setIsOpen(!isOpen);

  const { t } = useTranslation("common");

  useEffect(() => {
    onSearch(searchInput);
  }, [debouncedValue]);

  const goBack = () => {
    if (page <= 1) return;

    setPage(page - 1);
  };

  const goNext = () => {
    if (page >= pages) return;

    setPage(page + 1);
  };

  return (
    <div className={tw("relative")} ref={createMenuRef}>
      {label && (
        <label
          htmlFor={id}
          className={tw("block", "text-sm", "text-gray-700", "mb-1")}
        >
          {label}
        </label>
      )}

      <button
        id={id}
        type="button"
        onClick={toggleIsOpen}
        className={tw(
          "py-2",
          "px-3",
          "rounded-md",
          "shadow-sm",
          "flex",
          "justify-between",
          "items-center",
          "w-full",
          "shadow-sm",
          "sm:text-sm",
          "rounded-md",
          "bg-white",
          "focus:ring-gray-900",
          "focus:border-gray-900",
          "border-gray-300",
          {
            [tw("border", "border-gray-300")]: !errorMessage,
            [tw("border-2", "border-error", "bg-error-light")]: !!errorMessage,
          }
        )}
        aria-describedby={errorMessage ? "address-error" : undefined}
      >
        {buttonLabel}
      </button>

      {errorMessage && (
        <p className={tw("mt-2", "text-sm", "text-error")} id="address-error">
          {errorMessage}
        </p>
      )}

      {isOpen && (
        <div
          className={tw(
            "absolute",
            "z-10",
            "w-full",
            "mt-1",
            "bg-silver",
            "shadow",
            "rounded-lg",
            "flex",
            "flex-col",
            fixedHeight
          )}
        >
          <div className={tw("py-2", "px-4")}>
            <Input
              autoFocus
              id="searchInput"
              name="searchInput"
              defaultValue={searchInput}
              label={t("dropdown.searchInput.label", "Search")}
              placeholder={
                placeholder ?? t("dropdown.searchInput.label", "Search")
              }
              hideLabel
              onChange={(event) => setSearchInput(event.target.value)}
              LeadingIcon={HiSearch}
            />
          </div>

          {isLoading ? (
            <div className={tw("h-full", "overflow-hidden")}>
              <Spinner />
            </div>
          ) : items.length === 0 ? (
            emptyState
          ) : (
            <>
              {listHeader}

              <ul className={tw("bg-white", "py-1", "space-y-1", "h-full")}>
                {items.map((item) => {
                  const selectOption = () => {
                    onSelect(item);

                    setIsOpen(false);
                  };

                  return (
                    <li key={item[itemKey]}>
                      <button
                        type="button"
                        onClick={selectOption}
                        className={tw(
                          "text-left",
                          "w-full",
                          "hover:bg-silver",
                          "py-1",
                          "px-3"
                        )}
                      >
                        {renderListItemLabel(item)}
                      </button>
                    </li>
                  );
                })}
              </ul>

              {pages > 1 && (
                <div
                  className={tw(
                    "flex",
                    "justify-center",
                    "items-center",
                    "p-4",
                    "space-x-4"
                  )}
                >
                  <button
                    type="button"
                    onClick={goBack}
                    className={tw("text-blue-900")}
                  >
                    <HiChevronLeft size={24} />
                  </button>

                  <p
                    className={tw(
                      "text-sm",
                      "font-semibold",
                      "text-deepBlue-900"
                    )}
                  >
                    <Trans
                      ns="common"
                      i18nKey="searchBrreg.pageNav"
                      defaults="{{ page }} of {{ pages }}"
                      values={{ page, pages }}
                    />
                  </p>

                  <button
                    type="button"
                    onClick={goNext}
                    className={tw("text-blue-900")}
                  >
                    <HiChevronRight size={24} />
                  </button>
                </div>
              )}
            </>
          )}
          {pages < 2 && <div className={tw("h-14", "flex-shrink-0")} />}

          {footer}
        </div>
      )}
    </div>
  );
};
