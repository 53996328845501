import React, { useEffect, useRef, useState } from "react";
import { HiX } from "react-icons/hi";
import SignaturePad from "signature_pad";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
} from "components/common/basic";

import PopupTemplate from "./PopupTemplate";

interface Props {
  onClose: () => void;
  documentName: string;
  onSignature: (data: string) => void;
}

export default ({ onClose, documentName, onSignature }: Props): JSX.Element => {
  const [hasSignature, setHasSignature] = useState(false);
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { t } = useTranslation("common");

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    const p = 20; // Horizontal padding
    const w = canvasRef.current.offsetWidth - p;
    const h = canvasRef.current.offsetHeight;

    const textWidth = context.measureText(event.target.value).width;
    context.fillText(
      event.target.value,
      Math.max(w / 2 - textWidth / 2, p / 2), // Centers text
      h / 2,
      w
    );

    setHasSignature(event.target.value.length > 0);
  };

  const clearSignaturePad = () => {
    if (!signaturePad) return;

    signaturePad.clear();
    setHasSignature(false);
  };

  const submitSignature = () => {
    if (!canvasRef.current || !hasSignature) return;

    const data = canvasRef.current.toDataURL();
    onSignature(data);

    // Test image without uploading:
    // canvasRef.current.toBlob((blob) => {
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement("a");
    //   a.className = tw("hidden");
    //   a.href = url;

    //   document.body.appendChild(a);
    //   a.click();

    //   URL.revokeObjectURL(url);
    // });
  };

  useEffect(() => {
    if (signaturePad) return;

    if (canvasRef.current)
      setSignaturePad(
        new SignaturePad(canvasRef.current, {
          backgroundColor: "rgb(255, 255, 255)",
        })
      );
  }, [canvasRef.current]);

  useEffect(() => {
    if (!signaturePad) return;

    const onBegin = () => setHasSignature(true);
    signaturePad.addEventListener("beginStroke", onBegin);
    return () => signaturePad.removeEventListener("beginStroke", onBegin);
  }, [signaturePad]);

  const fontFamily = "Reenie Beanie";
  useEffect(() => {
    if (!signaturePad || !canvasRef.current) return;

    const context = canvasRef.current.getContext("2d");
    if (!context) return;

    // Prevents blurry text on HD screens
    const ratio = Math.max(window.devicePixelRatio, 1);
    const w = canvasRef.current.offsetWidth;
    const h = canvasRef.current.offsetHeight;
    canvasRef.current.width = w * ratio;
    canvasRef.current.height = h * ratio;
    canvasRef.current.style.width = w + "px";
    canvasRef.current.style.height = h + "px";
    context.scale(ratio, ratio);

    const fontStyles = "";
    const fontSize = 48;
    context.font = `${fontStyles} ${fontSize}px ${fontFamily}`;

    signaturePad.clear();
  }, [signaturePad]);

  return (
    <PopupTemplate onClose={onClose}>
      <Card
        footer={
          <CardFooter
            className={tw("flex", "justify-end", "bg-deepBlue-50", "space-x-3")}
          >
            <Button
              id="signature-cancel"
              variant="tertiary"
              size="sm"
              onClick={onClose}
            >
              <Trans ns="common" i18nKey="popup.signDocument.cancel">
                Cancel
              </Trans>
            </Button>

            <Button
              id="signature-sign_document"
              variant="primary"
              size="sm"
              onClick={submitSignature}
              disabled={!hasSignature}
            >
              <Trans ns="common" i18nKey="popup.signDocument.submit">
                Sign document
              </Trans>
            </Button>
          </CardFooter>
        }
      >
        <CardBody>
          <div className={tw("space-y-2")}>
            <div className={tw("w-full", "flex", "justify-between")}>
              <h3 className={tw("text-lg", "font-extrabold")}>
                <Trans ns="common" i18nKey="popup.signDocument.heading">
                  Sign document
                </Trans>
              </h3>

              <button onClick={onClose}>
                <HiX size={24} className={tw("text-gray-400")} />
              </button>
            </div>

            <p className={tw("text-sm", "text-gray-500")}>
              <Trans
                ns="common"
                i18nKey="popup.signDocument.body"
                defaults="Add your signature in the box below. By clicking Sign document I am signing the document “{{ documentName }}” and agree to be legally bound by this digital signature - Just the same as a pen-and-paper signature by using initials"
                values={{ documentName }}
              />
            </p>
          </div>

          <div
            className={tw(
              "grid",
              "w-full",
              "border-2",
              "border-gray-100",
              "items-center"
            )}
          >
            <div
              style={{ fontFamily }}
              className={tw(
                "text-transparent",
                "pointer-events-none",
                "fixed",
                "-top-48"
              )}
            >
              Canvas font preloader
            </div>

            <canvas
              className={tw(
                "w-full",
                "row-span-full",
                "col-span-full",
                "z-0",
                "justify-self-stretch",
                "h-48"
              )}
              ref={canvasRef}
            />

            {!hasSignature && (
              <p
                className={tw(
                  "row-span-full",
                  "col-span-full",
                  "z-10",
                  "mx-auto",
                  "pointer-events-none",
                  "select-none"
                )}
              >
                <Trans ns="common" i18nKey="popup.signDocument.canvas.helpText">
                  Draw your signature here
                </Trans>
              </p>
            )}
          </div>

          <form
            className={tw("w-full", "flex", "flex-col", "space-y-4")}
            autoComplete="off"
          >
            <div className={tw("flex", "space-x-4")}>
              {/* <Button
                id="signature-undo"
                size="sm"
                variant="tertiary"
                onClick={() => {
                  if (!signaturePad) return;

                  const data = signaturePad.toData();
                  if (!data) return;

                  signaturePad.fromData(data.slice(0, -1));
                  if (data.length === 1) setHasSignature(false);
                }}
              >
                <Trans ns="common" i18nKey="popup.signDocument.action.undo">
                  Undo
                </Trans>
              </Button> */}

              <Button
                id="signature-clear"
                size="sm"
                variant="tertiary"
                onClick={clearSignaturePad}
                type="reset"
              >
                <Trans ns="common" i18nKey="popup.signDocument.action.clear">
                  Clear
                </Trans>
              </Button>
            </div>

            <Input
              id="name"
              name="name"
              label={t(
                "popup.signDocument.input.name.label",
                "Or type your name and we will create a digital signature for you"
              )}
              placeholder={t(
                "popup.signDocument.input.name.placeholder",
                "My Name"
              )}
              onChange={onNameChange}
            />
          </form>
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
