import Cookie from "js-cookie";

import tw from "tw-generated";
import { useTranslation } from "translations";

import { Page } from "../common/wrappers";
import { Spinner } from "../common/basic";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  const cookie = Cookie.get("dp-test-cookie");
  const isTest = (cookie ? JSON.parse(cookie) : {}).active;

  const shouldRedirect = !isTest && window.location.hostname !== "localhost";

  if (shouldRedirect)
    window.location.href = t("links.homepage", "https://manymore.com/en");

  const contentStyles = tw(
    "mx-auto",
    "max-w-2xl",
    "flex",
    "flex-col",
    "space-y-4",
    "items-center",
    "text-center"
  );

  return (
    <Page
      title={t("tokenHandler.pageTitle", "Redirecting...")}
      className={tw("bg-silver")}
    >
      <div className={contentStyles}>
        <Spinner />
      </div>
    </Page>
  );
};
