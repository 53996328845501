import { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { HiChevronDown } from "react-icons/hi";
import { IconType } from "react-icons";

import tw, { TTailwindString } from "tw-generated";
import { useMenu } from "hooks";
import { MenuList } from "../wrappers/Layout/common";
import { MenuItem } from "../wrappers/Layout/common/MenuList/types";

interface Props {
  menuItems: MenuItem[];
  Icon?: IconType;
  menuStyles?: TTailwindString;
}

export default ({
  menuItems,
  menuStyles,
  Icon = HiChevronDown,
}: Props): JSX.Element => {
  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingActionMenu, setIsShowingActionMenu] = useMenu(createMenuRef);
  const toggleMenu = () => setIsShowingActionMenu(!isShowingActionMenu);

  const { pathname } = useLocation();
  useEffect(() => {
    setIsShowingActionMenu(false);
  }, [pathname]);

  const dropdownMenuStyles = tw(
    menuStyles,
    "z-20",
    "absolute",
    "right-0",
    "my-2",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  return (
    <div className={tw("relative")} ref={createMenuRef}>
      <button
        type="button"
        aria-label="action menu"
        aria-expanded={isShowingActionMenu}
        onClick={toggleMenu}
        className={tw(
          "z-10",
          "p-1",
          "border",
          "border-gray-300",
          "rounded-md",
          "bg-white",
          "hover:bg-silver"
        )}
      >
        <Icon size={24} />
      </button>

      {isShowingActionMenu && (
        <div className={dropdownMenuStyles}>
          <MenuList
            menuItems={menuItems}
            closeSelf={() => setIsShowingActionMenu(false)}
          />
        </div>
      )}
    </div>
  );
};
