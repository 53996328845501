import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { Button, LanguageSelect, Link } from "components/common/basic";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  const footerStyles = tw(
    "flex",
    "flex-col-reverse",
    "md:flex-row",
    "justify-between",
    "items-start",
    "md:items-end",
    "bg-deepBlue-900",
    "py-6",
    "px-4",
    "md:py-4",
    "md:px-6"
  );

  return (
    <footer className={footerStyles}>
      <p className={tw("text-deepBlue-500", "md:text-deepBlue-100")}>
        <Trans ns="common" i18nKey="footer.copyright">
          © 2021 manymore.com AS
        </Trans>
      </p>

      <div className={tw("my-4", "md:my-0")}>
        <p className={tw("text-deepBlue-100", "md:hidden")}>
          <Trans ns="common" i18nKey="footer.languageSelect.label">
            LANGUAGE
          </Trans>
        </p>

        <div className={tw("mt-4", "md:mt-0")}>
          <LanguageSelect isDark />
        </div>
      </div>

      <Link
        id="onboarding_footer-contact_us"
        to={t("footer.contact.link", "https://en.support.manymore.com/")}
        isExternal
        unstyled
      >
        <Button id="onboarding_footer-contact_us" size="md" variant="secondary">
          <Trans ns="common" i18nKey="footer.contact.label">
            Contact Us
          </Trans>
        </Button>
      </Link>
    </footer>
  );
};
