import { PropsWithChildren, useEffect } from "react";

import tw, { TMaxWidth } from "tw-generated";

interface Props {
  onClose: () => void;
  maxWidth?: TMaxWidth;
  isClosable?: boolean;
}

export default ({
  onClose,
  maxWidth = "max-w-screen-sm",
  children,
  isClosable = true,
}: PropsWithChildren<Props>): JSX.Element => {
  // Closes menus relying on `useMenu`
  useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();

      window.dispatchEvent(new Event("keyup"));
    }
  }, []);

  const onCloseHandler = isClosable ? onClose : undefined;

  const backgroundStyles = tw(
    "fixed",
    "top-0",
    "left-0",
    "z-50",
    "w-screen",
    "h-screen",
    "overflow-x-hidden",
    "overflow-y-auto",
    "flex",
    "justify-center"
  );

  return (
    <div
      className={backgroundStyles}
      style={{ background: "rgba(32, 50, 79, 0.6)" }}
    >
      <button
        tabIndex={-1}
        className={tw("z-0", "fixed", "w-full", "h-full", "opacity-0")}
        onClick={onCloseHandler}
      />

      <div className={tw("z-10", "absolute", "w-full", maxWidth)}>
        <button
          tabIndex={-1}
          className={tw("h-10", "w-full", "opacity-0")}
          onClick={onCloseHandler}
        />

        <div className={tw("px-4", "flex", "justify-center")}>{children}</div>

        <button
          tabIndex={-1}
          className={tw("h-10", "w-full", "opacity-0")}
          onClick={onCloseHandler}
        />
      </div>
    </div>
  );
};
