import { gql } from "@apollo/client";

import { UserInvoiceImport } from "graphql/types";

import { EVENT_FRAGMENT, EventFragment } from "./event";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const USER_INVOICE_IMPORT_FRAGMENT = gql`
  fragment UserInvoiceImportFragment on UserInvoiceImport {
    createdAt
    events {
      ...EventFragment
    }
    file {
      ...AttachmentFragment
    }
    id
    importNumber
    name
    status
  }
  ${EVENT_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
`;

export interface UserInvoiceImportFragment
  extends Pick<
    UserInvoiceImport,
    "createdAt" | "events" | "id" | "importNumber" | "name" | "status"
  > {
  events: EventFragment[];
  file: AttachmentFragment;
}
