import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryInvoicesArgs } from "graphql/types";

import {
  CLIENT_FRAGMENT,
  FREELANCE_PROFILE_FRAGMENT,
  REFERENCE_PERSON_FRAGMENT,
} from "graphql/fragments";

import { SimpleInvoice } from "./simplifiedInvoices";
import { PaginatedPayload } from "./shared/types";

const SIMPLIFIED_INVOICES = gql`
  query (
    $page: Int
    $perPage: Int
    $filter: InvoicesFilter
    $sortBy: InvoicesSortByEnum
  ) {
    partneredInvoices(
      page: $page
      perPage: $perPage
      filter: $filter
      sortBy: $sortBy
    ) {
      items {
        id
        client {
          ...ClientFragment
        }
        clientReferencePerson {
          ...ReferencePersonFragment
        }
        freelanceProfile {
          ...FreelanceProfileFragment
        }
        submittedAt
        rejectedAt
        publishedAt
        resolvedAt
        invoiceNumber
        status
        amount
        vat
        payoutAmount
        refundStatus
      }
      total
    }
  }
  ${CLIENT_FRAGMENT}
  ${FREELANCE_PROFILE_FRAGMENT}
  ${REFERENCE_PERSON_FRAGMENT}
`;

interface PartneredInvoicesQuery {
  partneredInvoices: PaginatedPayload<SimpleInvoice>;
}

type QueryArgs = Pick<
  QueryInvoicesArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const usePartneredInvoicesLazyQuery = (): {
  getInvoices: (args?: QueryArgs) => Promise<SimpleInvoice[] | undefined>;
  invoices: SimpleInvoice[];
  isLoading: boolean;
  called: boolean;
} => {
  const [getInvoicesQuery, { data, loading: isLoading, called }] = useLazyQuery<
    PartneredInvoicesQuery,
    QueryArgs
  >(SIMPLIFIED_INVOICES, {
    fetchPolicy: "network-only",
  });

  const partneredInvoices =
    data?.partneredInvoices.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(invoices?: SimpleInvoice[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(partneredInvoices);
      resolveRef.current = undefined;
    }
  }, [partneredInvoices, called, isLoading]);

  const getInvoices = async (args?: QueryArgs) => {
    const variables = { ...args };

    getInvoicesQuery({ variables });

    return new Promise<SimpleInvoice[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getInvoices, invoices: partneredInvoices, isLoading, called };
};
