import {
  Trans as DefaultTrans,
  TransProps,
  useTranslation,
} from "react-i18next";
import { NameSpace } from "./shared";

interface Props extends Omit<TransProps<string>, "ns" | "i18nKey"> {
  ns: NameSpace;
  i18nKey: string;
}

export default ({ children, ...props }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DefaultTrans {...props} t={t}>
      {children}
    </DefaultTrans>
  );
};
