import {
  FreelanceProfileWizard,
  FreelanceProfileWizardSuccess,
} from "./FreelanceProfileWizard";
import { lazy } from "react";

const AccountSelection = lazy(() => import("./AccountSelection"));
const AccountSettings = lazy(() => import("./AccountSettings"));
const Benefits = lazy(() => import("./Benefits"));
const PayoutSettings = lazy(() => import("./PayoutSettings"));
const JobChecks = lazy(() => import("./JobChecks"));
const Payslips = lazy(() => import("./Payslips"));
const PayoutService = lazy(() => import("./PayoutService"));

export {
  AccountSelection,
  AccountSettings,
  Benefits,
  PayoutSettings,
  FreelanceProfileWizard,
  FreelanceProfileWizardSuccess,
  JobChecks,
  Payslips,
  PayoutService,
};
