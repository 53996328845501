import { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import {
  emailValidationSchema,
  phoneValidationSchema,
  requiredStringSchema,
} from "utils";
import { ClientTypeEnum } from "graphql/types";
import {
  useCreateClientMutation,
  useUpdateClientMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { ClientFragment } from "graphql/fragments";
import { Divider, Input } from "components/common/basic";

import { AddClientContext } from "./AddClient";

export interface Props {
  formId: string;
  onSuccessfulEdit: (client: ClientFragment) => void;
  client?: ClientFragment;
}

export default ({ formId, onSuccessfulEdit, client }: Props): JSX.Element => {
  const { createClient } = useCreateClientMutation();
  const { updateClient } = useUpdateClientMutation();

  const { t } = useTranslation("common");
  const { freelanceProfileId } = useContext(AddClientContext);

  const validationSchema = Yup.object({
    clientNumber: Yup.number()
      .max(
        99999999,
        t(
          "clientForm_individual.clientNumber.error.max",
          "Client number must be between 1 and 8 digits"
        )
      )
      .integer(
        t(
          "clientForm_individual.clientNumber.error.integer",
          "Client number can not include punctuation"
        )
      ),
    name: requiredStringSchema(t)(
      t("clientForm_individual.name.label", "Full client name")
    ),
    email: emailValidationSchema(t),
    phone: phoneValidationSchema(t).required(
      t(
        "clientForm_individual.phone.error.required",
        "A phone number is required"
      )
    ),
    address: Yup.object({
      line1: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_individual.address.line1.error.required",
            "Address line 1 is required"
          )
        ),
      postalCode: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_individual.address.postalCode.error.required",
            "A post code is required"
          )
        ),
      city: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_individual.address.city.error.required",
            "A city is required"
          )
        ),
      country: Yup.string()
        .trim()
        .required(
          t(
            "clientForm_individual.address.country.error.required",
            "A country is required"
          )
        ),
    }),
  });

  const defaultValues = {
    id: client?.id ?? "",
    clientType: ClientTypeEnum.Individual,
    clientNumber:
      client?.clientNumber ?? Math.floor(Math.random() * 100_000_000),
    friendlyName: client?.friendlyName ?? "",
    phone: client?.phone ?? "",
    name: client?.name ?? "",
    email: client?.email ?? "",
    address: client?.address ?? {
      line1: "",
      postalCode: "",
      city: "",
      country: "NO",
    },
    autoSendEhf: client?.autoSendEhf ?? false,
    autoSendEmail: client?.autoSendEmail ?? false,
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ClientFragment>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({
    name,
    friendlyName,
    email,
    phone,
    clientNumber,
    autoSendEhf,
    autoSendEmail,
    address,
  }: ClientFragment) => {
    const attributes = {
      name,
      friendlyName,
      email,
      phone,
      clientNumber,
      autoSendEhf: [true, "true"].includes(autoSendEhf ?? false),
      autoSendEmail: [true, "true"].includes(autoSendEmail ?? false),
      address: {
        ...address,
        line1: address?.line1 ?? "",
        postalCode: address?.postalCode ?? "",
        city: address?.city ?? "",
        country: address?.country ?? "",
      },
    };

    if (client?.id)
      updateClient({ id: client.id, attributes }, onSuccessfulEdit);

    if (freelanceProfileId)
      createClient(
        {
          attributes: {
            freelanceProfileId,
            clientType: ClientTypeEnum.Individual,
            ...attributes,
          },
        },
        onSuccessfulEdit
      );
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={tw("w-full", "flex", "flex-col", "space-y-4")}
    >
      {[
        "id",
        "autoSendEhf",
        "autoSendEmail",
        "clientType",
        "address.country",
      ].map((name) => (
        <input
          key={name}
          {...register(name as keyof ClientFragment)}
          style={{ display: "none" }}
        />
      ))}

      <h2 className={tw("my-4", "text-lg", "font-bold")}>
        <Trans
          ns="account"
          i18nKey="clientForm_individual.clientInfoSection.title"
        >
          Client info
        </Trans>
      </h2>

      <p>
        <Trans
          ns="account"
          i18nKey="clientForm_individual.clientInfoSection.heading"
        >
          Please provide all the details below. It is important that the details
          are correct so that we can invoice your client without any
          misunderstanding.
        </Trans>
      </p>

      <Input
        id="name"
        {...register("name")}
        label={t("clientForm_individual.name.label", "Full client name")}
        placeholder={t(
          "clientForm_individual.name.placeholder",
          "Full client name"
        )}
        errorMessage={errors.name?.message}
      />

      <Input
        id="friendlyName"
        {...register("friendlyName")}
        label={t("clientForm_individual.friendlyName.label", "Friendly name")}
        placeholder={t(
          "clientForm_individual.friendlyName.placeholder",
          "Friendly name"
        )}
        errorMessage={errors.friendlyName?.message}
      />

      <Input
        id="email"
        type="email"
        {...register("email")}
        label={t("clientForm_individual.email.label", "Email address")}
        placeholder={t(
          "clientForm_individual.email.placeholder",
          "Email address"
        )}
        errorMessage={errors.email?.message}
      />

      <Input
        id="phone"
        {...register("phone")}
        label={t("clientForm_individual.phone.label", "Phone number")}
        placeholder={t(
          "clientForm_individual.phone.placeholder",
          "+47 000 00 000"
        )}
        errorMessage={errors.phone?.message}
      />

      <Input
        id="clientNumber"
        {...register("clientNumber")}
        label={t("clientForm_individual.clientNumber.label", "Client number")}
        placeholder={t(
          "clientForm_individual.clientNumber.placeholder",
          "Client number"
        )}
        errorMessage={errors.clientNumber?.message}
      />

      <Divider />

      <div className={tw("my-4", "flex", "justify-between", "items-center")}>
        <h2 className={tw("text-lg", "font-bold")}>
          <Trans
            ns="account"
            i18nKey="clientForm_individual.addressSection.title"
          >
            Organisation address
          </Trans>
        </h2>
      </div>

      <div className={tw("space-y-4")}>
        <Input
          id="address.line1"
          {...register("address.line1")}
          label={t(
            "clientForm_individual.addressSection.line1.label",
            "Address"
          )}
          placeholder={t(
            "clientForm_individual.addressSection.line1.placeholder",
            "Address"
          )}
          errorMessage={errors.address?.["line1"]?.message}
        />

        <Input
          id="address.line2"
          {...register("address.line2")}
          label={t(
            "clientForm_individual.addressSection.line2.label",
            "Address line 2"
          )}
          placeholder={t(
            "clientForm_individual.addressSection.line2.placeholder",
            "Address line 2"
          )}
          errorMessage={errors.address?.["line2"]?.message}
        />

        <div className={tw("flex", "space-x-4")}>
          <div className={tw("w-1/3")}>
            <Input
              id="address.postalCode"
              {...register("address.postalCode")}
              label={t(
                "clientForm_individual.addressSection.postalCode.label",
                "Post code"
              )}
              placeholder={t(
                "clientForm_individual.addressSection.postalCode.placeholder",
                "Post code"
              )}
              errorMessage={errors.address?.["postalCode"]?.message}
            />
          </div>

          <div className={tw("w-2/3")}>
            <Input
              id="address.city"
              {...register("address.city")}
              label={t(
                "clientForm_individual.addressSection.city.label",
                "City"
              )}
              placeholder={t(
                "clientForm_individual.addressSection.city.placeholder",
                "City"
              )}
              errorMessage={errors.address?.["city"]?.message}
            />
          </div>
        </div>

        <Input
          id="address.county"
          {...register("address.county")}
          label={t(
            "clientForm_individual.addressSection.county.label",
            "County"
          )}
          placeholder={t(
            "clientForm_individual.addressSection.county.placeholder",
            "County"
          )}
          errorMessage={errors.address?.["county"]?.message}
        />

        <Input
          id="address.country"
          label={t(
            "clientForm_individual.addressSection.country.label",
            "Country"
          )}
          value={client?.address?.country ?? "NO"}
          disabled
        />
      </div>
    </form>
  );
};
