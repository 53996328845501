/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { QueryJobsArgs } from "graphql/types";

import { JOB_FRAGMENT, JobFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const JOBS = gql`
  query ($page: Int, $perPage: Int) {
    jobs(page: $page, perPage: $perPage) {
      items {
        ...JobFragment
      }
      total
    }
  }
  ${JOB_FRAGMENT}
`;

export type JobsQuery = {
  jobs: PaginatedPayload<JobFragment>;
};

type QueryArgs = Pick<QueryJobsArgs, "page" | "perPage">;

export const useJobsQuery = (args: QueryArgs = {}) => {
  const { data, loading: isLoading } = useQuery<JobsQuery, QueryArgs>(JOBS, {
    variables: { ...args },
  });

  const jobs = data?.jobs.items?.filter(Boolean) ?? [];
  const total = data?.jobs.total ?? 0;

  return { jobs, total, isLoading };
};

export const useJobsLazyQuery = () => {
  const [getJobsQuery, { data, loading: isLoading, called }] = useLazyQuery<
    JobsQuery,
    QueryArgs
  >(JOBS, { fetchPolicy: "network-only" });

  const jobs = data?.jobs.items?.filter(Boolean) ?? [];
  const total = data?.jobs.total ?? 0;

  const resolveRef = useRef<(jobs?: JobFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(jobs);
      resolveRef.current = undefined;
    }
  }, [jobs, called, isLoading]);

  const getJobs = async (args?: QueryArgs) => {
    const variables = { ...args };

    getJobsQuery({ variables });

    return new Promise<JobFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getJobs, jobs, total, isLoading };
};
