/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { DocumentAttributes } from "graphql/types";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const CREATE_DOCUMENT = gql`
  mutation ($attributes: DocumentAttributes!) {
    createDocument(input: { attributes: $attributes }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface CreateDocumentMutation {
  createDocument: Payload | null;
}

export const useCreateDocumentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateDocumentMutation,
    { attributes: DocumentAttributes }
  >(CREATE_DOCUMENT);

  const createDocument = (
    attributes: DocumentAttributes,
    onSuccess?: (document: DocumentFragment) => void
  ) =>
    mutation({ variables: { attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createDocument?.errors ?? [];
        const document = data?.createDocument?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && document && onSuccess(document),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createDocument,
    isLoading: loading,
    document: data?.createDocument?.document,
    errors: data?.createDocument?.errors ?? [],
  };
};
