import React, { useEffect, useState } from "react";

import { debounce } from "utils";

interface Context {
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  is2xl: boolean;
}

export const TailwindContext = React.createContext<Context>({
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
  is2xl: false,
});

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props): JSX.Element => {
  const [breakPoints, setBreakpoints] = useState({
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    is2xl: false,
  });

  useEffect(() => {
    const resizeHandler = debounce(() => {
      const isSm = getBreakpoint(640);
      const isMd = getBreakpoint(768);
      const isLg = getBreakpoint(1024);
      const isXl = getBreakpoint(1280);
      const is2xl = getBreakpoint(1536);

      setBreakpoints({ isSm, isMd, isLg, isXl, is2xl });

      document.documentElement.style.setProperty(
        "--vh",
        window.innerHeight / 100 + "px"
      );
    }, 50);

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <TailwindContext.Provider value={{ ...breakPoints }}>
      {children}
    </TailwindContext.Provider>
  );
};

const getBreakpoint = (minWidth: number) =>
  window.matchMedia(`(min-width: ${minWidth}px)`).matches;
