import { useContext, useEffect, useState } from "react";
import { HiSearch, HiPlus } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import { useClientsLazyQuery } from "graphql/queries";
import { ClientFragment } from "graphql/fragments";
import { PopupContext } from "providers/PopupHandler";
import { Button } from "components/common/basic";

import BESearch from "./BESearch";

interface Props {
  onSelect: (clientID: string) => void;
  freelanceProfileId: string;
  label?: string;
  errorMessage?: string;
}

export default ({
  onSelect,
  freelanceProfileId,
  label,
  errorMessage,
}: Props): JSX.Element => {
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [client, setClient] = useState<ClientFragment>();
  const { clients, getClients, total, isLoading } = useClientsLazyQuery();
  const { openPopup } = useContext(PopupContext);

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getClients({
      freelanceProfileId,
      page: p,
      perPage,
      filter: { search: searchInput ?? keyword },
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  useEffect(() => {
    fetchAndSetPage(1);
  }, [freelanceProfileId]);

  const buttonLabel = (
    <>
      {client ? (
        client.name
      ) : (
        <Trans ns="common" i18nKey="searchClient.label">
          Search by client name
        </Trans>
      )}

      <HiSearch size={20} />
    </>
  );

  return (
    <BESearch<ClientFragment>
      id="client-search"
      itemKey="id"
      items={clients}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setClient(option);
        onSelect(option.id);
      }}
      buttonLabel={buttonLabel}
      label={label}
      emptyState={emptyState}
      isLoading={isLoading}
      errorMessage={errorMessage}
      footer={
        <div className={tw("py-2", "px-4")}>
          <Button
            id="client_search-add_new_client"
            onClick={() =>
              openPopup({
                id: "ClientCreation",
                props: {
                  clientOrFreelanceProfileId: freelanceProfileId,
                  onSuccess: (newClient) => {
                    onSelect(newClient.id);
                  },
                },
              })
            }
            variant="tertiary"
            fullWidth
            LeadingIcon={HiPlus}
          >
            <Trans ns="common" i18nKey="searchClient.button.addClient">
              Add a new client
            </Trans>
          </Button>
        </div>
      }
      fixedHeight="h-108"
    />
  );
};

const emptyState = (
  <div className={tw("text-center", "py-20", "px-4", "bg-white", "h-full")}>
    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchClient_empty.heading">
        You have not added any clients yet.
      </Trans>
    </p>

    <p className={tw("text-gray-900", "text-sm")}>
      <Trans ns="common" i18nKey="searchClient_empty.body">
        Please use the add button to find and add a client.
      </Trans>
    </p>
  </div>
);

const renderListItemLabel = (client: ClientFragment) => (
  <>
    <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
      {client.name}
    </p>

    <p className={tw("block", "text-gray-500", "text-xs", "h-4")}>
      {client.orgNumber && (
        <Trans
          ns="common"
          i18nKey="searchClient.listItem.orgNumber"
          defaults="Org. nr. {{ orgNumber }}"
          values={{ orgNumber: client.orgNumber }}
        />
      )}
    </p>
  </>
);
