/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { QueryDocumentRecipientsArgs } from "graphql/types";

import {
  DOCUMENT_RECIPIENT_FRAGMENT,
  DocumentRecipientFragment,
} from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

export const DOCUMENT_RECIPIENTS = gql`
  query (
    $filter: DocumentRecipientsFilter
    $page: Int
    $perPage: Int
    $sortBy: DocumentRecipientsSortByEnum
  ) {
    documentRecipients(
      filter: $filter
      page: $page
      perPage: $perPage
      sortBy: $sortBy
    ) {
      items {
        ...DocumentRecipientFragment
      }
      total
    }
  }
  ${DOCUMENT_RECIPIENT_FRAGMENT}
`;

export type DocumentRecipientsQuery = {
  documentRecipients: PaginatedPayload<DocumentRecipientFragment>;
};

type QueryArgs = Pick<
  QueryDocumentRecipientsArgs,
  "page" | "perPage" | "filter" | "sortBy"
>;

export const useDocumentRecipientsLazyQuery = () => {
  const [getDocumentRecipientsQuery, { data, loading: isLoading, called }] =
    useLazyQuery<DocumentRecipientsQuery, QueryArgs>(DOCUMENT_RECIPIENTS, {
      fetchPolicy: "network-only",
    });

  const documentRecipients =
    data?.documentRecipients.items?.filter(Boolean) ?? [];
  const total = data?.documentRecipients.total ?? 0;

  const resolveRef =
    useRef<(documentRecipients?: DocumentRecipientFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(documentRecipients);
      resolveRef.current = undefined;
    }
  }, [documentRecipients, called, isLoading]);

  const getDocumentRecipients = async (args?: QueryArgs) => {
    const variables = { ...args };

    getDocumentRecipientsQuery({ variables });

    return new Promise<DocumentRecipientFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getDocumentRecipients,
    documentRecipients,
    total,
    isLoading,
    called,
  };
};
