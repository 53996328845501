import { useState } from "react";
import { IconType } from "react-icons";
import { HiOutlineQuestionMarkCircle, HiX } from "react-icons/hi";

import tw from "tw-generated";

import { ActionID, Link } from "../basic";

interface Props {
  heading: string;
  notice: {
    heading: string;
    body: string;
    readMoreLink?: {
      id: ActionID;
      label: string;
      url: string;
    };
  };
  LeadingIcon?: IconType;
}

export default ({ heading, notice, LeadingIcon }: Props): JSX.Element => {
  const [isShown, setIsShown] = useState(true);

  return (
    <div className={tw("flex", "flex-col", "space-y-2", "max-w-lg", "w-full")}>
      <div className={tw("flex", "items-center")}>
        {LeadingIcon && <LeadingIcon size={24} className={tw("mr-2")} />}
        <h1 className={tw("text-2xl", "font-extrabold")}>{heading}</h1>

        <button onClick={() => setIsShown(!isShown)}>
          <HiOutlineQuestionMarkCircle
            size={24}
            className={tw("text-gray-400", "flex-shrink-0", "ml-4")}
          />
        </button>
      </div>

      {isShown && (
        <div
          className={tw(
            "rounded-md",
            "bg-blue-100",
            "p-4",
            "flex",
            "flex-col",
            "space-y-1"
          )}
        >
          <div className={tw("flex", "justify-between")}>
            <h2
              className={tw(
                "font-bold",
                "text-gray-900",
                "whitespace-pre-wrap"
              )}
            >
              {notice.heading}
            </h2>

            <button onClick={() => setIsShown(false)}>
              <HiX size={20} className={tw("text-gray-900", "flex-shrink-0")} />
            </button>
          </div>

          <p className={tw("text-gray-900", "whitespace-pre-wrap")}>
            {notice.body}
            {notice.readMoreLink && (
              <>
                {" "}
                <Link id={notice.readMoreLink.id} to={notice.readMoreLink.url}>
                  {notice.readMoreLink.label}
                </Link>
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
};
