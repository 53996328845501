import { gql } from "@apollo/client";

import { Session } from "graphql/types";

import { ACCOUNT_FRAGMENT, AccountFragment } from "./account";
import { PHONE_NUMBER_FRAGMENT, PhoneNumberFragment } from "./phoneNumber";
import { USER_FRAGMENT, UserFragment } from "./user";

export const SESSION_FRAGMENT = gql`
  fragment SessionFragment on Session {
    account {
      ...AccountFragment
    }
    accounts {
      id
      name
      email
      accountType
      hasFreelanceProfiles
      logo {
        ...AttachmentFragment
      }
    }
    administrator
    phoneNumber {
      ...PhoneNumberFragment
    }
    user {
      ...UserFragment
    }
    hasFreelanceProfiles
    hasIndividualFreelanceProfile
  }
  ${ACCOUNT_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
  ${USER_FRAGMENT}
`;

export interface SessionFragment
  extends Pick<
    Session,
    | "administrator"
    | "phoneNumber"
    | "user"
    | "hasFreelanceProfiles"
    | "hasIndividualFreelanceProfile"
  > {
  account: AccountFragment;
  accounts: Pick<
    AccountFragment,
    "id" | "name" | "email" | "accountType" | "hasFreelanceProfiles" | "logo"
  >[];
  phoneNumber: PhoneNumberFragment;
  user: UserFragment;
}
