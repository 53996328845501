import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { getIsValidLanguage, IntlContext } from "providers/i18n";
import { getLocalePrefix } from "utils";
import { LanguageEnum } from "graphql/types";

export default (): null => {
  const { currentLocale, setLocale } = useContext(IntlContext);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const locale = pathname.split("/")[1];

    const isValidLanguage = getIsValidLanguage(locale);
    if (isValidLanguage) {
      const validLocale = locale?.toUpperCase() as LanguageEnum;
      if (currentLocale !== validLocale) setLocale(validLocale);
    } else {
      navigate(
        getLocalePrefix(currentLocale) + pathname.split(locale)[1] + search
      );
    }
  }, [pathname]);

  return null;
};
