import { useEffect, useState } from "react";
import { Navigate } from "react-router";

import { FreelanceTypeEnum } from "graphql/types";
import { useFreelanceProfilesLazyQuery } from "graphql/queries";
import { FreelanceProfileFragment } from "graphql/fragments";
import { useLocalizedRoutePath } from "utils";
import { Page } from "components/common/wrappers";
import { useTranslation } from "translations";

import CreateOrganizationProfile from "./CreateOrgProfile";
import CreateIndividualProfile from "./CreateIndProfile";

interface Props {
  freelanceType: FreelanceTypeEnum;
  profile?: FreelanceProfileFragment;
}

export default ({ freelanceType, profile }: Props): JSX.Element => {
  const [redirectPath, setRedirectPath] = useState("");

  const { t } = useTranslation("account");
  const getRoutePath = useLocalizedRoutePath();

  const { getFreelanceProfiles, freelanceProfiles } =
    useFreelanceProfilesLazyQuery();

  useEffect(() => {
    getFreelanceProfiles();
  }, []);

  const onSuccess = (_profile: FreelanceProfileFragment) => {
    const successPath = getRoutePath("PAYOUT_PROFILES");

    setRedirectPath(successPath);
  };

  if (redirectPath) return <Navigate to={redirectPath} />;

  return (
    <Page
      title={t(
        "freelanceProfileWizard.freelanceProfileCreation.pageTitle",
        "Create a freelance profile"
      )}
      disablePadding
    >
      {freelanceType === FreelanceTypeEnum.Organization ? (
        <CreateOrganizationProfile
          onSuccessfulEdit={onSuccess}
          freelanceProfiles={freelanceProfiles}
          profile={profile}
        />
      ) : (
        <CreateIndividualProfile
          onSuccessfulEdit={onSuccess}
          profile={profile}
        />
      )}
    </Page>
  );
};
