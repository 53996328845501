import React, { useState } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";

import Input, { Props as InputProps } from "../basic/Input";

export default React.forwardRef(
  (
    props: Omit<
      InputProps,
      | "LeadingIcon"
      | "leadingAction"
      | "TrailingIcon"
      | "trailingAction"
      | "type"
      | "onChange"
    >,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const [isShowingPassword, setIsShowingPassword] = useState(false);

    return (
      <Input
        {...props}
        ref={ref}
        type={isShowingPassword ? "text" : "password"}
        TrailingIcon={isShowingPassword ? HiEyeOff : HiEye}
        trailingAction={() => setIsShowingPassword(!isShowingPassword)}
      />
    );
  }
);
