import { gql } from "@apollo/client";

import {
  Invoice,
  InvoiceLine,
  BenefitInvoiceLine,
  LineTypeEnum,
  DietInvoiceLine,
  ExpenseInvoiceLine,
  ExtraInvoiceLine,
  MileageInvoiceLine,
  WorkInvoiceLine,
} from "graphql/types";

import {
  OCCUPATION_CODE_FRAGMENT,
  OccupationCodeFragment,
} from "./occupationCode";

const baseLineThatNeedsOccupationCode = `
  id
  amount
  collapsed
  description
  lineType
  occupationCode {
    ...OccupationCodeFragment
  }
  quantity
  remainingQuantity
  unitPrice
  vat
  vatRate
  invoice {
    id
    status
  }
`;

export const BENEFIT_LINE_FRAGMENT = gql`
  fragment BenefitLineFragment on BenefitInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export const DIET_LINE_FRAGMENT = gql`
  fragment DietLineFragment on DietInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
    dietNonTaxableUnitPrice
    dietTaxableUnitPrice
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export const EXPENSE_LINE_FRAGMENT = gql`
  fragment ExpenseLineFragment on ExpenseInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;
export const EXTRA_LINE_FRAGMENT = gql`
  fragment ExtraLineFragment on ExtraInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export const MILEAGE_LINE_FRAGMENT = gql`
  fragment MileageLineFragment on MileageInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
    addressFrom
    addressTo
  }
  ${OCCUPATION_CODE_FRAGMENT}
`;

export const WORK_LINE_FRAGMENT = gql`
  fragment WorkLineFragment on WorkInvoiceLine {
    ${baseLineThatNeedsOccupationCode}
    workReport {
      type
      endDate
      startDate
      ... on TaskReport {
        hours
      }
      ... on TimeReport {
        startTime
        endTime
      }
    }
    dependentLines {
      ${baseLineThatNeedsOccupationCode}
      ...DietLineFragment
      ...MileageLineFragment
    }
  }
  ${OCCUPATION_CODE_FRAGMENT}
  ${DIET_LINE_FRAGMENT}
  ${MILEAGE_LINE_FRAGMENT}
`;

interface BaseLine
  extends Pick<
    InvoiceLine,
    | "id"
    | "amount"
    | "collapsed"
    | "description"
    | "lineType"
    | "quantity"
    | "remainingQuantity"
    | "unitPrice"
    | "vat"
    | "vatRate"
  > {
  invoice: Pick<Invoice, "id" | "status">;
  occupationCode: OccupationCodeFragment;
}

export interface BenefitLine
  extends BaseLine,
    Pick<BenefitInvoiceLine, "lineType"> {
  lineType: LineTypeEnum.Benefit;
}

export interface DietLine
  extends BaseLine,
    Pick<DietInvoiceLine, "dietNonTaxableUnitPrice" | "dietTaxableUnitPrice"> {
  lineType: LineTypeEnum.Diet;
}

export interface ExpenseLine
  extends BaseLine,
    Pick<ExpenseInvoiceLine, "lineType"> {
  lineType: LineTypeEnum.Expense;
}

export interface ExtraLine
  extends BaseLine,
    Pick<ExtraInvoiceLine, "lineType"> {
  lineType: LineTypeEnum.Extra;
}

export interface MileageLine
  extends BaseLine,
    Pick<MileageInvoiceLine, "addressFrom" | "addressTo"> {
  lineType: LineTypeEnum.Mileage;
}

export interface WorkLine
  extends BaseLine,
    Pick<WorkInvoiceLine, "workReport"> {
  lineType: LineTypeEnum.Work;
  dependentLines: DependentLine[];
}

export type DependentLine =
  | BenefitLine
  | DietLine
  | ExpenseLine
  | ExtraLine
  | MileageLine;

export type InvoiceLineFragment = WorkLine | DependentLine;
