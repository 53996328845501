import { lazy } from "react";

const CreditNote = lazy(() => import("./CreditNote"));
const CreditNoteCreation = lazy(() => import("./CreditNoteCreation"));
const Invoice = lazy(() => import("./Invoice"));
const Invoices = lazy(() => import("./Invoices"));
const InvoiceCreation = lazy(() => import("./InvoiceCreation"));
const InvoiceDuplication = lazy(() => import("./InvoiceDuplication"));
const InvoiceImport = lazy(() => import("./InvoiceImport"));
const InvoiceInitialization = lazy(() => import("./InvoiceInitialization"));
const PublicInvoice = lazy(() => import("./PublicInvoice"));
const Purchases = lazy(() => import("./Purchases"));

export {
  CreditNote,
  CreditNoteCreation,
  Invoice,
  Invoices,
  InvoiceCreation,
  InvoiceDuplication,
  InvoiceImport,
  InvoiceInitialization,
  PublicInvoice,
  Purchases,
};
