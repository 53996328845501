import { HiSearch } from "react-icons/hi";

import tw from "tw-generated";
import { Trans } from "translations";
import { useSuppliersLazyQuery } from "graphql/queries";
import { FreelanceProfileFragment } from "graphql/fragments";

import BESearch from "./BESearch";
import { useState } from "react";
import { FreelanceTypeEnum } from "graphql/types";

interface Props {
  onSelect: (clientID: string) => void;
  label?: string;
  errorMessage?: string;
}

export default ({ onSelect, label, errorMessage }: Props): JSX.Element => {
  const perPage = 5;
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [supplier, setSupplier] = useState<FreelanceProfileFragment>();
  const { suppliers, getSuppliers, total } = useSuppliersLazyQuery();

  const fetchAndSetPage = (p: number, searchInput?: string) =>
    getSuppliers({
      page: p,
      perPage,
      filter: { search: searchInput ?? keyword },
    }).then(() => {
      if (typeof searchInput === "string") setKeyword(searchInput);

      setPage(p);
    });

  const buttonLabel = (
    <>
      {supplier ? (
        supplier.freelanceType === FreelanceTypeEnum.Organization ? (
          `${supplier.organizationName} (${supplier.organizationNumber})`
        ) : (
          `${supplier.firstName} ${supplier.lastName}`
        )
      ) : (
        <Trans ns="common" i18nKey="searchClient.label">
          Search by client name
        </Trans>
      )}

      <HiSearch size={20} />
    </>
  );

  return (
    <BESearch<FreelanceProfileFragment>
      id="supplier-search"
      itemKey="id"
      items={suppliers}
      page={page}
      pages={Math.ceil(total / perPage)}
      setPage={fetchAndSetPage}
      renderListItemLabel={renderListItemLabel}
      onSearch={(searchInput) => fetchAndSetPage(1, searchInput)}
      onSelect={(option) => {
        setSupplier(option);
        onSelect(option.id);
      }}
      buttonLabel={buttonLabel}
      label={label}
      errorMessage={errorMessage}
    />
  );
};

const renderListItemLabel = (supplier: FreelanceProfileFragment) => (
  <>
    <p className={tw("block", "text-gray-900", "text-sm", "font-semibold")}>
      {supplier.freelanceType === FreelanceTypeEnum.Organization
        ? `${supplier.organizationName} (${supplier.organizationNumber})`
        : `${supplier.firstName} ${supplier.lastName}`}
    </p>

    <p className={tw("block", "text-gray-500", "text-xs", "h-4")}>
      {supplier.organizationNumber && (
        <Trans
          ns="common"
          i18nKey="searchClient.listItem.orgNumber"
          defaults="Org. nr. {{ orgNumber }}"
          values={{ orgNumber: supplier.organizationNumber }}
        />
      )}
    </p>
  </>
);
