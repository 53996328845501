import { gql } from "@apollo/client";

import { DocumentVersion } from "graphql/types";

import { DocumentFileFragment, DOCUMENT_FILE_FRAGMENT } from "./documentFile";
import {
  DocumentRecipientFragment,
  DOCUMENT_RECIPIENT_FRAGMENT,
} from "./documentRecipient";

export const DOCUMENT_VERSION_FRAGMENT = gql`
  fragment DocumentVersionFragment on DocumentVersion {
    id
    activatedOn
    activation
    bankId
    copyRecipients
    customFields {
      format
      helpText
      name
      required
    }
    designation
    details
    digitalSignature
    documentFiles {
      ...DocumentFileFragment
    }
    documentRecipients {
      ...DocumentRecipientFragment
    }
    expiration
    expirationMonths
    expiredOn
    hosts {
      designation
      email
      name
      phone
    }
    signableOn
    version
  }
  ${DOCUMENT_FILE_FRAGMENT}
  ${DOCUMENT_RECIPIENT_FRAGMENT}
`;

export interface DocumentVersionFragment
  extends Pick<
    DocumentVersion,
    | "id"
    | "activatedOn"
    | "activation"
    | "bankId"
    | "copyRecipients"
    | "customFields"
    | "designation"
    | "digitalSignature"
    | "expiration"
    | "expirationMonths"
    | "expiredOn"
    | "hosts"
    | "signableOn"
    | "version"
    | "details"
  > {
  documentFiles: DocumentFileFragment[];
  documentRecipients: DocumentRecipientFragment[];
}
