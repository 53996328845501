import { HiChevronRight } from "react-icons/hi";

import { tw } from "tw-generated";

interface Props {
  illustration: JSX.Element;
  label: string;
  subLabel?: string;
  onClick?: () => void;
  variant?: "outlined" | "filled";
}

export default ({
  illustration,
  label,
  subLabel,
  onClick,
  variant = "outlined",
}: Props): JSX.Element => {
  const buttonBase = tw(
    "w-full",
    "p-3",
    "flex",
    "justify-between",
    "items-center",
    "border-2",
    "rounded",
    "focus:outline-none",
    "focus:border-gray-900"
  );
  const outlined = tw(
    "bg-white",
    "border-primary",
    "hover:bg-silver",
    "hover:border-primary-hover"
  );
  const filled = tw(
    "bg-primary",
    "border-primary",
    "text-primary-contrast",
    "hover:bg-primary-hover",
    "hover:border-primary-hover"
  );
  const buttonStyles = tw(buttonBase, {
    [outlined]: variant === "outlined",
    [filled]: variant === "filled",
  });

  return (
    <button onClick={onClick} type="button" className={buttonStyles}>
      {illustration}

      <div
        className={tw(
          "ml-3",
          "mr-5",
          "flex",
          "flex-col",
          "space-y-1",
          "text-left"
        )}
      >
        <p className={tw("font-extrabold")}>{label}</p>
        <p className={tw("text-xs")}>{subLabel}</p>
      </div>

      <HiChevronRight size="24px" />
    </button>
  );
};
