/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  DOCUMENT_FRAGMENT,
  DocumentFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";

const INSTANTIATE_DOCUMENT_TEMPLATE = gql`
  mutation ($id: ID!) {
    instantiateDocumentTemplate(input: { id: $id }) {
      document {
        ...DocumentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  document?: DocumentFragment | null;
  errors: ErrorFragment[];
}

interface InstantiateDocumentTemplateMutation {
  instantiateDocumentTemplate: Payload | null;
}

export const useInstantiateDocumentTemplateMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    InstantiateDocumentTemplateMutation,
    { id: string }
  >(INSTANTIATE_DOCUMENT_TEMPLATE);

  const instantiateDocumentTemplate = (
    id: string,
    onSuccess?: (document: DocumentFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.instantiateDocumentTemplate?.errors ?? [];
        const document = data?.instantiateDocumentTemplate?.document;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && document && onSuccess(document),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    document: data?.instantiateDocumentTemplate?.document,
    instantiateDocumentTemplate,
    isLoading: loading,
    errors: data?.instantiateDocumentTemplate?.errors ?? [],
  };
};
