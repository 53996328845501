/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  ERROR_FRAGMENT,
  ErrorFragment,
  ENROLMENT_FRAGMENT,
  EnrolmentFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const SET_ENROLMENT = gql`
  mutation ($freelanceProfileId: ID!, $partnerAccountId: ID!) {
    setEnrollment(
      input: {
        freelanceProfileId: $freelanceProfileId
        partnerAccountId: $partnerAccountId
      }
    ) {
      enrollment {
        ...EnrolmentFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ENROLMENT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  enrollment?: EnrolmentFragment | null;
  errors: ErrorFragment[];
}

interface SetEnrolmentMutation {
  setEnrollment: Payload | null;
}

export const useSetEnrolmentMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.setEnrolment",
    "Payout profile was set successfully"
  );
  // Values for extraction
  // t("common:success.setEnrolment", "Payout profile was set successfully")

  const [mutation, { data, loading, called }] = useMutation<
    SetEnrolmentMutation,
    { freelanceProfileId: string; partnerAccountId: string }
  >(SET_ENROLMENT);

  const setEnrolment = (
    variables: { freelanceProfileId: string; partnerAccountId: string },
    onSuccess?: (response: EnrolmentFragment) => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.setEnrollment?.errors ?? [];
        const enrolment = data?.setEnrollment?.enrollment;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => enrolment && onSuccess && onSuccess(enrolment),
          onRetry: () => setEnrolment(variables, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    setEnrolment,
    isLoading: loading,
    called,
    enrolment: data?.setEnrollment?.enrollment,
    errors: data?.setEnrollment?.errors ?? [],
  };
};
