/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { CreatePersonPayload, CreatePersonInput } from "graphql/types";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import {
  ERROR_FRAGMENT,
  ErrorFragment,
  PERSON_FRAGMENT,
  PersonFragment,
} from "graphql/fragments";
import { PEOPLE, PeopleQuery } from "graphql/queries";

const CREATE_PERSON = gql`
  mutation ($input: CreatePersonInput!) {
    createPerson(input: $input) {
      person {
        ...PersonFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PERSON_FRAGMENT}
`;

interface Payload extends CreatePersonPayload {
  person?: PersonFragment;
  errors: ErrorFragment[];
}

export interface CreatePersonMutation {
  createPerson: Payload | null;
}

export const useCreatePersonMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreatePersonMutation,
    { input: CreatePersonInput }
  >(CREATE_PERSON);

  const createPerson = (
    input: CreatePersonInput,
    onSuccess?: (person?: PersonFragment) => void
  ) =>
    mutation({
      variables: { input },
      update(cache, { data: newPersonData }) {
        const newPersonNode = newPersonData?.createPerson?.person;
        if (!newPersonNode) return;

        cache.modify({
          fields: {
            people() {
              const existingPeople = cache.readQuery<PeopleQuery>({
                query: PEOPLE,
              });
              if (!existingPeople) return;

              const items = [
                ...(existingPeople.people.items ?? []),
                newPersonNode,
              ];

              cache.writeQuery<PeopleQuery>({
                query: PEOPLE,
                data: {
                  people: {
                    items,
                    total: existingPeople.people.total + 1,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.createPerson?.errors ?? [];
        const person = data?.createPerson?.person ?? undefined;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && onSuccess(person),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createPerson,
    person: data?.createPerson?.person,
    isLoading: loading,
    errors: data?.createPerson?.errors ?? [],
  };
};
