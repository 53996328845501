import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { HiChevronDown } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { getLocalePrefix } from "utils";
import { IntlContext } from "providers/i18n";
import { LanguageEnum } from "graphql/types";

interface Props {
  isDark?: boolean;
}

export default ({ isDark }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { currentLocale } = useContext(IntlContext);
  const { pathname } = useLocation();
  const { t } = useTranslation("common");

  const selectBase = tw(
    "appearance-none",
    "bg-none",
    "border",
    "py-2",
    "pl-3",
    "pr-10",
    "block",
    "w-full",
    "shadow-sm",
    "sm:text-sm",
    "rounded-md"
  );
  const lightSelect = tw(
    "focus:ring-gray-900",
    "focus:border-gray-900",
    "border-gray-300"
  );
  const darkSelect = tw(
    "bg-deepBlue-700",
    "text-white",
    "hover:bg-deepBlue-500",
    "focus:outline-none",
    "focus:ring-white",
    "focus:border-white"
  );
  const selectStyles = tw(selectBase, {
    [lightSelect]: !isDark,
    [darkSelect]: isDark,
  });

  const trailingIconWrapperStyles = tw(
    "pointer-events-none",
    "absolute",
    "inset-y-0",
    "right-0",
    "px-2",
    "flex",
    "items-center"
  );

  const iconStyles = tw("h-4", "w-4", { "text-white": isDark });

  return (
    <div className={tw("relative")}>
      <label htmlFor="languageSelect" className={tw("sr-only")}>
        <Trans ns="common" i18nKey="languageSelect.label">
          Language
        </Trans>
      </label>

      <select
        id="languageSelect"
        name="language"
        className={selectStyles}
        value={currentLocale}
        onChange={({ target: { value } }) => {
          const currentLocalePrefix = getLocalePrefix(currentLocale);
          const newLocalePrefix = getLocalePrefix(value as LanguageEnum);

          const newPath = pathname.replace(
            currentLocalePrefix,
            newLocalePrefix
          );

          navigate(newPath);
        }}
      >
        {Object.values(LanguageEnum).map((value) => {
          const label = t(`language.${value.toLowerCase()}`, value);

          return (
            <option value={value} key={value}>
              {label}
            </option>
          );
        })}
      </select>

      <div className={trailingIconWrapperStyles}>
        <HiChevronDown className={iconStyles} />
      </div>
    </div>
  );
};

// Values for extraction
// t("common:language.en", "English")
// t("common:language.nb", "Norwegian")
