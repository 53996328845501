import { HiOutlineCheckCircle, HiX } from "react-icons/hi";
import toast, { Toast } from "react-hot-toast";

import tw from "tw-generated";

interface Props {
  heading?: string | JSX.Element;
  body?: string | JSX.Element;
  toastProps?: Toast;
}

export default ({ heading, body, toastProps }: Props): JSX.Element => (
  <div
    className={tw(
      toastProps
        ? tw("w-96", {
            "animate-toastIn": toastProps.visible,
            "animate-toastOut": !toastProps.visible,
          })
        : "w-full",
      "rounded-lg",
      "shadow-lg",
      "bg-white",
      "p-4",
      "flex",
      "space-x-3"
    )}
  >
    <HiOutlineCheckCircle size={24} className={tw("text-success")} />

    <div className={tw("flex-1", "flex", "flex-col", "space-y-1")}>
      <p
        className={tw(
          "text-sm",
          "font-semibold",
          "text-gray-900",
          "whitespace-pre-wrap"
        )}
      >
        {heading}
      </p>

      <p className={tw("text-sm", "text-gray-500", "whitespace-pre-wrap")}>
        {body}
      </p>
    </div>

    {toastProps?.id && (
      <button onClick={() => toast.dismiss(toastProps.id)}>
        <HiX size={24} className={tw("text-gray-400")} />
      </button>
    )}
  </div>
);
