/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { DocumentGroupAttributes } from "graphql/types";
import {
  DOCUMENT_GROUP_FRAGMENT,
  DocumentGroupFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const UPDATE_DOCUMENT_GROUP = gql`
  mutation ($id: ID!, $attributes: DocumentGroupAttributes!) {
    updateDocumentGroup(input: { id: $id, attributes: $attributes }) {
      documentGroup {
        ...DocumentGroupFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_GROUP_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentGroup?: DocumentGroupFragment | null;
  errors: ErrorFragment[];
}

interface UpdateDocumentGroupMutation {
  updateDocumentGroup: Payload | null;
}

export const useUpdateDocumentGroupMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    UpdateDocumentGroupMutation,
    { id: string; attributes: DocumentGroupAttributes }
  >(UPDATE_DOCUMENT_GROUP);

  const updateDocumentGroup = (
    variables: { id: string; attributes: DocumentGroupAttributes },
    onSuccess?: (documentGroup: DocumentGroupFragment) => void
  ) =>
    mutation({ variables })
      .then(({ data, errors }) => {
        const dataErrors = data?.updateDocumentGroup?.errors ?? [];
        const documentGroup = data?.updateDocumentGroup?.documentGroup;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentGroup && onSuccess(documentGroup),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    updateDocumentGroup,
    isLoading: loading,
    documentGroup: data?.updateDocumentGroup?.documentGroup,
    errors: data?.updateDocumentGroup?.errors ?? [],
  };
};
