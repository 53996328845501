import individualIllustration from "assets/illustrations/buttons/deliveryMan.svg";
import organizationIllustration from "assets/illustrations/buttons/manWithTablet.svg";
import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { Page, PartialBackground } from "components/common/wrappers";
import { Card, CardBody, CardButton, Divider } from "components/common/basic";
import { FreelanceTypeEnum } from "graphql/types";

interface Props {
  setFreelanceType: (freelanceType: FreelanceTypeEnum) => void;
}

export default ({ setFreelanceType }: Props): JSX.Element => {
  const { t } = useTranslation("account");

  const setInd = () => setFreelanceType(FreelanceTypeEnum.Individual);
  const setOrg = () => setFreelanceType(FreelanceTypeEnum.Organization);

  return (
    <Page
      title={t(
        "freelanceProfileWizard.freelanceTypeSelection.pageTitle",
        "Select freelance profile type"
      )}
      disablePadding
    >
      <PartialBackground
        heading={
          <>
            <h1 className={tw("text-2xl", "font-extrabold")}>
              <Trans
                ns="account"
                i18nKey="freelanceProfileWizard.freelanceTypeSelection.header"
              >
                Set up your profile for sending invoices
              </Trans>
            </h1>

            <p className={tw("mt-2", "mb-5")}>
              <Trans
                ns="account"
                i18nKey="freelanceProfileWizard.freelanceTypeSelection.subHeader"
              >
                Select one of the two options below to get you started. You can
                always add the other option if needed!
              </Trans>
            </p>

            <div className={tw("mb-12")}>
              <Divider />
            </div>
          </>
        }
        content={
          <div className={tw("mx-auto", "max-w-screen-lg")}>
            <Card>
              <CardBody>
                <div
                  className={tw(
                    "w-full",
                    "flex",
                    "flex-wrap",
                    "space-y-4",
                    "md:flex-nowrap",
                    "md:space-y-0",
                    "md:space-x-8",
                    "md:px-16"
                  )}
                >
                  <CardButton
                    illustration={
                      <img
                        className={tw("h-20", "object-cover")}
                        src={individualIllustration}
                        alt="Man on bicycle"
                      />
                    }
                    onClick={setInd}
                    label={t(
                      "freelanceProfileWizard.freelanceTypeSelection.button.individual.label",
                      "Lønnsmottaker"
                    )}
                    subLabel={t(
                      "freelanceProfileWizard.freelanceTypeSelection.button.individual.subLabel",
                      "I want that manymore handles my taxes"
                    )}
                  />

                  <CardButton
                    illustration={
                      <img
                        className={tw("h-20", "object-cover")}
                        src={organizationIllustration}
                        alt="Man on tablet"
                      />
                    }
                    onClick={setOrg}
                    label={t(
                      "freelanceProfileWizard.freelanceTypeSelection.button.organization.label",
                      "ENK / AS"
                    )}
                    subLabel={t(
                      "freelanceProfileWizard.freelanceTypeSelection.button.organization.subLabel",
                      "I have an ENK/AS and will handle my taxes"
                    )}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        }
      />
    </Page>
  );
};
