import { useContext, useEffect } from "react";
import {
  Control,
  FieldError,
  FieldErrors,
  Merge,
  useFieldArray,
  UseFormRegister,
} from "react-hook-form";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";

import tw, { TArg } from "tw-generated";
import { Trans } from "translations";
import { Button } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { PersonFragment } from "graphql/fragments";
import { getNameFromSession } from "utils";

export interface FormWithPeopleInputTable {
  people?: PersonFragment[];
}

interface Props {
  control: Control<FormWithPeopleInputTable>;
  register: UseFormRegister<FormWithPeopleInputTable>;
  clearErrors: () => void;
  peopleErrors?: Merge<FieldError, FieldErrors<PersonFragment[]>>;
  minPeople?: number;
  defaultPeopleAmount?: number;
  showAddSelf?: boolean;
  defaultValues?: PersonFragment[];
}

export default ({
  control,
  register,
  clearErrors,
  peopleErrors = [],
  minPeople = 0,
  defaultPeopleAmount = minPeople,
  showAddSelf,
  defaultValues,
}: Props): JSX.Element => {
  const { session } = useContext(AuthContext);

  const { fields, append, remove } = useFieldArray({ control, name: "people" });

  const addPerson = (person = { name: "" } as PersonFragment) => {
    // Prevents issue occuring when adding a person after validating fields
    clearErrors?.();

    append(person);
  };

  const addDefaultPerson = () => addPerson();

  const addSelf = () => {
    if (!session) return;

    const name = getNameFromSession(session);

    addPerson({ name, email: session.user.email } as PersonFragment);
  };

  const removePerson = (index: number) => {
    remove(index);

    clearErrors?.();
  };

  useEffect(() => {
    if (defaultValues) defaultValues.forEach((person) => append(person));
    else if (defaultPeopleAmount > 0)
      [...Array(defaultPeopleAmount)].forEach(addPerson);
  }, []);

  const errors =
    (peopleErrors as FieldErrors<PersonFragment[]>).reduce(
      (acc: string[], errors) => {
        if (errors) {
          const errorMessages = Object.values(errors).reduce(
            (newMessages: string[], message) => {
              if (typeof message !== "string") return newMessages;

              if (typeof message === "string" && acc.includes(message))
                return newMessages;

              return [...newMessages, message];
            },
            []
          );

          return [...acc, ...errorMessages];
        }

        return acc;
      },
      []
    ) ?? [];

  const columnHeaderStyles = tw(
    "flex-grow",
    "flex-shrink-0",
    "py-3",
    "px-6",
    "text-xs",
    "uppercase",
    "text-deepBlue-700",
    "bg-deepBlue-50"
  );

  return (
    <>
      {fields.length > 0 && (
        <div className={tw("w-full", "overflow-x-auto")}>
          <div className={tw("w-full", "flex")}>
            <p className={tw("w-48", columnHeaderStyles)}>
              <Trans
                ns="common"
                i18nKey="popup.addPeople.form.columnHeader.name"
              >
                Name
              </Trans>
            </p>
            <p className={tw("w-48", columnHeaderStyles)}>
              <Trans
                ns="common"
                i18nKey="popup.addPeople.form.columnHeader.email"
              >
                Email
              </Trans>
            </p>
            <p className={tw("w-48", columnHeaderStyles)}>
              <Trans
                ns="common"
                i18nKey="popup.addPeople.form.columnHeader.phone"
              >
                Phone
              </Trans>
            </p>

            {fields.length > minPeople && (
              <span className={tw("w-14", "flex-shrink-0", "bg-deepBlue-50")} />
            )}
          </div>

          {fields.map(({ id, name, email, phone }, index) => {
            const cellBaseStyles = tw(
              "border-deepBlue-100",
              "border-r",
              "border-b"
            );
            const cellStyles = tw(cellBaseStyles, "flex-grow", "flex-shrink-0");

            const cellInputStyles = tw(
              "py-4",
              "px-6",
              "h-full",
              "w-full",
              "sm:text-sm",
              "outline-none",
              "ring-inset" as TArg, // TODO: Remove casting when "ring-inset" becomes available
              "focus-within:ring-2",
              "focus-within:ring-blue-500"
            );
            const errorStyles = tw(
              "ring-2",
              "ring-error",
              "bg-error-light",
              "text-error"
            );
            const personErrors = peopleErrors?.[index];

            return (
              <div className={tw("w-full", "flex")} key={id}>
                <div className={tw("border-l", "w-48", cellStyles)}>
                  <label
                    htmlFor={`people.${index}.name`}
                    className={tw("sr-only")}
                  >
                    <Trans
                      ns="common"
                      i18nKey="popup.addPeople.form.name.label"
                      defaults="#{{ number }} Name"
                      values={{ number: index + 1 }}
                    />
                  </label>
                  <input
                    className={tw(cellInputStyles, {
                      [errorStyles]: Boolean(personErrors?.name),
                    })}
                    id={`people.${index}.name`}
                    {...register(`people.${index}.name`)}
                    defaultValue={name ?? ""}
                  />
                </div>

                <div className={tw("w-48", cellStyles)}>
                  <label
                    htmlFor={`people.${index}.email`}
                    className={tw("sr-only")}
                  >
                    <Trans
                      ns="common"
                      i18nKey="popup.addPeople.form.email.label"
                      defaults="#{{ number }} Email"
                      values={{ number: index + 1 }}
                    />
                  </label>
                  <input
                    className={tw(cellInputStyles, {
                      [errorStyles]: Boolean(personErrors?.email),
                    })}
                    id={`people.${index}.email`}
                    {...register(`people.${index}.email`)}
                    defaultValue={email ?? undefined}
                  />
                </div>

                <div className={tw("w-48", cellStyles)}>
                  <label
                    htmlFor={`people.${index}.phone.label`}
                    className={tw("sr-only")}
                  >
                    <Trans
                      ns="common"
                      i18nKey="popup.addPeople.form.phone"
                      defaults="#{{ number }} Phone"
                      values={{ number: index + 1 }}
                    />
                  </label>
                  <input
                    className={tw(cellInputStyles, {
                      [errorStyles]: Boolean(personErrors?.phone),
                    })}
                    id={`people.${index}.phone`}
                    {...register(`people.${index}.phone`)}
                    defaultValue={phone ?? undefined}
                  />
                </div>

                {fields.length > minPeople && (
                  <div className={tw(cellBaseStyles, "p-2")}>
                    <button
                      className={tw(
                        "p-2",
                        "rounded-md",
                        "border",
                        "border-deepBlue-100"
                      )}
                      onClick={
                        fields.length > minPeople
                          ? () => removePerson(index)
                          : undefined
                      }
                    >
                      <HiOutlineTrash size={20} />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {errors.length > 0 && (
        <ul className={tw("list-inside", "list-disc")}>
          {errors.map((errorMessage) => (
            <li key={errorMessage} className={tw("text-error", "text-sm")}>
              {errorMessage}
            </li>
          ))}
        </ul>
      )}

      <div className={tw("flex", "space-x-4")}>
        <Button
          id="people_input_table-add_person"
          onClick={addDefaultPerson}
          variant="secondary"
          size="md"
          LeadingIcon={HiPlus}
        >
          <Trans ns="common" i18nKey="popup.addPeople.form.button.addPerson">
            Add person
          </Trans>
        </Button>

        {showAddSelf && (
          <Button
            id="people_input_table-add_me"
            onClick={addSelf}
            variant="secondary"
            size="md"
            LeadingIcon={HiPlus}
          >
            <Trans ns="common" i18nKey="popup.addPeople.form.button.addMe">
              Add me
            </Trans>
          </Button>
        )}
      </div>
    </>
  );
};
