/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRef, useEffect } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { QueryNodeArgs } from "graphql/types";
import { DOCUMENT_FRAGMENT, DocumentFragment } from "graphql/fragments";

export const DOCUMENT = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Document {
        ...DocumentFragment
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export type DocumentQuery = { node: DocumentFragment };

export const useDocumentQuery = (id: string) => {
  const { data, loading } = useQuery<DocumentQuery>(DOCUMENT, {
    variables: { id },
  });

  return { document: data?.node, isLoading: loading };
};

export const useDocumentLazyQuery = () => {
  const [getDocumentQuery, { data, loading, called }] = useLazyQuery<
    DocumentQuery,
    QueryNodeArgs
  >(DOCUMENT);

  const resolveRef = useRef<(document?: DocumentFragment) => void>();

  useEffect(() => {
    if (called && !loading && resolveRef.current) {
      resolveRef.current(data?.node);
      resolveRef.current = undefined;
    }
  }, [data, called, loading]);

  const getDocument = async (id: string) => {
    getDocumentQuery({ variables: { id } });

    return new Promise<DocumentFragment | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return {
    getDocument,
    document: data?.node,
    isLoading: loading,
  };
};
