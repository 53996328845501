import tw from "tw-generated";
import { Divider, Link } from "components/common/basic";

import {
  HeadingItem,
  MenuItem,
  MenuItemAction,
  MenuItemGroup,
  MenuItemLink,
} from "./types";
import MenuItemGroupComponent from "./MenuItemGroup";
import { useFloating } from "@floating-ui/react-dom";
import { useState } from "react";

interface Props {
  item: MenuItem;
  iconsOnly?: boolean;
  closeSelf?: () => void;
}
export default ({ item, iconsOnly, closeSelf }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: "right",
    strategy: "fixed",
  });

  if (item === "DIVIDER")
    return (
      <div className={tw("py-2")}>
        <Divider />
      </div>
    );

  if (Object.prototype.hasOwnProperty.call(item, "text")) {
    const headingItem = item as HeadingItem;

    if (iconsOnly) return <span className={tw("h-3", "block")} />;

    const { Icon, text } = headingItem;
    return (
      <div className={tw("px-4", "pb-0", "flex", "space-x-1", "items-center")}>
        <h5
          className={tw(
            "text-xs",
            "font-semibold",
            "uppercase",
            "text-deepBlue-300",
            "overflow-hidden",
            "overflow-ellipsis"
          )}
        >
          {text}
        </h5>

        {Icon && <Icon size={16} className={tw("text-deepBlue-300")} />}
      </div>
    );
  }

  const menuItem = item as MenuItemLink | MenuItemAction | MenuItemGroup;

  const listItemStyles = tw(
    "group",
    "flex",
    "items-center",
    "w-full",
    "space-x-3",
    "p-2",
    "rounded-md",
    {
      "bg-blue-100": menuItem.isActive,
      "text-blue-900": menuItem.isActive,
      "text-deepBlue-700": !menuItem.isActive,
      "hover:bg-silver": !menuItem.isDestructive && !menuItem.isActive,
      "hover:bg-error-light": menuItem.isDestructive,
      "hover:text-error": menuItem.isDestructive,
    }
  );
  const listItemIconStyles = tw({
    "text-deepBlue-500": !menuItem.isActive,
    "text-blue-900": menuItem.isActive,
    "group-hover:text-error": menuItem.isDestructive,
  });

  if (Object.prototype.hasOwnProperty.call(menuItem, "items"))
    return (
      <MenuItemGroupComponent
        item={menuItem as MenuItemGroup}
        iconsOnly={iconsOnly}
        closeSelf={closeSelf}
      />
    );

  const { Icon, iconClassName } = menuItem;
  const label = Icon ? (
    <>
      <span
        ref={isHover ? reference : undefined}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Icon size="24px" className={iconClassName ?? listItemIconStyles} />
      </span>

      {iconsOnly ? (
        <span
          className={tw(
            "p-2",
            "bg-gray-900",
            "rounded-md",
            "text-white",
            "text-xs",
            "font-semibold",
            "whitespace-nowrap",
            { hidden: !isHover }
          )}
          ref={floating}
          style={{ position: strategy, left: x ?? 0, top: y ?? 0 }}
        >
          <span className="arrow-left" style={{ left: -7 }} />
          {menuItem.label}
        </span>
      ) : (
        <span className={tw("truncate")}>{menuItem.label}</span>
      )}
    </>
  ) : (
    menuItem.label
  );

  if (Object.prototype.hasOwnProperty.call(menuItem, "onClick")) {
    const { onClick } = menuItem as MenuItemAction;

    return (
      <li>
        <button
          className={listItemStyles}
          onClick={() => {
            onClick();
            closeSelf?.();
          }}
        >
          {label}
        </button>
      </li>
    );
  }

  if (Object.prototype.hasOwnProperty.call(menuItem, "to")) {
    const linkProps = menuItem as MenuItemLink;

    return (
      <li>
        <Link
          {...linkProps}
          unstyled
          title={
            iconsOnly && typeof menuItem.label === "string"
              ? menuItem.label
              : undefined
          }
        >
          <div className={listItemStyles}>{label}</div>
        </Link>
      </li>
    );
  }

  return null;
};
