import { IconType } from "react-icons";
import tw from "tw-generated";

interface Props<T> {
  tabs: {
    label: string;
    value: T;
    LeadingIcon?: IconType;
  }[];
  activeTab: T;
  setActiveTab: (tab: T) => void;
  size?: "sm" | "md";
}

export default <T,>({
  tabs,
  activeTab,
  setActiveTab,
  size = "sm",
}: Props<T>): JSX.Element => {
  const tabContainerStyles = tw("flex", "space-x-8");

  const smStyles = tw("text-sm", "font-bold");
  const mdStyles = tw("text-base", "font-semibold");

  const tabBase = tw(
    "inline-flex",
    "items-center",
    "space-x-1",
    "text-left",
    "px-1",
    "border-b-2",
    "pt-1",
    "pt-2",
    "pb-1",
    "sm:pt-4",
    "sm:pb-3",
    {
      [smStyles]: size === "sm",
      [mdStyles]: size === "md",
    }
  );
  const inactiveTabStyles = tw(
    tabBase,
    "border-transparent",
    "text-gray-500",
    "hover:border-gray-300",
    "hover:text-gray-700"
  );
  const activeTabStyles = tw(tabBase, "border-blue-900", "text-blue-900");

  return (
    <div className={tabContainerStyles}>
      {tabs.map(({ label, value, LeadingIcon }) => {
        const isActive = activeTab === value;
        const setIsActive = () => setActiveTab(value);

        return (
          <button
            key={label}
            className={isActive ? activeTabStyles : inactiveTabStyles}
            type="button"
            onClick={setIsActive}
          >
            {LeadingIcon && <LeadingIcon size={size === "sm" ? 16 : 20} />}

            <span>{label}</span>
          </button>
        );
      })}
    </div>
  );
};
