/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { UserInvoiceImportAttributes } from "graphql/types";
import {
  USER_INVOICE_IMPORT_FRAGMENT,
  UserInvoiceImportFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const CREATE_USER_INVOICE_IMPORT = gql`
  mutation ($attributes: UserInvoiceImportAttributes!) {
    createUserInvoiceImport(input: { attributes: $attributes }) {
      userInvoiceImport {
        ...UserInvoiceImportFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${USER_INVOICE_IMPORT_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  userInvoiceImport?: UserInvoiceImportFragment | null;
  errors: ErrorFragment[];
}

interface CreateUserInvoiceImportMutation {
  createUserInvoiceImport: Payload | null;
}

export const useCreateUserInvoiceImportMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateUserInvoiceImportMutation,
    { attributes: UserInvoiceImportAttributes }
  >(CREATE_USER_INVOICE_IMPORT);

  const createUserInvoiceImport = (
    attributes: UserInvoiceImportAttributes,
    onSuccess?: (userInvoiceImport: UserInvoiceImportFragment) => void
  ) =>
    mutation({ variables: { attributes } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createUserInvoiceImport?.errors ?? [];
        const line = data?.createUserInvoiceImport?.userInvoiceImport;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => line && onSuccess && onSuccess(line),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createUserInvoiceImport,
    isLoading: loading,
    userInvoiceImport: data?.createUserInvoiceImport?.userInvoiceImport,
    errors: data?.createUserInvoiceImport?.errors ?? [],
  };
};
