/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { SendSupportMessageInput } from "graphql/types";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const SEND_SUPPORT_MESSAGE = gql`
  mutation ($input: SendSupportMessageInput!) {
    sendSupportMessage(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendSupportMessageMutation {
  sendSupportMessage: Payload | null;
}

export const useSendSupportMessageMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sendSupportMessage",
    "The message was sent successfully"
  );
  // Values for extraction
  // t("common:success.sendSupportMessage", "The message was sent successfully")

  const [mutation, { data, loading }] = useMutation<
    SendSupportMessageMutation,
    { input: SendSupportMessageInput }
  >(SEND_SUPPORT_MESSAGE);

  const sendSupportMessage = (
    input: SendSupportMessageInput,
    onSuccess?: () => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendSupportMessage?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => sendSupportMessage(input, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendSupportMessage,
    isLoading: loading,
    errors: data?.sendSupportMessage?.errors ?? [],
  };
};
