/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  DOCUMENT_VERSION_FRAGMENT,
  DocumentVersionFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { DocumentGroupQuery, DOCUMENT_GROUP } from "graphql/queries";

const TRANSFER_DOCUMENT_RECIPIENTS = gql`
  mutation (
    $id: ID!
    $excludeTerminated: Boolean
    $previousDocumentVersionId: ID
  ) {
    transferDocumentRecipients(
      input: {
        documentVersionId: $id
        excludeTerminated: $excludeTerminated
        previousDocumentVersionId: $previousDocumentVersionId
      }
    ) {
      documentVersion {
        ...DocumentVersionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${DOCUMENT_VERSION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  documentVersion?: DocumentVersionFragment | null;
  errors: ErrorFragment[];
}

interface TransferDocumentRecipientsMutation {
  transferDocumentRecipients: Payload | null;
}

export const useTransferDocumentRecipientsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    TransferDocumentRecipientsMutation,
    {
      id: string;
      excludeTerminated?: boolean;
      previousDocumentVersionId?: string;
    }
  >(TRANSFER_DOCUMENT_RECIPIENTS);

  const transferDocumentRecipients = (
    variables: {
      id: string;
      excludeTerminated?: boolean;
      previousDocumentVersionId?: string;
    },
    onSuccess?: (documentVersion: DocumentVersionFragment) => void
  ) =>
    mutation({
      variables,
      update(cache, { data: newDocumentRecipientsData }) {
        const newDocumentRecipients =
          newDocumentRecipientsData?.transferDocumentRecipients?.documentVersion
            ?.documentRecipients;
        if (!newDocumentRecipients) return;

        cache.modify({
          fields: {
            node() {
              const documentGroup = cache.readQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
              });
              if (!documentGroup) return;

              const documentVersions = documentGroup.node.documentVersions.map(
                (version) => {
                  if (version.id === variables.id) {
                    const documentRecipients = [
                      ...version.documentRecipients,
                      ...newDocumentRecipients,
                    ];

                    return { ...version, documentRecipients };
                  }

                  return version;
                }
              );

              cache.writeQuery<DocumentGroupQuery>({
                query: DOCUMENT_GROUP,
                data: {
                  node: {
                    ...documentGroup.node,
                    documentVersions,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.transferDocumentRecipients?.errors ?? [];
        const documentVersion =
          data?.transferDocumentRecipients?.documentVersion;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () =>
            onSuccess && documentVersion && onSuccess(documentVersion),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    transferDocumentRecipients,
    isLoading: loading,
    documentVersion: data?.transferDocumentRecipients?.documentVersion,
    errors: data?.transferDocumentRecipients?.errors ?? [],
  };
};
