import { useEffect, useState } from "react";
import tw from "tw-generated";
import { Button, Card, CardBody, CardHeader } from "../../basic";
import { StepLoader as StepLoaderComponent } from "./FakeStepLoader";
import PopupTemplate from "../PopupTemplate";
import { ErrorFragment } from "graphql/fragments";
import { ErrorCodeEnum } from "graphql/types";

interface Props {
  onClose: () => void;
  title: string;
  steps: string[];
  action: (
    onSuccess: () => void,
    onError: (errors: ErrorFragment[]) => void
  ) => void;
  isAutoComplete?: boolean;
}

export default ({ onClose, title, steps, action, isAutoComplete }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stepErrorIndex, setStepErrorIndex] = useState(-1);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    action(
      () => setIsLoading(false),
      (errors) => {
        if (!errors.length) return;

        const isValidationError = errors.some(
          (error) => error.code === ErrorCodeEnum.ValidationFailed
        );
        setStepErrorIndex(isValidationError ? 1 : 2);
      }
    );
  }, []);

  useEffect(() => {
    if (stepErrorIndex > -1) return;

    if (isCompleted && isAutoComplete) setTimeout(onClose, 1000);
  }, [stepErrorIndex, isCompleted]);

  return (
    <PopupTemplate onClose={onClose} isClosable={false}>
      <Card
        header={
          <CardHeader>
            <h3 className={tw("text-lg", "font-bold")}>{title}</h3>
          </CardHeader>
        }
        footer={
          isAutoComplete ? (
            <div className={tw("p-3")}></div>
          ) : (
            <div
              className={tw(
                "flex",
                "justify-end",
                "bg-deepBlue-50",
                "p-3",
                "rounded-b-lg"
              )}
            >
              <Button
                className={tw("self-end")}
                size="sm"
                disabled={!isCompleted}
                id="step_loader-close_popup"
                onClick={onClose}
              >
                Continue
              </Button>
            </div>
          )
        }
      >
        <CardBody>
          <StepLoaderComponent
            steps={steps}
            setIsCompleted={setIsCompleted}
            stepErrorIndex={stepErrorIndex}
            isLoading={isLoading}
          />
        </CardBody>
      </Card>
    </PopupTemplate>
  );
};
