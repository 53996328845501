export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailField: any;
};

export type Account = Node & {
  __typename?: 'Account';
  accountType: AccountTypeEnum;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  facebookLink?: Maybe<Scalars['String']>;
  hasFreelanceProfiles: Scalars['Boolean'];
  id: Scalars['ID'];
  identities?: Maybe<Array<Identity>>;
  industrySector?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  logo?: Maybe<Attachment>;
  name?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  twitterLink?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export enum AccountTypeEnum {
  Personal = 'PERSONAL',
  Team = 'TEAM'
}

export enum ActorEnum {
  Administrator = 'ADMINISTRATOR',
  Anonymous = 'ANONYMOUS',
  Client = 'CLIENT',
  Partner = 'PARTNER',
  System = 'SYSTEM',
  User = 'USER'
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type AddressAttributes = {
  city: Scalars['String'];
  country: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postalCode: Scalars['String'];
};

/** Autogenerated input type of ApproveEnrollment */
export type ApproveEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveEnrollment. */
export type ApproveEnrollmentPayload = {
  __typename?: 'ApproveEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<Error>;
};

/** Autogenerated input type of ApproveJobApplication */
export type ApproveJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ApproveJobApplication. */
export type ApproveJobApplicationPayload = {
  __typename?: 'ApproveJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

/** Autogenerated input type of ArchiveClient */
export type ArchiveClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ArchiveClient. */
export type ArchiveClientPayload = {
  __typename?: 'ArchiveClientPayload';
  client: Client;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ArchiveFreelanceProfile */
export type ArchiveFreelanceProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ArchiveFreelanceProfile. */
export type ArchiveFreelanceProfilePayload = {
  __typename?: 'ArchiveFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  freelanceProfile: FreelanceProfile;
};

export type Attachment = {
  __typename?: 'Attachment';
  contentType?: Maybe<Scalars['String']>;
  downloadPath: Scalars['String'];
  thumbPath?: Maybe<Scalars['String']>;
};

export type BankAccount = Node & {
  __typename?: 'BankAccount';
  accountNumber: Scalars['String'];
  createdAt: Scalars['String'];
  currency: CurrencyEnum;
  default: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankAccountAttributes = {
  accountNumber: Scalars['String'];
  currency: CurrencyEnum;
  default?: InputMaybe<Scalars['Boolean']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type Base64AttachmentAttributes = {
  data: Scalars['String'];
};

export type BenefitInvoiceLine = InvoiceLine & Node & {
  __typename?: 'BenefitInvoiceLine';
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

export type BulkInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']>;
  addressTo?: InputMaybe<Scalars['String']>;
  collapsed?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  lineType?: InputMaybe<LineTypeEnum>;
  occupationCodeId?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unitPrice?: InputMaybe<Scalars['Int']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Invoice receiver */
export type Client = Node & {
  __typename?: 'Client';
  address?: Maybe<Address>;
  archivedAt?: Maybe<Scalars['String']>;
  autoSendEhf?: Maybe<Scalars['Boolean']>;
  autoSendEmail?: Maybe<Scalars['Boolean']>;
  /** Unique identifier to be used in accounting systems */
  clientNumber: Scalars['Int'];
  clientType: ClientTypeEnum;
  email?: Maybe<Scalars['String']>;
  friendlyName?: Maybe<Scalars['String']>;
  helpArticle?: Maybe<HelpArticle>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nationalRegisterCheck: Scalars['Boolean'];
  orgNumber?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  referencePeople: Array<ReferencePerson>;
};

export type ClientPagination = {
  __typename?: 'ClientPagination';
  items: Array<Client>;
  total: Scalars['Int'];
};

export enum ClientTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type ClientsFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  clientType?: InputMaybe<ClientTypeEnum>;
  search?: InputMaybe<Scalars['String']>;
};

export enum ClientsSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  deleted?: Maybe<Scalars['Boolean']>;
  markedAsBankruptcy?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  orgNumber: Scalars['String'];
  underForcedLiquidation?: Maybe<Scalars['Boolean']>;
  underLiquidation?: Maybe<Scalars['Boolean']>;
};

export type CompanyPagination = {
  __typename?: 'CompanyPagination';
  items: Array<Company>;
  total: Scalars['Int'];
};

/** Autogenerated input type of CreateBankAccount */
export type CreateBankAccountInput = {
  attributes: BankAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  freelanceProfileId: Scalars['ID'];
};

/** Autogenerated return type of CreateBankAccount. */
export type CreateBankAccountPayload = {
  __typename?: 'CreateBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type CreateClientAttributes = {
  address: AddressAttributes;
  autoSendEhf?: InputMaybe<Scalars['Boolean']>;
  autoSendEmail?: InputMaybe<Scalars['Boolean']>;
  clientNumber?: InputMaybe<Scalars['Int']>;
  clientNumberSeries?: InputMaybe<RangeAttributes>;
  clientType: ClientTypeEnum;
  email?: InputMaybe<Scalars['String']>;
  freelanceProfileId: Scalars['ID'];
  friendlyName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nationalRegisterCheck?: InputMaybe<Scalars['Boolean']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of CreateClient */
export type CreateClientInput = {
  attributes: CreateClientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateClient. */
export type CreateClientPayload = {
  __typename?: 'CreateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateCreditNote */
export type CreateCreditNoteInput = {
  attributes: CreditNoteAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateCreditNote. */
export type CreateCreditNotePayload = {
  __typename?: 'CreateCreditNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  creditNote?: Maybe<CreditNote>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentFile */
export type CreateDocumentFileInput = {
  attributes: DocumentFileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId?: InputMaybe<Scalars['ID']>;
  documentVersionId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateDocumentFile. */
export type CreateDocumentFilePayload = {
  __typename?: 'CreateDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentFile?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentGroup */
export type CreateDocumentGroupInput = {
  attributes: DocumentGroupAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateDocumentGroup. */
export type CreateDocumentGroupPayload = {
  __typename?: 'CreateDocumentGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentGroup?: Maybe<DocumentGroup>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocument */
export type CreateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateDocument. */
export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentRecipients */
export type CreateDocumentRecipientsInput = {
  attributes: Array<DocumentRecipientAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentVersionId: Scalars['ID'];
};

/** Autogenerated return type of CreateDocumentRecipients. */
export type CreateDocumentRecipientsPayload = {
  __typename?: 'CreateDocumentRecipientsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentTemplate */
export type CreateDocumentTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
};

/** Autogenerated return type of CreateDocumentTemplate. */
export type CreateDocumentTemplatePayload = {
  __typename?: 'CreateDocumentTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentTemplate?: Maybe<DocumentTemplate>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreateDocumentVersion */
export type CreateDocumentVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentGroupId: Scalars['ID'];
};

/** Autogenerated return type of CreateDocumentVersion. */
export type CreateDocumentVersionPayload = {
  __typename?: 'CreateDocumentVersionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

export type CreateFreelanceProfileAttributes = {
  address: AddressAttributes;
  bankAccount: BankAccountAttributes;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  freelanceType: FreelanceTypeEnum;
  invoiceLogo?: InputMaybe<Base64AttachmentAttributes>;
  invoiceStartingNumber?: InputMaybe<Scalars['Int']>;
  journalFormat?: InputMaybe<JournalFormatEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  organizationNumber?: InputMaybe<Scalars['String']>;
  personalNumber?: InputMaybe<Scalars['String']>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of CreateFreelanceProfile */
export type CreateFreelanceProfileInput = {
  attributes: CreateFreelanceProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateFreelanceProfile. */
export type CreateFreelanceProfilePayload = {
  __typename?: 'CreateFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type CreateInvoiceAttributes = {
  bankAccountId?: InputMaybe<Scalars['ID']>;
  buyerReference?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  clientReferencePerson?: InputMaybe<ReferencePersonAttributes>;
  dueOn?: InputMaybe<Scalars['String']>;
  freelanceProfileId: Scalars['ID'];
  invoicedOn?: InputMaybe<Scalars['String']>;
  lines?: InputMaybe<Array<BulkInvoiceLineAttributes>>;
  orderReference?: InputMaybe<Scalars['String']>;
  senderReferencePerson?: InputMaybe<ReferencePersonAttributes>;
};

/** Autogenerated input type of CreateInvoice */
export type CreateInvoiceInput = {
  attributes: CreateInvoiceAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type CreateInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']>;
  addressTo?: InputMaybe<Scalars['String']>;
  collapsed?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  lineType: LineTypeEnum;
  occupationCodeId: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Float']>;
  unitPrice?: InputMaybe<Scalars['Int']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Autogenerated input type of CreateInvoiceLine */
export type CreateInvoiceLineInput = {
  attributes: CreateInvoiceLineAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
};

/** Autogenerated return type of CreateInvoiceLine. */
export type CreateInvoiceLinePayload = {
  __typename?: 'CreateInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoiceLine?: Maybe<InvoiceLine>;
};

/** Autogenerated return type of CreateInvoice. */
export type CreateInvoicePayload = {
  __typename?: 'CreateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type CreateJobApplicationAttributes = {
  applicationFormAnswers?: InputMaybe<Array<FormAnswerAttributes>>;
  jobId: Scalars['ID'];
};

/** Autogenerated input type of CreateJobApplication */
export type CreateJobApplicationInput = {
  attributes: CreateJobApplicationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateJobApplication. */
export type CreateJobApplicationPayload = {
  __typename?: 'CreateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type CreateJobAttributes = {
  address?: InputMaybe<AddressAttributes>;
  applicationDeadline?: InputMaybe<Scalars['String']>;
  applicationForm?: InputMaybe<FormAttributes>;
  applicationLimit?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<PartnerColorsAttributes>;
  compensation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  documentGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  enrollmentCallback?: InputMaybe<Scalars['Boolean']>;
  jobChecks?: InputMaybe<Array<JobCheckConfigAttributes>>;
  jobType?: InputMaybe<Scalars['String']>;
  languageRequirements?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  payout?: InputMaybe<Scalars['String']>;
  privacyLink?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
  termsLink?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Autogenerated input type of CreateJobCheck */
export type CreateJobCheckInput = {
  attributes: JobCheckAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateJobCheck. */
export type CreateJobCheckPayload = {
  __typename?: 'CreateJobCheckPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobCheck?: Maybe<JobCheck>;
};

/** Autogenerated input type of CreateJob */
export type CreateJobInput = {
  attributes: CreateJobAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateJob. */
export type CreateJobPayload = {
  __typename?: 'CreateJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

/** Autogenerated input type of CreateJournal */
export type CreateJournalInput = {
  attributes: JournalAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateJournal. */
export type CreateJournalPayload = {
  __typename?: 'CreateJournalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePeople */
export type CreatePeopleInput = {
  attributes: Array<DocumentRecipientAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePeople. */
export type CreatePeoplePayload = {
  __typename?: 'CreatePeoplePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of CreatePerson */
export type CreatePersonInput = {
  attributes: PersonAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePerson. */
export type CreatePersonPayload = {
  __typename?: 'CreatePersonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  person?: Maybe<Person>;
};

/** Autogenerated input type of CreateReceipt */
export type CreateReceiptInput = {
  attributes: ReceiptAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
};

/** Autogenerated return type of CreateReceipt. */
export type CreateReceiptPayload = {
  __typename?: 'CreateReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  receipt?: Maybe<Receipt>;
};

/** Autogenerated input type of CreateSession */
export type CreateSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailField'];
  password: Scalars['String'];
};

/** Autogenerated input type of CreateSessionOtp */
export type CreateSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailField'];
};

/** Autogenerated return type of CreateSessionOtp. */
export type CreateSessionOtpPayload = {
  __typename?: 'CreateSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated return type of CreateSession. */
export type CreateSessionPayload = {
  __typename?: 'CreateSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTeamAccount */
export type CreateTeamAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Autogenerated return type of CreateTeamAccount. */
export type CreateTeamAccountPayload = {
  __typename?: 'CreateTeamAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type CreateTransferAttributes = {
  amount: Scalars['Int'];
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fromFinanceAccount?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  toFinanceAccount?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateTransfer */
export type CreateTransferInput = {
  attributes: CreateTransferAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTransfer. */
export type CreateTransferPayload = {
  __typename?: 'CreateTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  transfer?: Maybe<Transfer>;
};

export type CreateUserAttributes = {
  email: Scalars['EmailField'];
  language?: InputMaybe<LanguageEnum>;
  marketingConsent?: InputMaybe<Scalars['Boolean']>;
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']>;
  occupation?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  attributes: CreateUserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  partnerAccountId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated input type of CreateUserInvoiceImport */
export type CreateUserInvoiceImportInput = {
  attributes: UserInvoiceImportAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUserInvoiceImport. */
export type CreateUserInvoiceImportPayload = {
  __typename?: 'CreateUserInvoiceImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  userInvoiceImport?: Maybe<UserInvoiceImport>;
};

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
};

export type CreditNote = Node & {
  __typename?: 'CreditNote';
  amount: Scalars['Int'];
  approvedAt?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  creditNoteNumber?: Maybe<Scalars['String']>;
  creditedOn?: Maybe<Scalars['String']>;
  currency: CurrencyEnum;
  ehfSentAt?: Maybe<Scalars['String']>;
  emailSentAt?: Maybe<Scalars['String']>;
  employerTaxAmount?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<Event>>;
  freelanceProfile: FreelanceProfile;
  grossSalary?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lines: Array<CreditNoteLine>;
  payoutAmount?: Maybe<Scalars['Int']>;
  pdfDocument?: Maybe<Attachment>;
  platformFeeAmount?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['String']>;
  salaryTaxAmount?: Maybe<Scalars['Int']>;
  status: InvoiceStatusEnum;
  vat: Scalars['Int'];
};

export type CreditNoteAttributes = {
  invoiceId: Scalars['ID'];
  lines: Array<CreditNoteLineAttributes>;
};

export type CreditNoteLine = Node & {
  __typename?: 'CreditNoteLine';
  amount: Scalars['Int'];
  creditNote: CreditNote;
  description?: Maybe<Scalars['String']>;
  employerTaxAmount?: Maybe<Scalars['Int']>;
  grossSalary?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceLine: InvoiceLine;
  payoutAmount?: Maybe<Scalars['Int']>;
  platformFeeAmount?: Maybe<Scalars['Int']>;
  quantity: Scalars['Float'];
  salaryTaxAmount?: Maybe<Scalars['Int']>;
  vat: Scalars['Int'];
};

export type CreditNoteLineAttributes = {
  description: Scalars['String'];
  invoiceLineId: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type CreditNotePagination = {
  __typename?: 'CreditNotePagination';
  items: Array<CreditNote>;
  total: Scalars['Int'];
};

export type CreditNotesFilter = {
  amountFrom?: InputMaybe<Scalars['Int']>;
  amountnTo?: InputMaybe<Scalars['Int']>;
  clientExclude?: InputMaybe<Array<Scalars['ID']>>;
  clientInclude?: InputMaybe<Array<Scalars['ID']>>;
  creditedOnFrom?: InputMaybe<Scalars['String']>;
  creditedOnTo?: InputMaybe<Scalars['String']>;
  freelanceProfileId?: InputMaybe<Scalars['ID']>;
  publishedAtFrom?: InputMaybe<Scalars['String']>;
  publishedAtTo?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  statusExclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  statusInclude?: InputMaybe<Array<InvoiceStatusEnum>>;
};

export enum CreditNotesSortByEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientNameAsc = 'CLIENT_NAME_ASC',
  ClientNameDesc = 'CLIENT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreditNoteNumberNumberAsc = 'CREDIT_NOTE_NUMBER_NUMBER_ASC',
  CreditNoteNumberNumberDesc = 'CREDIT_NOTE_NUMBER_NUMBER_DESC',
  PublishedAtAsc = 'PUBLISHED_AT_ASC',
  PublishedAtDesc = 'PUBLISHED_AT_DESC'
}

/** Autogenerated input type of CriiptoVerify */
export type CriiptoVerifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

/** Autogenerated return type of CriiptoVerify. */
export type CriiptoVerifyPayload = {
  __typename?: 'CriiptoVerifyPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export enum CurrencyEnum {
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD'
}

/** Autogenerated input type of DestroyBankAccount */
export type DestroyBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyBankAccount. */
export type DestroyBankAccountPayload = {
  __typename?: 'DestroyBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocumentFile */
export type DestroyDocumentFileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyDocumentFile. */
export type DestroyDocumentFilePayload = {
  __typename?: 'DestroyDocumentFilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentType?: Maybe<DocumentFile>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyDocument */
export type DestroyDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyDocument. */
export type DestroyDocumentPayload = {
  __typename?: 'DestroyDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyInvoice */
export type DestroyInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DestroyInvoiceLine */
export type DestroyInvoiceLineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyInvoiceLine. */
export type DestroyInvoiceLinePayload = {
  __typename?: 'DestroyInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated return type of DestroyInvoice. */
export type DestroyInvoicePayload = {
  __typename?: 'DestroyInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyReceipt */
export type DestroyReceiptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyReceipt. */
export type DestroyReceiptPayload = {
  __typename?: 'DestroyReceiptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyTransfer */
export type DestroyTransferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyTransfer. */
export type DestroyTransferPayload = {
  __typename?: 'DestroyTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of DestroyUserInvoiceImport */
export type DestroyUserInvoiceImportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DestroyUserInvoiceImport. */
export type DestroyUserInvoiceImportPayload = {
  __typename?: 'DestroyUserInvoiceImportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type DietInvoiceLine = InvoiceLine & Node & {
  __typename?: 'DietInvoiceLine';
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  dietNonTaxableUnitPrice: Scalars['Int'];
  dietTaxableUnitPrice: Scalars['Int'];
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

export type Document = Node & {
  __typename?: 'Document';
  activatedOn?: Maybe<Scalars['String']>;
  activation?: Maybe<DocumentActivationEnum>;
  category?: Maybe<Scalars['String']>;
  copyRecipients: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  documentFiles: Array<DocumentFile>;
  documentNumber: Scalars['String'];
  documentRecipient?: Maybe<DocumentRecipient>;
  expiration?: Maybe<DocumentExpirationEnum>;
  expirationMonths?: Maybe<Scalars['Int']>;
  expiredOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inADocumentGroup: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  owned: Scalars['Boolean'];
  refusedAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  signatures: Array<Signature>;
  signedAt?: Maybe<Scalars['String']>;
  signedDocument?: Maybe<Attachment>;
  status?: Maybe<DocumentStatusEnum>;
  submittedAt?: Maybe<Scalars['String']>;
  terminatedCopyRecipients: Array<Scalars['String']>;
  terminatedOn?: Maybe<Scalars['String']>;
  terminationReason?: Maybe<Scalars['String']>;
};

export enum DocumentActivationEnum {
  Date = 'DATE',
  Signature = 'SIGNATURE'
}

export type DocumentAttributes = {
  activatedOn?: InputMaybe<Scalars['String']>;
  activation?: InputMaybe<DocumentActivationEnum>;
  category?: InputMaybe<Scalars['String']>;
  copyRecipients?: InputMaybe<Array<Scalars['String']>>;
  expiration?: InputMaybe<DocumentExpirationEnum>;
  expirationMonths?: InputMaybe<Scalars['Int']>;
  expiredOn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  signatures?: InputMaybe<Array<SignatureAttributes>>;
};

export enum DocumentExpirationEnum {
  Activation = 'ACTIVATION',
  Date = 'DATE',
  None = 'NONE'
}

export type DocumentField = {
  __typename?: 'DocumentField';
  format: DocumentFieldFormatEnum;
  helpText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export type DocumentFieldAttributes = {
  format: DocumentFieldFormatEnum;
  helpText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export enum DocumentFieldFormatEnum {
  Date = 'DATE',
  Number = 'NUMBER',
  Text = 'TEXT'
}

export type DocumentFile = Node & {
  __typename?: 'DocumentFile';
  file: Attachment;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DocumentFileAttributes = {
  file: Base64AttachmentAttributes;
  name: Scalars['String'];
};

export type DocumentGroup = Node & {
  __typename?: 'DocumentGroup';
  description?: Maybe<Scalars['String']>;
  documentVersions: Array<DocumentVersion>;
  id: Scalars['ID'];
  name: Scalars['String'];
  sequenceNumber: Scalars['Int'];
  token: Scalars['String'];
};

export type DocumentGroupAttributes = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DocumentGroupPagination = {
  __typename?: 'DocumentGroupPagination';
  items: Array<DocumentGroup>;
  total: Scalars['Int'];
};

export type DocumentHost = {
  __typename?: 'DocumentHost';
  designation: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type DocumentHostAttributes = {
  designation: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type DocumentPagination = {
  __typename?: 'DocumentPagination';
  items: Array<Document>;
  total: Scalars['Int'];
};

export type DocumentRecipient = Node & {
  __typename?: 'DocumentRecipient';
  document: Document;
  documentVersion: DocumentVersion;
  email: Scalars['String'];
  id: Scalars['ID'];
  invitedBy: DocumentRecipientInvitedByEnum;
  name: Scalars['String'];
  notifiedAt?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sequenceNumber: Scalars['Int'];
  signature: Signature;
  signedAt?: Maybe<Scalars['String']>;
  status: DocumentRecipientStatusEnum;
};

export type DocumentRecipientAttributes = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export enum DocumentRecipientInvitedByEnum {
  Api = 'API',
  Csv = 'CSV',
  Link = 'LINK',
  Manual = 'MANUAL'
}

export type DocumentRecipientPagination = {
  __typename?: 'DocumentRecipientPagination';
  items: Array<DocumentRecipient>;
  total: Scalars['Int'];
};

export enum DocumentRecipientStatusEnum {
  NotSent = 'NOT_SENT',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Sent = 'SENT',
  Signed = 'SIGNED',
  Terminated = 'TERMINATED',
  Voided = 'VOIDED'
}

export type DocumentRecipientsFilter = {
  documentVersionId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DocumentRecipientStatusEnum>;
};

export enum DocumentRecipientsSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  InvitedByAsc = 'INVITED_BY_ASC',
  InvitedByDesc = 'INVITED_BY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NotifiedAtAsc = 'NOTIFIED_AT_ASC',
  NotifiedAtDesc = 'NOTIFIED_AT_DESC',
  SequenceNumberAsc = 'SEQUENCE_NUMBER_ASC',
  SequenceNumberDesc = 'SEQUENCE_NUMBER_DESC',
  SignedAtAsc = 'SIGNED_AT_ASC',
  SignedAtDesc = 'SIGNED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export enum DocumentStatusEnum {
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED',
  Voided = 'VOIDED'
}

export type DocumentTemplate = Node & {
  __typename?: 'DocumentTemplate';
  activatedOn?: Maybe<Scalars['String']>;
  activation?: Maybe<DocumentActivationEnum>;
  category?: Maybe<Scalars['String']>;
  copyRecipients: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  documentFiles: Array<DocumentFile>;
  expiration?: Maybe<DocumentExpirationEnum>;
  expirationMonths?: Maybe<Scalars['Int']>;
  expiredOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  signatureTemplates: Array<SignatureTemplate>;
};

export type DocumentTemplatePagination = {
  __typename?: 'DocumentTemplatePagination';
  items: Array<DocumentTemplate>;
  total: Scalars['Int'];
};

export type DocumentTemplatesFilter = {
  activatedOnFrom?: InputMaybe<Scalars['String']>;
  activatedOnTo?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  expiredOnFrom?: InputMaybe<Scalars['String']>;
  expiredOnTo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DocumentValue = {
  __typename?: 'DocumentValue';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentValueAttributes = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentVersion = Node & {
  __typename?: 'DocumentVersion';
  activatedOn?: Maybe<Scalars['String']>;
  activation: DocumentActivationEnum;
  bankId: Scalars['Boolean'];
  copyRecipients: Array<Scalars['String']>;
  customFields: Array<DocumentField>;
  designation: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  digitalSignature: Scalars['Boolean'];
  documentFiles: Array<DocumentFile>;
  documentRecipients: Array<DocumentRecipient>;
  expiration: DocumentExpirationEnum;
  expirationMonths?: Maybe<Scalars['Int']>;
  expiredOn?: Maybe<Scalars['String']>;
  export?: Maybe<Attachment>;
  hosts: Array<DocumentHost>;
  id: Scalars['ID'];
  signableOn: Scalars['String'];
  version: Scalars['Int'];
};

export type DocumentVersionAttributes = {
  activatedOn?: InputMaybe<Scalars['String']>;
  activation?: InputMaybe<DocumentActivationEnum>;
  bankId?: InputMaybe<Scalars['Boolean']>;
  copyRecipients?: InputMaybe<Array<Scalars['String']>>;
  customFields?: InputMaybe<Array<DocumentFieldAttributes>>;
  designation?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  digitalSignature?: InputMaybe<Scalars['Boolean']>;
  expiration?: InputMaybe<DocumentExpirationEnum>;
  expirationMonths?: InputMaybe<Scalars['Int']>;
  expiredOn?: InputMaybe<Scalars['String']>;
  hosts?: InputMaybe<Array<DocumentHostAttributes>>;
  signableOn?: InputMaybe<Scalars['String']>;
};

export type DocumentsFilter = {
  activatedOnFrom?: InputMaybe<Scalars['String']>;
  activatedOnTo?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  createdAtFrom?: InputMaybe<Scalars['String']>;
  createdAtTo?: InputMaybe<Scalars['String']>;
  expiredOnFrom?: InputMaybe<Scalars['String']>;
  expiredOnTo?: InputMaybe<Scalars['String']>;
  inADocumentGroup?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  signer?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DocumentStatusEnum>;
};

/** Autogenerated input type of DuplicateInvoice */
export type DuplicateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DuplicateInvoice. */
export type DuplicateInvoicePayload = {
  __typename?: 'DuplicateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type Enrollment = Node & {
  __typename?: 'Enrollment';
  account: Account;
  approved: Scalars['Boolean'];
  bankAccounts?: Maybe<BankAccount>;
  freelanceProfile?: Maybe<FreelanceProfile>;
  id: Scalars['ID'];
  locked: Scalars['Boolean'];
  partnerAccount: Account;
};

export type Error = {
  __typename?: 'Error';
  code: ErrorCodeEnum;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ErrorCodeEnum {
  AccountBlocked = 'ACCOUNT_BLOCKED',
  AccountNotFound = 'ACCOUNT_NOT_FOUND',
  AttemptsLimitExceeded = 'ATTEMPTS_LIMIT_EXCEEDED',
  AuthLevel_2Required = 'AUTH_LEVEL_2_REQUIRED',
  BankIdVerificationRequired = 'BANK_ID_VERIFICATION_REQUIRED',
  Blank = 'BLANK',
  BrregQueryFailed = 'BRREG_QUERY_FAILED',
  ClientEmailMissing = 'CLIENT_EMAIL_MISSING',
  ClientInsolvent = 'CLIENT_INSOLVENT',
  DeliveryFrequencyExceeded = 'DELIVERY_FREQUENCY_EXCEEDED',
  DocumentNotFound = 'DOCUMENT_NOT_FOUND',
  DocumentSigned = 'DOCUMENT_SIGNED',
  FreelanceProfileArchived = 'FREELANCE_PROFILE_ARCHIVED',
  Immutable = 'IMMUTABLE',
  Inclusion = 'INCLUSION',
  Invalid = 'INVALID',
  InvalidAction = 'INVALID_ACTION',
  InvalidInvoicedOn = 'INVALID_INVOICED_ON',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidToken = 'INVALID_TOKEN',
  InvalidTransition = 'INVALID_TRANSITION',
  InvoiceCalculationFailed = 'INVOICE_CALCULATION_FAILED',
  InvoiceNotCredited = 'INVOICE_NOT_CREDITED',
  InvoiceNotOrganization = 'INVOICE_NOT_ORGANIZATION',
  InvoiceNotPublished = 'INVOICE_NOT_PUBLISHED',
  InvoiceNotSubmitted = 'INVOICE_NOT_SUBMITTED',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  JobCheckPending = 'JOB_CHECK_PENDING',
  JobCheckSucceeded = 'JOB_CHECK_SUCCEEDED',
  LineMismatch = 'LINE_MISMATCH',
  OccupationCodeDisabled = 'OCCUPATION_CODE_DISABLED',
  OrganizationRoleCheckFailed = 'ORGANIZATION_ROLE_CHECK_FAILED',
  OtpExpired = 'OTP_EXPIRED',
  OtpExpiredAndResent = 'OTP_EXPIRED_AND_RESENT',
  PartnerCallbackError = 'PARTNER_CALLBACK_ERROR',
  PersonalNumberMismatch = 'PERSONAL_NUMBER_MISMATCH',
  SenderEqualsReceiver = 'SENDER_EQUALS_RECEIVER',
  SmsSendingFailed = 'SMS_SENDING_FAILED',
  Taken = 'TAKEN',
  TokenExpired = 'TOKEN_EXPIRED',
  TooBig = 'TOO_BIG',
  TooBigOrEqual = 'TOO_BIG_OR_EQUAL',
  TooShort = 'TOO_SHORT',
  TooSmall = 'TOO_SMALL',
  TooSmallOrEqual = 'TOO_SMALL_OR_EQUAL',
  TransitionFailed = 'TRANSITION_FAILED',
  UserAlreadyConfirmed = 'USER_ALREADY_CONFIRMED',
  UserBlocked = 'USER_BLOCKED',
  UserExists = 'USER_EXISTS',
  UserNotConfirmed = 'USER_NOT_CONFIRMED',
  UserNotFound = 'USER_NOT_FOUND',
  ValidationFailed = 'VALIDATION_FAILED',
  VerificationFailed = 'VERIFICATION_FAILED'
}

export type Event = {
  __typename?: 'Event';
  actor: ActorEnum;
  eventType: EventTypeEnum;
  occurredAt: Scalars['String'];
};

export enum EventTypeEnum {
  AccountMerged = 'ACCOUNT_MERGED',
  AttachmentDownloaded = 'ATTACHMENT_DOWNLOADED',
  AMeldingAccepted = 'A_MELDING_ACCEPTED',
  AMeldingApproved = 'A_MELDING_APPROVED',
  AMeldingPaid = 'A_MELDING_PAID',
  AMeldingRejected = 'A_MELDING_REJECTED',
  AMeldingResolved = 'A_MELDING_RESOLVED',
  AMeldingScheduled = 'A_MELDING_SCHEDULED',
  AMeldingSubmitted = 'A_MELDING_SUBMITTED',
  AMeldingVoided = 'A_MELDING_VOIDED',
  CreditNoteApproved = 'CREDIT_NOTE_APPROVED',
  CreditNoteApprovedForDeduction = 'CREDIT_NOTE_APPROVED_FOR_DEDUCTION',
  CreditNoteDeducted = 'CREDIT_NOTE_DEDUCTED',
  CreditNotePublished = 'CREDIT_NOTE_PUBLISHED',
  CreditNoteRejected = 'CREDIT_NOTE_REJECTED',
  CreditNoteRejectedForDeduction = 'CREDIT_NOTE_REJECTED_FOR_DEDUCTION',
  CreditNoteResolved = 'CREDIT_NOTE_RESOLVED',
  CreditNoteSentManually = 'CREDIT_NOTE_SENT_MANUALLY',
  CreditNoteSentViaEhf = 'CREDIT_NOTE_SENT_VIA_EHF',
  CreditNoteSentViaEmail = 'CREDIT_NOTE_SENT_VIA_EMAIL',
  CreditNoteSubmitted = 'CREDIT_NOTE_SUBMITTED',
  CreditNoteUnapprovedForDeduction = 'CREDIT_NOTE_UNAPPROVED_FOR_DEDUCTION',
  ExportedToFiken = 'EXPORTED_TO_FIKEN',
  IdentityCreated = 'IDENTITY_CREATED',
  IdentityUpdated = 'IDENTITY_UPDATED',
  InvoiceApproved = 'INVOICE_APPROVED',
  InvoiceApprovedForPayout = 'INVOICE_APPROVED_FOR_PAYOUT',
  InvoiceCredited = 'INVOICE_CREDITED',
  InvoiceHidden = 'INVOICE_HIDDEN',
  InvoiceNullified = 'INVOICE_NULLIFIED',
  InvoicePaidOut = 'INVOICE_PAID_OUT',
  InvoicePublished = 'INVOICE_PUBLISHED',
  InvoiceRejected = 'INVOICE_REJECTED',
  InvoiceRejectedForPayout = 'INVOICE_REJECTED_FOR_PAYOUT',
  InvoiceResolved = 'INVOICE_RESOLVED',
  InvoiceSentManually = 'INVOICE_SENT_MANUALLY',
  InvoiceSentViaEhf = 'INVOICE_SENT_VIA_EHF',
  InvoiceSentViaEmail = 'INVOICE_SENT_VIA_EMAIL',
  InvoiceSubmitted = 'INVOICE_SUBMITTED',
  InvoiceUnapproved = 'INVOICE_UNAPPROVED',
  InvoiceUnapprovedForPayout = 'INVOICE_UNAPPROVED_FOR_PAYOUT',
  InvoiceUnpublished = 'INVOICE_UNPUBLISHED',
  InvoiceVoided = 'INVOICE_VOIDED',
  JobCheckFailed = 'JOB_CHECK_FAILED',
  JobCheckInReview = 'JOB_CHECK_IN_REVIEW',
  JobCheckPending = 'JOB_CHECK_PENDING',
  JobCheckSucceeded = 'JOB_CHECK_SUCCEEDED',
  JournalCreated = 'JOURNAL_CREATED',
  MagicLinkCreated = 'MAGIC_LINK_CREATED',
  UserInvoiceImportCompleted = 'USER_INVOICE_IMPORT_COMPLETED',
  UserInvoiceImportFailed = 'USER_INVOICE_IMPORT_FAILED',
  UserInvoiceImportInProgress = 'USER_INVOICE_IMPORT_IN_PROGRESS',
  UserUpdated = 'USER_UPDATED'
}

export type ExpenseInvoiceLine = InvoiceLine & Node & {
  __typename?: 'ExpenseInvoiceLine';
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

/** Autogenerated input type of ExportDocumentVersion */
export type ExportDocumentVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ExportDocumentVersion. */
export type ExportDocumentVersionPayload = {
  __typename?: 'ExportDocumentVersionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

export type ExtraInvoiceLine = InvoiceLine & Node & {
  __typename?: 'ExtraInvoiceLine';
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

/** Autogenerated input type of FindOrCreateJobApplication */
export type FindOrCreateJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  jobId: Scalars['ID'];
};

/** Autogenerated return type of FindOrCreateJobApplication. */
export type FindOrCreateJobApplicationPayload = {
  __typename?: 'FindOrCreateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type Form = {
  __typename?: 'Form';
  pages: Array<FormPage>;
};

export type FormAnswer = {
  __typename?: 'FormAnswer';
  questionId: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type FormAnswerAttributes = {
  questionId: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type FormAttributes = {
  pages: Array<FormPageAttributes>;
};

export type FormCondition = {
  __typename?: 'FormCondition';
  matchValues: Array<Scalars['String']>;
  questionId: Scalars['String'];
  type: FormConditionTypeEnum;
};

export type FormConditionAttributes = {
  matchValues: Array<Scalars['String']>;
  questionId: Scalars['String'];
  type: FormConditionTypeEnum;
};

export enum FormConditionTypeEnum {
  HideIfMatch = 'HIDE_IF_MATCH',
  ShowIfMatch = 'SHOW_IF_MATCH'
}

export type FormOption = {
  __typename?: 'FormOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FormOptionAttributes = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FormPage = {
  __typename?: 'FormPage';
  description: Scalars['String'];
  pageNumber: Scalars['Int'];
  questions: Array<FormQuestion>;
  title: Scalars['String'];
};

export type FormPageAttributes = {
  description: Scalars['String'];
  pageNumber: Scalars['Int'];
  questions: Array<FormQuestionAttributes>;
  title: Scalars['String'];
};

export type FormQuestion = {
  __typename?: 'FormQuestion';
  condition?: Maybe<FormCondition>;
  id: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Array<FormOption>>;
  required: Scalars['Boolean'];
  type: FormQuestionTypeEnum;
  validationMessage?: Maybe<Scalars['String']>;
  validationRegexp?: Maybe<Scalars['String']>;
};

export type FormQuestionAttributes = {
  condition?: InputMaybe<FormConditionAttributes>;
  id: Scalars['String'];
  label: Scalars['String'];
  options?: InputMaybe<Array<FormOptionAttributes>>;
  required: Scalars['Boolean'];
  type: FormQuestionTypeEnum;
  validationMessage?: InputMaybe<Scalars['String']>;
  validationRegexp?: InputMaybe<Scalars['String']>;
};

export enum FormQuestionTypeEnum {
  Checkboxes = 'CHECKBOXES',
  Radios = 'RADIOS',
  Text = 'TEXT'
}

export type FreelanceProfile = Node & {
  __typename?: 'FreelanceProfile';
  address?: Maybe<Address>;
  agreementPdf?: Maybe<Attachment>;
  archivedAt?: Maybe<Scalars['String']>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  defaultBankAccount?: Maybe<BankAccount>;
  firstName?: Maybe<Scalars['String']>;
  freelanceType: FreelanceTypeEnum;
  id: Scalars['ID'];
  invoiceLogo?: Maybe<Attachment>;
  invoiceStartingNumber?: Maybe<Scalars['Int']>;
  jobChecks?: Maybe<Array<JobCheck>>;
  journalClosedMonth?: Maybe<Scalars['String']>;
  journalFormat?: Maybe<JournalFormatEnum>;
  journals?: Maybe<Array<Journal>>;
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationNumber?: Maybe<Scalars['String']>;
  referencePeople: Array<ReferencePerson>;
  taxCardStatus?: Maybe<Scalars['Boolean']>;
  verified: Scalars['Boolean'];
};

export type FreelanceProfileLookup = {
  __typename?: 'FreelanceProfileLookup';
  belongsToAccount: Scalars['Boolean'];
  company?: Maybe<Company>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type FreelanceProfilePagination = {
  __typename?: 'FreelanceProfilePagination';
  items: Array<FreelanceProfile>;
  total: Scalars['Int'];
};

export type FreelanceProfilesFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  freelanceType?: InputMaybe<FreelanceTypeEnum>;
  search?: InputMaybe<Scalars['String']>;
};

export enum FreelanceProfilesSortByEnum {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC'
}

export enum FreelanceTypeEnum {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

export type HelpArticle = {
  __typename?: 'HelpArticle';
  blockManualInvoicing: Scalars['Boolean'];
  enHelpArticleUrl?: Maybe<Scalars['String']>;
  enMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nbHelpArticleUrl?: Maybe<Scalars['String']>;
  nbMessage?: Maybe<Scalars['String']>;
  showAttachmentWarning: Scalars['Boolean'];
  supportEmail?: Maybe<Scalars['String']>;
};

export type Identity = {
  __typename?: 'Identity';
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  identifier: Scalars['String'];
  lastName: Scalars['String'];
  temporary: Scalars['Boolean'];
};

/** Autogenerated input type of InstantiateDocumentTemplate */
export type InstantiateDocumentTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of InstantiateDocumentTemplate. */
export type InstantiateDocumentTemplatePayload = {
  __typename?: 'InstantiateDocumentTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  amount: Scalars['Int'];
  approvedAt?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccount>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  bundled: Scalars['Boolean'];
  buyerReference?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  clientReferencePerson?: Maybe<ReferencePerson>;
  createdAt: Scalars['String'];
  creditNotes: Array<CreditNote>;
  currency?: Maybe<CurrencyEnum>;
  dueOn?: Maybe<Scalars['String']>;
  ehfSentAt?: Maybe<Scalars['String']>;
  emailSentAt?: Maybe<Scalars['String']>;
  employerTax?: Maybe<Scalars['Float']>;
  employerTaxAmount?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<Event>>;
  freelanceProfile: FreelanceProfile;
  grossSalary?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoicedOn?: Maybe<Scalars['String']>;
  kid?: Maybe<Scalars['String']>;
  lines: Array<InvoiceLine>;
  nullifiedAt?: Maybe<Scalars['String']>;
  orderReference?: Maybe<Scalars['String']>;
  payoutAmount?: Maybe<Scalars['Int']>;
  pdfDocument?: Maybe<Attachment>;
  platformFee?: Maybe<Scalars['Float']>;
  platformFeeAmount?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['String']>;
  receipts: Array<Receipt>;
  refundStatus: InvoiceRefundStatusEnum;
  rejectedAt?: Maybe<Scalars['String']>;
  remainingAmount: Scalars['Int'];
  remainingEmployerTaxAmount: Scalars['Int'];
  remainingGrossSalary: Scalars['Int'];
  remainingPayoutAmount: Scalars['Int'];
  remainingPlatformFeeAmount: Scalars['Int'];
  remainingSalaryTaxAmount: Scalars['Int'];
  remainingVat: Scalars['Int'];
  resolvedAt?: Maybe<Scalars['String']>;
  salaryTax?: Maybe<Scalars['Float']>;
  salaryTaxAmount?: Maybe<Scalars['Int']>;
  senderReferencePerson?: Maybe<ReferencePerson>;
  senderVatRegistered: Scalars['Boolean'];
  status: InvoiceStatusEnum;
  submittedAt?: Maybe<Scalars['String']>;
  thirdParty: Scalars['Boolean'];
  transfers?: Maybe<Array<Transfer>>;
  vat: Scalars['Int'];
};

export type InvoiceLine = {
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

export type InvoicePagination = {
  __typename?: 'InvoicePagination';
  items: Array<Invoice>;
  total: Scalars['Int'];
};

export enum InvoiceRefundStatusEnum {
  Full = 'FULL',
  No = 'NO',
  Partial = 'PARTIAL',
  Pending = 'PENDING'
}

export enum InvoiceStatusEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Nullified = 'NULLIFIED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED',
  Submitted = 'SUBMITTED'
}

export type InvoicesFilter = {
  amountFrom?: InputMaybe<Scalars['Int']>;
  amountnTo?: InputMaybe<Scalars['Int']>;
  clientExclude?: InputMaybe<Array<Scalars['ID']>>;
  clientInclude?: InputMaybe<Array<Scalars['ID']>>;
  freelanceProfileId?: InputMaybe<Scalars['ID']>;
  invoicedOnFrom?: InputMaybe<Scalars['String']>;
  invoicedOnTo?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  statusExclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  statusInclude?: InputMaybe<Array<InvoiceStatusEnum>>;
  submittedAtFrom?: InputMaybe<Scalars['String']>;
  submittedAtTo?: InputMaybe<Scalars['String']>;
};

export enum InvoicesSortByEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ClientNameAsc = 'CLIENT_NAME_ASC',
  ClientNameDesc = 'CLIENT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  InvoiceNumberAsc = 'INVOICE_NUMBER_ASC',
  InvoiceNumberDesc = 'INVOICE_NUMBER_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC'
}

export type Job = Node & {
  __typename?: 'Job';
  account: Account;
  address?: Maybe<Address>;
  applicationDeadline?: Maybe<Scalars['String']>;
  applicationForm?: Maybe<Form>;
  applicationLimit?: Maybe<Scalars['Int']>;
  approvedApplications?: Maybe<Scalars['Int']>;
  colors: PartnerColors;
  compensation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentGroups?: Maybe<Array<DocumentGroup>>;
  enrolledApplications?: Maybe<Scalars['Int']>;
  enrollment?: Maybe<Enrollment>;
  id: Scalars['ID'];
  jobChecks: Array<JobCheckConfig>;
  jobType?: Maybe<Scalars['String']>;
  languageRequirements?: Maybe<Scalars['String']>;
  logo?: Maybe<Attachment>;
  partnerLogo?: Maybe<Attachment>;
  partnerPrivacyLink?: Maybe<Scalars['String']>;
  partnerTermsLink?: Maybe<Scalars['String']>;
  payout?: Maybe<Scalars['String']>;
  privacyLink?: Maybe<Scalars['String']>;
  qualifiedApplications?: Maybe<Scalars['Int']>;
  rejectedApplications?: Maybe<Scalars['Int']>;
  submittedApplications?: Maybe<Scalars['Int']>;
  tagline?: Maybe<Scalars['String']>;
  termsLink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type JobApplication = Node & {
  __typename?: 'JobApplication';
  applicationFormAnswers?: Maybe<Array<FormAnswer>>;
  email: Scalars['String'];
  enrollment?: Maybe<Enrollment>;
  id: Scalars['ID'];
  job: Job;
  name?: Maybe<Scalars['String']>;
  status: JobApplicationStatusEnum;
  submittedJobChecks: Array<JobCheckTypeEnum>;
};

export type JobApplicationPagination = {
  __typename?: 'JobApplicationPagination';
  items: Array<JobApplication>;
  total: Scalars['Int'];
};

export enum JobApplicationStatusEnum {
  Approved = 'APPROVED',
  Enrolled = 'ENROLLED',
  Pending = 'PENDING',
  Qualified = 'QUALIFIED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type JobApplicationsFilter = {
  email?: InputMaybe<Scalars['String']>;
  jobId?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobApplicationStatusEnum>;
};

export type JobCheck = Node & {
  __typename?: 'JobCheck';
  content?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Attachment>>;
  freelanceProfile?: Maybe<FreelanceProfile>;
  id: Scalars['ID'];
  jobCheckType: JobCheckTypeEnum;
  status: JobCheckStatusEnum;
};

export type JobCheckAttributes = {
  content?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<Base64AttachmentAttributes>>;
  freelanceProfileId?: InputMaybe<Scalars['ID']>;
  jobCheckType: JobCheckTypeEnum;
};

export type JobCheckConfig = {
  __typename?: 'JobCheckConfig';
  condition?: Maybe<FormCondition>;
  id: JobCheckTypeEnum;
};

export type JobCheckConfigAttributes = {
  condition?: InputMaybe<FormConditionAttributes>;
  id: JobCheckTypeEnum;
};

export type JobCheckPagination = {
  __typename?: 'JobCheckPagination';
  items: Array<JobCheck>;
  total: Scalars['Int'];
};

export enum JobCheckStatusEnum {
  Failed = 'FAILED',
  InReview = 'IN_REVIEW',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum JobCheckTypeEnum {
  BankAccount = 'BANK_ACCOUNT',
  Credit = 'CREDIT',
  DriversLicense = 'DRIVERS_LICENSE',
  HealthPersonnelRegister = 'HEALTH_PERSONNEL_REGISTER',
  HseCard = 'HSE_CARD',
  Identity = 'IDENTITY',
  RightToWork = 'RIGHT_TO_WORK'
}

export type JobPagination = {
  __typename?: 'JobPagination';
  items: Array<Job>;
  total: Scalars['Int'];
};

export type Journal = Node & {
  __typename?: 'Journal';
  amountWithVat: Scalars['Int'];
  exportType: JournalTypeEnum;
  format: JournalFormatEnum;
  id: Scalars['ID'];
  month: Scalars['String'];
  sequenceNumber: Scalars['Int'];
};

export type JournalAttributes = {
  exportType: JournalTypeEnum;
  freelanceProfileId: Scalars['ID'];
  month: Scalars['String'];
};

export enum JournalFormatEnum {
  Tripletex = 'TRIPLETEX',
  Visma = 'VISMA'
}

export enum JournalTypeEnum {
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE'
}

export enum LanguageEnum {
  En = 'EN',
  Nb = 'NB'
}

export enum LineTypeEnum {
  Benefit = 'BENEFIT',
  Diet = 'DIET',
  Expense = 'EXPENSE',
  Extra = 'EXTRA',
  Mileage = 'MILEAGE',
  Work = 'WORK'
}

export type MileageInvoiceLine = InvoiceLine & Node & {
  __typename?: 'MileageInvoiceLine';
  addressFrom?: Maybe<Scalars['String']>;
  addressTo?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveEnrollment?: Maybe<ApproveEnrollmentPayload>;
  approveJobApplication?: Maybe<ApproveJobApplicationPayload>;
  archiveClient?: Maybe<ArchiveClientPayload>;
  archiveFreelanceProfile?: Maybe<ArchiveFreelanceProfilePayload>;
  createBankAccount?: Maybe<CreateBankAccountPayload>;
  createClient?: Maybe<CreateClientPayload>;
  createCreditNote?: Maybe<CreateCreditNotePayload>;
  createDocument?: Maybe<CreateDocumentPayload>;
  createDocumentFile?: Maybe<CreateDocumentFilePayload>;
  createDocumentGroup?: Maybe<CreateDocumentGroupPayload>;
  createDocumentRecipients?: Maybe<CreateDocumentRecipientsPayload>;
  createDocumentTemplate?: Maybe<CreateDocumentTemplatePayload>;
  createDocumentVersion?: Maybe<CreateDocumentVersionPayload>;
  createFreelanceProfile?: Maybe<CreateFreelanceProfilePayload>;
  createInvoice?: Maybe<CreateInvoicePayload>;
  createInvoiceLine?: Maybe<CreateInvoiceLinePayload>;
  createJob?: Maybe<CreateJobPayload>;
  createJobApplication?: Maybe<CreateJobApplicationPayload>;
  createJobCheck?: Maybe<CreateJobCheckPayload>;
  createJournal?: Maybe<CreateJournalPayload>;
  createPeople?: Maybe<CreatePeoplePayload>;
  createPerson?: Maybe<CreatePersonPayload>;
  createReceipt?: Maybe<CreateReceiptPayload>;
  createSession?: Maybe<CreateSessionPayload>;
  createSessionOtp?: Maybe<CreateSessionOtpPayload>;
  createTeamAccount?: Maybe<CreateTeamAccountPayload>;
  createTransfer?: Maybe<CreateTransferPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createUserInvoiceImport?: Maybe<CreateUserInvoiceImportPayload>;
  criiptoVerify?: Maybe<CriiptoVerifyPayload>;
  destroyBankAccount?: Maybe<DestroyBankAccountPayload>;
  destroyDocument?: Maybe<DestroyDocumentPayload>;
  destroyDocumentFile?: Maybe<DestroyDocumentFilePayload>;
  destroyInvoice?: Maybe<DestroyInvoicePayload>;
  destroyInvoiceLine?: Maybe<DestroyInvoiceLinePayload>;
  destroyReceipt?: Maybe<DestroyReceiptPayload>;
  destroyTransfer?: Maybe<DestroyTransferPayload>;
  destroyUserInvoiceImport?: Maybe<DestroyUserInvoiceImportPayload>;
  duplicateInvoice?: Maybe<DuplicateInvoicePayload>;
  exportDocumentVersion?: Maybe<ExportDocumentVersionPayload>;
  findOrCreateJobApplication?: Maybe<FindOrCreateJobApplicationPayload>;
  instantiateDocumentTemplate?: Maybe<InstantiateDocumentTemplatePayload>;
  notifyDocument?: Maybe<NotifyDocumentPayload>;
  notifyDocumentRecipientHosts?: Maybe<NotifyDocumentRecipientHostsPayload>;
  notifySignature?: Maybe<NotifySignaturePayload>;
  refuseDocument?: Maybe<RefuseDocumentPayload>;
  rejectJobApplication?: Maybe<RejectJobApplicationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resolveInvoice?: Maybe<ResolveInvoicePayload>;
  sendCreditNoteViaEhf?: Maybe<SendCreditNoteViaEhfPayload>;
  sendCreditNoteViaEmail?: Maybe<SendCreditNoteViaEmailPayload>;
  sendDocument?: Maybe<SendDocumentPayload>;
  sendDocumentVersion?: Maybe<SendDocumentVersionPayload>;
  sendInvoiceViaEhf?: Maybe<SendInvoiceViaEhfPayload>;
  sendInvoiceViaEmail?: Maybe<SendInvoiceViaEmailPayload>;
  sendPasswordResetInstructions?: Maybe<SendPasswordResetInstructionsPayload>;
  sendSmsConfirmation?: Maybe<SendSmsConfirmationPayload>;
  sendSupportMessage?: Maybe<SendSupportMessagePayload>;
  setDefaultBankAccount?: Maybe<SetDefaultBankAccountPayload>;
  setEnrollment?: Maybe<SetEnrollmentPayload>;
  signDocument?: Maybe<SignDocumentPayload>;
  submitDocument?: Maybe<SubmitDocumentPayload>;
  submitInvoice?: Maybe<SubmitInvoicePayload>;
  submitJobApplication?: Maybe<SubmitJobApplicationPayload>;
  switchAccount?: Maybe<SwitchAccountPayload>;
  terminateDocument?: Maybe<TerminateDocumentPayload>;
  transferDocumentRecipients?: Maybe<TransferDocumentRecipientsPayload>;
  unarchiveFreelanceProfile?: Maybe<UnarchiveFreelanceProfilePayload>;
  updateAccount?: Maybe<UpdateAccountPayload>;
  updateClient?: Maybe<UpdateClientPayload>;
  updateDocument?: Maybe<UpdateDocumentPayload>;
  updateDocumentGroup?: Maybe<UpdateDocumentGroupPayload>;
  updateDocumentRecipient?: Maybe<UpdateDocumentRecipientPayload>;
  updateDocumentVersion?: Maybe<UpdateDocumentVersionPayload>;
  updateFreelanceProfile?: Maybe<UpdateFreelanceProfilePayload>;
  updateInvoice?: Maybe<UpdateInvoicePayload>;
  updateInvoiceLine?: Maybe<UpdateInvoiceLinePayload>;
  updateJob?: Maybe<UpdateJobPayload>;
  updateJobApplication?: Maybe<UpdateJobApplicationPayload>;
  updateTransfer?: Maybe<UpdateTransferPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  uploadDocumentRecipients?: Maybe<UploadDocumentRecipientsPayload>;
  uploadPeople?: Maybe<UploadPeoplePayload>;
  useSessionOtp?: Maybe<UseSessionOtpPayload>;
  validatePasswordResetToken?: Maybe<ValidatePasswordResetTokenPayload>;
  verifyPhoneNumber?: Maybe<VerifyPhoneNumberPayload>;
  voidDocument?: Maybe<VoidDocumentPayload>;
  voidDocumentRecipient?: Maybe<VoidDocumentRecipientPayload>;
};


export type MutationApproveEnrollmentArgs = {
  input: ApproveEnrollmentInput;
};


export type MutationApproveJobApplicationArgs = {
  input: ApproveJobApplicationInput;
};


export type MutationArchiveClientArgs = {
  input: ArchiveClientInput;
};


export type MutationArchiveFreelanceProfileArgs = {
  input: ArchiveFreelanceProfileInput;
};


export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateCreditNoteArgs = {
  input: CreateCreditNoteInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentFileArgs = {
  input: CreateDocumentFileInput;
};


export type MutationCreateDocumentGroupArgs = {
  input: CreateDocumentGroupInput;
};


export type MutationCreateDocumentRecipientsArgs = {
  input: CreateDocumentRecipientsInput;
};


export type MutationCreateDocumentTemplateArgs = {
  input: CreateDocumentTemplateInput;
};


export type MutationCreateDocumentVersionArgs = {
  input: CreateDocumentVersionInput;
};


export type MutationCreateFreelanceProfileArgs = {
  input: CreateFreelanceProfileInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateInvoiceLineArgs = {
  input: CreateInvoiceLineInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobApplicationArgs = {
  input: CreateJobApplicationInput;
};


export type MutationCreateJobCheckArgs = {
  input: CreateJobCheckInput;
};


export type MutationCreateJournalArgs = {
  input: CreateJournalInput;
};


export type MutationCreatePeopleArgs = {
  input: CreatePeopleInput;
};


export type MutationCreatePersonArgs = {
  input: CreatePersonInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateSessionOtpArgs = {
  input: CreateSessionOtpInput;
};


export type MutationCreateTeamAccountArgs = {
  input: CreateTeamAccountInput;
};


export type MutationCreateTransferArgs = {
  input: CreateTransferInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserInvoiceImportArgs = {
  input: CreateUserInvoiceImportInput;
};


export type MutationCriiptoVerifyArgs = {
  input: CriiptoVerifyInput;
};


export type MutationDestroyBankAccountArgs = {
  input: DestroyBankAccountInput;
};


export type MutationDestroyDocumentArgs = {
  input: DestroyDocumentInput;
};


export type MutationDestroyDocumentFileArgs = {
  input: DestroyDocumentFileInput;
};


export type MutationDestroyInvoiceArgs = {
  input: DestroyInvoiceInput;
};


export type MutationDestroyInvoiceLineArgs = {
  input: DestroyInvoiceLineInput;
};


export type MutationDestroyReceiptArgs = {
  input: DestroyReceiptInput;
};


export type MutationDestroyTransferArgs = {
  input: DestroyTransferInput;
};


export type MutationDestroyUserInvoiceImportArgs = {
  input: DestroyUserInvoiceImportInput;
};


export type MutationDuplicateInvoiceArgs = {
  input: DuplicateInvoiceInput;
};


export type MutationExportDocumentVersionArgs = {
  input: ExportDocumentVersionInput;
};


export type MutationFindOrCreateJobApplicationArgs = {
  input: FindOrCreateJobApplicationInput;
};


export type MutationInstantiateDocumentTemplateArgs = {
  input: InstantiateDocumentTemplateInput;
};


export type MutationNotifyDocumentArgs = {
  input: NotifyDocumentInput;
};


export type MutationNotifyDocumentRecipientHostsArgs = {
  input: NotifyDocumentRecipientHostsInput;
};


export type MutationNotifySignatureArgs = {
  input: NotifySignatureInput;
};


export type MutationRefuseDocumentArgs = {
  input: RefuseDocumentInput;
};


export type MutationRejectJobApplicationArgs = {
  input: RejectJobApplicationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResolveInvoiceArgs = {
  input: ResolveInvoiceInput;
};


export type MutationSendCreditNoteViaEhfArgs = {
  input: SendCreditNoteViaEhfInput;
};


export type MutationSendCreditNoteViaEmailArgs = {
  input: SendCreditNoteViaEmailInput;
};


export type MutationSendDocumentArgs = {
  input: SendDocumentInput;
};


export type MutationSendDocumentVersionArgs = {
  input: SendDocumentVersionInput;
};


export type MutationSendInvoiceViaEhfArgs = {
  input: SendInvoiceViaEhfInput;
};


export type MutationSendInvoiceViaEmailArgs = {
  input: SendInvoiceViaEmailInput;
};


export type MutationSendPasswordResetInstructionsArgs = {
  input: SendPasswordResetInstructionsInput;
};


export type MutationSendSmsConfirmationArgs = {
  input: SendSmsConfirmationInput;
};


export type MutationSendSupportMessageArgs = {
  input: SendSupportMessageInput;
};


export type MutationSetDefaultBankAccountArgs = {
  input: SetDefaultBankAccountInput;
};


export type MutationSetEnrollmentArgs = {
  input: SetEnrollmentInput;
};


export type MutationSignDocumentArgs = {
  input: SignDocumentInput;
};


export type MutationSubmitDocumentArgs = {
  input: SubmitDocumentInput;
};


export type MutationSubmitInvoiceArgs = {
  input: SubmitInvoiceInput;
};


export type MutationSubmitJobApplicationArgs = {
  input: SubmitJobApplicationInput;
};


export type MutationSwitchAccountArgs = {
  input: SwitchAccountInput;
};


export type MutationTerminateDocumentArgs = {
  input: TerminateDocumentInput;
};


export type MutationTransferDocumentRecipientsArgs = {
  input: TransferDocumentRecipientsInput;
};


export type MutationUnarchiveFreelanceProfileArgs = {
  input: UnarchiveFreelanceProfileInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentGroupArgs = {
  input: UpdateDocumentGroupInput;
};


export type MutationUpdateDocumentRecipientArgs = {
  input: UpdateDocumentRecipientInput;
};


export type MutationUpdateDocumentVersionArgs = {
  input: UpdateDocumentVersionInput;
};


export type MutationUpdateFreelanceProfileArgs = {
  input: UpdateFreelanceProfileInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceLineArgs = {
  input: UpdateInvoiceLineInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateJobApplicationArgs = {
  input: UpdateJobApplicationInput;
};


export type MutationUpdateTransferArgs = {
  input: UpdateTransferInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadDocumentRecipientsArgs = {
  input: UploadDocumentRecipientsInput;
};


export type MutationUploadPeopleArgs = {
  input: UploadPeopleInput;
};


export type MutationUseSessionOtpArgs = {
  input: UseSessionOtpInput;
};


export type MutationValidatePasswordResetTokenArgs = {
  input: ValidatePasswordResetTokenInput;
};


export type MutationVerifyPhoneNumberArgs = {
  input: VerifyPhoneNumberInput;
};


export type MutationVoidDocumentArgs = {
  input: VoidDocumentInput;
};


export type MutationVoidDocumentRecipientArgs = {
  input: VoidDocumentRecipientInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

/** Autogenerated input type of NotifyDocument */
export type NotifyDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of NotifyDocument. */
export type NotifyDocumentPayload = {
  __typename?: 'NotifyDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of NotifyDocumentRecipientHosts */
export type NotifyDocumentRecipientHostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of NotifyDocumentRecipientHosts. */
export type NotifyDocumentRecipientHostsPayload = {
  __typename?: 'NotifyDocumentRecipientHostsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentRecipient?: Maybe<DocumentRecipient>;
  errors: Array<Error>;
};

/** Autogenerated input type of NotifySignature */
export type NotifySignatureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of NotifySignature. */
export type NotifySignaturePayload = {
  __typename?: 'NotifySignaturePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  signature?: Maybe<Signature>;
};

export type OccupationCode = Node & {
  __typename?: 'OccupationCode';
  code: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  parentCode?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  tagList?: Maybe<Scalars['String']>;
};

export type OccupationCodePagination = {
  __typename?: 'OccupationCodePagination';
  items: Array<OccupationCode>;
  total: Scalars['Int'];
};

export type PartnerColors = {
  __typename?: 'PartnerColors';
  contrast?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  hover?: Maybe<Scalars['String']>;
};

export type PartnerColorsAttributes = {
  contrast?: InputMaybe<Scalars['String']>;
  default?: InputMaybe<Scalars['String']>;
  hover?: InputMaybe<Scalars['String']>;
};

export type PeopleFilter = {
  documentGroupId?: InputMaybe<Scalars['ID']>;
  jobCheckType?: InputMaybe<JobCheckTypeEnum>;
  jobId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum PeopleSortByEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC'
}

export type Person = Node & {
  __typename?: 'Person';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PersonAttributes = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type PersonPagination = {
  __typename?: 'PersonPagination';
  items: Array<Person>;
  total: Scalars['Int'];
};

export type PhoneNumber = Node & {
  __typename?: 'PhoneNumber';
  countryCode: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  number: Scalars['String'];
  tokenSentAt?: Maybe<Scalars['String']>;
  tokenVerifiedAt?: Maybe<Scalars['String']>;
};

export type PhoneNumberPagination = {
  __typename?: 'PhoneNumberPagination';
  items: Array<PhoneNumber>;
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  accountDocuments: DocumentPagination;
  checkSlug: Scalars['Boolean'];
  clients: ClientPagination;
  companies: CompanyPagination;
  company?: Maybe<Company>;
  creditNotes: CreditNotePagination;
  documentGroups: DocumentGroupPagination;
  documentRecipients: DocumentRecipientPagination;
  documentTemplates: DocumentTemplatePagination;
  documents: DocumentPagination;
  freelanceProfileLookup: FreelanceProfileLookup;
  freelanceProfiles: FreelanceProfilePagination;
  invoices: InvoicePagination;
  jobApplications: JobApplicationPagination;
  jobChecks: JobCheckPagination;
  jobs: JobPagination;
  lastUsedPhoneNumber?: Maybe<PhoneNumber>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  occupationCodes: OccupationCodePagination;
  partnerJobApplications: JobApplicationPagination;
  partnerJobs: JobPagination;
  people: PersonPagination;
  phoneNumbers: PhoneNumberPagination;
  publicDocument?: Maybe<Document>;
  publicSignature?: Maybe<Signature>;
  purchases: InvoicePagination;
  refunds: CreditNotePagination;
  salaryReports: SalaryReportPagination;
  salarySlips: SalarySlipPagination;
  session?: Maybe<Session>;
  suppliers: FreelanceProfilePagination;
  tasks: Array<Task>;
  userDocuments: DocumentPagination;
  userInvoiceImports: UserInvoiceImportPagination;
};


export type QueryAccountDocumentsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryCheckSlugArgs = {
  slug: Scalars['String'];
};


export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  freelanceProfileId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ClientsSortByEnum>;
};


export type QueryCompaniesArgs = {
  name: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  orgNumber?: InputMaybe<Scalars['String']>;
};


export type QueryCreditNotesArgs = {
  filter?: InputMaybe<CreditNotesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CreditNotesSortByEnum>;
};


export type QueryDocumentGroupsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryDocumentRecipientsArgs = {
  filter?: InputMaybe<DocumentRecipientsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DocumentRecipientsSortByEnum>;
};


export type QueryDocumentTemplatesArgs = {
  filter?: InputMaybe<DocumentTemplatesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryDocumentsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryFreelanceProfileLookupArgs = {
  jobApplicationId: Scalars['ID'];
  organizationNumber: Scalars['String'];
};


export type QueryFreelanceProfilesArgs = {
  filter?: InputMaybe<FreelanceProfilesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<FreelanceProfilesSortByEnum>;
};


export type QueryInvoicesArgs = {
  filter?: InputMaybe<InvoicesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<InvoicesSortByEnum>;
};


export type QueryJobApplicationsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryJobChecksArgs = {
  jobCheckType?: InputMaybe<JobCheckTypeEnum>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryJobsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryOccupationCodesArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnerJobApplicationsArgs = {
  filter?: InputMaybe<JobApplicationsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryPartnerJobsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryPeopleArgs = {
  filter?: InputMaybe<PeopleFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<PeopleSortByEnum>;
};


export type QueryPhoneNumbersArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryPublicDocumentArgs = {
  token: Scalars['String'];
};


export type QueryPublicSignatureArgs = {
  token: Scalars['String'];
};


export type QueryPurchasesArgs = {
  filter?: InputMaybe<InvoicesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<InvoicesSortByEnum>;
};


export type QueryRefundsArgs = {
  filter?: InputMaybe<CreditNotesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CreditNotesSortByEnum>;
};


export type QuerySalaryReportsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QuerySalarySlipsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QuerySuppliersArgs = {
  filter?: InputMaybe<FreelanceProfilesFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<FreelanceProfilesSortByEnum>;
};


export type QueryUserDocumentsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};


export type QueryUserInvoiceImportsArgs = {
  filter?: InputMaybe<UserInvoiceImportsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type RangeAttributes = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type Receipt = Node & {
  __typename?: 'Receipt';
  file: Attachment;
  id: Scalars['ID'];
};

export type ReceiptAttributes = {
  file: Base64AttachmentAttributes;
};

export type ReferencePerson = {
  __typename?: 'ReferencePerson';
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
};

export type ReferencePersonAttributes = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  referenceNumber?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of RefuseDocument */
export type RefuseDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of RefuseDocument. */
export type RefuseDocumentPayload = {
  __typename?: 'RefuseDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of RejectJobApplication */
export type RejectJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectJobApplication. */
export type RejectJobApplicationPayload = {
  __typename?: 'RejectJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

/** Autogenerated input type of ResetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailField'];
  password: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of ResolveInvoice */
export type ResolveInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ResolveInvoice. */
export type ResolveInvoicePayload = {
  __typename?: 'ResolveInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type SalaryReport = Node & {
  __typename?: 'SalaryReport';
  id: Scalars['ID'];
  payoutAmount: Scalars['Int'];
  pdfDocument?: Maybe<Attachment>;
  salaryTaxAmount: Scalars['Int'];
  year: Scalars['Int'];
};

export type SalaryReportPagination = {
  __typename?: 'SalaryReportPagination';
  items: Array<SalaryReport>;
  total: Scalars['Int'];
};

export type SalarySlip = Node & {
  __typename?: 'SalarySlip';
  id: Scalars['ID'];
  payoutAmount: Scalars['Int'];
  payoutDate: Scalars['String'];
  pdfDocument?: Maybe<Attachment>;
  salaryTax: Scalars['Float'];
  salaryTaxAmount: Scalars['Int'];
};

export type SalarySlipPagination = {
  __typename?: 'SalarySlipPagination';
  items: Array<SalarySlip>;
  total: Scalars['Int'];
};

/** Autogenerated input type of SendCreditNoteViaEhf */
export type SendCreditNoteViaEhfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendCreditNoteViaEhf. */
export type SendCreditNoteViaEhfPayload = {
  __typename?: 'SendCreditNoteViaEhfPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendCreditNoteViaEmail */
export type SendCreditNoteViaEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendCreditNoteViaEmail. */
export type SendCreditNoteViaEmailPayload = {
  __typename?: 'SendCreditNoteViaEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendDocument */
export type SendDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendDocument. */
export type SendDocumentPayload = {
  __typename?: 'SendDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendDocumentVersion */
export type SendDocumentVersionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  recipients: SendDocumentVersionRecipientsEnum;
};

/** Autogenerated return type of SendDocumentVersion. */
export type SendDocumentVersionPayload = {
  __typename?: 'SendDocumentVersionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

export enum SendDocumentVersionRecipientsEnum {
  NotSent = 'NOT_SENT',
  NotSigned = 'NOT_SIGNED'
}

/** Autogenerated input type of SendInvoiceViaEhf */
export type SendInvoiceViaEhfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendInvoiceViaEhf. */
export type SendInvoiceViaEhfPayload = {
  __typename?: 'SendInvoiceViaEhfPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendInvoiceViaEmail */
export type SendInvoiceViaEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SendInvoiceViaEmail. */
export type SendInvoiceViaEmailPayload = {
  __typename?: 'SendInvoiceViaEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendPasswordResetInstructions */
export type SendPasswordResetInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailField'];
};

/** Autogenerated return type of SendPasswordResetInstructions. */
export type SendPasswordResetInstructionsPayload = {
  __typename?: 'SendPasswordResetInstructionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendSmsConfirmation */
export type SendSmsConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

/** Autogenerated return type of SendSmsConfirmation. */
export type SendSmsConfirmationPayload = {
  __typename?: 'SendSmsConfirmationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SendSupportMessage */
export type SendSupportMessageInput = {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  freelanceProfileId: Scalars['ID'];
  message: Scalars['String'];
};

/** Autogenerated return type of SendSupportMessage. */
export type SendSupportMessagePayload = {
  __typename?: 'SendSupportMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type Session = {
  __typename?: 'Session';
  account: Account;
  accounts: Array<Account>;
  administrator?: Maybe<Scalars['String']>;
  hasFreelanceProfiles: Scalars['Boolean'];
  hasIndividualFreelanceProfile: Scalars['Boolean'];
  phoneNumber?: Maybe<PhoneNumber>;
  user: User;
};

/** Autogenerated input type of SetDefaultBankAccount */
export type SetDefaultBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SetDefaultBankAccount. */
export type SetDefaultBankAccountPayload = {
  __typename?: 'SetDefaultBankAccountPayload';
  bankAccount?: Maybe<BankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of SetEnrollment */
export type SetEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  freelanceProfileId: Scalars['ID'];
  partnerAccountId: Scalars['ID'];
};

/** Autogenerated return type of SetEnrollment. */
export type SetEnrollmentPayload = {
  __typename?: 'SetEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enrollment?: Maybe<Enrollment>;
  errors: Array<Error>;
};

export type SignDocumentAttributes = {
  customValues?: InputMaybe<Array<DocumentValueAttributes>>;
  digitalSignature?: InputMaybe<Base64AttachmentAttributes>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of SignDocument */
export type SignDocumentInput = {
  attributes: SignDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SignDocument. */
export type SignDocumentPayload = {
  __typename?: 'SignDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type Signature = Node & {
  __typename?: 'Signature';
  clientName?: Maybe<Scalars['String']>;
  customFields: Array<DocumentField>;
  customValues: Array<DocumentValue>;
  designation: Scalars['String'];
  document: Document;
  documentRecipient?: Maybe<DocumentRecipient>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notifiedAt?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  refusedAt?: Maybe<Scalars['String']>;
  signatureNumber: Scalars['Int'];
  signedAt?: Maybe<Scalars['String']>;
  status: SignatureStatusEnum;
  userId?: Maybe<Scalars['ID']>;
};

export type SignatureAttributes = {
  designation: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum SignatureStatusEnum {
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Signed = 'SIGNED'
}

export type SignatureTemplate = {
  __typename?: 'SignatureTemplate';
  designation: Scalars['String'];
};

/** Autogenerated input type of SubmitDocument */
export type SubmitDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SubmitDocument. */
export type SubmitDocumentPayload = {
  __typename?: 'SubmitDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of SubmitInvoice */
export type SubmitInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SubmitInvoice. */
export type SubmitInvoicePayload = {
  __typename?: 'SubmitInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

/** Autogenerated input type of SubmitJobApplication */
export type SubmitJobApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SubmitJobApplication. */
export type SubmitJobApplicationPayload = {
  __typename?: 'SubmitJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

/** Autogenerated input type of SwitchAccount */
export type SwitchAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of SwitchAccount. */
export type SwitchAccountPayload = {
  __typename?: 'SwitchAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  account?: Maybe<Account>;
  jobCheck?: Maybe<JobCheck>;
  signature?: Maybe<Signature>;
  status: TaskStatusEnum;
  taskType: TaskTypeEnum;
};

export type TaskReport = WorkReport & {
  __typename?: 'TaskReport';
  endDate: Scalars['String'];
  hours: Scalars['Float'];
  startDate: Scalars['String'];
  type: WorkReportTypeEnum;
};

export enum TaskStatusEnum {
  Done = 'DONE',
  Todo = 'TODO'
}

export enum TaskTypeEnum {
  JobCheck = 'JOB_CHECK',
  Signature = 'SIGNATURE'
}

export type TerminateDocumentAttributes = {
  terminatedCopyRecipients?: InputMaybe<Array<Scalars['String']>>;
  terminatedOn: Scalars['String'];
  terminationReason: Scalars['String'];
};

/** Autogenerated input type of TerminateDocument */
export type TerminateDocumentInput = {
  attributes: TerminateDocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of TerminateDocument. */
export type TerminateDocumentPayload = {
  __typename?: 'TerminateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

export type TimeReport = WorkReport & {
  __typename?: 'TimeReport';
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  type: WorkReportTypeEnum;
};

export type Transfer = Node & {
  __typename?: 'Transfer';
  amount: Scalars['Int'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fromFinanceAccount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  toFinanceAccount?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TransferDocumentRecipients */
export type TransferDocumentRecipientsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentVersionId: Scalars['ID'];
  excludeTerminated?: InputMaybe<Scalars['Boolean']>;
  previousDocumentVersionId?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of TransferDocumentRecipients. */
export type TransferDocumentRecipientsPayload = {
  __typename?: 'TransferDocumentRecipientsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

/** Autogenerated input type of UnarchiveFreelanceProfile */
export type UnarchiveFreelanceProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveFreelanceProfile. */
export type UnarchiveFreelanceProfilePayload = {
  __typename?: 'UnarchiveFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  freelanceProfile: FreelanceProfile;
};

export type UpdateAccountAttributes = {
  description?: InputMaybe<Scalars['String']>;
  facebookLink?: InputMaybe<Scalars['String']>;
  industrySector?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  linkedinLink?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  twitterLink?: InputMaybe<Scalars['String']>;
  webhookUrl?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateAccount */
export type UpdateAccountInput = {
  attributes: UpdateAccountAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAccount. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

export type UpdateClientAttributes = {
  address?: InputMaybe<AddressAttributes>;
  autoSendEhf?: InputMaybe<Scalars['Boolean']>;
  autoSendEmail?: InputMaybe<Scalars['Boolean']>;
  clientNumber?: InputMaybe<Scalars['Int']>;
  clientType?: InputMaybe<ClientTypeEnum>;
  email?: InputMaybe<Scalars['String']>;
  friendlyName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nationalRegisterCheck?: InputMaybe<Scalars['Boolean']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of UpdateClient */
export type UpdateClientInput = {
  attributes: UpdateClientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateClient. */
export type UpdateClientPayload = {
  __typename?: 'UpdateClientPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocumentGroup */
export type UpdateDocumentGroupInput = {
  attributes: DocumentGroupAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateDocumentGroup. */
export type UpdateDocumentGroupPayload = {
  __typename?: 'UpdateDocumentGroupPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentGroup?: Maybe<DocumentGroup>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocument */
export type UpdateDocumentInput = {
  attributes: DocumentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateDocument. */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocumentRecipient */
export type UpdateDocumentRecipientInput = {
  attributes: DocumentRecipientAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateDocumentRecipient. */
export type UpdateDocumentRecipientPayload = {
  __typename?: 'UpdateDocumentRecipientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentRecipient?: Maybe<DocumentRecipient>;
  errors: Array<Error>;
};

/** Autogenerated input type of UpdateDocumentVersion */
export type UpdateDocumentVersionInput = {
  attributes: DocumentVersionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateDocumentVersion. */
export type UpdateDocumentVersionPayload = {
  __typename?: 'UpdateDocumentVersionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

export type UpdateFreelanceProfileAttributes = {
  address?: InputMaybe<AddressAttributes>;
  bankAccount?: InputMaybe<BankAccountAttributes>;
  invoiceLogo?: InputMaybe<Base64AttachmentAttributes>;
  invoiceStartingNumber?: InputMaybe<Scalars['Int']>;
  journalClosedMonth?: InputMaybe<Scalars['String']>;
  journalFormat?: InputMaybe<JournalFormatEnum>;
  referencePeople?: InputMaybe<Array<ReferencePersonAttributes>>;
};

/** Autogenerated input type of UpdateFreelanceProfile */
export type UpdateFreelanceProfileInput = {
  attributes: UpdateFreelanceProfileAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateFreelanceProfile. */
export type UpdateFreelanceProfilePayload = {
  __typename?: 'UpdateFreelanceProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  freelanceProfile?: Maybe<FreelanceProfile>;
};

export type UpdateInvoiceAttributes = {
  bankAccountId?: InputMaybe<Scalars['ID']>;
  buyerReference?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['ID']>;
  clientReferencePerson?: InputMaybe<ReferencePersonAttributes>;
  dueOn?: InputMaybe<Scalars['String']>;
  freelanceProfileId?: InputMaybe<Scalars['ID']>;
  invoicedOn?: InputMaybe<Scalars['String']>;
  lines?: InputMaybe<Array<BulkInvoiceLineAttributes>>;
  orderReference?: InputMaybe<Scalars['String']>;
  senderReferencePerson?: InputMaybe<ReferencePersonAttributes>;
};

/** Autogenerated input type of UpdateInvoice */
export type UpdateInvoiceInput = {
  attributes: UpdateInvoiceAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateInvoiceLineAttributes = {
  addressFrom?: InputMaybe<Scalars['String']>;
  addressTo?: InputMaybe<Scalars['String']>;
  collapsed?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  dietNonTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  dietTaxableUnitPrice?: InputMaybe<Scalars['Int']>;
  lineType?: InputMaybe<LineTypeEnum>;
  occupationCodeId?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unitPrice?: InputMaybe<Scalars['Int']>;
  vatRate?: InputMaybe<Scalars['Float']>;
  workInvoiceLineId?: InputMaybe<Scalars['ID']>;
  workReport?: InputMaybe<WorkReportAttributes>;
};

/** Autogenerated input type of UpdateInvoiceLine */
export type UpdateInvoiceLineInput = {
  attributes: UpdateInvoiceLineAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateInvoiceLine. */
export type UpdateInvoiceLinePayload = {
  __typename?: 'UpdateInvoiceLinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoiceLine?: Maybe<InvoiceLine>;
};

/** Autogenerated return type of UpdateInvoice. */
export type UpdateInvoicePayload = {
  __typename?: 'UpdateInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  invoice?: Maybe<Invoice>;
};

export type UpdateJobApplicationAttributes = {
  applicationFormAnswers?: InputMaybe<Array<FormAnswerAttributes>>;
};

/** Autogenerated input type of UpdateJobApplication */
export type UpdateJobApplicationInput = {
  attributes: UpdateJobApplicationAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateJobApplication. */
export type UpdateJobApplicationPayload = {
  __typename?: 'UpdateJobApplicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  jobApplication?: Maybe<JobApplication>;
};

export type UpdateJobAttributes = {
  address?: InputMaybe<AddressAttributes>;
  applicationDeadline?: InputMaybe<Scalars['String']>;
  applicationForm?: InputMaybe<FormAttributes>;
  applicationLimit?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<PartnerColorsAttributes>;
  compensation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  documentGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  enrollmentCallback?: InputMaybe<Scalars['Boolean']>;
  jobChecks?: InputMaybe<Array<JobCheckConfigAttributes>>;
  jobType?: InputMaybe<Scalars['String']>;
  languageRequirements?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Base64AttachmentAttributes>;
  payout?: InputMaybe<Scalars['String']>;
  privacyLink?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<Scalars['String']>;
  termsLink?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateJob */
export type UpdateJobInput = {
  attributes: UpdateJobAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateJob. */
export type UpdateJobPayload = {
  __typename?: 'UpdateJobPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  job?: Maybe<Job>;
};

export type UpdateTransferAttributes = {
  amount?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fromFinanceAccount?: InputMaybe<Scalars['String']>;
  toFinanceAccount?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateTransfer */
export type UpdateTransferInput = {
  attributes: UpdateTransferAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UpdateTransfer. */
export type UpdateTransferPayload = {
  __typename?: 'UpdateTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  transfer?: Maybe<Transfer>;
};

export type UpdateUserAttributes = {
  defaultAccountId?: InputMaybe<Scalars['ID']>;
  language?: InputMaybe<LanguageEnum>;
  marketingConsent?: InputMaybe<Scalars['Boolean']>;
  necessaryCookiesConsent?: InputMaybe<Scalars['Boolean']>;
  occupation?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UpdateUserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UploadDocumentRecipients */
export type UploadDocumentRecipientsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  csv: Base64AttachmentAttributes;
  documentVersionId: Scalars['ID'];
};

/** Autogenerated return type of UploadDocumentRecipients. */
export type UploadDocumentRecipientsPayload = {
  __typename?: 'UploadDocumentRecipientsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentVersion?: Maybe<DocumentVersion>;
  errors: Array<Error>;
};

/** Autogenerated input type of UploadPeople */
export type UploadPeopleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  csv: Base64AttachmentAttributes;
};

/** Autogenerated return type of UploadPeople. */
export type UploadPeoplePayload = {
  __typename?: 'UploadPeoplePayload';
  account?: Maybe<Account>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of UseSessionOtp */
export type UseSessionOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  otp: Scalars['String'];
};

/** Autogenerated return type of UseSessionOtp. */
export type UseSessionOtpPayload = {
  __typename?: 'UseSessionOtpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  errors: Array<Error>;
  token?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  confirmed: Scalars['Boolean'];
  defaultAccount?: Maybe<Account>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language: LanguageEnum;
  lastName?: Maybe<Scalars['String']>;
  marketingConsent: Scalars['Boolean'];
  necessaryCookiesConsent: Scalars['Boolean'];
  occupation?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
};

export type UserInvoiceImport = Node & {
  __typename?: 'UserInvoiceImport';
  createdAt: Scalars['String'];
  events: Array<Event>;
  file: Attachment;
  id: Scalars['ID'];
  importNumber: Scalars['Int'];
  name: Scalars['String'];
  status: UserInvoiceImportStatusEnum;
};

export type UserInvoiceImportAttributes = {
  file: Base64AttachmentAttributes;
  name: Scalars['String'];
};

export type UserInvoiceImportPagination = {
  __typename?: 'UserInvoiceImportPagination';
  items: Array<UserInvoiceImport>;
  total: Scalars['Int'];
};

export enum UserInvoiceImportStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type UserInvoiceImportsFilter = {
  createdAtFrom?: InputMaybe<Scalars['String']>;
  createdAtTo?: InputMaybe<Scalars['String']>;
  importNumber?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<UserInvoiceImportStatusEnum>;
};

/** Autogenerated input type of ValidatePasswordResetToken */
export type ValidatePasswordResetTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailField'];
  token: Scalars['String'];
};

/** Autogenerated return type of ValidatePasswordResetToken. */
export type ValidatePasswordResetTokenPayload = {
  __typename?: 'ValidatePasswordResetTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of VerifyPhoneNumber */
export type VerifyPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  number: Scalars['String'];
  token: Scalars['String'];
};

/** Autogenerated return type of VerifyPhoneNumber. */
export type VerifyPhoneNumberPayload = {
  __typename?: 'VerifyPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Error>;
};

/** Autogenerated input type of VoidDocument */
export type VoidDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of VoidDocument. */
export type VoidDocumentPayload = {
  __typename?: 'VoidDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  errors: Array<Error>;
};

/** Autogenerated input type of VoidDocumentRecipient */
export type VoidDocumentRecipientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of VoidDocumentRecipient. */
export type VoidDocumentRecipientPayload = {
  __typename?: 'VoidDocumentRecipientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentRecipient?: Maybe<DocumentRecipient>;
  errors: Array<Error>;
};

export type WorkInvoiceLine = InvoiceLine & Node & {
  __typename?: 'WorkInvoiceLine';
  amount: Scalars['Int'];
  collapsed: Scalars['Boolean'];
  dependentLines: Array<InvoiceLine>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  lineType: LineTypeEnum;
  occupationCode: OccupationCode;
  quantity: Scalars['Float'];
  remainingQuantity: Scalars['Float'];
  unitPrice: Scalars['Int'];
  vat: Scalars['Int'];
  vatRate: Scalars['Float'];
  workReport: WorkReport;
};

export type WorkReport = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  type: WorkReportTypeEnum;
};

export type WorkReportAttributes = {
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  type: WorkReportTypeEnum;
};

export enum WorkReportTypeEnum {
  Task = 'TASK',
  Time = 'TIME'
}
