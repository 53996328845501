/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  INVOICE_FRAGMENT,
  InvoiceFragment,
  ERROR_FRAGMENT,
  ErrorFragment,
} from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const RESOLVE_INVOICE = gql`
  mutation ($id: ID!) {
    resolveInvoice(input: { id: $id }) {
      invoice {
        ...InvoiceFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

interface Payload {
  invoice?: InvoiceFragment | null;
  errors: ErrorFragment[];
}

interface ResolveInvoiceMutation {
  resolveInvoice: Payload | null;
}

export const useResolveInvoiceMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.resolvedInvoice",
    "Invoice was resolved successfully"
  );
  // Values for extraction
  // t("common:success.resolvedInvoice", "Invoice was resolved successfully")

  const [mutation, { data, loading, called }] = useMutation<
    ResolveInvoiceMutation,
    { id: string }
  >(RESOLVE_INVOICE);

  const resolveInvoice = (
    id: string,
    onSuccess?: (response: InvoiceFragment) => void
  ) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.resolveInvoice?.errors ?? [];
        const invoice = data?.resolveInvoice?.invoice;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => invoice && onSuccess && onSuccess(invoice),
          onRetry: () => resolveInvoice(id, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    resolveInvoice,
    isLoading: loading,
    called,
    invoice: data?.resolveInvoice?.invoice,
    errors: data?.resolveInvoice?.errors ?? [],
  };
};
