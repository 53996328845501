import { Control, Controller, useForm, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HiLockClosed } from "react-icons/hi";

import tw from "tw-generated";
import { Trans, useTranslation } from "translations";
import { FreelanceTypeEnum } from "graphql/types";
import {
  useCreateFreelanceProfileMutation,
  useUpdateFreelanceProfileMutation,
} from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { FreelanceProfileFragment, IdentityFragment } from "graphql/fragments";
import { Divider, Input } from "components/common/basic";
import { AddressSearch, BankAccountSection } from "components/common/composite";
import {
  bankAccountNumberValidationSchema,
  getAlphanumericValueFromString,
} from "utils";

interface FormValues extends FreelanceProfileFragment {
  personalNumber: string;
}

export interface Props {
  onSuccessfulEdit: (freelanceProfile: FreelanceProfileFragment) => void;
  formId: string;
  freelanceProfile?: FreelanceProfileFragment;
  identity?: IdentityFragment;
}

export default ({
  onSuccessfulEdit,
  formId,
  freelanceProfile,
  identity,
}: Props): JSX.Element => {
  const showBankAccount = !freelanceProfile;

  const { createFreelanceProfile } = useCreateFreelanceProfileMutation();
  const { updateFreelanceProfile } = useUpdateFreelanceProfileMutation();

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    personalNumber: Yup.string().test(
      "isValidPersonalNumber",
      t(
        "freelanceProfileForm_individual.error.notValidPersonalNumber",
        "Please provide a valid personal number"
      ),
      (value: unknown) => {
        if (identity) return true;

        if (typeof value !== "string") return false;

        const validFormats = {
          NO: /^\d{11}$/,
          SE: /^(\d{6}|\d{8})[-+]\d{4}$/,
        };
        return Object.values(validFormats).some((regex) => regex.test(value));
      }
    ),
    address: Yup.object()
      .shape({
        line1: Yup.string().required(),
        postalCode: Yup.string().required(),
        city: Yup.string().required(),
        country: Yup.string().required(),
      })
      .required()
      .nullable(),
    ...(showBankAccount
      ? {
          defaultBankAccount: Yup.object({
            accountNumber: bankAccountNumberValidationSchema(t),
          }),
        }
      : {}),
  });

  const defaultValues = {
    id: freelanceProfile?.id,
    freelanceType: FreelanceTypeEnum.Individual,
    firstName: identity?.firstName ?? "",
    lastName: identity?.lastName ?? "",
    personalNumber: identity ? "**********" : "",
    address: freelanceProfile?.address ?? {
      line1: "",
      postalCode: "",
      city: "",
      country: "",
    },
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const onSubmit = ({
    firstName,
    lastName,
    personalNumber,
    address,
    defaultBankAccount,
  }: FormValues) => {
    const attributes = {
      ...(identity ? {} : { firstName, lastName, personalNumber }),
      address: {
        ...address,
        line1: address?.line1 ?? "",
        postalCode: address?.postalCode ?? "",
        city: address?.city ?? "",
        country: address?.country ?? "",
      },
    };

    if (freelanceProfile?.id)
      updateFreelanceProfile(
        { id: freelanceProfile.id, attributes },
        onSuccessfulEdit
      );
    else {
      if (!defaultBankAccount) return;
      const createAttributes = {
        ...attributes,
        bankAccount: {
          ...defaultBankAccount,
          accountNumber: getAlphanumericValueFromString(
            defaultBankAccount.accountNumber
          ),
          default: true,
        },
      };

      createFreelanceProfile(
        {
          attributes: {
            freelanceType: FreelanceTypeEnum.Individual,
            ...createAttributes,
          },
        },
        onSuccessfulEdit
      );
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={tw("w-full", "flex", "flex-col", "space-y-4")}
    >
      <h2 className={tw("text-lg", "font-bold", "text-gray-900")}>
        <Trans
          ns="account"
          i18nKey="freelanceProfileForm_individual.cardHeading"
        >
          Let's get your freelance profile up and running
        </Trans>
      </h2>

      <p className={tw("text-sm")}>
        <Trans
          ns="account"
          i18nKey="freelanceProfileForm_individual.cardSubHeading"
        >
          Please provide the information below to secure that we are able to pay
          you
        </Trans>
      </p>

      <Input
        id="firstName"
        {...register("firstName")}
        label={t(
          "freelanceProfileForm_individual.firstName.label",
          "First Name"
        )}
        TrailingIcon={identity && HiLockClosed}
        disabled={Boolean(identity)}
        errorMessage={errors.firstName?.message}
      />

      <Input
        id="lastName"
        {...register("lastName")}
        label={t("freelanceProfileForm_individual.lastName.label", "Last Name")}
        TrailingIcon={identity && HiLockClosed}
        disabled={Boolean(identity)}
        errorMessage={errors.lastName?.message}
      />

      <Input
        id="personalNumber"
        {...register("personalNumber")}
        label={t(
          "freelanceProfileForm_individual.personalNumber.label",
          "Personal number"
        )}
        TrailingIcon={identity && HiLockClosed}
        disabled={Boolean(identity)}
        errorMessage={errors.personalNumber?.message}
      />

      <Controller
        control={control}
        name="address"
        render={({ field }) => (
          <AddressSearch
            label={t(
              "freelanceProfileForm_individual.address.label",
              "Your home address"
            )}
            address={field.value}
            setAddress={field.onChange}
            errorMessage={
              errors.address &&
              t(
                "freelanceProfileForm_individual.address.error.required",
                "Address is required"
              )
            }
          />
        )}
      />

      {showBankAccount && (
        <>
          <Divider />

          <BankAccountSection
            // TODO: Find a way to prevent typecasting
            control={control as unknown as Control<FreelanceProfileFragment>}
            register={
              register as unknown as UseFormRegister<FreelanceProfileFragment>
            }
            bankAccountErrors={errors.defaultBankAccount}
          />
        </>
      )}
    </form>
  );
};
