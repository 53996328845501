import tw from "tw-generated";
import { useTranslation } from "translations";
import { Button, CardBody, CheckBox, Link } from "components/common/basic";
import { HiOutlineDownload } from "react-icons/hi";

interface Props {
  onChecked: () => void;
  isOrganization: boolean;
  isChecked: boolean;
}

export default ({
  onChecked,
  isOrganization,
  isChecked,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <CardBody
      className={tw("items-stretch", "bg-blue-100", "rounded-xl", "m-4", "p-4")}
    >
      <div className={tw("flex", "items-center")}>
        <HiOutlineDownload
          className={tw("mr-3", "w-5", "h-5", "text-deepBlue-900")}
        />
        <h2 className={tw("text-lg", "font-bold")}>
          {t(
            "popup.freelanceProfile.freelanceProfileAgreement.title",
            "Download Manymore freelance agreement"
          )}
        </h2>
      </div>

      <p>
        {t(
          "popup.freelanceProfile.freelanceProfileAgreement.heading",
          "Please download and read through the document for the signing button to become active. Once signed, your freelance profile is active and you can send invoices through Manymore.com."
        )}
      </p>
      <div>
        <Link
          id="freelance_profile_agreement-go_to_agreement"
          isExternal
          to={
            isOrganization
              ? t(
                  "popup.freelanceProfile.freelanceProfileAgreement_organization.linkToDocument",
                  "https://core.manymore.com/agreement_organization.pdf"
                )
              : t(
                  "popup.freelanceProfile.freelanceProfileAgreement_individual.linkToDocument",
                  "https://core.manymore.com/agreement_individual.pdf"
                )
          }
          unstyled
          onClick={onChecked}
        >
          <Button
            variant="secondary"
            id="freelance_profile_agreement-go_to_agreement"
            isDiv
          >
            <CheckBox
              id={"freelance_profile_agreement-go_to_agreement"}
              name="checkbox icon"
              checked={isChecked}
              isNonClickable
              label={t(
                "popup.freelanceProfile.freelanceProfileAgreement.documentName",
                "Read samarbeidsavtale.pdf"
              )}
            />
          </Button>
        </Link>
      </div>
    </CardBody>
  );
};
