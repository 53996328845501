import { useRef, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { QueryPhoneNumbersArgs } from "graphql/types";

import { PHONE_NUMBER_FRAGMENT, PhoneNumberFragment } from "graphql/fragments";

import { PaginatedPayload } from "./shared/types";

const PHONE_NUMBERS = gql`
  query ($page: Int, $perPage: Int) {
    phoneNumbers(page: $page, perPage: $perPage) {
      items {
        ...PhoneNumberFragment
      }
      total
    }
  }
  ${PHONE_NUMBER_FRAGMENT}
`;

export type PhoneNumbersQuery = {
  phoneNumbers: PaginatedPayload<PhoneNumberFragment>;
};

type QueryArgs = Pick<QueryPhoneNumbersArgs, "page" | "perPage">;

export const usePhoneNumbersQuery = (
  args: QueryArgs = {}
): {
  phoneNumbers: PhoneNumberFragment[];
  isLoading: boolean;
} => {
  const { data, loading: isLoading } = useQuery<PhoneNumbersQuery, QueryArgs>(
    PHONE_NUMBERS,
    { variables: { ...args } }
  );

  const phoneNumbers = data?.phoneNumbers.items?.filter(Boolean) ?? [];

  return { phoneNumbers, isLoading };
};

export const usePhoneNumbersLazyQuery = (): {
  getPhoneNumbers: (
    args?: QueryArgs
  ) => Promise<PhoneNumberFragment[] | undefined>;
  phoneNumbers: PhoneNumberFragment[];
  isLoading: boolean;
  called: boolean;
} => {
  const [getPhoneNumbersQuery, { data, loading: isLoading, called }] =
    useLazyQuery<PhoneNumbersQuery, QueryArgs>(PHONE_NUMBERS, {
      fetchPolicy: "network-only",
    });

  const phoneNumbers = data?.phoneNumbers.items?.filter(Boolean) ?? [];

  const resolveRef = useRef<(phoneNumbers?: PhoneNumberFragment[]) => void>();

  useEffect(() => {
    if (called && !isLoading && resolveRef.current) {
      resolveRef.current(phoneNumbers);
      resolveRef.current = undefined;
    }
  }, [phoneNumbers, called, isLoading]);

  const getPhoneNumbers = async (args?: QueryArgs) => {
    getPhoneNumbersQuery({ variables: args });

    return new Promise<PhoneNumberFragment[] | undefined>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  return { getPhoneNumbers, phoneNumbers, isLoading, called };
};
