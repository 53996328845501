import { IconType } from "react-icons";

import { tw } from "tw-generated";

type BadgeSize = "sm" | "lg";
type BadgeVariant = "default" | "pending" | "success" | "warning" | "error";

interface Props {
  size?: BadgeSize;
  variant?: BadgeVariant;
  isRounded?: boolean;
  LeadingIcon?: IconType;
  text?: string;
}

export default ({
  variant = "default",
  size = "sm",
  isRounded = false,
  LeadingIcon,
  text,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const small = tw("py-0.5", "px-2.5", "text-xs");
  const large = tw("py-2", "px-3", "text-sm");

  const base = tw(
    "inline-flex",
    "items-center",
    "justify-center",
    "space-x-1",
    isRounded ? "rounded-full" : "rounded",
    "font-semibold",
    {
      [small]: size === "sm",
      [large]: size === "lg",
    }
  );

  const badgeStyles = (() => {
    switch (variant) {
      case "default":
        return tw(base, "bg-badge-defaultBg", "text-badge-defaultText");

      case "pending":
        return tw(base, "bg-badge-pendingBg", "text-badge-pendingText");

      case "success":
        return tw(base, "bg-badge-successBg", "text-badge-successText");

      case "warning":
        return tw(base, "bg-badge-warningBg", "text-badge-warningText");

      case "error":
        return tw(base, "bg-badge-errorBg", "text-badge-errorText");

      default:
        return base;
    }
  })();

  return (
    <div className={badgeStyles}>
      {LeadingIcon && <LeadingIcon size={16} />}

      {text && <span>{text}</span>}
    </div>
  );
};
