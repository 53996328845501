import { EventTypeEnum, InvoiceStatusEnum } from "graphql/types";

export default (eventType: EventTypeEnum): InvoiceStatusEnum => {
  switch (eventType) {
    case EventTypeEnum.InvoiceApproved:
      return InvoiceStatusEnum.Approved;
    case EventTypeEnum.InvoicePublished:
      return InvoiceStatusEnum.Published;
    case EventTypeEnum.InvoiceRejected:
      return InvoiceStatusEnum.Rejected;
    case EventTypeEnum.InvoiceResolved:
      return InvoiceStatusEnum.Resolved;
    case EventTypeEnum.InvoiceSubmitted:
      return InvoiceStatusEnum.Submitted;
    case EventTypeEnum.InvoiceUnapproved:
      return InvoiceStatusEnum.Submitted;

    case EventTypeEnum.InvoiceCredited:
    case EventTypeEnum.InvoiceVoided:
    case EventTypeEnum.InvoiceNullified:
      return InvoiceStatusEnum.Nullified;

    case EventTypeEnum.InvoiceHidden:
    case EventTypeEnum.InvoiceApprovedForPayout:
    case EventTypeEnum.InvoiceUnapprovedForPayout:
    case EventTypeEnum.InvoiceSentManually:
    case EventTypeEnum.InvoiceSentViaEhf:
    case EventTypeEnum.InvoiceSentViaEmail:
    case EventTypeEnum.InvoicePaidOut:
    case EventTypeEnum.InvoiceRejectedForPayout:
    case EventTypeEnum.InvoiceUnpublished:

    case EventTypeEnum.UserInvoiceImportInProgress:
    case EventTypeEnum.UserInvoiceImportCompleted:
    case EventTypeEnum.UserInvoiceImportFailed:

    case EventTypeEnum.CreditNoteApproved:
    case EventTypeEnum.CreditNotePublished:
    case EventTypeEnum.CreditNoteRejected:
    case EventTypeEnum.CreditNoteResolved:
    case EventTypeEnum.CreditNoteSentManually:
    case EventTypeEnum.CreditNoteSentViaEhf:
    case EventTypeEnum.CreditNoteSentViaEmail:
    case EventTypeEnum.CreditNoteSubmitted:
    case EventTypeEnum.CreditNoteApprovedForDeduction:
    case EventTypeEnum.CreditNoteUnapprovedForDeduction:
    case EventTypeEnum.CreditNoteDeducted:
    case EventTypeEnum.CreditNoteRejectedForDeduction:

    case EventTypeEnum.JobCheckFailed:
    case EventTypeEnum.JobCheckInReview:
    case EventTypeEnum.JobCheckPending:
    case EventTypeEnum.JobCheckSucceeded:

    case EventTypeEnum.AMeldingAccepted:
    case EventTypeEnum.AMeldingApproved:
    case EventTypeEnum.AMeldingPaid:
    case EventTypeEnum.AMeldingRejected:
    case EventTypeEnum.AMeldingResolved:
    case EventTypeEnum.AMeldingScheduled:
    case EventTypeEnum.AMeldingSubmitted:
    case EventTypeEnum.AMeldingVoided:

    case EventTypeEnum.ExportedToFiken:
    case EventTypeEnum.AccountMerged:
    case EventTypeEnum.JournalCreated:
    case EventTypeEnum.AttachmentDownloaded:
    case EventTypeEnum.MagicLinkCreated:
    case EventTypeEnum.IdentityCreated:
    case EventTypeEnum.IdentityUpdated:
    case EventTypeEnum.UserUpdated:
      return InvoiceStatusEnum.Approved;

    default:
      return ((_eventType: never) => InvoiceStatusEnum.Draft)(eventType);
  }
};
