/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloError, gql, useMutation } from "@apollo/client";

import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";

const CREATE_CREDIT_NOTE = gql`
  mutation ($id: ID!) {
    sendCreditNoteViaEmail(input: { id: $id }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload {
  errors: ErrorFragment[];
}

interface SendCreditNoteViaEmailMutation {
  sendCreditNoteViaEmail: Payload | null;
}

export const useSendCreditNoteViaEmailMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.sentCreditNoteViaEmail",
    "Credit note was sent successfully via email"
  );
  // Values for extraction
  // t("common:success.sentCreditNoteViaEmail", "Credit note was sent successfully via email")

  const [mutation, { data, loading }] = useMutation<
    SendCreditNoteViaEmailMutation,
    { id: string }
  >(CREATE_CREDIT_NOTE);

  const sendCreditNoteViaEmail = (id: string, onSuccess?: () => void) =>
    mutation({ variables: { id } })
      .then(({ data, errors }) => {
        const dataErrors = data?.sendCreditNoteViaEmail?.errors ?? [];

        handleErrors({ dataErrors, graphqlErrors: errors, onSuccess });
      })
      .catch((_error: ApolloError) => null);

  return {
    sendCreditNoteViaEmail,
    isLoading: loading,
    errors: data?.sendCreditNoteViaEmail?.errors ?? [],
  };
};
