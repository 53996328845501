import { lazy } from "react";

const Agreement = lazy(() => import("./Agreement"));
const Document = lazy(() => import("./Document"));
const Documents = lazy(() => import("./Documents"));
const DocumentTypeSelector = lazy(() => import("./DocumentTypeSelector"));
const PublicDocument = lazy(() => import("./PublicDocument"));
const QuickSign = lazy(() => import("./QuickSign"));
const Signature = lazy(() => import("./Signature"));
const SignatureTestPage = lazy(() => import("./SignatureTestPage"));

export {
  Agreement,
  Document,
  Documents,
  DocumentTypeSelector,
  PublicDocument,
  Signature,
  SignatureTestPage,
  QuickSign,
};
