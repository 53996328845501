import tw from "tw-generated";
import logo from "assets/branding/manymore_icon.svg";

interface Props {
  size?: "sm" | "md";
}

export default ({ size = "md" }: Props) => {
  const small = tw("h-8", "w-8");
  const medium = tw("h-24", "w-24");

  const spinnerStyles = tw("animate-spin", {
    [small]: size === "sm",
    [medium]: size === "md",
  });

  return (
    <div className={tw("relative")}>
      {size === "md" && (
        <img
          className={tw("absolute", "h-11", "top-7", "left-6")}
          src={logo}
          alt="logo manymore"
        />
      )}
      <svg
        className={spinnerStyles}
        viewBox="0 0 220 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="110" cy="110" r="102" stroke="#222222" strokeWidth="15" />
        <path
          d="M212 110C212 53.667 166.333 8 110 8"
          stroke="#E6E8EB"
          strokeWidth="15"
        />
      </svg>
    </div>
  );
};
