import { useState } from "react";

import { PhoneNumberFragment } from "graphql/fragments";

import { ValidateSMSCodeForm } from "./ValidateSMSCodeForm";
import { SendSMSCodeForm } from "./SendSMSCodeForm";
import PopupTemplate from "../PopupTemplate";

interface Props {
  onClose: () => void;
  onSuccessfulVerification: () => void;
}

export default ({ onClose, onSuccessfulVerification }: Props): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumberFragment>();

  return (
    <PopupTemplate onClose={onClose}>
      {phoneNumber ? (
        <ValidateSMSCodeForm
          onClose={onClose}
          phoneNumber={phoneNumber}
          onSuccessfulValidation={onSuccessfulVerification}
        />
      ) : (
        <SendSMSCodeForm onClose={onClose} onSubmit={setPhoneNumber} />
      )}
    </PopupTemplate>
  );
};
